import MTZ from "moment-timezone";
import axios from "axios";
import { apiURL } from "../../appRedux/actions/helpers";
import moment from "moment";
import Feed, { EventType } from "@dxfeed/api";
import { updateBar } from "./candleControl";
// Setup config
const supportedResolutions = [
  "1S", "5S", "15S", "30S", "1", "3", "5", "15", "30", "60", "120", "240", "360", "D", "2D", "3D", "W"];
const config = {
  supported_resolutions: supportedResolutions,
};
let allBars = [];
const api_root =
  process.env["REACT_APP_RELEASE_STAGE"] === "development"
    // true
    ? `http://staging-backend.tradealgo.com`
    : `${apiURL}`;
const history = {};
let sub;
let secPrice = {
  last_price: "",
  bounds: { ticker: "" },
};
let socket;
const socket_url = process.env.REACT_APP_WEBSOCKET
socket = new WebSocket(socket_url);
// Trading View JS Datafeed
const FreeTierDatafeed = {
  onReady: (cb) => {
    setTimeout(() => cb(config), 0);
  },
  storeSec: (lastPrice) => {
    secPrice = lastPrice;
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    try {
      const results = [];
      const { data } = await axios.get(`${api_root}/stock/symbol/${userInput}`, { withCredentials: true })
      const availableSymbols = data;
      if (availableSymbols.length) {
        for (let i = 0; i <= availableSymbols.length; i++) {
          const data = availableSymbols[i];
          if (data) {
            let type = "Stock";
            if (data.type && data.type.includes("fund")) {
              type = "Fund";
            } else {
              if (data.type === "etf") {
                type = "Etf";
              } else {
                if (data.type === "common_stock") {
                  type = "Stock";
                }
              }
            }
            let formatted = {
              symbol: data.ticker,
              full_name: data.name,
              description: data.name,
              exchange: "US",
              ticker: data.ticker,
              type,
            };
            if (
              data.type === "common_stock" ||
              data.type === "etf" ||
              data.type === "mutual_fund"
            ) {
              results.push(formatted);
            }
          }
        }
        onResultReadyCallback(results);
      }
    } catch (error) {
      console.log(error, 'error searching symbols')
    }
  },

  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    // expects a symbolInfo object in response
    var symbol_stub = {
      isOption: symbolName.includes('OPTION_CONTRACT'),
      name: symbolName.replace("OPTION_CONTRACT", ""),
      description: symbolName.replace("OPTION_CONTRACT", ""),
      type: "stock",
      timezone: "Etc/UTC",
      ticker: symbolName.replace("OPTION_CONTRACT", ""),
      // exchange: 'NYSE',
      minmov: 1,
      pricescale: 100,
      has_weekly_and_monthly: false,
      has_seconds: true,
      seconds_multipliers: ["1", "5", "15", "30"],
      has_intraday: true,
      intraday_multipliers: ["1", "5", "15", "30", "60", "120", "240", "360"],
      supported_resolution: supportedResolutions,
      volume_precision: 1,
      data_status: "streaming",
    };
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      let { from, to, firstDataRequest } = periodParams;
      let qs = {
        // firstDataRequest,
        symbol: symbolInfo.name,
        resolution: resolution.replace("1D", "D"),
        fromTime: moment(from * 1000).subtract(resolution.includes("S") ? firstDataRequest ? 5 : 12 : 5, resolution.includes("S") ? "hours" : 'days').valueOf(),
        toTime: to * 1000,
        timespan: "m"
      };
      const sunday = moment().startOf('week').format("YYYY-MM-DD");
      let fromTime = moment(qs.fromTime).format("YYYY-MM-DD");
      if (moment(sunday).isSame(fromTime, 'day') && resolution.includes('S')) {
        qs.fromTime = moment(qs.fromTime).subtract(2, 'days').startOf("day").valueOf();
      }
      if (qs.resolution === "D") {
        // day
        qs.timespan = "d";
        qs.resolution = 1;
      } else if (resolution.includes("S")) {
        qs.timespan = 's'
        qs.resolution = resolution.replace("S", "")
      } else {
        //minute || hour
        qs.timespan = 'm';
        qs.resolution = qs.resolution;
        if (qs.resolution >= 60) {
          qs.timespan = 'h'
          qs.resolution = qs.resolution / 60;
        }
      }
      let uri;
      let symbol = qs.symbol;
      const timespanLookup = {
        's': 'second',
        'm': 'minute',
        "d": 'day',
        'h': 'hour'
      }
      const timespan = timespanLookup[qs.timespan]
      uri = `https://api.polygon.io/v2/aggs/ticker/${symbol}/range/${qs.resolution}/${timespan}/${qs.fromTime}/${qs.toTime}?adjusted=true&sort=desc&limit=50000&apiKey=jEcRSkiDiv2IBoMuerMCHPgM1CSrjZGf`
      const { data } = await axios.get(uri)
      let bars = [];
      if (!!data?.results) {
        bars = data.results.map((candle) => ({
          open: candle.o,
          high: candle.h,
          low: candle.l,
          close: candle.c,
          time: candle.t,
          volume: candle.v
        })).sort((a, b) => a.time - b.time);
      }
      if (bars.length) {
        if (firstDataRequest) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        }
        allBars = allBars.concat(bars).sort((a, b) => a.time - b.time);
        // return bars
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      console.log({ error });
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    let symbol = symbolInfo.name.replace("/", "");
    if (history[symbolInfo.name]) {
      const newSub = {
        symbol,
        subscribeUID,
        resolution,
        symbolInfo,
        lastBar: history[symbolInfo.name].lastBar,
        listener: onRealtimeCallback,
      };
      sub = newSub;
    }
    socket.send(
      JSON.stringify([`current_candle_delayed;${symbol}`])
    );
  },

  unsubscribeBars: (subscriberUID) => {
    allBars = [];
  },
};
socket.onopen = function (event) {
  console.log("socket opened");
};

socket.addEventListener("message", function (event) {
  if (event.data !== "pong") {
    const message = JSON.parse(event.data);
    if (message) {
      const data = message?.data;
      if (!!data) {
        const trades = JSON.parse(data)
        trades.forEach((el) => {
          let barData
          if (!!el.ticker) {
            barData = {
              symbol: el.ticker,
              price: Number(el.price),
              volume: Number(el.volume),
              time: Number(el.time),
            };
          }

          var _lastBar = updateBar(barData, sub);
          // send the most recent bar back to TV's realtimeUpdate callback
          if (allBars[allBars.length - 1]) {
            allBars[allBars.length - 1].close = Number(_lastBar.close);
          }
          _lastBar.close = Number(_lastBar.close);
          sub.listener(_lastBar);
          // update our own record of lastBar
          sub.lastBar = _lastBar;
        });
      }
    }
  }
});

export function getCurrentCandleSocket() {
  return socket;
}
export function getAllBars() {
  if (allBars[0] && !allBars[0]?.ticker?.includes("darkFlow")) {
    return allBars;
  } else {
    return [];
  }
}


export function getFreeSub() {
  if (!!sub?.lastBar) {
    return sub.lastBar
  } else {
    return {
      isLastBar: false,
      close: '',
      symbol: "",
    }
  }
}

export function getUnsub() {
  return FreeTierDatafeed.unsubscribeBars;
}


export function closeSocket() {
  if (socket.readyState === socket.OPEN) {
    socket.send(JSON.stringify([]))
  }
}


export default FreeTierDatafeed;
