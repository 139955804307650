import CompanyPrice from "./CompanyPrice";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import React, { useMemo } from "react";
import clsx from "clsx";
import moment from "moment";
import { numberWithCommas } from "../utilities";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightlightIcon from '@mui/icons-material/Nightlight';
import { Skeleton } from "@material-ui/lab";
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { useMedia } from "react-media";

const GLOBAL_MEDIA_QUERIES = {
  isTablet: "screen and (min-width: 1200px) and (max-width: 1600px)",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    backgroundColor: theme.palette.primary.chartBackground,
    position: "relative",
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.chartBackground,
    color: theme.palette.primary.text,
    // width: "98.8%",
    // width: '97%',
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    marginLeft: "4px",
  },
  background: {
    backgroundColor: theme.palette.primary.chartBackground,
    color: theme.palette.primary.text,
    height: "130px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  navLink: {
    marginTop: "60px",
    marginLeft: "0px",
    minWidth: "477px",
  },
  border: {
    borderLeft: `1px solid ${theme.palette.primary.slider}`,
    height: "151px",
    // marginTop: "17px",
    marginLeft: "15px",
    marginRight: "25px",
  },
  border2: {
    borderLeft: `1px solid ${theme.palette.primary.slider}`,
    height: "151px",
    // marginTop: "17px",
    marginRight: "10px",
  },
  hr: {
    marginLeft: "-27px",
    width: "174px",
    marginTop: 11,
    marginBottom: 10,
    borderColor: theme.palette.primary.slider,
  },
  seperator: {
    // marginLeft: "-15px",
    width: "275px",
    marginTop: 6,
    marginBottom: 6,
    borderColor: theme.palette.primary.slider,
  },
  borderColor: {
    borderColor: theme.palette.primary.slider,
  },
  ohlc: {
    color: theme.palette.primary.greyTitles,
    fontWeight: "600",
    fontSize: "14px",
  },
}));
const TabHeaders2 = (props) => {
  const classes = useStyles();
  const {
    tickerDetails,
    securityPrice,
    currentMarketStatus,
    symbols,
    lastPrice,
    setLastPrice,
  } = props;
  const { name, loading } = tickerDetails;
  const { isTablet } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  const isStockBeingTraded = useMemo(() => {
    const todayDate = moment.tz("America/New_York").format("YYYY-MM-DD");
    const secDate = moment
      .tz(securityPrice.date, "America/New_York")
      .format("YYYY-MM-DD");
    return todayDate === secDate;
  }, [securityPrice.date]);

  const marketStatus = useMemo(() => {
    if (currentMarketStatus === "open" && !securityPrice?.open_price) {
      return "pre-open";
    }
    if (currentMarketStatus === "after" && !securityPrice?.close_price) {
      return "open";
    }
    return currentMarketStatus;
  }, [currentMarketStatus, securityPrice]);
  const ohlc = useMemo(() => {
    const open = securityPrice?.open_price || 0;
    const high = securityPrice?.bounds?.high || 0;
    const low = securityPrice?.bounds?.low || 0;
    const close =
      marketStatus === "after" || marketStatus === "closed"
        ? securityPrice?.close_price || 0
        : securityPrice?.prev_close_price || 0;
    let compare = {
      "pre-open": parseFloat(
        securityPrice?.prev_close_price || 0 //
      ).toFixed(2),
      open: parseFloat(securityPrice?.prev_close_price || 0).toFixed(2),
      after: parseFloat(securityPrice?.prev_close_price || 0).toFixed(2),
      closed: parseFloat(securityPrice?.open_price || 0).toFixed(2),
    }[marketStatus];

    let lastPriceUsed = {
      "pre-open": parseFloat(lastPrice || securityPrice?.last_price || 0).toFixed(2),
      open: parseFloat(lastPrice || securityPrice?.last_price || 0).toFixed(2),
      after: parseFloat(!!securityPrice?.close_price ? securityPrice?.close_price : lastPrice || securityPrice?.last_price || 0).toFixed(2),
      closed: parseFloat(securityPrice?.close_price || 0).toFixed(2),
    }[marketStatus];

    return {
      open,
      high: lastPrice > high ? lastPrice : high,
      low: lastPrice < low ? lastPrice : low,
      close,
      closeType: !!securityPrice?.close_price ? "Close" : "Previous Close",
      performancePercent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
      positivePerformance: (((lastPriceUsed - compare) / compare) * 100) > 0,
    };
    // eslint-disable-next-line
  }, [JSON.stringify(securityPrice), marketStatus, lastPrice]);
  const bottomPerformance = useMemo(() => {

    let compare = {
      after: parseFloat(securityPrice?.close_price || 0).toFixed(2),
    }[marketStatus];

    let lastPriceUsed = {
      after: parseFloat(lastPrice || securityPrice?.last_price || 0).toFixed(2),
    }[marketStatus];

    const showedPrice = {
      after: lastPrice || securityPrice.last_price || 0,
    }[marketStatus]

    return {
      price: showedPrice,
      change: lastPriceUsed - compare,
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
    };
    // eslint-disable-next-line
  }, [
    marketStatus,
    securityPrice,
    symbols.value,
    lastPrice,
  ]);
  const bottomChange = useMemo(() => {
    return (
      <>
        {

          <span style={{ fontSize: '14px', marginTop: '3px' }}>
            {isTablet && (
              <>
                <br />
              </>
            )}
            <span className={clsx(classes.text, "text-bold")} style={{ marginLeft: '5px' }}>
              {(bottomPerformance?.price || 0).toFixed(2)}
            </span>
            <span className="text-bold" style={{
              marginLeft: '5px', color: String(bottomPerformance.percent).includes("-")
                ? "red"
                : "rgba(84, 202, 19, 1)",
            }}>
              {String(bottomPerformance.percent).includes("-") ? "" : "+ "}

              {bottomPerformance.change.toFixed(2)}
            </span>
            {
              bottomPerformance.change > 0 ? (
                <span style={{ marginLeft: '5px' }} className={`positive-text-color text-bold  price-change`}>
                  ({'+' + numberWithCommas(bottomPerformance?.percent || "")}%)
                </span>
              ) : (
                <span style={{ marginLeft: '5px' }} className={`negative-text-color text-bold  price-change`}>
                  ({numberWithCommas(bottomPerformance?.percent || "")}%)
                </span>
              )
            }
          </span>
        }
      </>
    );
  }, [bottomPerformance, isTablet]);

  const todaysDate = moment().format("dddd, MMMM D");
  const CompPrice = useMemo(() => {
    return <CompanyPrice
      lastPrice={lastPrice}
      setLastPrice={setLastPrice}
      ticker={symbols.value}
      marketStatus={marketStatus}
      securityPrice={securityPrice}
    />
    // eslint-disable-next-line
  }, [lastPrice, symbols.value, marketStatus, securityPrice])
  return (
    <div className={clsx("container", classes.root)}>
      <div className="row">
        <div
          className="col-lg-12 col-xl-3 col-md-12 col-sm-12 col-xs-12"
          style={{
            textAlign: "center",
            paddingTop: "19px",
          }}
        >
          <h4 className={clsx("text-bold", classes.text)}>{symbols.value}:US</h4>
          <span
            className={clsx(classes.text)}
            style={{
              color: "grey",
              fontSize: "1.05rem",
              position: "relative",
              top: "-2px",
            }}
          >
            {loading ? <Skeleton animation="wave" /> : name?.length >= 21 ? name?.slice(0, 21) + "..." : name || ''}
          </span>
        </div>
        <div className="col-lg-12 col-xl-4 col-md-12 col-sm-12 col-xs-12">
          <div className="container " style={{ padding: '7px', paddingLeft: '35px', paddingTop: '17px' }}>
            <div className="row">
              {
                !!ohlc.close && (

                  <div className="col-12 row-wrapper2">
                    <span className={clsx(classes.ohlc, "left-title2")}>{ohlc.closeType}</span>
                    <span className={clsx(classes.ohlc, "right-title2")}>
                      ${Number(ohlc.close).toFixed(2)}
                    </span>
                  </div>
                )
              }
              {
                ohlc.performancePercent !== "Infinity" && (

                  <div className="col-12 row-wrapper2">
                    <span className={clsx(classes.ohlc, "left-title2")}>Performance Since Close</span>
                    <span className={clsx(classes.ohlc, "right-title2")}>
                      {ohlc.positivePerformance && '+'}
                      {Number(ohlc.performancePercent).toFixed(2)}%
                    </span>
                  </div>
                )
              }
              <div className="col-12 row-wrapper2">
                <span className={clsx(classes.ohlc, "left-title2")}>Open</span>
                <span className={clsx(classes.ohlc, "right-title2")}>
                  ${Number(ohlc.open).toFixed(2)}
                </span>
              </div>
              <div className="col-12 row-wrapper2">
                <span className={clsx(classes.ohlc, "left-title2")}>Day's Range</span>
                <span className={clsx(classes.ohlc, "right-title2")}>
                  ${Number(ohlc.low).toFixed(2) + ' - $' + Number(ohlc.high).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-5 col-md-12 col-sm-12 col-xs-12">
          <div style={{ minWidth: '276px', paddingLeft: '30px' }}>
            <div
              className={clsx(classes.text)}
              style={{
                color: "grey",
                fontSize: "1.05rem",
                paddingTop: "15px",
                textAlign: "center",
              }}
            >
              {tickerDetails.loading ? <Skeleton width={'74%'} animation="wave" style={{ position: 'relative', left: '37px' }} /> : todaysDate}
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              {tickerDetails.loading ? <Skeleton animation="wave" height={'50px'} width={"50%"} style={{ marginBottom: '25px' }} /> : CompPrice}
            </div>
            <div
              style={{
                position: "relative",
                top: "-23px",
                color: "grey",
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {
                tickerDetails.loading && <Skeleton width={'88%'} animation="wave" />
              }
              {marketStatus === 'pre-market' && isStockBeingTraded && !tickerDetails.loading ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    top: '-8px',
                    left: '-7px'
                  }}
                >
                  <WbSunnyIcon style={{ marginRight: '5px', fill: '#ffd247' }} />
                  Pre-market
                  {/* {bottomChange} */}
                </span>
              ) : null}
              {marketStatus === 'after' && isStockBeingTraded && securityPrice.close_price !== 0
                && !tickerDetails.loading ? (
                <span className={classes.text} style={{ position: 'relative', left: '-6px', whiteSpace: isTablet ? 'wrap' : 'nowrap' }}>
                  <span style={{ position: 'relative', left: isTablet ? '24px' : '' }}>
                    <NightlightIcon style={{ marginRight: '5px', fill: 'rgb(48 86 205 / 99%)', transform: "rotate(-14deg)" }} />
                    After Hours
                  </span>
                  <span
                    style={{
                      color: "grey",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    {bottomChange}
                  </span>
                </span>
              ) : null}
              {marketStatus === 'closed' && !tickerDetails.loading ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    top: '-8px',
                    left: '-17px'
                  }}>
                  <NightsStayIcon style={{ marginRight: '5px', fill: 'rgb(48 86 205 / 99%)', transform: "rotate(-14deg)" }} />
                  Closed
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  darkflowCards: state.darkflow,
  marketStatus: state.companyData.companyData.marketState,
  themeMode: state.themeMode.themeMode,
  symbols: state.symbol.symbol,
  tickerDetails: state.companyData.companyData.tickerDetails,
});

export default connect(stateToProps, null)(TabHeaders2);
