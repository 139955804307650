import React from "react";
import LiveSessionsTabs from '../common/Tabs/LiveSessionsTabs';
import moment from 'moment';
import { heightRegex, widthRegex } from '../Tutorials/TutorialsContainer';
import { Card, CardBody } from "reactstrap";
import { makeStyles } from "@material-ui/core";
import "./previousVideos.scss";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },

}));
function PreviousVideos({ tab, setTab, basics = false, videos, currentVideo, setCurrentVideo, loading }) {
  const classes = useStyles()
  function videoSetter(el) {
    setCurrentVideo({
      live: false,
      url: el.embed.html
        .replace(widthRegex, 'width="100%"')
        .replace(heightRegex, 'height="600px"'),
      date: moment(el.created_time).format("LLL"),
      description: el.description,
      duration: moment
        .duration(el.duration, "seconds")
        .format("h [hrs], m [min]"),
      link: el.link,
    });
  }
  if (loading) return null
  return (
    <>
      <div style={{ padding: "0 15px" }}>
        <LiveSessionsTabs
          tutorials={false}
          activeTab={tab}
          setActiveTab={setTab}
          setCurrentVideo={setCurrentVideo}
          currentVideo={currentVideo}
        />
      </div>

      <div className='previous-videos-page'>
        {
          videos && videos.filter((i) => !!i.duration).map((el, index) => (
            <div
              key={index}
              className={'previous-video'}
              onClick={() => {
                videoSetter(el, true);
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              <div className={classes.background + ' previous-video-inner-content'}>
                <div>
                  <img
                    className="previous-video-thumbnail"
                    src={
                      el.pictures.sizes[3].link_with_play_button
                    }
                    alt="video-thumbnail"
                  />
                </div>
                <div className="previous-video-title">
                  {moment(el.release_time).format('llll')}
                </div>
                <div className="previous-video-length">
                  {
                    moment
                      .duration(el.duration, "seconds")
                      .format("h[h]:m[m]")
                      .replace(/\:0s$/, "")
                      .replace(/\:0m$/, "")
                      .replace(":", " ")
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}

export default PreviousVideos;
