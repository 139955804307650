import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const SWING_SUGGESTIONS_LOAD_TOP = "SG/LOAD/TOP";
export const SWING_SUGGESTIONS_LOAD_TOP_SUCCESS = "SG/LOAD/TOP/SUCCESS";
export const SWING_SUGGESTIONS_LOAD_TOP_FAILURE = "SG/LOAD/TOP/FAILURE";

// Action Creators
export const swingSuggestionsLoad = () => ({
    type: SWING_SUGGESTIONS_LOAD_TOP,
});
export const swingSuggestionsLoadSuccess = (data) => ({
    type: SWING_SUGGESTIONS_LOAD_TOP_SUCCESS,
    data
});
export const swingSuggestionsLoadFailure = (error) => ({
    type: SWING_SUGGESTIONS_LOAD_TOP_FAILURE,
    error,
});

// Sagas
function* fetchSwingSuggestions(action) {
    try {
        yield put(swingSuggestionsLoadSuccess({
            swingSuggestions: [], loading: true
        }));
        const response = yield axios.get(`${apiURL}/swing-suggested-buys-options`, {withCredentials: true});
        yield put(
            swingSuggestionsLoadSuccess({
                swingSuggestions: response.data,
                loading: false,
            })
        );
    } catch (error) {
        yield put(swingSuggestionsLoadFailure(error));
    }
}

function* listenSwingSuggestionsLoad() {
    yield takeEvery(SWING_SUGGESTIONS_LOAD_TOP, fetchSwingSuggestions);
}

// Root Saga
export function* saga() {
    yield all([fork(listenSwingSuggestionsLoad)]);
}

const INIT_STATE = {
    swingSuggestions: [],
    loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SWING_SUGGESTIONS_LOAD_TOP_SUCCESS:
            return {
                ...state,
                swingSuggestions: action.data.swingSuggestions,
                loading: action.data.loading,
            };
        default:
            return state;
    }
};

export default reducer;
