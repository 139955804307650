import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const DARKFLOW_LOAD_TOP = "DF/LOAD/TOP";
export const DARKFLOW_LOAD_TOP_SUCCESS = "DF/LOAD/TOP/SUCCESS";
export const DARKFLOW_LOAD_TOP_FAILURE = "DF/LOAD/TOP/FAILURE";

// Action Creators
export const darkflowLoad = (risk) => ({
  type: DARKFLOW_LOAD_TOP,
  risk,
});
export const darkflowLoadSuccess = (data) => ({
  type: DARKFLOW_LOAD_TOP_SUCCESS,
  data,
});
export const darkflowLoadFailure = (error) => ({
  type: DARKFLOW_LOAD_TOP_FAILURE,
  error,
});
function filterDarkflow(darkflow, up) {
  return darkflow.sort((a, b) => Number(b.multiplier) - Number(a.multiplier))
    .filter((item) => {
      const livePrice = item.last_price
      const performance =
        Math.round((livePrice / item.trend_start - 1) * 1000) / 10;
      return up
        ? performance > 0 && !!item.market_cap
        : performance < 0 && !!item.market_cap;
    })
}

// Sagas
function* fetchDarkFlow(action) {
  try {
    let finalObj = {
      large: {
        trending_up: [],
        trending_down: [],
        loading: true
      },
      medium: {
        trending_up: [],
        trending_down: [],
        loading: true
      },
      small: {
        trending_up: [],
        trending_down: [],
        loading: true
      }
    }
    const { weekly } = yield select((state) => state.darkflowTable);
    yield put(darkflowLoadSuccess(finalObj));
    const responseLarge = yield axios.get(`${apiURL}/ats/darkflow?market_cap=large${weekly ? "&weekly=1" : ""}`,{withCredentials: true}
    );
    let up = filterDarkflow(responseLarge.data.trending_up, true).slice(0,6)
    let down = filterDarkflow(responseLarge.data.trending_down, false).slice(0,6)
    let large = {
      trending_up: up,
      trending_down: down,
      loading: false,
    }
    finalObj.large = large
    yield put(darkflowLoadSuccess(finalObj));
    const responseMedium = yield axios.get(`${apiURL}/ats/darkflow?market_cap=medium${weekly ? "&weekly=1" : ""}`,{withCredentials: true}
    );
    let upM = filterDarkflow(responseMedium.data.trending_up, true).slice(0,6)
    let downM = filterDarkflow(responseMedium.data.trending_down, false).slice(0,6)
    let medium = {
      trending_up: upM,
      trending_down: downM,
      loading: false,
    }
    finalObj.medium = medium
    yield put(darkflowLoadSuccess(finalObj));
    const responseSmall = yield axios.get(`${apiURL}/ats/darkflow?market_cap=small${weekly ? "&weekly=1" : ""}`,{withCredentials: true}
    );
    let upS = filterDarkflow(responseSmall.data.trending_up, true).slice(0,6)
    let downS = filterDarkflow(responseSmall.data.trending_down, false).slice(0,6)
    let small = {
      trending_up: upS,
      trending_down: downS,
      loading: false,
    }
    finalObj.small = small
    yield put(darkflowLoadSuccess(finalObj));
  } catch (error) {
    yield put(darkflowLoadFailure(error));
  }
}

function* listenDarkflowLoad() {
  yield takeEvery(DARKFLOW_LOAD_TOP, fetchDarkFlow);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDarkflowLoad)]);
}

const INIT_STATE = {
  large: {
    trending_up: [],
    trending_down: [],
    loading: true
  },
  medium: {
    trending_up: [],
    trending_down: [],
    loading: true
  },
  small: {
    trending_up: [],
    trending_down: [],
    loading: true
  }
}
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DARKFLOW_LOAD_TOP_SUCCESS:
      return {
        ...state,
        large: action.data.large,
        medium: action.data.medium,
        small: action.data.small,
      };
    default:
      return state;
  }
};

export default reducer;
