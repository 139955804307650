  // Action Types
  export const LIVE_CHAT_SET = "SC/SET";
  
  // Action Creators
  export const liveChatSetter = (hideChat,liveChatExpanded) => {
    return {
      type: LIVE_CHAT_SET,
      hideChat,
      liveChatExpanded
    };
  };
  
  
  const INIT_STATE = {
    hideChat: false,
    liveChatExpanded: false
  };
  // Reducer
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case LIVE_CHAT_SET:
        return {
          ...state,
          hideChat: action.hideChat,
          liveChatExpanded: action.liveChatExpanded
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  