import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from "react";
import PreviousVideos from '../BasicsTraining/PreviousVideos';
import { connect } from 'react-redux';
import { streamsLoad } from '../../appRedux/ducks/pastStreams';
import "./style.scss";

import VerifiedIcon from '@mui/icons-material/Verified';
import profileImg from "../../assets/images/profile-Ross.png";
import DaneProfileImage from "../../assets/images/dane.png";

function MarketAnalysis({ fetchStreams, pastStreams, loading }) {
  const [currentVideo, setCurrentVideo] = useState({
    live: true,
    url: '',
    date: "",
    description:
      "",
    duration: "",
    link: "",
  });
  const [previousVideos, setPreviousVideos] = useState([])
  const [tab, setTab] = useState("1");
  useEffect(() => {
    fetchStreams('market-analysis')
  }, [])

  useEffect(() => {
    if (!!pastStreams.length) {
      setPreviousVideos(pastStreams)
    }
  }, [pastStreams])

  return (
    <div className="container-xl-live">
      <Helmet>
        <title>Trade With Ross | TradeAlgo</title>
      </Helmet>

      {
        currentVideo.live ? (
          <div className="market-analysis-hero">
            <iframe
              className="market-analysis-event-video"
              src="https://vimeo.com/event/1439418/embed"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title='market-analysis-video'
            ></iframe>
            <iframe
              src="https://vimeo.com/event/1439418/chat"
              className="market-analysis-event-chat"
              title='market-analysis-chat'
            ></iframe>
          </div>
        ) : (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: currentVideo.url,
              }}
            />
            <div style={{ position: "relative", top: "5px", left: "29px" }}>
              <div className="row">
                <span
                  className="col-12"
                  style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
                >
                  {currentVideo.duration} | {currentVideo.date} |{" "}
                  {tab === "1" ? "Group Training" : ""}
                </span>
              </div>
              <div className="row">
                <span className="col-12" style={{ paddingTop: "10px" }}>
                  {currentVideo.description}
                </span>
              </div>
            </div>
            <br />
            <div
              style={{
                width: "99%",
                border: "1px solid #F1F1F1",
                marginLeft: "24px",
              }}
            ></div>
            <br />
          </div>
        )
      }

      <div className='market-analysis-presentor-wrapper'>
        <div className='market-analysis-half-width'>
          <div className='market-analysis-presentor'>
            <img src={profileImg} alt="profile" className='market-analysis-presentor-avatar' />
            <div className='market-analysis-presentor-detail'>
              <div className='market-analysis-presentor-name'>Ross Santos <VerifiedIcon /></div>
              <div className='market-analysis-presentor-position'>TradeAlgo Lead Systems Analyst</div>
            </div>
          </div>

          <div className='market-analysis-event-info'>
            <div className='market-analysis-event-info-section'>
              <div className='market-analysis-event-title'>Pre-market Analysis Sessions</div>
              <div className='market-analysis-event-description'>Analyst will go over platform to identify potential dashboard picks, will provide levels to watch out for with day-trades and set ups.</div>
              <div className='market-analysis-event-schedule'>
                <div>
                  <div className='market-analysis-event-schedule-status'>LIVE Schedule</div>
                  <div className='market-analysis-event-schedule-day'>Every Monday to Friday</div>
                  <div className='market-analysis-event-schedule-time'>Time: 9:00am-9:30am est</div>
                </div>
                <div className='market-analysis-event-schedule-reply-msg'>Replays are available at anytime.</div>
              </div>
            </div>
          </div>

          <div className='market-analysis-event-info'>
            <div className='market-analysis-event-info-section'>
              <div className='market-analysis-event-title'>Live Trading Sessions</div>
              <div className='market-analysis-event-description'>These group training sessions are designed to explain how to take full advantage of the alerts Trade Algo has to offer.</div>
              <div className='market-analysis-event-schedule'>
                <div>
                  <div className='market-analysis-event-schedule-status'>LIVE Schedule</div>
                  <div className='market-analysis-event-schedule-day'>Every Monday to Friday</div>
                  <div className='market-analysis-event-schedule-time'>Time: 9:30am-10:30am est</div>
                </div>
                <div className='market-analysis-event-schedule-reply-msg'>Replays are available at anytime.</div>
              </div>
            </div>
          </div>

        </div>

        <div className='market-analysis-half-width'>
          <div className='market-analysis-presentor'>
            <img src={DaneProfileImage} alt="profile" className='market-analysis-presentor-avatar' />
            <div className='market-analysis-presentor-detail'>
              <div className='market-analysis-presentor-name'>Dane Glisek <VerifiedIcon /></div>
              <div className='market-analysis-presentor-position'>TradeAlgo Analyst</div>
            </div>
          </div>

          <div className='market-analysis-event-info'>
            <div className='market-analysis-event-info-section'>
              <div className='market-analysis-event-title'>Live Trading Sessions</div>
              <div className='market-analysis-event-description'>These group training sessions are designed to explain how to take full advantage of the alerts Trade Algo has to offer.</div>
              <div className='market-analysis-event-schedule'>
                <div>
                  <div className='market-analysis-event-schedule-status'>LIVE Schedule</div>
                  <div className='market-analysis-event-schedule-day'>Every Monday to Friday</div>
                  <div className='market-analysis-event-schedule-time'>Time: 3:00pm-4:00pm est</div>
                </div>
                <div className='market-analysis-event-schedule-reply-msg'>Replays are available at anytime.</div>
              </div>
            </div>
          </div>
          <div className='market-analysis-event-info'>
            <div className='market-analysis-event-info-section'>
              <div className='market-analysis-event-title'>After Market Recap</div>
              <div className='market-analysis-event-description'>Dane will review the day, covering potential trades and also review what is coming up within the next few days and potential plays that could show up then.</div>
              <div className='market-analysis-event-schedule'>
                <div>
                  <div className='market-analysis-event-schedule-status'>LIVE Schedule</div>
                  <div className='market-analysis-event-schedule-day'>Every Monday to Friday</div>
                  <div className='market-analysis-event-schedule-time'>Time: 4:00pm-4:45pm est</div>
                </div>
                <div className='market-analysis-event-schedule-reply-msg'>Replays are available at anytime.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PreviousVideos tab={tab} setTab={setTab} basics={false} loading={loading} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo} videos={previousVideos} />
    </div>
  );
}
const stateToProps = (state) => ({
  pastStreams: state.streams.streams,
  loading: state.streams.loading
});
const dispatchToProps = (dispatch) => ({
  fetchStreams: (endpoint) => dispatch(streamsLoad(endpoint)),
});
export default connect(stateToProps, dispatchToProps)(MarketAnalysis);