import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SidebarSkeleton() {
    const items = [...Array(11).keys()]
    return (
        <>
            {
                items.map((i, index) => <Skeleton key={i} style={{ marginBottom: index === 7 ? `10vh` : '' }} className="sidebar-skeleton" animation="wave" />)
            }
        </>
    )



}
