import { FinishModal, ModalTitle } from "../../homePage/ModalHelpers";

export const joyrideStyles = {
    options: {
        primaryColor: "#FA973C",
        width: "clamp(234px, 500px, 900px)"
    },
    buttonNext: {
        width: "107px",
        height: "33px",
        borderRadius: "5px",
        background: "linear-gradient(269deg, #5EEECD 0%, #007BFF 100%)"
    },
    buttonBack: {
        fontSize: '16px',
    },
    buttonSkip: {
        fontSize: '16px',
    },
    tooltipTitle: {
        fontSize: "28px"
    },
    tooltipFooter: {
        // backgroundColor: 'red'
    },
    tooltip: {
    }
};

export const homePageSteps = [
    {
        title: <ModalTitle title={"Dashboard"} />,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Welcome to your first step towards success! </div>
                <div className="main-power-text">The Main Dashboard is where our most powerful data is harnessed every day. </div>
                <div className="main-power-text">All equities are tracked by our machine learning technology. Your days of hunting through thousands of stocks and spending hours on trading are over!</div>
            </div>
        ),
        locale: { skip: "End Tour", back: "" },
        placement: 'center',
        target: 'body',
        disableBeacon: true,
    },
    {
        title: <ModalTitle title={"Top 10 Chart"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">The Top 10 stocks with the highest dark-pool volume (private institutional trading) are marked here in order of importance from 1-10.</div>
            </div>
        ),
        target: '.MuiDataGrid-root',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Single Stock"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Click on next and lets see all the additional info our algos are gathering on this stock.</div>
            </div>
        ),
        target: '.MuiDataGrid-row',
        locale: { skip: "End Tour" },
        disableBeacon: true,
    },
    // company page
    {
        title: <ModalTitle title={"Company Stock Page"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Every stock in the market is being tracked real-time behind the scenes!</div>
            </div>
        ),
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        locale: { skip: 'End Tour', back: "" },
    },
    {
        title: <ModalTitle title={"Company Stock Chart"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Here is the stock's real time chart. All technical charting tools are available.</div>
            </div>
        ),
        placement: 'right-start',
        target: '.stock-wrapper',
        disableBeacon: true,
        locale: { skip: "End Tour" },

    },
    {
        title: <ModalTitle title={"Company Financials"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">On each stock page you can also see the company’s financials, all of their daily options activity, and their dark-pool activity as well.</div>
                <div className="main-power-text">Look at that huge increase from the previous 7 days! No wonder the stock is on the main dashboard moving so much!</div>
            </div>
        ),
        placement: 'left-start',
        target: '.fun-joyride-target',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Search Bar"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">To look up an individual stock, just type it into the search bar here.</div>
            </div>
        ),
        placement: 'bottom-start',
        target: '.header-search',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Text Alerts"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Awesome, its only going to get easier! </div>
                <div className="main-power-text">Let’s get you signed up for your automatic text alerts. Click next to continue on to the Alerts Tab.</div>
            </div>
        ),
        placement: 'top-end',
        target: '.sidebar-alerts',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    // alerts page
    {
        title: <ModalTitle title={"Trade Alerts"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Our Trade Algo top analysts send out important dark pool trade alerts throughout the day.</div>
            </div>
        ),
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        locale: { skip: "End Tour", back: "" },
    },
    {
        title: <ModalTitle title={"Text Alerts"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Follow these steps listed here after the tutorial to download the telegram app to recieve alerts on your phone. </div>
            </div>
        ),
        placement: 'bottom-end',
        target: '.card-body',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Support Channels & Trade Rooms"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Almost done, just a few more steps!</div>
                <div className="main-power-text">TradeAlgo offers several support channels and trading rooms to help people succeed.</div>
                <div className="main-power-text">Let’s go check on our key live day trading room. Click next to be taken to the “Daytrade With Brian” page. </div>
            </div>
        ),
        placement: 'top-end',
        target: '.brian-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Daytrade with Brian"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Our lead analyst Brian Mitchell trades using dark-pools from market open to market close.</div>
                <div className="main-power-text">This is a great place for people to earn while they learn. Feel free to ask questions, make comments, and share success stories throughout the day. </div>
            </div>
        ),
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        locale: { skip: "End Tour", back: "" },
    },
    {
        title: <ModalTitle title={"Trade Board"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Brian posts trade alerts on the trade board. You will see when he enters a trade. You will see when he exits as well.</div>
            </div>
        ),
        placement: 'left-start',
        target: '.sendbird-openchannel-app-trades',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Livestream"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">You can also watch him livestream trade each day at 1:45pm PST. This room is where a lot of users come to make the big bucks each morning.</div>
            </div>
        ),
        placement: 'right',
        target: '.mr-20',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Daytrade with Brian"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">It is critical that new users spend time in this room to make $$$ while they learn how to use TradeAlgo on their own.</div>
            </div>
        ),
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Trade With Ross"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Lets jump over to our Swing Trading room.</div>
                <div className="main-power-text">Click next to be taken to the “Trade With Ross” page. </div>
            </div>
        ),
        placement: 'bottom',
        target: '.ross-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Swing Trading"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">Our swing trading analysts Ross & Dane livestream their dark-pool trades every day. You can attend the morning session at 9:30am EST or the afternoon session at 3pm EST.</div>
            </div>
        ),
        placement: 'center',
        target: 'body',
        disableBeacon: true,
        locale: { skip: "End Tour", back: "" },
    },
    {
        title: <ModalTitle title={"Swing Trade Livestream"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">

                <div className="main-power-text">Since these are livestreams, you can ask questions and get real time answers from analysts.</div>
            </div>
        ),
        placement: 'left-start',
        target: '.market-analysis-event-chat',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Return to Dashboard"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Let’s head back to the Dashboard one more time.</div>
            </div>
        ),
        placement: 'bottom',
        target: '.main-dash-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Dashboard Support"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Congrats! You know your way around TradeAlgo like a pro!</div>
                <div className="main-power-text">If you ever need support you come here to get assistance.</div>
            </div>
        ),
        placement: 'top-end',
        target: '.support-menu-item ',
        disableBeacon: true,
        locale: { skip: "End Tour", back: "" },
    },
    {
        title: <ModalTitle title={"Options Mentorship"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">If you’re new to TradeAlgo, it’s important that you schedule yourself for a 1-on-1 coaching session on the “Options Mentorship” tab. </div>
            </div>
        ),
        placement: 'right',
        target: '.options-mentor-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Options Academy"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">If you’re new to trading altogether, make sure you sign up for the “Options Academy”. After going through the academy for a few days, you’ll be an expert!</div>
            </div>
        ),
        placement: 'right',
        target: '.academy-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Tutorials"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="main-power-text">You can also go to the “Tutorials“ tab which contains a library of helpful training videos that offer further detailed insight and instruction.</div>
            </div>
        ),
        placement: 'right',
        target: '.tutorials-joyride',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <ModalTitle title={"Information Tooltip"} />,
        run: false,
        content: (
            <div className="d-flex flex-col gap-15 align-start mt-10">
                <div className="welcome-blue">Last but not least </div>
                <div className="main-power-text">
                    If you would ever like further insight into the tools we offer, click the question mark on each tab for a quick 2-4 minute tutorial.
                </div>
            </div>
        ),
        placement: 'bottom',
        target: '.joyride-helper svg',
        disableBeacon: true,
        locale: { skip: "End Tour" },
    },
    {
        title: <FinishModal title={"Congratulations"} />,

        content: (
            <div className="d-flex flex-col gap-15 align-items-center mt-10">
                <div className="welcome-blue">You’ve finished the tutorial and you’re off to the races now!</div>
                <div className="main-power-text text-center">
                    First thing’s first: make sure you attend the Daytrade With Brian room. This is the single most important space for new users of TradeAlgo. Don’t be shy! Everyone in the chat is  very friendly!
                </div>
            </div>
        ),
        locale: {
            last: "Finish", skip: <></>
        },
        placement: 'center',
        target: 'body',
        disableBeacon: true,
    },
]