import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { SOCKET_MESSAGE } from "./socket";
import { uniqueId } from "lodash";

// Action Types
export const COUNTER_LOAD_TOP = "CNT/LOAD/TOP";
export const COUNTER_LOAD_TOP_SUCCESS = "CNT/LOAD/TOP/SUCCESS";
export const COUNTER_LOAD_TOP_FAILURE = "CNT/LOAD/TOP/FAILURE";
// Action Creators
export const counterLoad = () => ({
  type: COUNTER_LOAD_TOP,
});
export const counterLoadSuccess = (data) => ({
  type: COUNTER_LOAD_TOP_SUCCESS,
  data
});
export const counterLoadFailure = (error) => ({
  type: COUNTER_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchCounter(action) {
  try {
    yield put(counterLoadSuccess({ counter: [], loading: true }));
    const response = yield axios.get(`${apiURL}/ats/ews/daily`);
    yield put(
      counterLoadSuccess({
        counter: response.data,
        loading: false,
      })
    );
  } catch (error) {
    yield put(counterLoadFailure(error));
  }
}

function* listenCounterLoad() {
  yield takeEvery(COUNTER_LOAD_TOP, fetchCounter);
}

// Root Saga
export function* saga() {
  yield all([fork(listenCounterLoad)]);
}

const INIT_STATE = {
  counter: [],
  loading: true,
};
// Reducer
const reducer =  (state = INIT_STATE, action) => {
  switch (action.type) {
    case SOCKET_MESSAGE: 
    if (!action.channel.includes("COUNTER")) return state; // If not for options, then ignore
    let tickerNotFound = true
    // if the ticker exists update the score, and last minute with score and last time maintain the price
    let updated = [...state.counter].map((item) => {
      if(item.ticker === action.data[0].ticker) {
        tickerNotFound = false;
        return {
          ...item, 
          score: action.data[0].score,
          last_minute: action.data[0].last_time + ':00',
          last_price: Number(action.data[0].last_price)
        }
      } else {
        return item;
      }
    })
    if(tickerNotFound && !!state.counter.length) {
      let symbol = action.data[0].ticker;
        let price = action.data[0].last_price
        let record = {
          id: uniqueId('1234'),
          ticker: symbol,
          score: action.data[0].score,
          last_price: Number(price),
          last_minute: action.data[0].last_time + ':00',
        }
       updated = [...updated, record]
    }
    return {
      ...state,
      counter: updated,
    }
    case COUNTER_LOAD_TOP_SUCCESS:
      return {
        ...state,
        counter: action.data.counter,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
