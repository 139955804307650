import {  makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


export const CardSkeleton = ({width = '450px'}) => {
    // const useStyles = makeStyles((theme) => ({
    //     paper: {
    //       // width: 'auto', // allow table to stretch full width
    //       flexGrow: 1, // allow table to stretch full width
    //       '& > div': {
    //         height: 'auto !important', // override zero height from DataGrid
    //         width: 'auto !important', // override zero width from DataGrid
    //       },
    //       '& .MuiDataGrid-root': {
    //         overflow: 'auto', // set table to be responsive
    //         fontSize: '0.9rem',
    //       },
    //     },
    //     skeleton: {
    //       backgroundColor: theme.palette.primary.skeleton,
    //       minWidth: width,
    //       maxWidth: width,
    //       minHeight: "468px",
    //       maxHeight: "464px",
    //       borderRadius: "16px", margin: "15px" 
    //     }
    //   }));
    // const classes = useStyles();
    return (
        <Skeleton 
        // className={classes.skeleton}
         variant="rect" />
    );
  };
  