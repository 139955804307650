import axios from 'axios'
import moment from 'moment';
import MTZ from "moment-timezone"
import { isPolyREST } from '../../appRedux/actions/helpers';

const timespanLookup = {
    'm': 'minute',
    "d": 'day',
    'h': 'hour'
}
export async function generateBars(isOption, symbol, resolution, timespan, fromTime, toTime) {
    try {
        let bars = [];
        const timespanUri = timespanLookup[timespan]
        let uri;
        if (isPolyREST) {
            symbol = isOption ? symbol.split(' ')[0].replace('.', '') : symbol.replace('.', '/');
            uri = `https://ppg-rest.tradealgo.com/pg-candles/${isOption ? "O:" : ""}${symbol}/range/${resolution}/${timespanUri}/${fromTime}/${toTime}?adjusted=true&sort=desc&limit=50000`
        } else {
            symbol = isOption ? symbol : symbol.replace('.', '/');
            uri = `https://ws.tradealgo.com/rest/events.json?events=Candle&symbols=${encodeURIComponent(`${symbol}{=${resolution}${timespan}}`)}&fromTime=${fromTime}&toTime=${toTime}&indent`;
        }
        const { data } = await axios.get(uri)
        if (!!data?.results) {
            bars = data.results.map((candle) => ({
                open: candle.o,
                high: candle.h,
                low: candle.l,
                close: candle.c,
                time: candle.t,
                volume: candle.v
            })).sort((a, b) => a.time - b.time);
        }
        if (!!data?.Candle) {
            let keys = Object.keys(data.Candle);
            if (keys[0].includes(symbol)) {
                bars = data.Candle[keys[0]];
            }
        }
        return bars
    } catch (error) {
        console.log('error in generate bars function', error)
    }
}
export function generateOptionSymbol(ticker, expirationDate, strikePrice, cp, dxSymbol) {
    if (isPolyREST) {
        const exp = moment.utc(expirationDate).format('YYMMDD')
        const strike = `${strikePrice * 1000}`.padStart(8, '0')
        return `${ticker}${exp}${cp}${strike}OPTION_CONTRACT`
    } else {
        return `${dxSymbol}OPTION_CONTRACT`
    }
}

export function resolutionsSupported() {
    return isPolyREST ? ["1", "3", "5", "15", "30", "60", "120", "240", "360", "D", "2D", "3D", "W"] : ["1S", "5S", "15S", "30S", "1", "3", "5", "15", "30", "60", "120", "240", "360", "D", "2D", "3D", "W"];
}
export function updateBarDx(data, sub) {
        var lastBar = sub?.lastBar;
        let resolution = sub?.resolution;
        var coeff = resolution * 60;
        if (resolution && resolution?.includes('S')) {
            coeff = resolution.replace("S", "")
        }
        var lastBarSec = lastBar?.time;
        var _lastBar;
        const lastBarEndMinute = MTZ(lastBarSec).valueOf() / 1000;
        const dataTime = MTZ(data.time).valueOf() / 1000;
        const diff = dataTime - lastBarEndMinute;
        // console.log(diff, '< ----diff', coeff, '<---- coeff')
        if (diff >= coeff) {
            // console.log('DIFF WAS GREATER THAN COEFFECIENT')
            // create a new candle
            _lastBar = {
                time: data.time,
                open: Number(data.open),
                high: Number(data.high),
                low: Number(data.low),
                close: Number(data.close),
                volume: Number(data.volume),
                symbol: data.eventSymbol
            };
        } else {
            if (Number(data.low) < Number(lastBar.low)) {
                lastBar.low = Number(data.low);
            } else if (data.high > lastBar.high) {
                lastBar.high = Number(data.high);
            }
            lastBar.volume += data.volume
            lastBar.close = Number(data.close);
            // lastBar.time = data.time
            _lastBar = lastBar;
        }
    return _lastBar;
}
export function updateBar(data, sub) {
    var lastBar = sub?.lastBar;
    let resolution = sub?.resolution;
    var coeff = resolution * 60;
    if(resolution.includes('S')) {
        coeff = Number(resolution.replace(/S/g,''))
    }
    var lastBarSec = lastBar?.time;
    var _lastBar;
    const lastBarEndMinute = MTZ(lastBarSec).valueOf() / 1000;
    const dataTime = MTZ(data.time).valueOf() / 1000;
    const diff = dataTime - lastBarEndMinute;
    if (diff >= coeff) {
        // create a new candle, use last close as open **PERSONAL CHOICE**
        _lastBar = {
            time: data.time,
            open: Number(lastBar.close),
            high: Number(lastBar.close),
            low: Number(lastBar.close),
            close: Number(data.price),
            volume: Number(data.volume),
        };
    } else {
        if (Number(data.price) < Number(lastBar.low)) {
            lastBar.low = Number(data.price);
        } else if (data.price > lastBar.high) {
            lastBar.high = Number(data.price);
        }
        lastBar.volume += Number(data.volume);
        lastBar.close = Number(data.price);
        _lastBar = lastBar;
    }

    return _lastBar;
}

