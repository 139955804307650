import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./optionChain.css";
import { makeStyles, Tooltip } from "@material-ui/core";
import moment from "moment";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { TableSkeleton } from "../Skeletons/TableSkeleton";
import OptionContractTabs from "../Tabs/OptionContractTabs";
import OptionTVContainer from "../../TradingView/OptionTVContainer";
import { connect } from "react-redux";
import { optionSparklineLoad } from "../../../appRedux/ducks/optionSparklines";
import SparkLines from "../SparkLines";
import CssPop from "./animations/CssPop";
import clsx from "clsx";
import { ConditionalWrapper } from "../Layout/PrivateLayout/SidebarContent";
import { generateOptionSymbol } from "../../TradingView/candleControl";
import { isPolyREST } from "../../../appRedux/actions/helpers";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const timeSort = (a, b, order) => {
  return order === 'asc' ? moment(a.date_added, 'YYYY-MM-DD').diff(moment(b.date_added, 'YYYY-MM-DD')) : moment(b.date_added, 'YYYY-MM-DD').diff(moment(a.date_added, 'YYYY-MM-DD'))
}
function getComparator(order, orderBy) {
  if (orderBy === 'dd') return (a, b) => timeSort(a, b, order)
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const useStyles = makeStyles((theme) => ({
  innerTable: {
    lineHeight: "normal !important",
    "& .MuiTableCell-body": {
      borderBottom: "none",
    },
  },
  cardTitle: {
    width: "51px",
    height: "30px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "20px",
    lineHeight: "30px",
    padding: "14px",
    paddingBottom: "30px",
    color: theme.palette.primary.text + " !important",
    whiteSpace: 'nowrap'
  },
  background: {
    backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    "& .MuiButtonBase-root": {
      color: theme.palette.primary.text + " !important",
    },
    "& .MuiCollapse-wrapperInner": {
      backgroundColor: `${theme.palette.primary.innerWrapper} !important`,

    }
  },
  text: {
    color: theme.palette.primary.text + " !important",
    "& .MuiTableSortLabel-icon": {
      color: theme.palette.primary.text + " !important",
    }
  }
}));
function Row({
  row,
  expDate,
  calls,
  livePrice,
  getOptionSparkline,
  optionSparkline,
  currentId,
  setCurrentId,
  midpointChange,
  themeMode,
  swingTrades = false
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [timeline, setTimeline] = useState("1D");
  const lookupRef = useRef(null)
  const performance = useMemo(() => {
    return row?.change >= 0;
  }, [row.change])
  const longTermPerformance = useMemo(() => {
    return swingTrades ? row.changeSinceAdded >= 0 : false
  }, [row.changeSinceAdded])
  const dateFormatter = useCallback((index) => {
    return !!lookupRef.current ? moment(lookupRef.current[index]).format("MM/DD/YYYY") : '';
  }, [lookupRef.current])

  const options = {
    colors: [performance ? "#5EEECD" : "#F32228"],
    chart: {
      type: "area",
      height: "100%",
      sparkline: {
        enabled: true,
      },
      width: "90%",
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          // eslint-disable-next-line no-unused-vars
          formatter(seriesName, b) {
            let index = b.dataPointIndex
            return dateFormatter(index) + "   $"
          },
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.1,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 95],
      },
    },
  };
  const symbol = generateOptionSymbol(row.ticker, row.expiration, row.strike, row.cp, row.symbol)
  const sparklineData = useMemo(() => {
    const sym = symbol.replace('OPTION_CONTRACT', '')
    if (optionSparkline[sym]) {
      let data = []
      let timeLookup = []
      optionSparkline[sym].forEach(element => {
        data.push(element.close)
        timeLookup.push(element.time)
        lookupRef.current = timeLookup
      });
      return {
        data,
        timeLookup
      };
    } else {
      return {
        data: [],
        timeLookup: []
      };
    }
  }, [open, optionSparkline, symbol]);
  useEffect(() => {
    if (open) {
      getOptionSparkline(symbol.replace('OPTION_CONTRACT', ''), timeline);
    }
  }, [swingTrades, open, timeline, symbol]);

  useEffect(() => {
    if (currentId !== row.id) setOpen(false)
  }, [currentId, open, JSON.stringify(row)])

  function handleRowClick() {
    setOpen(!open)
    setCurrentId(row.id);
  }
  const Spark = useMemo(() => {
    return (
      <SparkLines
        style={{ lineHeight: "normal !important" }}
        series={[
          {
            data: [...sparklineData.data, row.lastPrice],
          },
        ]}
        options={options}
        type={options.chart.type}
        height={options.chart.height}
        width={options.chart.width}
      />
    )
  }, [JSON.stringify(sparklineData), open, options, row.lastPrice])

  return (
    <React.Fragment>
      <TableRow
        className={
          clsx(classes.background, Number(row.strike) >= livePrice ? `strike-higher-${themeMode}` : `strike-lower-${themeMode}`)
        }
        style={{ cursor: 'pointer' }}
        onClick={handleRowClick}
        sx={{
          "& > *": {
            lineHeight: "normal !important",
            borderBottom: "1px solid rgba(226, 232, 240, 0.5) !important",
          },
        }}
      >
        <TableCell component="th" scope="row">
          <span
            style={{
              color: "#8277DF",
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={handleRowClick}
          >
            {row.symbol}
          </span>
        </TableCell>
        {
          swingTrades && (
            <TableCell align="right">
              <span
                style={{
                  color: "#8277DF",
                  position: "relative",
                  right: '5px',
                  fontWeight: 600,
                  whiteSpace: 'nowrap'
                }}
              >
                {moment.utc(row.date_added).format('MMM Do YY')}
              </span>
            </TableCell>
          )
        }
        <TableCell align="right">
          <span
            style={{
              color: "#8277DF",
              position: "relative",
              right: '5px',
              fontWeight: 600,
            }}
          >
            {row.strike}
          </span>
        </TableCell>

        <TableCell align="right">
          {" "}
          <span
            className={classes.text}
            style={{
              color: "#4C4663",
              position: "relative",
              left: "3px",
              display: "flex",
            }}
          >
            <CssPop
              midpointChange={midpointChange}
              comparator={row.bid}
            />
          </span>
        </TableCell>
        <TableCell align="right">
          {" "}
          <span
            className={classes.text}
            style={{
              color: "#4C4663",
              display: "flex",
              position: "relative",
              left: "3px",
            }}
          >
            <CssPop midpointChange={midpointChange} comparator={row.lastPrice} />
          </span>
        </TableCell>
        <TableCell align="right">
          {" "}
          <span
            className={classes.text}
            style={{
              color: "#4C4663",
              position: "relative",
              left: "3px",
              display: "flex",

            }}
          >
            <CssPop midpointChange={midpointChange} comparator={row.ask} />
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          <span
            style={{
              color: performance ? "#0DAD60" : "red",
              position: "relative",
              left: performance ? "14px" : "6px",
            }}
          >
            {row.change.toFixed(2)}
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          <span
            style={{
              color: performance ? "#0DAD60" : "red",
              position: "relative",
              left: performance ? "14px" : "6px",
            }}
          >
            {row.openPrice ? Math.floor(row.changePercent) + "%" : "-"}
          </span>
        </TableCell>
        {
          swingTrades && (
            <TableCell component="th" scope="row">
              <span style={{
                color: longTermPerformance ? "#0DAD60" : "red",
                position: "relative",
                // left: longTermPerformance ? "14px" : "6px",
              }}>
                {Number(row.changePercentSinceAdded).toFixed(2)}%
              </span>
            </TableCell>
          )
        }
        <TableCell component="th" scope="row">
          <span className={classes.text} style={{ position: "relative", left: "6px" }}>
            {Math.round(row.dayVolume)}
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          <span className={classes.text} >
            {Math.round(row.openInterest)}
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          <span className={classes.text} style={{ position: "relative", left: "6px" }}>
            {Math.round(row.iv * 100)}%
          </span>
        </TableCell>
        {
          swingTrades && (
            <>
              <TableCell component="th" scope="row">
                <span className={classes.text}>
                  {Number(row.price_added)?.toFixed(2)}
                </span>
              </TableCell>
              <TableCell component="th" scope="row">
                <span style={{
                  color: longTermPerformance ? "#0DAD60" : "red",
                  position: "relative",
                  // left: longTermPerformance ? "14px" : "6px",
                }}>
                  {Number(row.changeSinceAdded)?.toFixed(2)}
                </span>
              </TableCell>

            </>
          )
        }
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleRowClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        style={{ display: open ? "contents" : "none" }}
        className={classes.innerTable}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <OptionContractTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                themeMode={themeMode}
              />
              {activeTab === "1" ? (
                <>
                  <Typography className={classes.text} variant="h6" component="div">

                    {
                      !swingTrades && (

                        `${row?.symbol?.toUpperCase() || ''} ${moment(expDate).format(
                          "MMM DD, YYYY"
                        )} ${row.strike} ${!swingTrades ? calls ? "Call" : "Put" : ''}`
                      )
                    }

                  </Typography>
                  <div className="container">
                    <div className="row">
                      <div className="col-6 row">
                        <div className="col-6 row-wrapper">
                          <span className="left-title">Day's Range</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.lastPrice < row.dailyLow ? row.lastPrice : row.dailyLow).toFixed(2)} - {Number(row.lastPrice > row.dailyHigh ? row.lastPrice : row.dailyHigh).toFixed(2)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">Historical Range</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.lastPrice < row.historicalLow ? row.lastPrice : row.historicalLow).toFixed(2)} - {Number(row.lastPrice > row.historicalHigh ? row.lastPrice : row.historicalHigh).toFixed(2)}
                          </span>
                        </div>

                        <div className="col-6 row-wrapper">
                          <span className="left-title">Support</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.support).toFixed(2)}
                          </span>
                        </div>

                        <div className="col-6 row-wrapper">
                          <span className="left-title">Resistance</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.resistance).toFixed(2)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">RSI</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {row.rsi < 30
                              ? `Oversold (${row.rsi})`
                              : row.rsi > 69
                                ? `Oversold (${row.rsi})`
                                : `Average (${row.rsi})`}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">VEGA</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.vega).toFixed(4)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">THETA</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.theta).toFixed(4)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">GAMMA</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.gamma).toFixed(4)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">DELTA</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.delta).toFixed(4)}
                          </span>
                        </div>
                        <div className="col-6 row-wrapper">
                          <span className="left-title">RHO</span>
                          <span className={clsx(classes.text, "right-title")}>
                            {Number(row.rho).toFixed(4)}
                          </span>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={"option-scanner-timelines"}>
                          <span
                            onClick={() => setTimeline("1D")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "1D" ? "active" : ""
                              }`}
                          >
                            1D
                          </span>
                          <span
                            onClick={() => setTimeline("5D")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "5D" ? "active" : ""
                              }`}
                          >
                            5D
                          </span>
                          <span
                            onClick={() => setTimeline("1M")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "1M" ? "active" : ""
                              }`}
                          >
                            1M
                          </span>
                          <span
                            onClick={() => setTimeline("6M")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "6M" ? "active" : ""
                              }`}
                          >
                            6M
                          </span>
                          <span
                            onClick={() => setTimeline("YTD")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "YTD" ? "active" : ""
                              }`}
                          >
                            YTD
                          </span>
                          <span
                            onClick={() => setTimeline("1Y")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "1Y" ? "active" : ""
                              }`}
                          >
                            1Y
                          </span>
                          <span
                            onClick={() => setTimeline("5Y")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "5Y" ? "active" : ""
                              }`}
                          >
                            5Y
                          </span>
                          <span
                            onClick={() => setTimeline("Max")}
                            className={`option-scanner-timeline-${(performance ? "up-" : "down-") + themeMode} ${timeline === "Max" ? "active" : ""
                              }`}
                          >
                            Max
                          </span>
                        </div>
                        <div style={{ position: "relative", left: "69px" }}>
                          {Spark}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : <OptionTVContainer themeMode={themeMode} symbol={isPolyREST ? `${symbol} ${row.symbol}OPTION_CONTRACT` :  `${row.symbol}OPTION_CONTRACT`} /> }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rows,
  lookupTable,
  calls,
  expDate,
  livePrice,
  loading,
  getOptionSparkline,
  optionSparkline,
  midpointChange = false,
  themeMode,
  swingTrades = false
}) {
  const classes = useStyles();
  const [order, setOrder] = useState(swingTrades ? "desc" : "asc");
  const [orderBy, setOrderBy] = useState(swingTrades ? "changePercentSinceAdded" : "strike");
  const [currentId, setCurrentId] = useState(null);
  const headCellsBase = [
    {
      id: "symbol",
      numeric: false,
      label: "Contract",

    },
    {
      id: "strike",
      numeric: true,
      label: "Strike",
    },
    {
      id: "bid",
      numeric: true,
      label: "Bid",
    },
    {
      id: "lastPrice",
      numeric: true,
      label: "Price",
    },
    {
      id: "ask",
      numeric: true,

      label: "Ask",
    },
    {
      id: "change",
      numeric: true,
      label: "Change",
    },
    {
      id: "changePercent",
      numeric: true,
      label: "% Change",
    },
    {
      id: "dayVolume",
      numeric: true,
      label: "Day Volume",
    },
    {
      id: "openInterest",
      numeric: true,
      tooltip: "Open Interest",
      label: swingTrades ? 'OI' : 'Open Interest'
    },
    {
      id: "iv",
      numeric: true,
      label: "IV",
      tooltip: 'Implied Volatility'
    },
  ];
  const swingCells = [{
    id: "price_added",
    numeric: true,
    label: "PD",
    tooltip: 'Price Detected'
  },
  {
    id: "changeSinceAdded",
    numeric: true,
    label: "CSA",
    tooltip: 'Change Since Added'
  },
  ]
  const headCells = useMemo(() => {

    if (!swingTrades) {
      return headCellsBase
    } else {
      let concatted = headCellsBase.concat(swingCells)
      concatted.splice(1, 0, {
        id: "dd",
        numeric: false,
        label: "DD",
        tooltip: 'Date Detected'
      })
      concatted.splice(8, 0, {
        id: "changePercentSinceAdded",
        numeric: true,
        label: "PCSA",
        tooltip: "% Change Since Added",
      })
      return concatted;
    }

  }, [swingTrades])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <TableContainer
      className={clsx(classes.background, calls ? "calls" : "puts")}
      style={{ borderRadius: "16px" }}
    >
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <h5 className={classes.cardTitle}> {!swingTrades ? calls ? "Calls" : "Puts" : ''} </h5>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell, i) => (
                  <ConditionalWrapper key={i} condition={!!headCell?.tooltip} wrapper={children => <Tooltip placement="top" title={headCell?.tooltip}>{children}</Tooltip>}>
                    <TableCell
                      key={headCell.id}
                      align={headCell.id === "strike" ? "right" : "inherit"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      className={clsx(classes.text, "row-title")}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  </ConditionalWrapper>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => (
                  <Row
                    midpointChange={midpointChange}
                    optionSparkline={optionSparkline}
                    getOptionSparkline={getOptionSparkline}
                    livePrice={livePrice}
                    expDate={expDate}
                    calls={calls}
                    lookupTable={lookupTable}
                    key={index}
                    row={row}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    themeMode={themeMode}
                    swingTrades={swingTrades}
                  />
                )
              )}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
}
const stateToProps = (state) => ({
  optionSparkline: state.optionSparkline.optionSparkline,
});

const dispatchToProps = (dispatch) => ({
  getOptionSparkline: (symbol, timespan) =>
    dispatch(optionSparklineLoad(symbol, timespan)),
});

export default connect(stateToProps, dispatchToProps)(CollapsibleTable);
