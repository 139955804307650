import React, { useEffect, useMemo, useState } from "react";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import Routes from "./routes/index";
import { Helmet } from "react-helmet";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { themeSetter } from "./appRedux/ducks/themeMode";
import { connect } from "react-redux";
import { cleanUnreadMessageCount, socketListen } from './appRedux/ducks/socket';
import Conversation from './components/TradingView/Conversation';
import { useAuth } from './contexts/Auth';
import logoBlack from "./assets/images/logo-black.png"
import chatIcon from "./assets/images/chat-icon.png"
import logoWhite from "./assets/images/logo.png"
import { chatParticipantsLoad } from './appRedux/ducks/chatParticipants';
import { Badge } from '@material-ui/core';
import { liveChatSetter } from './appRedux/ducks/hideLiveChat';
// import DocusignModal from './components/modals/DocusignModal/index'
import { useMedia } from "react-media";
import InvestorSurvey from "./components/homePage/InvestorSurvey";
import { SendBirdProvider } from 'sendbird-uikit';
import Joyride from "react-joyride";
import { homePageSteps, joyrideStyles } from "./components/common/Joyride/JoyrideHelpers";
import { symbolSetter } from "./appRedux/ducks/symbol";
import WelcomeModal from "./components/homePage/WelcomeModal";
import { addUserLoad } from "./appRedux/ducks/addUsername";
import moment from "moment";
import { addUserSettingsLoad } from "./appRedux/ducks/userSettings";
import { runJourneySetter } from "./appRedux/ducks/runJourney";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
LicenseInfo.setLicenseKey(
  '39597b806ce8caf91d01194c6a78c92aT1JERVI6MzEzMDUsRVhQSVJZPTE2NjY0NjcwOTYwMDAsS0VZVkVSU0lPTj0x',
);

const options = {
  autoConfig: true,
  debug: true,
};

const fbq = ReactPixel;
const channelUrl = process.env.REACT_APP_SENDBIRD_OPEN_CHANNEL_URL; // TA: 'sendbird_open_channel_12232_c4f329285fa22fe2280c1a08ebde56b0baeb42c9'
const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
const App = ({ setUserSettings, showJourney, setShowJourney, userSettings, addUsername, darkflowTable, themeMode, themeToggle, channels, listen, participants, loadParticipants, unreadMessageCount, cleanCount, hideChat, setHideChat, liveChatExpanded, setSymbol }) => {
  const [showChannel, setShowChannel] = useState(false);
  const [run, setRun] = useState(false)
  const history = useHistory();
  const location = useLocation();
  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: "",
  });
  const { user, userData, authUser, canSeeInternal, fetchUser } = useAuth();
  const { pathname } = useLocation();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  useEffect(() => {
    if (!!userData && pathname !== '/signout' && pathname !== '/signout' && !channels.includes('CHAT') && !pathname.includes('live-options') && !pathname.includes('company')) {
      listen(['CHAT', 'TRADE_MESSAGE'])
    }
  }, [pathname, channels, userData])
  const setJourneyViewed = () => {
    setUserSettings({
      ...userSettings,
      journey_completed: true,
    });
    setTimeout(() => {
      fetchUser();
    }, 2400);
  };
  useEffect(() => {
    loadParticipants()
  }, [])
  function JourneyCallbacks({ action, index, status, type }) {
    if (type === 'tour:end') {
      setJourneyViewed()
      setRun(false)
    }
    if (action === 'next' && index === 2 && type === "step:after") {
      const currTicker = darkflowTable?.trending_up[0]?.ticker;
      setSymbol({
        type: "",
        value: currTicker,
      });
      history.push(`/company/${currTicker}`)
    }
    if (action === 'next' && index === 7 && type === "step:after") {
      history.push(`/alerts/Auto/Up`)
    }
    if (action === 'next' && index === 10 && type === "step:after") {
      history.push(`/live-chat/real-time-trading-alerts`)
    }
    if (action === 'next' && index === 15 && type === "step:after") {
      history.push(`/market-analysis`)
    }
    if (action === 'next' && index === 18 && type === "step:after") {
      history.push(`/home/Intraday/Auto/Up`)
    }
  }
  const nickName = useMemo(() => {
    return userData ? userData?.display_name || "" : "";
  }, [JSON.stringify(userData)]);
  useEffect(() => {
    fbq.init("876551646440035", options);
    ReactPixel.init("1091661061298376", options);
  }, []);
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      fbq.pageView();
      ReactPixel.pageView();

      if (pathname.includes("thank-you") && !location.search) {
        ReactPixel.track("Purchase", { currency: "USD", value: 1 });
      } else if (
        pathname.includes("thank-you") &&
        location.search.includes("secondOrder=true")
      ) {
        ReactPixel.track("Purchase", { currency: "USD", value: 58 });
      }
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const utmParams = new URLSearchParams(location.search);
    localStorage.setItem("utm_source", utmParams.get("utm_source"));
    localStorage.setItem("utm_campaign", utmParams.get("utm_campaign"));
    localStorage.setItem("utm_term", utmParams.get("utm_term"));
    localStorage.setItem("utm_content", utmParams.get("utm_content"));
  }, []);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 403) {
        history.push("/signout");
      }

      return Promise.reject(error);
    }
  );
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      MuiPopover: {
        paper: {
          background: themeMode === 'dark' ? "#000" : 'white'
        }
      }
    },
    palette: {
      palette: {
        type: themeMode,
      },
      primary: {
        // background-color: rgba(25, 27, 28, 0.5);
        // color: rgb(201, 194, 186);

        // light: will be calculated from palette.prmary.main,
        main: themeMode === "dark" ? "#001128" : "#0i07bff",
        table: themeMode === "dark" ? "#001528" : "#FFFFFA",
        text: themeMode === "dark" ? "#FFFFFA" : "#34404A",
        secondaryText: themeMode === "dark" ? "#cfd1ff" : "#3c3c4a",
        background: themeMode === "dark" ? "rgb(28, 30, 32)" : "#F8FAFC",
        contrastBackground: themeMode === 'dark' ? "rgba(25, 27, 28, 0.5)" : "white",
        chartBackground: themeMode === "dark" ? "#202020" : "white",
        customSearch: themeMode === "dark" ? "#202020" : "#f1f5f9",
        boxShadow: themeMode === 'dark' ? "0 -2px 10px rgba(0, 0, 0, 1)" : "0 0 10px #e9ebed !important",
        popBoxShadow: themeMode === "dark" ? "0 6px 17px 0 rgba(0, 0, 0, 0.25)" : "0 6px 17px 0 rgba(0, 46, 96, 0.09)",
        hover: themeMode === "dark" ? "#3A3A3D" : "#F5F5F5",
        seperator: themeMode === "dark" ? "#363637" : "#dee1e6",
        specialInput: themeMode === "dark" ? "#3A3A3D" : "white",
        slider: themeMode === "dark" ? "#3d3a3df2" : "#dfd0d073",
        specialBackground: themeMode === 'dark' ? '#181818' : 'white',
        orderBookGreen: themeMode === "dark" ? "rgba(173, 247, 164, 0.12)" : "#adf7a4cf",
        orderBookRed: themeMode === "dark" ? "rgba(134, 10, 10, 0.28)" : "#ffc4c4",
        appHeader: themeMode === "dark" ? "#2C302E" : "#F8FAFC",
        tabHeaderText: themeMode === "dark" ? "#FFFFFF" : "#A1A7BB",
        tableBackground: themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "white",
        skeleton: themeMode === "dark" ? "#3A3A3D" : "lightgrey",
        skeletonBackground: themeMode === "dark" ? "#1a1717" : "white",
        tableHeaderText: themeMode === "dark" ? "white" : "grey",
        searchBg: themeMode === "dark" ? "#202020" : "white",
        searchBg2: themeMode === "dark" ? "rgb(177 177 177 / 71%)" : "#F1F1F1",
        bb: themeMode === "dark" ? "rgb(60, 64, 67)" : "",
        movement: themeMode === "dark" ? "#3e444d" : "#F1F5F9",
        os: themeMode === "dark" ? "rgba(21, 23, 23, 0.5)" : "rgba(255, 255, 255, 0.5)",
        innerWrapper: themeMode === "dark" ? "rgb(1 0 2)" : "white",
        greyTitles: themeMode === "dark" ? "white" : "#64748B",
        description: themeMode === "dark" ? "white" : "#555555",
        tableHeader: themeMode === "dark" ? "white" : "#b7b4c7",
        unusual: themeMode === "dark" ? "white" : "#475569",
        select: themeMode === "dark" ? "grey" : "#eaeaea",
        svg: themeMode === "dark" ? "#cfd1ff" : "#3c3c4a",
        searchBorder: themeMode === 'dark' ? '1px solid rgb(146,145,145)' : '1px solid white'
        // rgb(1 0 2) !important
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });
  useEffect(() => {
    const themeStorage = localStorage.getItem("theme");
    if (themeStorage) {
      themeToggle(themeStorage);
    } else {
      localStorage.setItem("theme", themeMode);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("theme", themeMode)
  }, [themeMode])
  const showWelcomeJourney = useMemo(() => {
    const show = (!!userData && !!authUser) && userData?.sign_in_attempts <= 2 && moment(user?.metadata?.creationTime).isAfter('10/21/2023') && !userData?.userSettings?.journey_completed && pathname.includes('/home')
    if (!!userSettings?.journey_completed) return false
    return show
  }, [userData, authUser, user, pathname, userSettings])
  return useMemo(() => {
    return (
      <ThemeProvider theme={theme}>
        {process.env.REACT_APP_GOOGLEANALYTICS && (
          <Helmet>
            <title>TradeAlgo</title>
            {/* <meta name="description" content="TradersAlgo is a fintech analytics company powering the open finance revolution. We democratize access to the world's most valuable financial data." /> */}
            <script
              async
              src={
                "https://www.googletagmanager.com/gtag/js?id=" +
                process.env.REACT_APP_GOOGLEANALYTICS
              }
            />
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '` +
                process.env.REACT_APP_GOOGLEANALYTICS +
                `');
            `}
            </script>
          </Helmet>
        )}
        {canSeeInternal && !showChannel && !pathname.includes('live-chat') && !pathname.includes('signin') && !pathname.includes('options-academy') && !pathname.includes('signup') && !pathname.includes('basics-training') && !pathname.includes('advanced-training') && !pathname.includes('live-walkthrough') && !pathname.includes('market-analysis') && !pathname.includes('tutorials') && !hideChat && (
          <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 1000 }}>
            <div
              className='chat-div'
              onClick={() => {
                if(!!userData?.display_name) {
                  setShowChannel(true)
                  cleanCount()
                } else {
                  history.push('/modify-username')
                }
              }}
            >
              <img style={{ height: '21.33px', width: '19.9px' }} src={themeMode === 'dark' ? logoWhite : logoBlack} />
              <div className="chat-inner-div">
                <span className="chat-span">
                  Chat
                </span>
                <span className='chat-span-bottom'>
                  {participants} participants
                </span>
              </div>
              <Badge overlap="circular" className="badge-icon" badgeContent={unreadMessageCount}>
                <img src={chatIcon} className="chat-icon" />
              </Badge>
            </div>
          </div>
        )}
        {
          (!!nickName.length && !!authUser && !pathname.includes('live-chat') && showChannel) && (
            <SendBirdProvider
              appId={appId}
              userId={authUser}
              nickname={nickName}
              stringSet={stringSet}
            >
              {
                !hideChat && (
                  <div className={`sendbird-theme--${themeMode}  `}>
                    <Conversation
                      onClose={() => {
                        setShowChannel(false);
                        setTimeout(() => {
                          setHideChat(hideChat, liveChatExpanded)
                        }, 100)
                      }}
                      channelUrl={channelUrl}
                    />
                  </div>
                )
              }
            </SendBirdProvider>
          )
        }
        {/* <DocusignModal /> */}
        {!isMobile && <InvestorSurvey />}
        {((showJourney || showWelcomeJourney) && !run) && (
          <WelcomeModal user={userData} fetchUser={fetchUser} setJourneyViewed={setJourneyViewed} setShowJourney={setShowJourney} addUsername={addUsername} setRun={setRun} />
        )}
        {<Joyride
          styles={joyrideStyles}
          run={run}
          steps={homePageSteps}
          continuous
          hideCloseButton
          showSkipButton
          scrollToFirstStep
          callback={JourneyCallbacks}
        />}
        <Routes />
      </ThemeProvider>
    );
  }, [theme, pathname, hideChat, canSeeInternal, showJourney, run, showWelcomeJourney, userData]);
};


const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  channels: state.Socket.channels,
  participants: state.chatParticipants.chatParticipants,
  messages: state.Socket.snackbarMessages,
  unreadMessageCount: state.Socket.unreadMessagesCount,
  hideChat: state.hideLiveChat.hideChat,
  liveChatExpanded: state.hideLiveChat.liveChatExpanded,
  darkflowTable: state.darkflowTable,
  userSettings: state.userSettings.userSettings,
  showJourney: state.runJourney.run
});

const dispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),
  listen: (channels) => dispatch(socketListen(channels)),
  loadParticipants: () => dispatch(chatParticipantsLoad()),
  cleanCount: () => dispatch(cleanUnreadMessageCount()),
  setHideChat: (open, liveChatExpanded) => dispatch(liveChatSetter(open, liveChatExpanded)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  addUsername: (username) => dispatch(addUserLoad(username)),
  setShowJourney: (run) => dispatch(runJourneySetter(run)),
  setUserSettings: (settings, firstRender) => dispatch(addUserSettingsLoad(settings, firstRender)),
})

export default connect(stateToProps, dispatchToProps)(App);
