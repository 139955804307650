import React, { useMemo, useState } from 'react';
import './Sentiment.scss';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Progress } from 'reactstrap';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from '@mui/material';
import { connect } from 'react-redux';
import moment from 'moment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip'
;
function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}
function categorizeSentiment(score) {
    if (score >= 76) {
        return 'Very Bullish';
    } else if (score >= 51) {
        return 'Bullish';
    } else if (score >= 26) {
        return 'Slightly Bullish';
    } else if (score >= -25 && score < 26) {
        return 'Neutral';
    } else if (score >= -50) {
        return 'Slightly Bearish';
    } else if (score >= -75) {
        return 'Bearish';
    } else {
        return 'Very Bearish';
    }
}
function categorizeNews(sentimentScore) {
    if (sentimentScore >= 76) {
        return 'Very Bullish';
    } else if (sentimentScore >= 51) {
        return 'Bullish';
    } else if (sentimentScore >= 26) {
        return 'Slightly Bullish';
    } else if (sentimentScore >= -25 && sentimentScore < 26) {
        return 'Neutral';
    } else if (sentimentScore >= -50) {
        return 'Slightly Bearish';
    } else if (sentimentScore >= -75) {
        return 'Bearish';
    } else {
        return 'Very Bearish';
    }
}
const SentimentDashboard = ({ news, newsSentiment, aiScores }) => {
    const [value, setValue] = useState('ai_scores_5min');
    const [newsIndex, setNewsIndex] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleLeftArrowClick = () => {
        setNewsIndex((prev) => prev - 1)
    };

    const handleRightArrowClick = () => {
        setNewsIndex((prev) => prev + 1)
    };
    const currNews = useMemo(() => {
        return news[newsIndex]
    }, [newsIndex, news])
    const numericNewsSentiment = Number(newsSentiment);
    const disabledLeft = !news[newsIndex - 1]
    const disabledRight = !news[newsIndex + 1]
    const decodedTitle = decodeHtml(currNews?.title || '');
    const content = currNews?.description || '';

    const aiScore = useMemo(() => {
        return aiScores?.find((item) => item?.table_name === value) || { sentiment: "" }
    }, [aiScores, value]);
    const hasAiData = !!aiScore?.sentiment?.length;
    const sentimentLabel = hasAiData ? categorizeSentiment(aiScore.ai_score) : 'Neutral';
    const newsSentimentCategory = categorizeNews(numericNewsSentiment);
    return (
        <div className={`sentiment-dashboard ${!hasAiData ? 'no-ai-data' : ''} ${!news.length ? 'no-news-sentiment' : ''}`}>
            {
                hasAiData && (
                    <div className="overall-sentiment">
                        <div className={`${aiScore?.ai_score > 0 ? 'sentiment-positive' : aiScore?.ai_score < 0 ? 'sentiment-negative' : ''}`}>
                            <div className='sentiment-overall'>
                                Overall Sentiment
                                <Tooltip arrow={true}
                                    title={
                                        <React.Fragment>
                                            The likelihood of certain events or outcomes is calculated based on 20 years of historical data and financial metrics.
                                            <br /><br />
                                            Based on the probabilities from both technical and financial metrics, joint probability is used to determine whether the Overall Sentiment is bullish or bearish sentiment.
                                        </React.Fragment>
                                    }
                                >
                                    <ErrorOutlineIcon className='icon-space'/>
                                </Tooltip>
                            </div>
                            <div  className={aiScore?.ai_score > 0 ? 'sentiment-bullish' : 'sentiment-bearish'}>
                                {sentimentLabel}
                            </div>
                        </div>

                        <TabContext value={value}>
                            <TabList onChange={handleChange} className="tabList" variant="fullWidth" >
                                <Tab label="5m" value="ai_scores_5min" />
                                <Tab label="15m" value="ai_scores_15min" />
                                <Tab label="1h" value="ai_scores_60min" />
                                <Tab label="4h" value="ai_scores_4h" />
                                <Tab label="1d" value="ai_scores_24h" />
                            </TabList>
                        </TabContext>

                        <div className="sentiment-details">
                            <div className='bb-text'>
                                Bullish / Bearish Percentage
                                <Tooltip arrow={true}
                                    title={
                                        "Based on 20 years of candlestick pattern and financial data, we use machine learning to predict the likelihood of a bullish or bearish sentiment."
                                    }
                                >
                                    <ErrorOutlineIcon className='icon-space'/>
                                </Tooltip>
                            </div>
                            <div className="time-intervals">
                                <span className='interval-text-g'>{!!aiScore?.bull_score ? aiScore?.bull_score.toFixed(2) : 0}%</span>
                                <Progress className='green-bar' value={!!aiScore?.bull_score ? aiScore?.bull_score.toFixed(2) : 0} />
                            </div>
                            <div className="score">
                                <span className='interval-text-r'>{!!aiScore?.bear_score ? aiScore?.bear_score.toFixed(2) : 0}%</span>
                                <Progress className='red-bar' value={!!aiScore?.bear_score ? aiScore?.bear_score.toFixed(2) : 0} />
                            </div>
                            <div className="ai-probability">
                                AI Probability Score
                                <Tooltip arrow={true}
                                    title={
                                        "Our system calculates an A.I Probability scoring based on the Bullish and Bearish Percentage."
                                    }
                                >
                                    <ErrorOutlineIcon className='icon-space'/>
                                </Tooltip>
                            </div>
                            <div className="ai-probability-score">
                                <span className={aiScore?.ai_score < 0 ? 'interval-text-r' : 'interval-text-g'}>{!!aiScore?.ai_score ? aiScore?.ai_score.toFixed(2) : 0}%</span>
                                <Progress className={aiScore?.ai_score >= 50 ? `green-bar` : 'red-bar'} value={!!aiScore?.ai_score ? Math.abs(aiScore?.ai_score).toFixed(2) : 0} />
                            </div>
                        </div>

                    </div>
                )
            }
            {
                !!news.length && (
                    <div className="news-section">
                        <div className={`${newsSentiment >= 0 ? 'news-positive' : 'news-negative'}`}>
                            <div className='news-text'>News Sentiment</div>
                            <div className={`sentiment-score ${newsSentiment >= 0 ? 'sentiment-num-positive' : 'sentiment-num-negative'}`}>
                                {newsSentimentCategory}
                            </div>
                        </div>
                        <div className="recent-events">
                            <div className='events-header'>
                                <div className="header-left">
                                    Recent Events
                                </div>
                                <div className="header-right">
                                    <IconButton disabled={disabledLeft} sx={{ opacity: disabledLeft ? 0.5 : 1 }} onClick={handleLeftArrowClick}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton >
                                    <IconButton disabled={disabledRight} sx={{ opacity: disabledRight ? 0.5 : 1 }} onClick={handleRightArrowClick}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className='events-title'>
                                {decodedTitle || ""}
                            </div>
                            <div className='published'>
                                Published on {moment(currNews?.published_utc).format('MMM Do YYYY')}
                            </div>
                            <div className='news-content'>
                                {truncateText(content, 250) || ''}
                            </div>
                            <a href={currNews?.article_url} target='_blank' rel="noreferrer" className='read-more'>
                                Read More
                            </a>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const stateToProps = (state) => ({
    // tickerDetails: state.companyData.companyData.tickerDetails,
    themeMode: state.themeMode.themeMode,
    companyDataLoading: state.companyData.companyDataLoading,
    news: state.companyData.companyData.news,
    newsSentiment: state.companyData.companyData.newsSentiment,
    aiScores: state.companyData.companyData.aiScores,
});


export default connect(stateToProps, null)(SentimentDashboard);
