import * as React from "react"

const LiveWalkIcon = ({fill}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.83 239.7" fill={fill}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path d="M239.69 115c-1.64-33.65-15.16-62.12-40.18-84.64-19.35-17.47-42.1-27.47-67.59-29.78-4.23-.39-8.45-.58-12.55-.58-32.75 0-61.43 12.2-85.25 36.27C15 55.56 3.75 79.59.58 107.68l-.58 5.1v14.27c.18 1.39.35 2.78.52 4.18.37 3 .75 6.15 1.32 9.24 6.66 35.58 25.79 63.28 56.86 82.32a114.47 114.47 0 0 0 52.36 16.52 5 5 0 0 1 .66.15c.22.06.44.12.66.16l.32.08h14.5c1.42-.19 2.84-.36 4.26-.53 3.09-.36 6.28-.74 9.44-1.36 40.07-7.87 69.53-30.47 87.56-67.16A112.35 112.35 0 0 0 239.69 115Zm-52.4-62.38A95.18 95.18 0 1 1 52.52 187a94.44 94.44 0 0 1-27.84-67.3 95.41 95.41 0 0 1 95.22-95.12v-3 3a94.6 94.6 0 0 1 67.39 27.99Z" />
        <path d="M157.13 71.86a118.08 118.08 0 0 0-12.62 4.55q-36.54 14.15-73 28.34c-2.38.93-4.55 2.12-5.9 4.41-3.05 5.14-1 10.93 4.88 13.73 9.81 4.65 19.68 9.18 29.46 13.9a7 7 0 0 1 3 3c4.69 9.71 9.25 19.48 13.8 29.25 1.82 3.92 4.62 6.34 8.85 6.46 4.46 0 7.5-2.48 9.39-7.32q13.44-34.59 26.88-69.2c2.11-5.45 4.43-10.85 6-16.45 1.79-6.29-4.33-12.35-10.74-10.67Z" />
      </g>
    </g>
  </svg>
)

export default LiveWalkIcon
