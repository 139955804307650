import React, { useCallback, useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import axios from "axios";
import Cookies from 'universal-cookie';
import AuthContext from "./context";
import { analytics, auth } from "../../firebase/firebase";
import { apiURL } from "../../appRedux/actions/helpers";
import { useSnackbar } from 'notistack';
import moment from "moment";
import firebase from 'firebase/app';
import 'firebase/auth';
import { parsePhoneNumber } from 'libphonenumber-js'
const env = process.env['REACT_APP_ENV']
const ssoAtStr = `sso_at_${env}`
const sapStr = `sso_at_present_${env}`
export function rmAllCookiesAndStorage() {
  console.log("removing all cookies -----")
  //clear all cookies and storage to avoid any token mixups
  localStorage.clear()
  const cookies = new Cookies();
  // Remove SSO at Cookie
  cookies.remove(ssoAtStr, { path: '/', domain: '.tradealgo.com', secure: true, sameSite: 'strict' });
  cookies.remove(sapStr, { path: '/', domain: '.tradealgo.com', secure: true, sameSite: 'strict' });
}
function findData(user) {
  let displayName = ''
  let phoneNumber = ''
  if (user.providerData.length) {
    user.providerData.forEach((item) => {
      if (!!item.displayName) {
        displayName = item.displayName
      }
      if (!!item.phoneNumber) {
        phoneNumber = item.phoneNumber
      }
    })
  }
  return { displayName, phoneNumber }
}
const AuthProvider = ({ children }) => {
  const [dashboards, setDashboards] = useState([]);
  const [user, setUser] = useState(null);
  const [completeSignupMeta, setCompleteSignupMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signInLoading, setSignInLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    isError: false,
    links: false,
  });
  const [fetching, setFetching] = useState(false);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth_token")
  );
  const [userData, setUserData] = useState(null);
  const [userPinnedStocks, setUserPinnedStocks] = useState([]);
  const [authUser, setAuthUser] = useState(localStorage.getItem("user_id"));
  const [role, setRole] = useState("");
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  // membership
  const [memberShipPlan, setMemberShipPlan] = useState("");
  const [, setHasAddon] = useState(false);

  // user subscription check
  const [isUserBasic, setIsUserBasic] = React.useState(false);
  const [successRequestForDemo, setSuccessRequestForDemo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const tierLevel = useMemo(() => {
    // no flag has access to everything for now
    const today = moment()
    const isDefault = !userData?.feature_flags
    if (loading || !userData) return 1
    if (isDefault) return 1
    // basic is restriced access
    const isBasic = !!userData?.feature_flags && (userData?.feature_flags?.basic?.active || false) && moment(today).isBefore(userData?.feature_flags?.basic?.expires)
    // premium has features yet to be developed
    const isPremium = !!userData?.feature_flags && (userData?.feature_flags?.platinum?.active || false) && moment(today).isBefore(userData?.feature_flags?.platinum?.expires)
    if (isPremium) return 2
    if (isBasic) return 0
    // if userData has not been fetched yet
    else return 1
  }, [userData, loading])
  // options academy
  // const hasOptionsAcademy = false
  const hasOptionsAcademy = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.options_academy?.active) return true
    return false
  }, [userData, loading])

  // starter
  const hasOptionsMentorshipStarter = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.options_mentorship_starter?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsStarter = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.options_mentorship_starter?.training_sessions_total || 0
  }, [userData, loading])

  // Intermediate
  const hasOptionsMentorshipIntermediate = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.options_mentorship_intermediate?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsIntermediate = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.options_mentorship_intermediate?.training_sessions_total || 0
  }, [userData, loading])

  // Advanced
  const hasOptionsMentorshipAdvanced = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.options_mentorship_advanced?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsAdvanced = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.options_mentorship_advanced?.training_sessions_total || 0
  }, [userData, loading])

  // platinum
  const hasPlatinumProgram = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.platinum?.active) return true
    return false
  }, [userData, loading])
  const mentorshipSessionsPlatinum = useMemo(() => {
    if (loading || !userData) return 0
    return userData?.feature_flags?.platinum?.training_sessions_total || 0
  }, [userData, loading])
  // emarld
  const hasEmerald = useMemo(() => {
    if (loading || !userData) return false
    if (!!userData?.feature_flags?.emerald?.active) return true
    return false
  }, [userData, loading])
  // shared
  const canSeeInternal = useMemo(() => {
    if (loading || !userData || !userData.feature_flags || !Object.keys(userData?.feature_flags || {}).length) {
      console.log('0-------------')
      return false
    }
    // if they have any options mentorship package or the platinum program, allow access
    if (hasPlatinumProgram || hasOptionsMentorshipAdvanced || hasOptionsMentorshipIntermediate || hasOptionsMentorshipStarter || hasEmerald) {
      console.log('1-------------')
      return true
    }
    // active base and data version 2 - can see
    if (userData?.feature_flags?.default?.active && userData?.data_version === 2) {
      console.log('2-------------')
      return true
    }
    // active tradegpt premium subscription
    if (userData?.feature_flags?.tradegpt_premium?.active) {
      console.log('2.5-------------')
      return true
    }
    // active terminal plus subscription
    if (userData?.feature_flags?.terminal_plus?.active) {
      console.log('3-------------')
      return true
    }
    // base user no data version - can see
    if (!userData?.data_version) {
      console.log('4-------------')
      return true
    }
    // inactive base - deny access
    if (!!userData.feature_flags?.default && !userData?.feature_flags?.default?.active) {
      console.log('5-------------')
      return false
    }
    // no base, data_version = 2 - deny access
    if (!userData?.feature_flags?.default && userData?.data_version === 2) {
      console.log('6-------------')
      return false
    }
    return false
  }, [loading, userData, hasPlatinumProgram, hasOptionsMentorshipAdvanced, hasOptionsMentorshipIntermediate, hasOptionsMentorshipStarter, hasEmerald]);
  const mentorshipSessionsUsed = useMemo(() => {
    const init = {
      options_mentorship_starter: 0,
      options_mentorship_platinum: 0,
      options_mentorship_intermediate: 0,
      options_mentorship_advanced: 0,
    }
    if (loading || !userData) return init
    return userData?.training_sessions_used || init
  }, [userData, loading])

  const showCTABanner = useMemo(() => {
    const hasAnyMentorshipTier = hasPlatinumProgram || hasOptionsMentorshipAdvanced || hasOptionsMentorshipIntermediate || hasOptionsMentorshipStarter
    const starterSessions = (userData?.feature_flags?.options_mentorship_starter?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_starter || 0
    const intermediateSessions = (userData?.feature_flags?.options_mentorship_intermediate?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_intermediate || 0
    const advancedSessions = (userData?.feature_flags?.options_mentorship_advanced?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_advanced || 0
    const platinumSessions = (userData?.feature_flags?.platinum?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_platinum || 0
    const sessionsTotalLeft = starterSessions + intermediateSessions + advancedSessions + platinumSessions;
    return hasAnyMentorshipTier && (!!sessionsTotalLeft)
  }, [hasPlatinumProgram, hasOptionsMentorshipAdvanced, hasOptionsMentorshipIntermediate, hasOptionsMentorshipStarter, userData, loading, mentorshipSessionsUsed])

  const CTABannerLink = useMemo(() => {
    const hasAnyMentorshipTier = hasPlatinumProgram || hasOptionsMentorshipAdvanced || hasOptionsMentorshipIntermediate || hasOptionsMentorshipStarter
    const platinumSessions = (userData?.feature_flags?.platinum?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_platinum || 0
    const intermediateSessions = (userData?.feature_flags?.options_mentorship_intermediate?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_intermediate || 0
    const advancedSessions = (userData?.feature_flags?.options_mentorship_advanced?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_advanced || 0
    const starterSessions = (userData?.feature_flags?.options_mentorship_starter?.training_sessions_total || 0) - mentorshipSessionsUsed?.options_mentorship_starter || 0
    if (hasAnyMentorshipTier) {
      if (platinumSessions > 0) return "/options-mentorship/platinum"
      if (advancedSessions > 0) return "/options-mentorship/advanced"
      if (intermediateSessions > 0) return "/options-mentorship/intermediate"
      if (starterSessions > 0) return "/options-mentorship/starter"
    }
  }, [hasPlatinumProgram, hasOptionsMentorshipAdvanced, hasOptionsMentorshipIntermediate, hasOptionsMentorshipStarter, userData, loading, mentorshipSessionsUsed])

  const setClaims = useCallback(async () => {
    try {
      const result = await auth.currentUser.getIdTokenResult();

      if (result.claims.isAdmin) {
        setRole("admin");
      } else {
        setRole("customer");
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setClaims();
      } else {
        // removeAuth();
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setClaims]);

  const setAuth = useCallback((token, user, email) => {
    setAuthToken(token);
    setAuthUser(user);

    localStorage.setItem("user_id", user);
    localStorage.setItem("auth_token", token);
    localStorage.setItem("email", email);
  }, []);

  const removeAuth = useCallback(() => {
    setAuthToken(null);
    setAuthUser(null);
    setRole(null);

    localStorage.removeItem("user_id");
    localStorage.removeItem("auth_token");
  }, []);

  const getIdToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken();
    } catch (error) {
      return error;
    }
  }, []);

  const getRefreshToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken(true);
    } catch (error) {
      return error;
    }
  }, []);

  const handleErrorMessages = useCallback((error) => {
    const connectionError = error.message.includes(
      "The database connection is closing"
    );

    if (connectionError) {
      return setError({
        message:
          "Your session timed out. Please refresh the browser and try again.",
        isError: true,
        links: true,
      });
    } else {
      enqueueSnackbar(error.message, { variant: 'error' })
      // message.error(error.message);
    }
  }, []);

  const checkoutSessionRequest = useCallback(
    async (email, phone, firstName, lastName, user, couponId) => {
      try {
        const response = await axios.post(`${apiURL}/checkout`, {
          email: email,
          phone: phone,
          user_id: user,
          plan: couponId,
          firstName: firstName,
          lastName: lastName,
          utm_source: localStorage.getItem("utm_source"),
          utm_campaign: localStorage.getItem("utm_campaign"),
          utm_term: localStorage.getItem("utm_term"),
          utm_content: localStorage.getItem("utm_content"),
        }, { withCredentials: true });

        setCheckoutSessionId(response.data.session.id);
      } catch (error) {
        return error;
      }
    },
    []
  );

  const authenticateRequest = useCallback(async (tokenId) => {
    const cookies = new Cookies();

    try {
      const response = await axios.post(`${apiURL}/authenticate`, {
        token: tokenId,
      }, { withCredentials: true });

      // Use optional chaining to check for the success in the response
      const success = response?.data?.success;
      if (success) {
        const ssoToken = response?.data[ssoAtStr];
        // Set a cookie with the token using universal-cookie
        cookies.set(ssoAtStr, ssoToken, { domain: '.tradealgo.com', path: '/', secure: true, sameSite: 'strict' });
      }

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const authenticate = useCallback(async () => {
    try {
      setFetching(true);
      await getRefreshToken();

      const tokenId = await getIdToken();
      const user = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const tokenResult = await authenticateRequest(tokenId);

      if (tokenResult.data.error_code) {
        throw new Error(tokenResult.data.message);
      }

      setAuth(tokenId, user, email);
    } catch (error) {
      setError({ ...error, isError: true });
      removeAuth();
      enqueueSnackbar(error.message, { variant: 'error' })
      // message.error(error.message);
    } finally {
      setFetching(false);
    }
  }, [authenticateRequest, getIdToken, getRefreshToken, removeAuth, setAuth]);

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURL}/user`, { withCredentials: true });
      if (response.status === 200) {
        // setIsUserBasic(response.data.user.user_type !== "prime");
        setIsUserBasic(false);
        setUserData(response.data.user);
        analytics.setUserProperties({
          crm_id: response.data.user.userId,
        });
        analytics.setUserId(response.data.user.userId);
        if (_get(response, "data.pinnedStocks.length") > 1) {
          const stocks = response.data.pinnedStocks.map(
            (pinnedStock) => pinnedStock.ticker
          );
          setUserPinnedStocks(stocks);
        }
        setDashboards(response.data.dashboards);
        setLoading(false);
        const cookies = new Cookies();
        const ssoToken = response?.data?.customToken;
        if (ssoToken) {
          cookies.set(ssoAtStr, ssoToken, { domain: '.tradealgo.com', path: '/', secure: true, sameSite: 'strict' });
        }

        return true;
      }
    } catch (error) {
      setLoading(false);
      return error;
    }
  }, []);

  const signIn = useCallback(
    async (data) => {
      try {
        console.log("test sign in --------")
        setLoading(true);
        setSignInLoading(true);
        const response = await auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );

        const tokenId = await getIdToken();
        const tokenResult = await authenticateRequest(tokenId);
        // if the sign in was a success and the user has signed in < 10 times
        if (!!response?.user?.uid && Number((response?.user?.sign_in_attempts || 0)) < 10) {
          // log his sign in attempt
          const res = await axios.get(`${apiURL}/signed_in_log`, { withCredentials: true });
        }
        if (tokenResult.data.error_code) {
          if (
            tokenResult.data.error_code === "PAYMENT_INCOMPLETE" ||
            tokenResult.data.error_code === "USER_NOT_FOUND"
          ) {
            setAuthUser(response.user.uid);
            return await checkoutSessionRequest(
              data.email,
              null,
              null,
              null,
              response.user.uid
            );
          } else {
            throw new Error(tokenResult.data.message);
          }
        }

        await getRefreshToken();
        await setClaims();
        await fetchUser();
        setAuth(tokenId, response.user.uid, response.user.email);
        setSignInLoading(false);
        // Record successful auth
      } catch (error) {
        setLoading(false);
        setSignInLoading(false);
        const customErrorMessage = `Incorrect Email or Password`;
        enqueueSnackbar(customErrorMessage, { variant: 'error' });
      }
    },
    [
      authenticateRequest,
      checkoutSessionRequest,
      getIdToken,
      getRefreshToken,
      setAuth,
      setClaims,
      fetchUser,
      enqueueSnackbar,
    ]
  );

  const signInWithProvider = useCallback(
    async (provider) => {
      try {
        setSignInLoading(true);
        rmAllCookiesAndStorage()
        let authProvider;
        switch (provider) {
          case 'google':
            authProvider = new firebase.auth.GoogleAuthProvider();
            break;
          case 'microsoft':
            authProvider = new firebase.auth.OAuthProvider('microsoft.com');
            break;
          case 'apple':
            authProvider = new firebase.auth.OAuthProvider('apple.com');
            break;
          default:
            throw new Error('Unsupported provider');
        }
        const result = await firebase.auth().signInWithPopup(authProvider);
        // The rest of your logic...
        const tokenId = await getIdToken();
        const tokenResult = await authenticateRequest(tokenId);
        const user = result.user;
        // console.log({ tokenId, user, tokenResult })
        const uid = user.uid
        const email = user?.email || "";
        let phone = user?.phoneNumber || ""
        let name = user?.displayName || ""
        let country = user?.country || ""
        let { phoneNumber, displayName } = findData(user)
        if (displayName?.length > name?.length) {
          name = displayName
        }
        if (phone?.length < phoneNumber?.length) {
          phone = phoneNumber
        }
        if (!!phone.length) {
          country = parsePhoneNumber(phone)?.country || "";
        }
        const firestoreFields = {
          email,
          phoneNumber: phone,
          displayName,
          country
        }
        const updateFields = await axios.put(`${apiURL}/user/fields`, { ...firestoreFields }, { withCredentials: true });
        await getRefreshToken();
        await setClaims();
        setAuth(tokenId, uid, email);
        setSignInLoading(false);
        // Record successful auth
      } catch (error) {
        setLoading(false);
        console.log(error)
        handleErrorMessages(error);
        setSignInLoading(false);
      }
    },
    [
      authenticateRequest,
      checkoutSessionRequest,
      getIdToken,
      getRefreshToken,
      handleErrorMessages,
      setAuth,
      setClaims,
      fetchUser,
    ]
  );

  const signUp = useCallback(
    async (data, plan = 1) => {
      try {
        setLoading(true);

        const response = await auth.createUserWithEmailAndPassword(
          data.email,
          data.password
        );

        await axios.post(`${apiURL}/signup`, {
          userId: response.user.uid,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        }, { withCredentials: true });

        const tokenId = await getIdToken();
        await authenticateRequest(tokenId);

        await getRefreshToken();
        await setClaims();
        await fetchUser();

        setAuth(tokenId, response.user.uid, response.user.email);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setMemberShipPlan("");
        enqueueSnackbar(error.message, { variant: 'error' })
        // message.error(error.message);
      }
    },
    [
      authenticateRequest,
      fetchUser,
      getIdToken,
      getRefreshToken,
      setAuth,
      setClaims,
    ]
  );

  const completeSignup = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const response = await axios.post(`${apiURL}/complete-signup`, {
          email: data.email,
          password: data.password,
        }, { withCredentials: true });

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        if (response.data.success) {
          setLoading(false);
          setCompleteSignupMeta(response.data.userMeta);
          return true;
        }

        return false;
      } catch (error) {
        setLoading(false);
        handleErrorMessages(error);
        return false;
      } finally {
      }
    },
    [handleErrorMessages]
  );

  const signOut = useCallback(async () => {
    try {
      rmAllCookiesAndStorage()
      removeAuth();
      setIsUserBasic(false);
      setUserData(null);
      setUser(null);
      setUserPinnedStocks([]);
      await axios.post(`${apiURL}/signout`, {}, { withCredentials: true });
      await auth.signOut();
    } catch (error) {
      handleErrorMessages(error);
    }
  }, [handleErrorMessages, removeAuth]);

  const sendPasswordResetEmail = useCallback(async (email) => {
    try {
      let actionCodeSettings = {
        url: `http://${process.env.REACT_APP_REDIRECT_DOMAIN}/signin`,
        handleCodeInApp: false,
      };
      await auth.sendPasswordResetEmail(email, actionCodeSettings);

      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateUser = useCallback((newUserData) => {
    setUserData(newUserData);
  }, []);

  const sendRequestForDemo = useCallback(async (requestDetails) => {
    setLoading(true);
    try {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://hooks.zapier.com/hooks/catch/8839259/op2zsdu");
      await xhr.send(JSON.stringify(requestDetails));

      setSuccessRequestForDemo(true);
      setLoading(false);
      console.log("Pushed to Zapier successfully!");
    } catch (e) {
      setSuccessRequestForDemo(false);
      setLoading(false);
      console.error(e);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      memberShipPlan,
      setMemberShipPlan,
      successRequestForDemo,
      setSuccessRequestForDemo,
      sendRequestForDemo,
      setHasAddon,
      tierLevel,
      hasOptionsMentorshipStarter,
      mentorshipSessionsStarter,
      mentorshipSessionsUsed,
      hasPlatinumProgram,
      mentorshipSessionsPlatinum,
      hasOptionsMentorshipIntermediate,
      mentorshipSessionsIntermediate,
      hasOptionsMentorshipAdvanced,
      mentorshipSessionsAdvanced,
      showCTABanner,
      CTABannerLink,
      hasOptionsAcademy,
      canSeeInternal,
      signInWithProvider,
      setAuth,
      hasEmerald
    }),
    [
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      memberShipPlan,
      setMemberShipPlan,
      successRequestForDemo,
      setSuccessRequestForDemo,
      sendRequestForDemo,
      setHasAddon,
      tierLevel,
      hasOptionsMentorshipStarter,
      mentorshipSessionsStarter,
      mentorshipSessionsUsed,
      hasPlatinumProgram,
      mentorshipSessionsPlatinum,
      hasOptionsMentorshipIntermediate,
      mentorshipSessionsIntermediate,
      hasOptionsMentorshipAdvanced,
      mentorshipSessionsAdvanced,
      showCTABanner,
      CTABannerLink,
      hasOptionsAcademy,
      canSeeInternal,
      signInWithProvider,
      setAuth,
      hasEmerald
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
