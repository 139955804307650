export default {
  lg: [
    {
      w: 4,
      h: 3,
      x: 0,
      y: 0,
      i: "0",
      ticker: 'AAPL',
      static: false,
      studies: []
    },
    {
      w: 4,
      h: 3,
      x: 4,
      y: 0,
      i: "1",
      ticker: 'TSLA',
      "static": false,
      studies: []
    },
    {
      w: 4,
      h: 3,
      x: 8,
      y: 0,
      i: "2",
      ticker: 'SHOP',
      "static": false,
      studies: []
    },
    {
      w: 4,
      h: 3,
      x: 0,
      y: 3,
      i: "3",
      ticker: "AMD",
      "static": false,
      studies: []
    },
    {
      w: 4,
      h: 3,
      x: 4,
      y: 3,
      i: "4",
      ticker: "NVDA",
      "static": false,
      studies: []
    },
    {
      w: 4,
      h: 3,
      x: 8,
      y: 3,
      i: "5",
      ticker: "SPY",
      "static": false,
      studies: []
    },
  ],
};