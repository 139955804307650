import React, { useMemo } from "react";
import Button from '@mui/material/Button';
import "./index.scss";

const MentorshipPlans = ({ level }) => {
  const submit = () => {
    if (level === 'starter') {
      window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_dpZFHJJgR9S3nfmtdqlU9lUKmI6-c_-n30q4hG3f-1Pcj3KS8YlbiVU431pAXdbtlHGlvw8Jkbdj3AG3SC1ep62Ai2MvlGtc4CMk2Z7g8=";
    }

    if (level === 'intermediate') {
      window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_JpZFHJJgR9S3nfmtdqlU9lULOS_vA58mqwjZlG0bCUPpzTIy8f2oeDVZDq9l7mYsUBYW3n6ppoVT3ZE3SAxvZ_miO2M_UNhfAvYVD_n2X0vyBI";
    }

    if (level === 'advanced') {
      window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1_Mn9ZOuERtH9q97uMq8WyxgNh9xOSyIYbJbAwJlL6JSZft7V2uI-ZEJVjR445NVW_pBDurlN1zyQ-1L_wdjrgCWiaX8Ar2";
    }

    if (level === 'platinum') {
      window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1vSn8ZCl11nUNKv7_ktzGqmkp0S8uK0I8j-LTIEmKWHSYCs23LRMowYfA==";
    }
  }

  const priceObj = useMemo(() => {
    if (level === 'starter') {
      return '$749.50';
    }

    if (level === 'intermediate') {
      return '$2,450';
    }

    if (level === 'advanced') {
      return '$7,000';
    }

    if (level === 'platinum') {
      return '$9,995';
    }
  }, [level])

  return (
    <div className="mentorship-plans-page">
      <div className="mentorship-plans-page-header">
        <div className="mentorship-plans-page-header-title">
          You have no more<br />sessions left
        </div>
        <div className="mentorship-plans-page-header-subtitle">Click here to renew your mentorship</div>
        <div>USD <span className="dollas">{priceObj}</span> </div>
        <Button className="mentorship-plans-page-header-renew-button" onClick={submit.bind(this)}>Renew Mentorship</Button>
      </div>
    </div>
  )
}

export default MentorshipPlans;