import React from "react";
import { connect } from "react-redux";
import { themeSetter } from "../../../appRedux/ducks/themeMode";
import FooterBar from "./FooterBar";

const PublicLayout = (props) => {
  const { children} = props;
  return (
    <>
      {/* <CustomHeader logoIcon={<LogoIcon />}/> */}
      <main
        className={'login-page-content'}
      >
        {children}
      </main>
      {/* <FooterBar /> */}
    </>
  );
};



const mapDispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),
});

export default connect(null, mapDispatchToProps)(PublicLayout);
PublicLayout.defaultProps = {
  fixedPage: "",
  pageCenter: ""
};
