import logo from "../../assets/images/logo-black.png";

export const ModalTitle = ({ title }) => (
    <div className="first-modal-title">
        <img style={{ width: "28px" }} src={logo} />
        <div
            className="modal-gradient-text-2"
        >
            {title}
        </div>
    </div>
)

export const FinishModal = ({ title }) => (
    <div className="d-flex flex-col justify-center align-items-center">
        <img style={{ width: "40px" }} src={logo} />
        <div
            className="modal-gradient-text-2 mt-10"
        >
            {title}
        </div>
    </div>
)