
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Company from "./Company";
import MutualFund from "./MutualFund/MutualFund";
import Etf from "./Etf/Etf";
import UnusualActivity from "./UnusualActivity";
import "./index.scss";
import "./Equity.scss";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { FundamentalData } from "react-tradingview-embed";
import { numberWithCommas } from "../utilities";
import { CardBody, Card } from "reactstrap";
import { makeStyles } from "@material-ui/core";
import LiveOptionsContainer from "../LiveOptions/LiveOptionsContainer";
import { Skeleton } from "@material-ui/lab";
import { companyDataLoad } from "../../appRedux/ducks/companyData";
import clsx from "clsx";
import { useAuth } from "../../contexts/Auth";
import { optionExpiryLoad } from "../../appRedux/ducks/optionExpirations";
import OptionScannerContainer from "../OptionScanner/OptionScannerContainer";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Sentiment from './Sentiment'

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.tableBackground,
    "& .card": {
      border: "none !important",
      borderRadius: "24px",
    },
  },
  bottom: {
    backgroundColor: theme.palette.primary.tableBackground,
    color: theme.palette.primary.text,
  },
  description: {
    color: theme.palette.primary.description,
  }
}));
const SecurityContainer = (props) => {
  const { canSeeInternal, loading } = useAuth()
  const classes = useStyles();
  const { companyDataLoading, symbols, setSymbol, tickerDetails, fetchCompanyData, themeMode, optionExpiry, getOptionExpirations } = props;
  const { symbol } = useParams();
  useEffect(() => {
    fetchCompanyData(symbols.value)
    getOptionExpirations(symbols.value)
  }, [symbols.value]);
  const symbolMemo = useMemo(() => {
    return !!symbols.value.length ? symbols.value : symbol;
  }, [symbols.value]);
  const [expanded, setExpanded] = useState(true);
  const [expandedOptions, setExpandedOptions] = useState(true)
  const [store, setStore] = useState({
    company: {},
    mutualFund: {},
    etf: {},
    meta: {},
    security: {},
    securityMetrics: [],
    securityPrice: {},
    securitySnapshots: [],
    news: [],
    securityLoading: undefined,
    secLoading: undefined,
    securityType: "",
  });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setSymbol({
      type: !!store?.securityType.length ? store?.securityType : "company",
      value: symbol,
    });
  }, []);
  const fundamentalWidget = useMemo(() => {
  if (!!symbols.value) {

      return (
        <div className={classes.card}>
          <Card className={clsx(classes.card, "hide-scroll")}>
            <CardBody
              className={classes.card}
              style={{
                borderRadius: "16px",
                width: "auto",
                height: "max-content",
                border: "none",
              }}
            >
              <FundamentalData
                className="hide-scroll"
                widgetProps={{
                  symbol: symbols.value,
                  colorTheme: themeMode,
                  width: "100%",
                  isTransparent: themeMode === "light",
                }}
              />
            </CardBody>
          </Card>
        </div>
      );
    }
    return <></>
  }, [symbols.value, themeMode]);
  const unusualActivity = useMemo(() => {
    return <UnusualActivity ticker={symbolMemo} />;
  }, [symbolMemo]);
  return (
    <div className="security-page">
      <div className="row">
        <div className={" stock-wrapper col-xl-8 col-lg-12 col-md-12 col-sm-12"}>
          {store.securityType === "etf" ? (
            <Etf
              ticker={symbolMemo}
              etf={store.etf}
              securityMetrics={store.securityMetrics}
              securityPrice={props.securityPrice}
              securitySnapshots={store.securitySnapshots}
              news={store.news}
              currentMarketStatus={props.marketStatus}
            />
          ) : store.securityType === "mf" ? (
            <MutualFund
              ticker={symbolMemo}
              mutualFund={store.mutualFund}
              securityPrice={props.securityPrice}
              securityMetrics={store.securityMetrics}
              securitySnapshots={store.securitySnapshots}
              news={store.news}
              currentMarketStatus={props.marketStatus}
            />
          ) : (
            <Company
              ticker={symbolMemo}
              company={store.company}
              securityMetrics={store.securityMetrics}
              securityPrice={props.securityPrice}
              securitySnapshots={store.securitySnapshots}
              news={store.news}
              currentMarketStatus={props.marketStatus}
            />
          )}
        </div>

        <div className={"fun-joyride-target col-xl-4 col-lg-12 col-md-12 col-sm-12"}>{fundamentalWidget}</div>
      </div>
      {tickerDetails.notFound ? null : tickerDetails.ticker || companyDataLoading ? (
        <div className={clsx(classes.bottom, "row trade-algo-equity-page-info-row")}>
          <div className={"col-lg-8 col-xl-8 col-md-7 col-sm-12 col-xs-12 equity-page-info-left-part"}>
            {companyDataLoading ? (
              <div className={classes.description} id="parent">
                <Skeleton width={'100%'} />
                <Skeleton width={'100%'} />
                <Skeleton width={'100%'} />
                <Skeleton width={'100%'} />
                <Skeleton width={'100%'} />
              </div>
            ) : tickerDetails.ticker === symbols.value
              ? tickerDetails.description
              : "N/A"}
          </div>

          <div className={"col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12 equity-page-info-right-part"}>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Sector:</div>
              <div className={clsx(classes.description, "equity-page-info-value")}>
                {companyDataLoading ? <Skeleton className="details" /> : tickerDetails.ticker === symbols.value && tickerDetails.sector}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Industry:</div>
              <div className={clsx(classes.description, "equity-page-info-value")}>
                {companyDataLoading ? <Skeleton className="industry" /> : tickerDetails.ticker === symbols.value &&
                  tickerDetails.industry}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Employees:</div>
              <div className={clsx(classes.description, "equity-page-info-value")}>
                {companyDataLoading ? <Skeleton className="details" /> : tickerDetails.ticker === symbols.value &&
                  numberWithCommas(tickerDetails?.total_employees ?? "")}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Website:</div>
              <div className={clsx(classes.description, "equity-page-info-value")}>
                <a
                  style={{ fontSize: "17px" }}
                  href={
                    tickerDetails.ticker === symbols.value ? tickerDetails.homepage_url : ''
                  }
                  target="_blank"
                >
                  {" "}
                  {companyDataLoading ? <Skeleton className="website" /> : tickerDetails.ticker === symbols.value &&
                    tickerDetails.homepage_url}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <br />
      )}
      {
        !!(!loading && canSeeInternal) && (
          <>
          {/* <Sentiment /> */}
            <div id="scrollable-target" className="hide-scroll" style={{ marginBottom: '30px', borderRadius: 16, height: 'auto', maxHeight: '1000px', overflowY: "scroll" }}>
              <LiveOptionsContainer company={true} />
            </div>
            {
              !!(!!optionExpiry.expirationDates.length && symbols.value === optionExpiry.ticker) && (
                <>
                  <OptionScannerContainer expand={expandedOptions} setExpand={setExpandedOptions} companyTicker={symbols.value} company={true} />
                </>
              )
            }
            <div className="row mt-30">{unusualActivity}</div>
          </>
        )
      }

    </div>
  );
};

const stateToProps = (state) => ({
  // securityPrice: state.equities.price,
  securityPrice: state.companyData.companyData.secPrice,
  marketStatus: state.companyData.companyData.marketState,
  symbols: state.symbol.symbol,
  tickerDetails: state.companyData.companyData.tickerDetails,
  themeMode: state.themeMode.themeMode,
  optionExpiry: state.optionExpiry,
  companyDataLoading: state.companyData.companyDataLoading
});

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  fetchCompanyData: (symbol) => dispatch(companyDataLoad(symbol)),
  getOptionExpirations: (symbol) => dispatch(optionExpiryLoad(symbol)),
});

export default connect(stateToProps, dispatchToProps)(SecurityContainer);
