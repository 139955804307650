import * as React from "react"

const BasicsTrainingIcon = ({fill}) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#a)" fill={fill}>
      <path d="M22.977 9.283c-.024-1.433-1.07-2.644-2.283-2.644-.16 0-.32.021-.475.063-.377.1-.741.287-1.095.467-.11.057-.236.121-.358.18-.411.198-.806.398-1.223.61l-.023.011-.54.273h-.002v-.102c0-.062 0-.117.002-.177 0-.092.001-.165-.002-.236-.06-1.419-.899-2.523-2.04-2.686A4.967 4.967 0 0 0 14.235 5c-.146 0-.293.003-.439.007l-.177.004H4.378v.001l-.223-.002c-.122 0-.245 0-.369.002h-.293C1.979 5.015 1.001 6.182 1 7.985v8.018c0 1.817.981 2.992 2.5 2.994C4.173 19 4.847 19 5.522 19l2.953-.002c.494 0 .989 0 1.483.002h.193l1.311.001c.881 0 2.047-.001 3.242-.013 1.06-.01 1.941-.857 2.193-2.107.043-.212.062-.434.083-.67.01-.112.02-.228.033-.345l.009-.077.112.054.191.094c.263.13.53.268.788.401.564.291 1.148.592 1.736.854.262.117.535.177.813.177 1.246 0 2.283-1.183 2.312-2.636.033-1.7.033-3.482.001-5.448h.001Zm-7.532 6.766c0 .806-.31 1.216-.922 1.218l-1.157.001H7.455l-4.015-.001c-.411 0-.903-.205-.903-1.175V7.893c0-.716.327-1.146.874-1.15h.154l.31.001h.02a43.638 43.638 0 0 0 .6-.001h9.09l.211.001.615-.001h.1c.62.002.933.415.934 1.229v8.078Zm6.006-3.252-.001 1.69c-.003.664-.332 1.147-.783 1.147a.804.804 0 0 1-.356-.09c-.698-.346-1.4-.701-2.077-1.045l-.666-.337c-.49-.247-.588-.737-.584-1.104.005-.69.005-1.398 0-2.105-.005-.537.19-.908.58-1.105l.883-.446.09-.045c.58-.293 1.18-.597 1.772-.89a.81.81 0 0 1 .36-.093c.45 0 .779.48.781 1.14.003.59.002 1.174.002 1.739V12.796Z" />
      <path d="M12 11.95c.026 1.66-1.368 3.065-3.027 3.05-1.6-.015-2.945-1.35-2.973-2.952C5.972 10.387 7.363 8.985 9.026 9c1.6.014 2.947 1.35 2.974 2.951Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default BasicsTrainingIcon
