
import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import moment from "moment";
import { SOCKET_MESSAGE } from "./socket";
import { concat, orderBy, clone, } from "lodash";

// Action Types
export const LIVE_OPTIONS_LOAD = "LOF/LOAD/TOP";
export const LIVE_OPTIONS_LOAD_SUCCESS = "LOF/LOAD/TOP/SUCCESS";
export const LIVE_OPTIONS_LOAD_FAILURE = "LOF/LOAD/TOP/FAILURE";

// Action Creators
export const liveOptionsLoad = (prem_min = 35000, exp_within = 730, loadMore = false, date = '', tickers = '', clearCache = false, sortModel = {
    field: "time",
    sort: "desc",
}, bidAskFilter = []) => ({
    type: LIVE_OPTIONS_LOAD,
    tickers,
    prem_min,
    exp_within,
    loadMore,
    date,
    clearCache,
    sortModel,
    bidAskFilter
});
export const liveOptionsLoadSuccess = (data) => ({
    type: LIVE_OPTIONS_LOAD_SUCCESS,
    data,
});
export const liveOptionsLoadFailure = (error) => ({
    type: LIVE_OPTIONS_LOAD_FAILURE,
    error,
});
// Sagas
function* fetchLiveOptions(action) {
    try {
        // order_direction=desc&sort_column=time
        const { liveOptions, date: dateRedux, prem_min: prem_min_redux, exp_within: exp_within_redux, sortModel: sortModelRedux } = yield select((state) => state.newLiveOptions);
        const newLiveOptionsState = yield select((state) => state.newLiveOptions);
        const { prem_min, exp_within, loadMore, date, tickers, clearCache, sortModel, bidAskFilter } = action
        yield put(liveOptionsLoadSuccess({ ...newLiveOptionsState, clearCache, liveOptions, loading: true, sortModel, bidAskFilter }));
        if (!clearCache) {
            const diffDates = dateRedux !== date;
            const diffPrem = prem_min !== prem_min_redux;
            const diffExp = exp_within !== exp_within_redux
            const diffSortModel = JSON.stringify(sortModel) !== JSON.stringify(sortModelRedux)
            if (diffDates || diffPrem || diffExp || diffSortModel) {
                yield put(liveOptionsLoadSuccess({ clearCache, liveOptions: [], tickers, prem_min, exp_within, loadMore: false, date, summary: {}, loading: true, sortModel, bidAskFilter }));
            }
            const baFilter = bidAskFilter.length ? bidAskFilter.join(',') : ''
            let uri = `${apiURL}/darkpool/options?limit=101&prem_min=${prem_min}&exp_within=${exp_within}&order_direction=${sortModel.sort}&sort_column=${sortModel.field}&bid_ask=${baFilter}`

            let snapshotUri = `${apiURL}/darkpool/snapshot?prem_min=${prem_min}&exp_within=${exp_within}`

            if (tickers.length) {
                uri += `&ticker=${tickers}`
                snapshotUri += `&ticker=${tickers}`
            }
            if (loadMore) {
                if (!!liveOptions[liveOptions.length - 1]?.time || false) {
                    const last_time = [...liveOptions].sort((a, b) => Number(b.time) - Number(a.time))[liveOptions.length - 1].time
                    uri += `&last_time=${last_time}`
                }
            }
            if (date.length) {
                uri += `&date=${date}`
                snapshotUri += `&date=${date}`
            }
            const response = yield axios.get(uri, {
                withCredentials: true
            });
            let data = [...liveOptions.concat(response.data)]
            if (date.length) {
                const [startDate, endDate] = date.split(',')
                // extra check to ensure data validity
                data = data.filter((item) => moment(Number(item.time) * 1000).isSameOrAfter(startDate, "day") && moment(Number(item.time) * 1000).isSameOrBefore(endDate, "day"))
            }
            data = [
                ...new Map(data.map((item) => [item["id"], item])).values(),
            ];
            const snapshotResponse = yield axios.get(snapshotUri, {
                withCredentials: true
            });
            yield put(liveOptionsLoadSuccess({ clearCache, liveOptions: data, tickers, prem_min, exp_within, loadMore: false, date, summary: snapshotResponse.data, loading: false, sortModel, bidAskFilter }));
        } else {
            yield put(liveOptionsLoadSuccess({ clearCache, liveOptions: [], tickers, prem_min, exp_within, loadMore: false, date, summary: {}, loading: false, sortModel, bidAskFilter }));
        }
    } catch (error) {
        console.log(error, 'ERROR IN CATCH\n\n')
        yield put(liveOptionsLoadFailure(error));
    }
}

function* listenLiveOptionsLoad() {
    yield takeEvery(LIVE_OPTIONS_LOAD, fetchLiveOptions);
}

// Root Saga
export function* saga() {
    yield all([fork(listenLiveOptionsLoad)]);
}

const INIT_STATE = {
    liveOptions: [],
    tickers: '',
    prem_min: 35000,
    exp_within: 730,
    loadMore: false,
    date: '',
    summary: {
        put_count: 0,
        call_count: 0,
        call_flow: 0,
        put_flow: 0,
        flow_sentiment: 0.5,
    },
    loading: true,
    clearCache: false,
    sortModel: {
        field: "time",
        sort: "desc",
    },
    bidAskFilter: []
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SOCKET_MESSAGE: {
            try {
                if (!action.channel.includes("options")) return state; // If not for options, then ignore
                if (
                    state.date.length > 0 &&
                    moment(state.date.split(',')[1]).isBefore(moment.utc().format("YYYY-MM-DD"))
                ) {
                    // No socket updates when looking at historic options
                    return state;
                }
                const newData = concat(action.data, []).map((item) => ({
                    // Modify new Data
                    ...item,
                    id: Date.now(),
                    newItem: true
                }));

                const existingData = clone(state.liveOptions).map((item) => ({
                    ...item,
                    newItem: false
                })); // Clone existing data
                const { sortModel, bidAskFilter } = state
                const { field, sort } = sortModel
                let allData = orderBy(concat(existingData, newData), [field], [sort]); // Order by time
                // let allData = orderBy(concat(existingData, newData)); // Order by time
                if (state.tickers.length) {
                    allData = allData.filter((item) => state.tickers.includes(item.ticker))
                }
                if (!!state.prem_min) {
                    allData = allData.filter((item) => Number(item.prem) >= state.prem_min)
                }
                if (!!state.exp_within) {
                    let xDays = moment().add(Number(state.exp_within), 'days')
                    allData = allData.filter((item) => moment(item.exp).isSameOrBefore(xDays))
                }
                if (!!bidAskFilter.length) {
                    allData = allData.filter((item) => {
                        const { ask_price, bid_price, price_per_contract } = item
                        const fillLookup = {
                            "": '',
                            "1": ask_price < price_per_contract,  // Above Ask
                            "2": ask_price === price_per_contract, // At Ask
                            "3": (ask_price - price_per_contract) < (price_per_contract - bid_price), // Lean Ask
                            "4": bid_price > price_per_contract, // Below Bid
                            "5": bid_price === price_per_contract, // At bid 
                            "6": (ask_price - price_per_contract) > (price_per_contract - bid_price), // Lean Bid
                            "7": (ask_price - price_per_contract) === (price_per_contract - bid_price) // In Between
                        }
                        const arr = []
                        bidAskFilter.forEach((i) => arr.push(fillLookup[i]))
                        return arr.some(Boolean);
                    })
                }
                //UPDATE SUMMARY 
                let newSummary
                if (typeof state.summary === "object") {
                    newSummary = clone(state.summary);
                    allData.forEach((record) => {
                        if (record.newItem) {
                            if (record.cp === "P") {
                                newSummary.put_count++;
                                newSummary.put_total_prem += parseInt(record.prem);
                            } else {
                                newSummary.call_count++;
                                newSummary.call_total_prem += parseInt(record.prem);
                            }
                        }
                    });
                    newSummary.put_to_call =
                        Math.round((newSummary.put_count / newSummary.call_count) * 1000) /
                        1000;
                    newSummary.call_flow =
                        newSummary.call_count / (newSummary.call_count + newSummary.put_count);
                    newSummary.flow_sentiment =
                        newSummary.call_count / (newSummary.call_count + newSummary.put_count);
                    newSummary.put_flow =
                        newSummary.put_count / (newSummary.call_count + newSummary.put_count);
                }
                return {
                    ...state,
                    liveOptions: state.clearCache ? [] : allData,
                    summary: newSummary
                }
            } catch (error) {
                console.log(error, 'error in socket message catch--')
            }
            break;

        }
        case LIVE_OPTIONS_LOAD_SUCCESS:
            let { tickers, prem_min, exp_within, date, loadMore, liveOptions, summary, loading, clearCache, sortModel, bidAskFilter } = action.data
            let data = [...liveOptions]
            if (tickers.length) {
                if (typeof tickers === "string" && tickers.includes(',')) tickers = tickers.split(',')
                // make sure tickers is an array to allow for correct usage of .includes
                if (typeof tickers === "string" && !tickers.includes(',') && tickers.length) tickers = [tickers]
                data = data.filter((item) => tickers.includes(item.ticker))
            }
            if (!!prem_min) {
                data = data.filter((item) => Number(item.prem) >= prem_min)
            }
            if (!!exp_within) {
                let xDays = moment().add(Number(exp_within), 'days')
                data = data.filter((item) => moment(item.exp).isSameOrBefore(xDays))
            }
            return {
                ...state,
                tickers,
                prem_min,
                exp_within,
                date,
                loadMore,
                summary,
                loading,
                clearCache,
                liveOptions: clearCache ? [] : data,
                sortModel,
                bidAskFilter
            };
        default:
            return state;
    }
};

export default reducer;
