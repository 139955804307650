import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./styles/index.css";
import ErrorBoundry from "./ErrorBoundry";
import configureStore, { history } from "./store";
import { AuthProvider } from "./contexts/Auth";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <SnackbarProvider autoHideDuration={4000} anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}>
      <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
          <AuthProvider>
            <ErrorBoundry>
              <App />
            </ErrorBoundry>
          </AuthProvider>
        </ConnectedRouter>
      </Provider>
    </SnackbarProvider>,

);