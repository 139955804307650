import React from "react";
import Button from '@mui/material/Button';
import "./plans.scss";
import { connect } from "react-redux";
import { useAuth } from "../../../contexts/Auth";
import { CircularProgress } from "@material-ui/core";


const AdvancedPlan = ({userSettings}) => {
  const { hasOptionsMentorshipAdvanced, loading } = useAuth()
  const submit = () => {
    window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1_Mn9ZOuERtH9q97uMq8WyxgNh9xOSyIYbJbAwJlL6JSZft7V2uI-ZEJVjR445NVW_pBDurlN1zyQ-1L_wdjrgCWiaX8Ar2"
  }
  const processing = !!userSettings?.om_advanced_sign_up || !!userSettings?.om_advanced_renewed || hasOptionsMentorshipAdvanced;
  return (
    <div className="subscribe-plan-item advanced-item">
      <div className="subscribe-plan-item-title">
        TradeAlgo Options<br />Mentorship: <span className="subscribe-plan-item-type">Advanced</span>
      </div>
      <div className="subscribe-plan-item-includes-title">
        Includes
      </div>
      <ul className="subscribe-plan-item-includes-entries">
        <li className="subscribe-plan-item-includes-entry">29 sessions over 12 months</li>
        <li className="subscribe-plan-item-includes-entry">
          Options trading pro-led personalized coaching
        </li>
      </ul>
      <div className="subscribe-plan-item-price"><span className="subscribe-plan-item-small-text">USD</span> $7,000</div>
      <Button sx={{ width: processing ? '215px !important' : '' }} disabled={processing || loading} className="subscribe-plan-item-button" onClick={submit}>{loading ? <CircularProgress color="success" size={24} /> : processing ? "Payment Processing" : "Sign Me Up"}</Button>
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(AdvancedPlan);
