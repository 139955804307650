import {
  all,
  put,
  takeEvery,
  fork,
} from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const EQUITIES_LOAD = "EQ/LOAD";
export const EQUITIES_LOAD_SUCCESS = "EQ/SUCCESS";
export const EQUITIES_LOAD_FAILURE = "EQ/FAILURE";
export const EQUITIES_PRICE_LOAD = "EQ/PC/LOAD";
export const EQUITIES_PRICE_LOAD_SUCCESS = "EQ/PC/SUCCESS";
export const EQUITIES_PRICE_LOAD_FAILURE = "EQ/PC/FAILURE";
export const EQUITIES_INTRADAY_ADD = "EQ/ID/ADD";

// Action Types (performance)
export const EQUITIES_PERFORMANCE = "EQ/PERFORMANCE";

// Action Creators
export const equitiesLoad = (ticker) => ({
  type: EQUITIES_LOAD,
  ticker,
});
export const equitiesLoadSuccess = (payload) => ({
  type: EQUITIES_LOAD_SUCCESS,
  payload,
});
export const equitiesLoadFailure = (error) => ({
  type: EQUITIES_LOAD_FAILURE,
  error,
});
// Action Creators
export const equitiesSetPerformance = ({ past, present }) => {
  return {
    type: EQUITIES_PERFORMANCE,
    past,
    present,
  };
};

export const equitiesIntradayAdd = ( newCandle ) => ({
  type: EQUITIES_INTRADAY_ADD,
  newCandle
})

// Action Creators(price)
export const equitiesPriceLoad = (ticker) => ({
  type: EQUITIES_PRICE_LOAD,
  ticker,
});
export const equitiesPriceLoadSuccess = (payload) => ({
  type: EQUITIES_PRICE_LOAD_SUCCESS,
  payload,
});
export const equitiesPriceLoadFailure = (error) => ({
  type: EQUITIES_PRICE_LOAD_FAILURE,
  error,
});

function* fetchPriceData(action) {
  try {
    let endpoint = `${apiURL}/sec-price-change/${action.ticker}`
    const response = yield axios.get(endpoint, {withCredentials: true});
      if (response.data) {
      yield put(equitiesPriceLoadSuccess(response.data));
    } else {
      yield put(equitiesPriceLoadFailure("no price data found"));
    }
  } catch (error) {
    console.log("error", error);
    yield put(equitiesLoadFailure(error));
  }
}


function* listenFetchPrice() {
  yield takeEvery(EQUITIES_PRICE_LOAD, fetchPriceData);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchPrice)]);
}

const INIT_STATE = {
  intraday: {
    date: "",
    data: [],
    minute: {},
  },
  historic: {
    daily: [],
    weekly: [],
  },
  performance: {
    past: "",
    present: "",
    priceChange: "",
  },
  price: {
    open_price: "",
    last_price: "",
    close_price: "",
    performance: "",
    values: {
      week: {
        date: "",
        value: "",
      },
      twoweek: {
        date: "",
        value: "",
      },
      month: {
        date: "",
        value: "",
      },
      threemonth: {
        date: "",
        value: "",
      },
      year: {
        date: "",
        value: "",
      },
      open: {
        date: "",
        value: "",
      },
    },
  },
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EQUITIES_LOAD:
      return {
        ...state,
        intraday: {
          date: "",
          data: [],
          minute: {},
        },
        historic: {
          daily: [],
          weekly: [],
        },      
      };
    case EQUITIES_LOAD_SUCCESS:
      return {
        ...state,
        intraday: action.payload.intraday,
        historic: action.payload.historic,
      };
    case EQUITIES_INTRADAY_ADD:
      return {
        ...state,
        intraday: {...state.intraday, data: [...state.intraday.data, action.newCandle]}
      }
    case EQUITIES_PRICE_LOAD_SUCCESS:
      return {
        ...state,
        price: action.payload,
      };
    case EQUITIES_PERFORMANCE:
      const presentElement = action.present;
      const pastElement = action.past;
      const priceDifference = (
        Number(presentElement) - Number(pastElement)
      ).toString();
      const percent = ((priceDifference / Number(pastElement)) * 100)
        .toFixed(2)
        .toString();

      return {
        ...state,
        performance: {
          past: action.past,
          present: action.present,
          priceDifference,
          percent,
        },
      };
    default:
      return state;
  }
};

export default reducer;
