import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SocialCareComponent from "../SocialCareComponent";
import leftArrow from "./leftArrow.png"
import "./style.scss";
import { makeStyles } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import closeIcon from "./Minimize.png"
import { useMedia } from "react-media";
import PlatMentorDropown from "./PlatMentorDropdown";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.primary.background} !important`,
    zIndex: 1000000
  }
}));

const mediaQuery = {
  isMobile: "screen and (max-width: 799px)",
};

const PastVideos = ({ mentor, setMentor, availableMentors, selectNavigation, isTutorialsToggle, currentNav, currentVideoGroup }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const expandAnimationDone = () => {
    setCollapsed(false);
  }

  const collapseAnimationDone = () => {
    setCollapsed(true);
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 390 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className="find-out-more">
        <IconButton onClick={() => setDrawerOpen(false)} aria-label="delete" size="small">
          <img src={closeIcon} className="w-70px" fontSize="inherit" />
        </IconButton>
      </div>
      <div className="past-videos-top-section">
        <div className="past-videos-big-title">The Platinum Program by {mentor[0]}.</div>
        <div className="past-videos-small-title">
          The Platinum program is a flexible options trading strategy that helps traders profit by identifying undervalued options using technical analysis.
          <br /><br />
          This method is based on {mentorFName}’s extensive experience and expertise in the options trading industry and focuses on identifying undervalued options and using technical analysis to make informed trading decisions.
          <br /><br />
          The approach is designed to be flexible, allowing traders to adapt to changing market conditions and take advantage of opportunities as they arise.
        </div>
      </div>
      <div className="past-videos-key-resources mt-106">
        <div className="past-videos-big-title">Key Resources</div>
        <div className="past-videos-blue-title" onClick={() => selectNavigation('real-time-trading-alerts')}>Telegram Trade Alerts</div>
        <div className="past-videos-small-title">Receive timely trade alerts directly on your phone through the Telegram app. Powerful alerts show you what we're buying and selling in real time.</div>

        <div className="past-videos-blue-title" onClick={() => selectNavigation('tutorials')}>Tutorials</div>
        <div className="past-videos-small-title">Access a library of tutorials covering a curriculum designed to take you from beginner to pro. Learn new strategies, techniques, and best practices from {mentorFName} himself.</div>

        <div className="past-videos-blue-title" onClick={() => selectNavigation('livestream')}>Daily Livestream</div>
        <div className="past-videos-small-title">Tune in to a daily livestream where {mentorFName} shares his insights and analysis on the markets. Get a real-time view of market trends and opportunities.</div>
      </div>
    </Box>
  );
  const mentorFName = mentor[0].split(' ')[0]
  return (
    <div className="past-videos-wrapper">
      {
        !!(currentNav !== 'support' && currentNav !== 'real-time-trading-alerts' && currentVideoGroup === '' && collapsed) && (
          <PlatMentorDropown mentor={mentor} setMentor={setMentor} availableMentors={availableMentors} />
        )
      }
      <div className="find-out-more">
        {
          isMobile && (
            <Button onClick={() => setDrawerOpen(true)} variant="outlined" startIcon={<img className="left-arrow" src={leftArrow} />}>
              Find out more
            </Button>
          )
        }
        <Drawer
          classes={{ paper: classes.paper }}
          anchor={'right'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </div>
      <Collapse
        in={isTutorialsToggle}
        onEntered={expandAnimationDone}
        onExited={collapseAnimationDone}
      >
        <div className="past-videos-top-section">
          <div className="past-videos-big-title">The Platinum Program by {mentor[0]}.</div>
          <div className="past-videos-small-title">
            The Platinum program is a flexible options trading strategy that helps traders profit by identifying undervalued options using technical analysis.
            <br /><br />
            This method is based on {mentorFName}’s extensive experience and expertise in the options trading industry and focuses on identifying undervalued options and using technical analysis to make informed trading decisions.
            <br /><br />
            The approach is designed to be flexible, allowing traders to adapt to changing market conditions and take advantage of opportunities as they arise.
          </div>
        </div>
      </Collapse>

      {
        currentVideoGroup === '' && (currentNav !== 'real-time-trading-alerts') && (
          <div className={`past-videos-chat-widget ${collapsed ? `give-padding-top ${currentNav === 'support' && 'mt-106'}` : ''}`}>
            <iframe
              key={mentor}
              src={`https://vimeo.com/event/${mentor.includes("Eric Armenteros") ? '3162415' : '3745400'}/chat`}
              width="100%"
              height="100%"
              frameBorder="0"
              title="livestream-chat"
            >
            </iframe>
          </div>
        )
      }

      {
        currentNav !== 'real-time-trading-alerts' && (
          <SocialCareComponent />
        )
      }
      {
        !isMobile && (
          <div className="past-videos-key-resources">
            <div className="past-videos-big-title">Key Resources</div>
            <div className="past-videos-blue-title" onClick={() => selectNavigation('real-time-trading-alerts')}>Telegram Trade Alerts</div>
            <div className="past-videos-small-title">Receive timely trade alerts directly on your phone through the Telegram app. Powerful alerts show you what we're buying and selling in real time.</div>

            <div className="past-videos-blue-title" onClick={() => selectNavigation('tutorials')}>Tutorials</div>
            <div className="past-videos-small-title">Access a library of tutorials covering a curriculum designed to take you from beginner to pro. Learn new strategies, techniques, and best practices from {mentorFName} himself.</div>

            <div className="past-videos-blue-title" onClick={() => selectNavigation('livestream')}>Daily Livestream</div>
            <div className="past-videos-small-title">Tune in to a daily livestream where {mentorFName} shares his insights and analysis on the markets. Get a real-time view of market trends and opportunities.</div>
          </div>
        )
      }
    </div>
  )
}

export default PastVideos;