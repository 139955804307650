
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const UPDATE_TRADE_STATUS_LOAD = "UTS/LOAD/TOP";
export const UPDATE_TRADE_STATUS_LOAD_SUCCESS = "UTS/LOAD/TOP/SUCCESS";
export const UPDATE_TRADE_STATUS_LOAD_FAILURE = "UTS/LOAD/TOP/FAILURE";

// Action Creators
export const updateTradeStatusLoad = (trade_status, message_id) => ({
    type: UPDATE_TRADE_STATUS_LOAD,
    trade_status,
    message_id
});
export const updateTradeStatusLoadSuccess = (data) => ({
    type: UPDATE_TRADE_STATUS_LOAD_SUCCESS,
    data,
});
export const updateTradeStatusLoadFailure = (error) => ({
    type: UPDATE_TRADE_STATUS_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchUpdateTradeStatus(action) {
    try {
        const { trade_status, message_id } = action
        if (!!trade_status) {
            const response = yield axios.put(`${apiURL}/update-trade-status`, {
                trade_status,
                message_id
            }, {
                withCredentials: true
            });
            yield put(updateTradeStatusLoadSuccess(response?.data));
        }
    } catch (error) {
        yield put(updateTradeStatusLoadFailure(error));
    }
}

function* listenUpdateTradeStatusLoad() {
    yield takeEvery(UPDATE_TRADE_STATUS_LOAD, fetchUpdateTradeStatus);
}

// Root Saga
export function* saga() {
    yield all([fork(listenUpdateTradeStatusLoad)]);
}

const INIT_STATE = {
    updateTradeStatus: ''
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_TRADE_STATUS_LOAD_SUCCESS:
            return {
                ...state,
                updateTradeStatus: action.data
            };
        default:
            return state;
    }
};

export default reducer;
