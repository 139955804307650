import React, { useMemo } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./tabs.scss";
import { connect } from "react-redux";
const LiveSessionsTab = ({ themeMode, activeTab, setActiveTab, currentVideo, setCurrentVideo, tutorials = false }) => {

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode])

  return (
    <div style={{ position: 'relative', left: tutorials ? '35px' : '0px' }} className="tabs tabs--justify tabs--bordered-bottom">
      <div>
        <div
          style={{
            width: 'fit-content',
            display: "flex",
            alignItems: "start",
            whiteSpace: 'nowrap'
          }}
          className="tabs__wrap"
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => toggle("1")}
                style={{
                  color: activeTab === "1" ? color : "#A2A3A5",
                  fontFamily: "inter",
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                }}
              >
                {tutorials ? "Tutorials" : "Previous Streams"}
              </NavLink>
            </NavItem>
            {
              !tutorials && !currentVideo.live && (
                <NavItem>
                  <NavLink
                    disabled={currentVideo.live}
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      setCurrentVideo({
                        ...currentVideo,
                        live: true
                      })
                    }}
                    style={{
                      color: activeTab === "2" ? color : "#A2A3A5",
                      fontFamily: "inter",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      position: 'relative',
                      left: '-15px'
                    }}
                  >
                    Go Live
                  </NavLink>
                </NavItem>
              )
            } 
          </Nav>
        </div>
      </div>
      <br />
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(LiveSessionsTab);
