import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import { tutorialVideoLookup } from './tutorialMetadata';

function VideoModal({ open, setOpen, name }) {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                aria-labelledby="video-dialog-title"
                aria-describedby="video-dialog-description"
            >
                <DialogContent sx={{ minHeight: '35vh' }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <iframe
                            className='vimeo-tutorial-iframe'
                            title="vimeo-player"
                            src={tutorialVideoLookup[name]}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default VideoModal;