export const tradingAlertsData = [
  {
    title: 'Download & Install Telegram',
    items: [
      {
        title: 'How to download & Install Telegram on iOS?',
        description: '<a href="https://apps.apple.com/us/app/telegram-messenger/id686449807" target="_blank" class="trading-alerts-anchor">Click here to go to Telegram on App Store</a>, or just head over to the App Store on your iPhone and search for the Telegram app. Tap on “GET IT” to download and finish the installation.',
        id: 1
      },
      {
        title: 'How to download & Install Telegram on Android?',
        description: 'To download Telegram for Android, go to the Google play Android app store <a href="https://play.google.com/store/apps/details?id=org.telegram.messenger&pli=1" target="_blank" class="trading-alerts-anchor">here</a>. You can visit the link from your android phone or tablet to download and install Telegram Messenger app.',
        id: 2
      },
      {
        title: 'How to download & Install Telegram on macOS?',
        description: 'Go to the <a href="https://macos.telegram.org/" target="_blank" class="trading-alerts-anchor">App Store</a> and type Telegram Desktop in the search bar. Choose the social networking application in the list and proceed to the app screen in the store. Click on the Get button to install the app and open it from the Applications folder or use the Finder to locate it.',
        id: 3
      },
      {
        title: 'How to download & Install Telegram on Windows?',
        description: 'Visit the <a href="https://apps.microsoft.com/store/detail/telegram-desktop/9NZTWSQNTD0S?hl=en-us&gl=us" target="_blank" class="trading-alerts-anchor">official website</a> of Telegram using any web browser like Google Chrome, Microsoft Edge, etc. Press the “INSTALL” button on the website to start the download of the Telegram Application. It will then automatically re-direct you to the Windows Store App by Microsoft for the Windows device and the download will start in the background.<br/><br/>After the download gets finished, the Telegram app will get installed within 2-3 minutes. It will then show up on the all programs list of the Start menu.',
        id: 4
      }
    ]
  },
  {
    title: 'Setup Telegram Account',
    items: [
      {
        title: 'How to setup an Telegram account on iOS?',
        description: 'Once you launch the app for the first time, you’ll be taken to the welcome screen. Tap on “<strong>Start Messaging</strong>” to begin the setup. You’ll now be prompted to enter a valid phone number. Select your country for the country code, type in the phone number and tap on “<strong>Next</strong>” to continue. Telegram will send a verification code to your phone number via SMS. Type the code in the app and tap on “<strong>Next</strong>”.<br/><br/>First-time users will be prompted to type in your name and choose a profile picture for your Telegram account. Once you’re done, you’ll be taken to the main chat section of the app.',
        id: 5
      },
      {
        title: 'How to setup an Telegram account on Android?',
        description: 'When you launch the Telegram app after installing it, you will see the window showing <strong>Start Messaging</strong> button. Tap (touch and leave) the Start Messaging button. In the next displayed screen, Telegram will ask you to select your country and to enter your phone number. After selecting your country and entering your phone number, tap Done at the top-right, Telegram will send an <strong>activation code via SMS</strong> to this phone number.<br/><br/>Enter the code which you received in your SMS from Telegram. After verification, Telegram will take you to its registration screen where it will ask you to enter your name. Enter your name then tap Done.',
        id: 6
      },
      {
        title: 'How to setup an Telegram account on macOS?',
        description: 'Once the Telegram window pops up click on <strong>Start messaging</strong>. Choose your country from the drop down menu, fill in your phone number and click <strong>Next</strong>. You will get a verification code on your phone to complete the login process. After the confirmation is complete, you will be redirected to <strong>Telegram Home Screen.</strong>',
        id: 7
      },
      {
        title: 'How to setup an Telegram account on Windows?',
        description: 'Click on the Telegram app to open it. After the Telegram app opens up, it will show you a “<strong>START MESSAGING</strong>” button to proceed to login into your Telegram account. Click on the “START MESSAGING” button to proceed to the login window.<br/><br/>Then it will show you a QR code for scanning once you click on the “START MESSAGING” button. If you already have a mobile phone with the Telegram account activated in it, then Scan the given <strong>QR code</strong> that comes on your screen. It will open your chats on your Telegram phone to your Telegram Windows profile.<br/><br/>If you don’t have a Telegram account activated on your smartphone, then click on “<strong>Or login using your phone number</strong>”. It will then ask you for your phone number details and then it will send a unique OTP to your phone number via a message for verification. Enter the OTP to start messaging on Telegram via Windows device just like your smartphone.',
        id: 8
      }
    ]
  },
  {
    title: 'Access to TradeAlgo Platinum Program Channel',
    items: [
      {
        title: 'How to join Platinum Program Channel on Telegram?',
        description: 'Click on the link <a href="https://t.me/+12TAYz7H9nhhMzk5" target="_blank" class="trading-alerts-anchor">here</a> to access Platinum Program Telegram Channel, click on the "Join" button to request to join the channel.<br/><br/>Ensure that your Telegram username matches the name with the Tradealgo platinum program subscription while pending to be accepted into the channel.<br/><br/>Once we have review and verified your real name, you will be accepted into the channel.',
        id: 9
      },
    ]
  },
];