import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { counterLoad } from "../../appRedux/ducks/counter";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import moment from "moment";
import clsx from "clsx";
import CssPop from "../common/Tables/animations/CssPop";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import { socketListen } from "../../appRedux/ducks/socket";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.contrastBackground} !important`,
    color: theme.palette.primary.text,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));

const WarningSignalsContainer = ({
  warningSignals,
  setSymbol,
  loading,
  getWarningSignals,
  listen,
  currentPrices,
  socketListen
}) => {
  const classes = useStyles();
  const allTickers = useMemo(() => {
    return warningSignals?.map((item) => item.ticker) || [];
  }, [warningSignals])

  useEffect(() => {
    getWarningSignals();
    socketListen(["COUNTER"])
    return () => socketListen([])
  }, []);

  useEffect(() => {
    if (!!allTickers.length) {
      listen([...new Set(allTickers)], false, false, 'auto');
    }
    return () => listen([], false, true);
    // eslint-disable-next-line
  }, [loading, allTickers]);
  const flex = 1;
  const columns = useMemo(() => {
    return [
      {
        field: "ticker",
        headerName: "TICKER",
        flex,
        renderCell: (params) => {
          return (
            <div
              onClick={() => {
                setSymbol({
                  type: "",
                  value: params.value,
                });
              }}
              style={{
                position: "relative",
                left: "8px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <Link className={classes.text}
                style={{ color: "black" }} to={`/company/${params.value}`}>
                {params.value}
              </Link>
            </div>
          );
        },
      },
      {
        field: "last_price",
        headerName: "LAST PRICE",
        flex,
        renderCell: (params) => {
          const livePrice = (
            currentPrices.get(params.row.ticker) || Number(params.value) 
          ).toFixed(2);
          return (
            <span className={classes.text} style={{ position: 'relative', left: '20px', fontWeight: 600 }}>
              <CssPop comparator={livePrice} />
            </span>
          )
        }
      },
      {
        field: "score",
        headerName: "SCORE",
        flex,
        renderCell: (params) => {
          return (
            <span className={classes.text} style={{ position: 'relative', left: '10px', fontWeight: 600 }}>
              <CssPop comparator={params.value} />
            </span>
          )
        }
      },
      {
        field: "last_minute",
        headerName: "LAST MINUTE",
        flex,
        renderCell: (params) => {
          return (
            <span className={classes.text} style={{ position: 'relative', left: '20px', fontWeight: 600 }}>
              <CssPop comparator={params.value} />
            </span>
          )
        }
      },
    ];
  }, [currentPrices, JSON.stringify(warningSignals)])
  const rows = warningSignals || []
  const [sortModel, setSortModel] = useState([
    {
      field: "score",
      sort: "desc",
    },
  ]);
  const date = useMemo(() => {
    const date = rows[0]?.date?.replace(/T.*Z/, '') || '';
    return date ? moment(date)?.format("MMMM DD, YYYY") : ''
  }, [rows])
  return (
    <div >
      <Helmet>
        <title>Early Warning Signals | TradeAlgo</title>
      </Helmet>
      <Col md={12} lg={12}>
        <Card className={classes.background}>
          <CardBody>
            <br />
            <span style={{ position: 'relative', left: '10px', top: '-15px' }} className={clsx(classes.text, 'unusual')}>{date}</span>
            <TradeAlgoTable rows={rows} columns={columns} loading={loading} sortModel={sortModel} setSortModel={setSortModel} />
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const stateToProps = (state) => ({
  warningSignals: state.counter.counter,
  loading: state.counter.loading,
  // currentPrices: state.dxFeedSocket.socketData,
  currentPrices: state.Socket.currentPrices,});

const dispatchToProps = (dispatch) => ({
  getWarningSignals: () => dispatch(counterLoad()),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  listen: (symbols, options, disconnect, risk) => dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
  socketListen: (channels) => dispatch(socketListen(channels)),
});

export default connect(stateToProps, dispatchToProps)(WarningSignalsContainer);
