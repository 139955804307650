import { all, put, takeEvery, fork } from "redux-saga/effects";
import axios from "axios";

// Action Types
export const SEARCH_LOAD_TOP = "S/LOAD/TOP";
export const SEARCH_LOAD_TOP_SUCCESS = "S/LOAD/TOP/SUCCESS";
export const SEARCH_LOAD_TOP_FAILURE = "S/LOAD/TOP/FAILURE";

// Action Creators
export const searchLoad = (symbol) => ({
  type: SEARCH_LOAD_TOP,
  symbol,
});
export const searchLoadSuccess = (data,loading) => ({
  type: SEARCH_LOAD_TOP_SUCCESS,
  data,
  loading
});
export const searchLoadFailure = (error) => ({
  type: SEARCH_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSymbols(action) {
  try {
    const { symbol } = action;
    if (!symbol.length) {
      yield put(searchLoadSuccess([], false));
    } else {
      yield put(searchLoadSuccess([], true));
      const response = yield axios.get(`https://api.tradealgo.com/search/${symbol}`);
      yield put(searchLoadSuccess(response.data, false));
    }
  } catch (error) {
    yield put(searchLoadFailure(error));
  }
}

function* listenSymbols() {
  yield takeEvery(SEARCH_LOAD_TOP, fetchSymbols);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSymbols)]);
}

const INIT_STATE = {
    searchSymbols: []
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_LOAD_TOP_SUCCESS:
      return {
        ...state,
        searchSymbols: action.data,
        symbolsLoading: action.loading
      };
    default:
      return state;
  }
};

export default reducer;
