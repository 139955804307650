import './customProgressBar.scss';

const ProgressBar = (props) => {
	const bigTitle = props.bigTitle;
	const percentage = props.percentage;
	const title = props.title;
	const value = props.value;
	const color = props.color;	
	const deg = percentage ? (360/100) * percentage / 2 : 0;
	const themeMode = props.themeMode;
	return (
		<div className={`circle-wrap-${themeMode}`}>
			<div className="circle">
				<div className="mask full" style={{ transform: `rotate(${deg}deg)`, animation: `${percentage ? 'fill ease-in-out 2s' : 'none'}` }}>
					<div className="fill" style={{ backgroundColor: `${color}`, transform: `rotate(${deg}deg)`, animation: `${percentage ? 'fill ease-in-out 2s' : 'none'}` }}/>
				</div>
				<div className="mask half">
					<div className="fill" style={{ backgroundColor: `${color}`, transform: `rotate(${deg}deg)`, animation: `${percentage ? 'fill ease-in-out 2s' : 'none'}` }}/>
				</div>
				<div className="inside-circle" style={{ boxShadow: `0 0 12px 6px ${color}1A`}}>
					{ bigTitle && <div className={`inside-circle-percentage-${themeMode}`}>{bigTitle}</div> }
					{ !bigTitle && percentage && <div className={`inside-circle-percentage-${themeMode}`}>{percentage}%</div> }
					{ value && <div className={`inside-circle-value-${themeMode}`}>{value}</div> }
					{ title && <div className={`inside-circle-title-${themeMode}`}>{title}</div> }
				</div>
			</div>
		</div>
	)
}

export default ProgressBar;