import React, { useMemo } from 'react'
import taAvatar from "./taAvatar.png"
import activeCheck from "./activeCheckbox.png"
import inactiveCheck from "./inactiveCheckbox.png"
import { useAuth } from '../../contexts/Auth'
import SchoolIcon from '@mui/icons-material/School';
import DiamondIcon from '@mui/icons-material/Diamond';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import moment from 'moment';

const planLookup = {
    TradeAlgo: taAvatar,
    "Platinum Program": <DiamondIcon sx={{
        width: '60px',
        height: '45px'
    }} />,
    "Options Mentorship": <OndemandVideoIcon sx={{
        width: '60px',
        height: '40px',
        position: 'relative',
        bottom: '2px'
    }} />,
    "Options Academy": <SchoolIcon sx={{
        width: '60px',
        height: '45px'
    }} />

}
function Subscriptions() {
    const { userData } = useAuth()
    const rows = useMemo(() => {
        const active = userData?.subscriptions?.active?.map((i) => ({
            title: i.subscription_type,
            subtitle: i.subscription_subtype,
            status: i.active ? "Active" : "Expired",
            expires: i.product_sub_months == 999 ? "Lifetime" : moment(i.expires).format('MMM Do YY'),
            price: i.sales_price,
            months: i.product_sub_months,
            days: i.product_sub_days,
            period: i.recurring_period
        })) || []
        const expired = userData?.subscriptions?.inactive?.map((i) => ({
            title: i.subscription_type,
            subtitle: i.subscription_subtype,
            status: i.active ? "Active" : "Expired",
            expires: i.product_sub_months == 999 ? "Lifetime" : moment(i.expires).format('MMM Do YY'),
            price: i.sales_price,
            months: i.product_sub_months,
            days: i.product_sub_days,
            period: i.recurring_period
        })) || []
        return { expired, active }
    }, [userData])
    console.log({ userData, rows })
    return (
        <div className="subscriptions-wrapper">
            <div className='info-text mt-28'>
                The information will not be publicly displayed
            </div>
            <div className='titles-row'>
                <div className='title-text'>Plan</div> <div className='title-text psl-52'>Status</div> <div className='title-text psl-52'>Price</div> <div className='title-text psl-21'>Actions</div>
            </div>
            <div className='info-text'>
                Active Subscriptions
            </div>
            <div className='sub-table-wrapper'>
                {rows?.active?.map((i) => {
                    return (
                        <div className='sub-table-row'>
                            <div className='plan-wrapper'>
                                <div className='plan-logo'> {i.title === "TradeAlgo" ? <img src={planLookup[i.title]} /> : planLookup[i.title]}  </div>
                                <div className='text-wrapp'>
                                    <div className='plan-title'> {i.title} </div>
                                    <div className='plan-subtitle'> {i.subtitle} </div>
                                </div>
                            </div>
                            <div className='status-wrapper'>
                                <div className="inner-status">
                                    <div className='status-text'> <img className='sub-status-logo' src={activeCheck} /> Active </div>
                                    <div className='sub-exp-date'>{i.expires} </div>
                                </div>
                            </div>
                            <div className='sub-price-wrapper'>
                                <div className='price-text'>${i.price} </div>
                                <div className='price-range'> {i.period}</div>
                            </div>
                            <div className='sub-actions-wrapper'>
                                <div className='actions-text'>
                                    Auto Bill
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='info-text mt-20'>
                Expired Subscriptions
            </div>
            <div className='sub-table-wrapper'>
                {rows?.expired?.map((i) => {
                    return (
                        <div className='sub-table-row'>
                            <div className='plan-wrapper'>
                                <div className='plan-logo'> {i.title === "TradeAlgo" ? <img src={planLookup[i.title]} /> : planLookup[i.title]}  </div>
                                <div className='text-wrapp'>
                                    <div className='plan-title'> {i.title} </div>
                                    <div className='plan-subtitle'> {i.subtitle} </div>
                                </div>
                            </div>
                            <div className='status-wrapper'>
                                <div className="inner-status">
                                    <div className='expired-status-text'> <img className='sub-status-logo' src={inactiveCheck} /> Expired </div>
                                    <div className='sub-exp-date'>{i.expires} </div>
                                </div>
                            </div>
                            <div className='sub-price-wrapper'>
                                <div className='price-text'>${i.price} </div>
                                <div className='price-range'> {i.period}</div>
                            </div>
                            <div className='sub-actions-wrapper'>
                                <div className='actions-text'>
                                    Auto Bill
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Subscriptions