import * as React from 'react';
import { Select as SelectUnstyled } from '@mui/base';
import { Option as OptionUnstyled } from '@mui/base';
import { Popper as PopperUnstyled } from '@mui/base';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};


const StyledButton = styled('button')(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    min-height: calc(1.5em + 22px);
    min-width: 194px;
    background: #F1F5F9;
    border: 1px solid #F1F5F9;
    border-radius: 24px;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: black;
 
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 194px;
    background: #F1F5F9;
    border-radius: 24px;
    color: black;
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
    &.Mui-selected {
      background-color: ${blue[900]};
      color: ${blue[100]};
    }
    &.MuiOption-root:hover{
      background-color: ${grey[800]};
      color: ${grey[300]};
    }
  

    `
);
const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
  `;
const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
});


export function MobileTimeFrameTabs({ value, setValue }) {
  return (
    <CustomSelect value={value} onChange={(e, newValue) => setValue(newValue)}>
      <StyledOption value={'1'}>Intraday Activity</StyledOption>
      <StyledOption value={'2'}>Weekly Activity</StyledOption>
      <StyledOption value={'3'}>Popular Stocks</StyledOption>
    </CustomSelect>
  );
}
export function MobileMarketCapTabs({ value, setValue }) {
  return (
    <CustomSelect value={value} onChange={(e, v) => setValue(v)} >
      <StyledOption value={'1'}>Auto</StyledOption>
      <StyledOption value={'2'}>Large Caps</StyledOption>
      <StyledOption value={'3'}>Medium Caps</StyledOption>
      <StyledOption value={'4'}>Small Caps</StyledOption>
    </CustomSelect>
  );
}