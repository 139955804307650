import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const DARKFLOW_TABLE_LOAD_TOP = "DFT/LOAD/TOP";
export const DARKFLOW_TABLE_LOAD_TOP_SUCCESS = "DFT/LOAD/TOP/SUCCESS";
export const DARKFLOW_TABLE_LOAD_TOP_FAILURE = "DFT/LOAD/TOP/FAILURE";

// Action Creators
export const darkflowTableLoad = (risk, weekly = false) => ({
  type: DARKFLOW_TABLE_LOAD_TOP,
  risk,
  weekly
});
export const darkflowTableLoadSuccess = (data) => ({
  type: DARKFLOW_TABLE_LOAD_TOP_SUCCESS,
  data,
});
export const darkflowTableLoadFailure = (error) => ({
  type: DARKFLOW_TABLE_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchDarkFlowTable(action) {
  try {
    const { risk, weekly } = action;
    yield put(darkflowTableLoadSuccess({
      trending_up: [],
      trending_down: [],
      loading: true,
      risk, 
      weekly
    }));
    const response = yield axios.get(`${apiURL}/ats/darkflow?market_cap=${risk}${weekly ? "&weekly=1" : ""}`,{ withCredentials: true }
    );
    yield put(darkflowTableLoadSuccess({ ...response.data, loading: false, risk, weekly }));
  } catch (error) {
    yield put(darkflowTableLoadFailure(error));
  }
}

function* listenDarkflowTableLoad() {
  yield takeEvery(DARKFLOW_TABLE_LOAD_TOP, fetchDarkFlowTable);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDarkflowTableLoad)]);
}

const INIT_STATE = {
  trending_up: [],
  trending_down: [],
  loading: true,
  risk: '',
  weekly: false
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DARKFLOW_TABLE_LOAD_TOP_SUCCESS:
      return {
        ...state,
        trending_up: action.data.trending_up,
        trending_down: action.data.trending_down,
        loading: action.data.loading,
        risk: action.data.risk,
        weekly: action.data.weekly
      };
    default:
      return state;
  }
};

export default reducer;
