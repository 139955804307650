/* eslint-disable */
import Chip from "@mui/material/Chip";
import React, { useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from "@material-ui/core";
const styles = {
  'input-label': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    color: 'red'
  },

  'input': {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue'
    }
  }
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiChip-sizeMedium": {
      display: "none",
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue'
    },
    "& .MuiTextField-root": {
      margin: "0!important"
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: 'Poppins',
      position: 'relative',
      top: '-6px'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.primary.searchBg2,
      borderRadius: "16px",
      height: '44px',
      width: '100%',
      paddingRight: "35px!important",
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
      visibility: 'none'
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      display: 'none',
      position: "relative",
      top: "32px",
      // color: "rgba(162, 163, 165, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));

function OptionTickerSearch({
  options,
  loading,
  setTicker,
  symbol,
  setSymbol,
  // setFilter,
  tickers,
  // fetch,
  customMinWidth,
  setTickerString
}) {
  const classes = useStyles();
  const allTickersString = useMemo(() => {
    return tickers.length ? tickers.map((o) => o.ticker).join() : '';
  }, [tickers])
  useEffect(() => {
    setTickerString(allTickersString);
  },[allTickersString])


  return (
    <div className="row">
      <div className="col-12">
        <Autocomplete
          className={classes.root}
          style={{ position: "relative", width: '100%', minWidth: customMinWidth ? `${customMinWidth}px` : '290px' }}
          id="highlights-demo"
          options={options}
          loading={loading}
          value={tickers}
          disablePortal={true}
          multiple={true}
          noOptionsText={"No Matching Tickers Found"}
          getOptionLabel={(option) => option.ticker + " - " + option.name}
          isOptionEqualToValue={(option, value) => option.ticker === symbol}
          onChange={(e, value) => {
            var non_duplidated_data = [...value.reduce((map, obj) => map.set(obj.ticker, obj), new Map()).values()];
            if (non_duplidated_data) {
              setTicker(non_duplidated_data);
            }
          }}
          renderInput={(params) => (
            <TextField
            // aria-id="live-options-input"
              {...params}
              onChange={(e, value) => {
                setSymbol(e.target.value);
              }}
              value={symbol}
              InputProps={{
                ...params.InputProps,
                classes: { input: styles['input'] },
                endAdornment: (
                  <React.Fragment>
                    <span style={{ position: "relative", left: "20px", top: '-5px' }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.4121 14.4121L20 20"
                          stroke="#555"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                        <path
                          d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                          stroke="#555"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>

                      {params.InputProps.endAdornment}
                    </span>
                  </React.Fragment>
                ),
              }}
              style={{ border: "none !important" }}
              margin="normal"
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.ticker + ' - ' + option.name, inputValue);
            const parts = parse(option.ticker + ' - ' + option.name, matches);
            const el = option;
            return (
              <li aria-id="live-option-input-option" {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
      {
        !!tickers.length && (
          <div className="col-xl-12 col-lg-12" style={{ marginTop: tickers.length <= 5 ? '15px' : '19px' }}>
            {tickers.map((d, i) => {
              return (
                <Chip
                  style={{
                    margin: 2,
                    background: "rgb(127 248 205)",
                    color: "black",
                  }}
                  deleteIcon={
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.00195312"
                        y="0.45459"
                        width="24"
                        height="24"
                        rx="12"
                        fill="white"
                        fill-opacity="0.5"
                      />
                      <path
                        d="M16.002 8.45459L8.00195 16.4546"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        // 108,7000
                      />
                      <path
                        d="M8.00195 8.45459L16.002 16.4546"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  label={d.ticker}
                  onDelete={() => {
                    let clone = [...tickers];
                    let data = clone.filter((x) => x.ticker !== d.ticker);
                    setTicker(data);
                  }}
                />
              );
            })}
          </div>
        )
      }
    </div>
  );
}
export default OptionTickerSearch;
