import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import "../PlatinumProgram/index.scss";
import ChatContainer from '../Chat/ChatContainer';
import Support from './Support';
import { Button, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';

const DayTradeWithBrian = () => {
    const [currentNav, setCurrentNav] = useState('real-time-trading-alerts');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const hardHistory = useHistory();
    const { tab } = useParams();

    const selectNavigation = (nav) => {
        if (nav === 'support') {
            hardHistory.push(`/live-chat/support`);
        } else {
            hardHistory.push(`/live-chat/${nav}`);
        }
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    const doNavigation = (tab) => {
        setCurrentNav(tab)
    }

    useEffect(() => {
        doNavigation(tab);
    }, [tab]);

    const Modal = ({ isOpen, onClose, children }) => {
        const modalRef = useRef(); // Ref for the modal content

        useEffect(() => {
            const handleOutsideClick = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    onClose(); // Close the modal if the click is outside
                }
            };

            const handleEscapePress = (event) => {
                if (event.key === 'Escape') {
                    onClose(); // Close the modal if Escape key is pressed
                }
            };

            if (isOpen) {
                document.addEventListener('mousedown', handleOutsideClick);
                document.addEventListener('keydown', handleEscapePress);
            }

            return () => {
                // Cleanup the event listeners when the component unmounts or modal is closed
                document.removeEventListener('mousedown', handleOutsideClick);
                document.removeEventListener('keydown', handleEscapePress);
            };
        }, [isOpen, onClose]); // Dependencies array ensures effect runs only if `isOpen` or `onClose` changes

        if (!isOpen) return null;

        return (
            <div className="modal-overlay">
                <div className="modal-content" ref={modalRef}>
                    <IconButton onClick={onClose} className="close-button close-modal">
                        <CloseIcon />
                    </IconButton>
                    {children}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className='live-chat-page-navigator'>
                <div className={`live-chat-page-navItem ${currentNav === 'real-time-trading-alerts' ? 'active' : ''}`} onClick={() => selectNavigation('real-time-trading-alerts')}>Real-time Trading Alerts</div>
                <div className={`live-chat-page-navItem ${currentNav === 'livestream' ? 'active' : ''}`} onClick={() => selectNavigation('livestream')}>Livestream</div>
                <div className={`live-chat-page-navItem ${currentNav === 'support' ? 'active' : ''}`} onClick={() => selectNavigation('support')}>Support</div>
                <Button className="rules-button" onClick={() => setIsModalOpen(true)} startIcon={<ErrorIcon sx={{ color: "white !important" }} />} fullWidth>
                    CLICK HERE FOR THE GUIDELINES OF THIS ROOM TO TRADE SUCCESSFULLY
                </Button>
            </div>
            <div className='platinum-program-page-main-view'>
                {
                    (currentNav === "real-time-trading-alerts" || currentNav === 'livestream') && <ChatContainer setTab={selectNavigation} tab={currentNav} />
                }
                {
                    currentNav === 'support' && <Support tab={currentNav} setTab={selectNavigation} />
                }
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="rules-list">
                    <div className="modal-header">
                        <EditNoteIcon className="edit-note-icon" />
                        <div className="analyst-rules">Rules From Analyst</div>
                    </div>
                    <span className="desc-style">
                        <li className="bullets-rule">Please have a set position size for trades explicitly mentioned: Superlotto, lotto, and small sized</li>
                        <li className="bullets-rule">No complaining/negativity- 2 strikes and you will be kicked from chat</li>
                        <li className="bullets-rule">When trades reach 20% profit, stop is set to break even unless they are superlotto positions (no stoploss)</li>
                        <li className="bullets-rule">Brian's stop is automatically 30% from entry for all trades (except for superlottos)</li>
                        <li className="bullets-rule">After a trade is 10% or more from Brian's entry, NOT recommended to chase position</li>
                        <li className="bullets-rule">Brian will alert when there is a good risk to reward, <b>PLEASE DONT ASK FOR TRADES</b></li>
                        <li className="bullets-rule">If you ask to look at a stock, Brian will only do so with a premise/reason from a technical analysis standpoint + time frame</li>
                        <li className="bullets-rule">Superlottos are <b>RISKY</b> by nature. They have no stoploss and a majority of our trades lately</li>
                    </span>
                    <a href="https://docs.google.com/document/d/1NLoLcZm6YorkdCU60N6SEff0PjDSyJXQ_XKIazSEWUw/edit?pli=1" target="_blank" rel="noopener noreferrer" className="full-notes">Click to view all rules</a>
                </div>
            </Modal>
        </div>
    )
}

export default DayTradeWithBrian;
