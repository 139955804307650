import React from "react";
import { useAuth } from "../../contexts/Auth";
import "./index.scss"
import Button from "@mui/material/Button";
import introOptionsCard from "../../assets/images/optionsAcademy/Lesson-Video-Card.png"
import advancedTraining from "../../assets/images/optionsAcademy/advanced-training.png"
import Divider from '@mui/material/Divider';

const OptionsAcademySignup = () => {

    return (
        <div className="options-academy-signup-wrapper">
            <div className="titles-wrapper">
                <div className="ta-title">TradeAlgo</div>
                <div className="oa-title">Options Academy</div>
                <div className="oa-subtitle">
                    Trade Algo Options Academy demystifies the complexities of trading options. We cover essential concepts from technical analysis and option pricing, to more advanced topics including volatility trading and option Greeks. Our video courses are thoughtfully structured, allowing you to learn at your own pace and revisit the materials whenever you need to reinforce your understanding. Take control of your trades with Trade Algo Options Academy!
                </div>
                <div className="d-flex g-30">
                    <div className="d-flex flex-col">
                        <div className="price-wrapper">
                            <span className="price">$9.95</span>
                            <span className="ppm">USD / mth</span>
                        </div>
                        <Button onClick={() => window.open('https://checkout.tradealgo.com/OTesZx3zJo37D6kmR0rPPhVwfmjfmtdGgHdVL8PSs6B6qTfs1cwAjaHtd9-L', "_blank")} className="get-it-now">Get Monthly Access </Button>
                    </div>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <div className="d-flex flex-col">
                        <div className="price-wrapper">
                            <span className="price">$299</span>
                            <span className="ppm">USD / lifetime</span>
                        </div>
                        <Button onClick={() => window.open('https://checkout.tradealgo.com/OTesZx3zJo37D6ggT1vTOwE4Mmrdn9ZOlFFVL6XOs6x6qTfs1cwDg6jveduK', "_blank")} className="get-it-now">Get Lifetime Access </Button>
                    </div>
                </div>
            </div>
            <img className="advanced-training" src={advancedTraining} />
            <img className="intro-to-options" src={introOptionsCard} />
        </div>
    )
}

export default OptionsAcademySignup;
