import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import MostRecommendedImg from "../../../assets/images/most-recommended.png";
import "./plans.scss";
import { useAuth } from "../../../contexts/Auth";
import { CircularProgress } from "@material-ui/core";

const PlatinumPlan = ({ userSettings }) => {
  const { hasPlatinumProgram, loading } = useAuth()
  const submit = () => {
    window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1vSn8ZCl11nUNKv7_ktzGqmkp0S8uK0I8j-LTIEmKWHSYCs23LRMowYfA=="
  }
  const processing = userSettings.om_platinum_sign_up || !!userSettings?.om_platinum_renewed || !!userSettings?.platinum_sign_up || hasPlatinumProgram;
  return (
    <div className="subscribe-plan-item platinum-item plat-sub-item">
      <img src={MostRecommendedImg} alt="most recommended" className="most-recommended-badge" />
      <div className="subscribe-plan-item-title">
        TradeAlgo Options<br />Mentorship: <span className="subscribe-plan-item-type">Platinum</span>
      </div>
      <div className="subscribe-plan-item-includes-title">
        Includes
      </div>
      <ul className="subscribe-plan-item-includes-entries">
        <li className="subscribe-plan-item-includes-entry">52 sessions over 12 months</li>
        <li className="subscribe-plan-item-includes-entry">
          Options trading pro-led personalized coaching
        </li>
        <li className="subscribe-plan-item-includes-entry">
          Access to TradeAlgo Platinum Program
        </li>
      </ul>
      <div className="subscribe-plan-item-price"><span className="subscribe-plan-item-small-text">USD</span> $9,995</div>
      <Button sx={{ width: processing ? '215px !important' : '' }} disabled={processing || loading} className="subscribe-plan-item-button" onClick={submit.bind(this)}>{loading ? <CircularProgress color="success" size={24} /> : processing ? "Payment Processing" : "Sign Me Up"}</Button>
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(PlatinumPlan);