export const tutorialVideoLookup = {
    "Main Dashboard": "https://player.vimeo.com/video/844051520?h=788f723ee3",
    "Live Training": false,
    "Live Walkthrough": false,
    "Trade With Ross": "https://player.vimeo.com/video/844053019?h=67c690eb94",
    "Platinum Program 💎" : "https://player.vimeo.com/video/844052688?h=91b1dde247" ,
    "Option Chain": "https://player.vimeo.com/video/844052197?h=ed8ce97c73",
    "Live Options": "https://player.vimeo.com/video/844052013?h=28c7c634f0",
    "Daytrade With Brian": "https://player.vimeo.com/video/844051646?h=ca7926eef8",
    "Historical DarkFlow": "https://player.vimeo.com/video/844051762?h=55a112885c",
    "Historical ATS": "https://player.vimeo.com/video/844051762?h=55a112885c",
    "Scanner": "https://player.vimeo.com/video/844052826?h=b5be7d88d4",
    "Chart Grid": "https://player.vimeo.com/video/844051303?h=206f92350d",
    "Movement": 'https://player.vimeo.com/video/844052122?h=735c492eb3',
    "Alerts": "https://player.vimeo.com/video/844050959?h=b99fd5014d",
    "Options Academy": false,
    "Options Mentorship": "https://player.vimeo.com/video/844052478?h=cfcfbc4598",
    "SwingTrade AI": true,
    "TradeAlgo Options Mentorship": "https://player.vimeo.com/video/844052478?h=cfcfbc4598"
}