/* eslint-disable */
import React from "react";
import SparkLines from "../../SparkLines";

const SparkLinesRow = ({ params, up, currentPrices }) => {
  const dummyOptions = {
    colors: [up ? "#5EEECD" : "#F32228"],
    chart: {
      type: "area",
      height: 45,
      sparkline: {
        enabled: true,
      },
      width: 150,
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          // eslint-disable-next-line no-unused-vars
          formatter(seriesName) {
            return "   $";
          },
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.1,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 95],
      },
    },
  };
  return (
    <div
    className="spark-row"
      style={{
        lineHeight: "normal !important",
      }}
    >
      <SparkLines
        style={{ lineHeight: "normal !important" }}
        series={[
          {
            data: [...(params.value || [])].filter((i) => !!i).map((el) =>
              Number(el).toFixed(2)
            ),
          },
        ]}
        options={dummyOptions}
        type={dummyOptions.chart.type}
        height={dummyOptions.chart.height}
        width={dummyOptions.chart.width}
      />
    </div>
  );
};


export default SparkLinesRow
