import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "../common/SearchBar";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Logo from "../common/Logo";
import { LogoDarkIcon, LogoWhiteIcon } from "../common/Actions";
import "./topbar.scss";
import "../common/Layout/PrivateLayout/sidebarStyles.scss"
import { sidebarSetter } from "../../appRedux/ducks/sidebarOpen";
import { useMedia } from "react-media";
import VideoModal from "./VimeoModal";
import { tutorialVideoLookup } from "./tutorialMetadata";
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import { useAuth } from "../../contexts/Auth";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  background: {
    zIndex: 100,
    width: "100%",
    backgroundColor: theme.palette.primary.appHeader,
  },
  backgroundWithBanner: {
    zIndex: 100,
    width: "100%",
    backgroundColor: theme.palette.primary.appHeader,
  },
  text: {
    color: theme.palette.primary.text,
  },
  appBanner: {
    fontSize: "16px",
    backgroundColor: "#000000 !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    fontWeight: 500,
    top: 0,
  },
}));

const HomeHeader = ({ setSidebar, name, themeMode }) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const [mobileSearch, setMobileSearch] = useState(false)
  const [open, setOpen] = useState(false)
  const hardHistory = useHistory();
  const classes = useStyles();
  const [isClick, setClick] = useState(true);
  const headerDiv = useRef(null);
  const loc = window.location.pathname.includes('home') || window.location.pathname.includes('historical') || window.location.pathname.includes('movement') || window.location.pathname.includes('alerts')
  useEffect(() => {
    setSidebar(isClick)
    // eslint-disable-next-line
  }, [isClick])
  const { canSeeInternal } = useAuth()
  const logoUrl = useMemo(() => {
    return canSeeInternal ? '/home/Intraday/Auto/Up' : '/free-tier'
  }, [canSeeInternal])
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    let classes = Array.from(body.classList)
    if (!classes.includes("sidebar-enable")) {
      body.classList.add("sidebar-enable");
      if (!isMobile) document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      if (!isMobile) document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  useEffect(() => {
    if (!isMobile) {
      window.onscroll = () => {
        if (headerDiv != null && headerDiv.current != null) {
          if (window.pageYOffset > 30) {
            headerDiv.current.classList.add('header-bg-white');
          } else {
            headerDiv.current.classList.remove('header-bg-white');
          }
        }
      }
    }
  }, [isMobile]);
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode])

  useEffect(() => {
    var body = document.body;
    if (isMobile == false) {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
      setClick(true)
    } else {
      body.classList.remove("sidebar-enable");
      document.body.removeAttribute("data-sidebar-size");
    }
  }, [isMobile])

  if (isMobile) return (
    <>
      <VideoModal name={name} open={open} setOpen={setOpen} />
      <div className={clsx(classes.background, "d-flex w-100 align-items-center")} ref={headerDiv}>
        <button
          onClick={() => {
            tToggle();
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item"
          id="vertical-menu-btn"
        >
          <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.95508 1.45428H15.046M1.95508 6.90883H12.3187M1.95508 12.3634H15.046"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </button>
        <div className={`pb-8  w-100 d-flex justify-${mobileSearch ? 'end' : 'between'} align-center mr-31`}>
          {
            !mobileSearch && (
              <div className="">
                <span
                  style={{
                    fontSize: isMobile ? '15px' : '24px'
                  }}
                  className={clsx(classes.text, "home-title")}
                >
                  {name}
                  {
                    tutorialVideoLookup[name] && (
                      <span className="joyride-helper">
                        <Tooltip arrow={true} title={name === 'SwingTrade AI' ? "Our algorithms have identified these companies with great momentum and great fundamentals for potential swing trades. We recommend holding these assets for a minimum of 1 month and ideally between 6-12 months for best results. New picks are added every week as they are identified." : `Click to view a tutorial video about ${name}.`}>
                          <ErrorOutlineSharpIcon onClick={() => {
                            if (name !== 'SwingTrade AI') {
                              setOpen(true)
                            }
                          }} style={{ marginLeft: "10px" }} />
                        </Tooltip>
                      </span>
                    )
                  }
                </span>
              </div>
            )
          }
          <div className="">
            <div
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
              className="d-flex"
            />
            <div
              className="d-flex"
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {
                mobileSearch ? <SearchBar isMobile={isMobile} setMobileSearch={setMobileSearch} popupContainerId="header-search-bar" /> :
                  (
                    <svg
                      onClick={() => setMobileSearch(true)}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4121 14.4121L20 20"
                        stroke="#A2A3A5"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                      <path
                        d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                        stroke="#A2A3A5"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  )
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
  return (
    <div
      className={clsx(
        hardHistory.location &&
          hardHistory.location.pathname &&
          hardHistory.location.pathname === "/real-time-options-flow"
          ? classes.backgroundWithBanner
          : classes.background,
        "home-font-family py-3 header-height"
      )}
    >
      <VideoModal name={name} open={open} setOpen={setOpen} />
      <div className="container sidebar-content-container">

        <header id="page-topbar" className={`${themeMode}-mode`}>
          {/* <div className={classes.appBanner}>
          Own a piece of TradeAlgo! Invest before it’s too late => <a target="_blank" href="https://www.startengine.com/trade-algo/">StartEngine.com</a>
          </div> */}
          <div className="navbar-header">
            <div className="d-flex w-100">
              <div className={`navbar-brand-box ${themeMode === "dark" ? "dark-brandbox" : ''}`}>
                <Logo
                  url={logoUrl}
                  type="h1"
                  icon={
                    themeMode === "dark" ? <LogoWhiteIcon style={{ width: "30px", height: "20px" }} /> : <LogoDarkIcon style={{ width: "30px", height: "20px" }} />
                  }
                  from={"dashboard"}
                  header={true}
                  hide={!isClick && !isMobile}
                />

                <button
                  onClick={() => {
                    tToggle();
                  }}
                  type="button"
                  className="btn btn-sm font-size-16 header-item"
                  id="vertical-menu-btn"
                >
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.95508 1.45428H15.046M1.95508 6.90883H12.3187M1.95508 12.3634H15.046"
                      stroke={color}
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>
                </button>
              </div>

              <div className="d-flex w-100 align-items-center" ref={headerDiv}>
                <div className="row w-100 no-margin">
                  <div className="col-5">
                    <span
                      style={{
                        position: "relative",
                        top: "9px",
                        marginLeft: !isClick ? "0px" : "1rem"
                      }}
                      className={clsx(classes.text, "home-title")}
                    >
                      {name}
                      {
                        tutorialVideoLookup[name] && (
                          <span className="joyride-helper">
                            <Tooltip arrow={true} title={name === 'SwingTrade AI' ? "Our algorithms have identified these companies with great momentum and great fundamentals for potential swing trades. We recommend holding these assets for a minimum of 1 month and ideally between 6-12 months for best results. New picks are added every week as they are identified." : `Click to view a tutorial video about ${name}.`}>
                              <ErrorOutlineSharpIcon onClick={() => {
                                if (name !== 'SwingTrade AI') {
                                  setOpen(true)
                                }
                              }} style={{ marginLeft: "10px" }} />
                            </Tooltip>
                          </span>
                        )
                      }
                    </span>
                  </div>
                  <div className="col-7">
                    <div
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                      className="d-flex"
                    />
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "flex-end",
                        display: "flex",
                        alignItems: "flex-end",
                        position: "relative",
                        right: loc ? "14px" : "",
                      }}
                    >
                      <SearchBar popupContainerId="header-search-bar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen.open,
  themeMode: state.themeMode.themeMode
});
const dispatchToProps = (dispatch) => ({
  setSidebar: (open) => dispatch(sidebarSetter(open))
});
export default connect(stateToProps, dispatchToProps)(HomeHeader);
