import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function UserTabs({ value, setValue }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box
            sx={{
                width: "100%",
                justifyContent: "start",
                display: "flex",
            }}
        >
            <Tabs TabIndicatorProps={{
                style: {
                    backgroundColor: "#5EEECD"
                }
            }} value={value} onChange={handleChange} >
                <Tab label="User Profile" />
                <Tab label="My Subscriptions" />
            </Tabs>
        </Box>
    );
}
export default UserTabs