import * as React from "react"

const TWRIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 348.08 214.22"
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          stroke="#0ad595f0"
          d="M99.1 166.37a15.76 15.76 0 0 1-13.33-6.63 17.26 17.26 0 0 1-3-9.13c-.15-32.83-.17-49.78-.06-87 0-9 7-15.59 16.56-15.59s16.43 6.48 16.47 15.68c.06 12.39.05 1.94 0 14.12v73.65c0 6.95-4.71 12.43-12.18 14.32a18.26 18.26 0 0 1-4.46.58Z"
        />
        <path d="M248.78 166.37c-9.48 0-16.41-6.73-16.48-16v-50.25c.08-9.19 6.82-15.63 16.39-15.66S265.28 91 265.34 100c.1 16.21.1 32.77 0 50.63 0 9.08-7 15.7-16.46 15.75ZM174 166.37c-9.48 0-16.4-6.73-16.47-16v-63c.08-9.19 6.83-15.63 16.4-15.67s16.58 6.54 16.63 15.54c.1 16.21.1 45.53 0 63.39-.05 9.08-7 15.7-16.47 15.75Z" />
        <path d="M348.08 49.62v-7.88C347.08 20 334 3.13 316.27.65A78.28 78.28 0 0 0 305.34 0c-2.27 0-4.55.05-6.81.11l-2.75.06H52.52L49 .16H38.75C15.22.23 0 18.08 0 45.68v122.69c0 27.79 15.25 45.77 38.86 45.81h223.45c13.71 0 31.82 0 50.42-.19 16.48-.16 30.18-13.12 34.09-32.25a93.2 93.2 0 0 0 1.29-10.24Zm-23.9 119.46c0 12.33-4.83 18.6-14.33 18.63H37.94c-6.4 0-14-3.12-14-18V44.25c0-11 5.09-17.54 13.58-17.61h272.14c9.63 0 14.51 6.35 14.52 18.79q.02 61.85 0 123.65Z" />
      </g>
    </g>
  </svg>
)

export default TWRIcon
