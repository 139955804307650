import InfiniteScroll from "react-infinite-scroll-component";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { socketListen } from "../../appRedux/ducks/socket";
import { Helmet } from "react-helmet";
import Summary from "./Summary";
import "./index.scss";
import { RangePicker } from "../Earnings/components/calendar.tsx";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { searchLoad } from "../../appRedux/ducks/search";
import OptionTickerSearch from "../OptionScanner/OptionTickerSearch";
import useDebounce from "../OptionScanner/useDebounce";
import { Card, CardBody } from "reactstrap";
import Slider from "@mui/material/Slider";
import { withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import "../common/Tables/liveOptionsTable.css";
import "../common/Tables/animations/addedRowsAnimations.scss"
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { liveOptionsLoad } from "../../appRedux/ducks/newLiveOptions";
import { abbreviate } from "../homePage/TradeAlgoCard";
import { useMedia } from "react-media";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import BidAskSelect  from "./BidAskSelect";
import usePrevious from "../../hooks/usePrevious";
import BBi from "../../assets/images/liveOptions/BBi.png"
import AAi from "../../assets/images/liveOptions/AAi.png"
import LAi from "../../assets/images/liveOptions/LAi.png"
import LBi from "../../assets/images/liveOptions/LBi.png"
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";


const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.tableBackground + ' !important',
  },
  LA: {
    backgroundColor: 'rgba(220, 0, 78, 0.8)',
  },
  LB: {
    backgroundColor: "#EA580C",
  },
  BB: {
    backgroundColor: "#2563EB",
  },
  AA: {
    backgroundColor: " #0D9488",
  },
  LAi: {
    color: 'rgba(220, 0, 78, 0.8)',
  },
  LBi: {
    color: "#EA580C",
  },
  BBi: {
    color: "#2563EB",
  },
  AAi: {
    color: " #0D9488",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  popper: {
    "& .MuiSelectUnstyled-root": {
      background: theme.palette.primary.select,
      color: theme.palette.primary.text
    },
    "& .MuiSelectUnstyled-listbox ": {
      "fontFamily": "IBM Plex Sans,sans-serif",
      "fontSize": "14px",
      boxSizing: "borderBox",
      padding: "5px",
      margin: "0px 0",
      minWidth: "256px",
      background: "#F1F5F9",
      borderRadius: "24px",
      color: "black",
      overflow: "auto",
      outline: "0px",
    }
  },
}));

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const valuetext = (value) => {
  return <span className="slider-tooltip"> {abbreviate(value)} </span>;
};
const valuetextExpire = (value) => {
  return <span className="slider-tooltip"> {value} Days </span>;
};

const CustomSlider = withStyles({
  root: {
    color: "#5EEECD !important",
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#5EEECD",
    border: "1px solid currentColor",
    boxShadow: "#5EEECD 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#5EEECD 0 2px 3px 1px",
    },
    color: "#5EEECD",
  },
})(Slider);
const today = new Date();

const LiveOptionsContainer = ({
  themeMode,
  listen,
  searchedSymbols,
  loading,
  searchAllSymbols,
  company = false,
  symbols,
  setSymbolRedux,
  liveFetchOptions,
  newLiveOptions,
  optionsLoading,
}) => {

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const [bidAsk, setBidAsk] = useState([])
  // const search = useLocation().search;
  const params = useParams();
  const all = params['0']?.split('/') || {}
  const allParams = useMemo(() => {
    //  live-options/35000/730/tickers/date
    if (!company) {
      if (!!all[3]) {
        let [prem, expiry, tickers, dateRange] = all
        return {
          prem,
          expiry,
          tickers,
          dateRange
        }
      } else if (!!all[2]) {
        let [prem, expiry, tickers] = all
        return {
          prem,
          expiry,
          tickers
        }
      } else {
        let [prem, expiry] = all
        return {
          prem,
          expiry
        }
      }
    }
  }, [all, company])
  const history = useHistory()
  const [minPremium, setMinPremium] = useState(!!allParams?.prem ? Number(allParams?.prem) : 35000);
  const [expiresWithin, setExpiresWithin] = useState(!!allParams?.expiry ? Number(allParams?.expiry) : 730);
  const debouncedExpire = useDebounce(expiresWithin, 400);
  const debouncedPrem = useDebounce(minPremium, 400);
  const [show, setShow] = useState(false);
  const [rangeString, setRangeString] = useState('')
  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });

  const dateRangeString = useMemo(() => {
    const todayMoment = moment().format("YYYY-MM-DD");
    if (
      moment(dateRange.startDate).format("YYYY-MM-DD") === todayMoment &&
      moment(dateRange.endDate).format("YYYY-MM-DD") === todayMoment
    ) {
      return "Today's Contracts";
    } else {
      setBidAsk([])
      return `${moment(dateRange.startDate).format("MMM Do")} - ${moment(
        dateRange.endDate
      ).format("MMM Do")} Contracts`;
    }
  }, [dateRange]);
  const prevRange = usePrevious(dateRangeString)
  const prevBidAsk = usePrevious(bidAsk)
  const [symbol, setSymbol] = useState("");
  const [tickerString, setTickerString] = useState(!!allParams?.tickers ? allParams?.tickers : '');
  const [ticker, setTicker] = useState(!!allParams?.tickers ? all[2].split(',').map((ticker) => ({ ticker })) : []);
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP') {
        // Handle events
        const all = location.pathname.slice(1).split('/')
        all.shift()
        setMinPremium(Number(all[0]))
        setExpiresWithin(Number(all[1]))
        if (all[2]) {
          const mapped = all[2].split(',').map((ticker) => ({ ticker }))
          setTicker(mapped)
          setTickerString(all[2])
        } else {
          setTicker([])
          setTickerString('')
        }
      }
    })
  }, [])
  useEffect(() => {
    let basePath = '/live-options'
    if (!company) {
      // prem/exp/tickers(AAPL,TSLA || AAPL)/date(todo)
      const premBool = Number(allParams?.prem) !== Number(debouncedPrem)
      const expBool = Number(allParams?.expiry) !== Number(debouncedExpire)
      const tickerBool = (allParams?.tickers || '') !== tickerString
      if (premBool || expBool || tickerBool) {
        //  if the expiryUrl or the premiumUrl or tickerUrl is different than that of the one in state  or if there is a date in the url then we need to update the url
        //  console.log('history has changed ------', premBool, expBool, tickerBool)
        //  console.log(Number(allParams?.prem), Number(debouncedPrem), 'prem')
        //  console.log(Number(allParams?.expiry), Number(debouncedExpire), 'exp')
        //  console.log(allParams?.tickers, tickerString, 'tickers')
        history.push(`${basePath}/${debouncedPrem}/${debouncedExpire}${tickerBool ? `/${tickerString}` : !!tickerString.length ? `/${tickerString}` : ''}`)
      }
    }
    // to do add date 
  }, [debouncedPrem, debouncedExpire, tickerString, company])


  const [open, setOpen] = useState(false);
  const debouncedSymbol = useDebounce(symbol, 500);
  const hide = useMemo(() => {
    return company && !optionsLoading && minPremium === 35000 && expiresWithin === 730 && newLiveOptions.length === 0 && dateRangeString === `Today's Contracts` && bidAsk === "1"
  }, [company, debouncedPrem, debouncedExpire, newLiveOptions, dateRangeString, bidAsk])
  function rowClassName(params) {
    return `${params.row.newItem ? `new-item-${themeMode}` : ""}`
  }
  function IconAndTooltip(bid, ask, fillPrice, isMobile) {
    let text, Icon, tooltip
    if ((ask - fillPrice) < (fillPrice - bid)) {
      text = "LA"
      tooltip = "Leaning Ask, Price Filled leaning Ask"
      Icon = LAi
    }
    if ((ask - fillPrice) > (fillPrice - bid)) {
      text = "LB"
      tooltip = "Leaning Bid, Price Filled leaning Bid"
      Icon = LBi
    }
    if (ask <= fillPrice) {
      text = "AA"
      tooltip = "Above Ask, Price Filled at or Above Ask"
      Icon = AAi
    }
    if (bid >= fillPrice) {
      text = "BB"
      tooltip = "Below Bid, Price Filled at or Below Bid"
      Icon = BBi
    }
    if (Number(ask - fillPrice) === Number(fillPrice - bid)) return <> </>
    return (
      <Tooltip classes={{
        tooltip: classes[text],
        arrow: classes[(text + "i")]
      }} title={tooltip} placement="top" arrow>
        <span style={{ position: 'relative', left: isMobile ? '-6px' : '' }} className={`bid-ask-base ${text}`}>{text}<img src={Icon} className="BBi" /></span>
      </Tooltip>
    )
  }
  const columns = useMemo(() => {
    let base = [
      {
        field: "time",
        headerName: "Time",
        renderHeader: () => <span className="table-headers2">Time</span>,
        renderCell: (params) => (
          <span className={clsx(classes.text, `time  ${!isMobile && 'large-table'}`)}>
            {moment(Number(params.value) * 1000).isSame(moment(), 'Day') ? moment.tz(Number(params.value) * 1000, 'America/New_York').format("hh:mm:ss A") : moment.tz(Number(params.value) * 1000, 'America/New_York').format("MM/DD hh:mm:ss A")}
          </span>
        ),
        flex: 1.5,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "ticker",
        headerName: "Ticker",
        renderHeader: (params) => <span className="table-headers">Ticker</span>,
        renderCell: (params) => (
          <span
            className="ticker"
            style={{ position: 'relative', left: '-3px' }}
            onClick={() => {
              setSymbolRedux({
                type: "",
                value: params.value,
              });
            }}
          >
            <Link to={`/company/${params.value}`} className={clsx(classes.text, "ticker")}>
              <span
                // aria-id="option-ticker"
                className={clsx(classes.text, "ticker")}>
                {params.value}
              </span>
            </Link>
          </span>
        ),
        flex: 1,
      },
      {
        field: "exp",
        headerName: "Expiry",
        flex: 1,
        renderHeader: () => <span className="table-headers">Expiry</span>,
        renderCell: (params) => (
          <span className={clsx(classes.text, "strike")}>
            {moment.utc(params.value).format("MM/DD/YY")}
          </span>
        ),
      },
      {
        field: "strike",
        headerName: "Strike",
        renderHeader: () => <span className="table-headers">Strike</span>,
        renderCell: (params) => <span className={clsx(classes.text, "strike")}>{params.value}</span>,
        flex: 1,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },

      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        renderHeader: () => <span className="table-headers">C/P</span>,

        renderCell: (params) => (
          <span
            className={`cp ${params.value === "C" ? "green-call" : "red-put"}`}
          >
            {params.value === "C" ? "Calls" : "Puts"}
          </span>
        ),
      },
      {
        field: "spot",
        headerName: "Spot",
        flex: 1,
        renderHeader: () => <span className="table-headers">Spot</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "bid_price",
        headerName: "Bid",
        flex: 1,
        renderHeader: () => <span className="table-headers">Bid</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "contract_quantity",
        headerName: "Details",
        renderHeader: () => <span className="table-headers">Details</span>,
        renderCell: (params) => {
          return (
            <span style={{ position: 'relative', left: '-3px' }} className={clsx(classes.text, "ppc")}>
              {Math.round(params.row.contract_quantity) +
                " @ " +
                Number(params?.row?.price_per_contract || 0)?.toFixed(2) || ""}
              {isMobile && <br />}
              {dateRangeString.includes('Today') && (IconAndTooltip(Number(params.row.bid_price), Number(params.row.ask_price), Number(params.row.price_per_contract), isMobile))}
            </span>
          )
        },
        flex: 1.3,
      },
      {
        field: "ask_price",
        headerName: "Ask",
        flex: 1,
        renderHeader: () => <span className="table-headers">Ask</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: '3px' }}>{Number(params.value).toFixed(2)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "type",
        headerName: "Type",
        renderHeader: () => <span className="table-headers">Type</span>,
        renderCell: (params) => <span className={clsx(classes.text, "type-cell")}>{params.value}</span>,
        flex: 1,
      },
      {
        field: "prem",
        headerName: "Premium",
        flex: 1,
        renderHeader: () => <span className="table-headers">Premium</span>,
        renderCell: (params) => (
          <span className="prem-cell">${abbreviate(params.value)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
    ];
    if (!dateRangeString.includes('Today')) {
      base.splice(6, 1)
      base.splice(7, 1)
    }
    return base
  }, [isMobile, dateRangeString, classes, bidAsk])
  const [sortModel, setSortModel] = useState([
    {
      field: "time",
      sort: "desc",
    },
  ]);

  const handleChangePrem = (event, newValue) => {
    if (typeof newValue === "number") {
      setMinPremium(newValue);
    }
  };

  const handleChangeExpire = (event, newValue) => {
    if (typeof newValue === "number") {
      setExpiresWithin(newValue);
    }
  };

  useEffect(() => {
    company ? listen([`options;${symbols}`, 'CHAT']) : listen(["options", 'CHAT', "TRADE_MESSAGE"]);
    return () => listen([]);
  }, [symbols, company]);
  useEffect(() => {
    return () => {
      liveFetchOptions(35000, 730, false, '', '', true, sortModel[0], bidAsk)
    }
  }, [])
  const prevValues = useMemo(() => {
    return (prevBidAsk !== bidAsk || prevRange !== dateRangeString)
  }, [prevBidAsk, bidAsk, prevRange, dateRangeString])
  useEffect(() => {
    if (company) {
      !!symbols && liveFetchOptions(debouncedPrem, debouncedExpire, false, rangeString, symbols, prevValues, sortModel[0], bidAsk)
    } else {
      liveFetchOptions(debouncedPrem, debouncedExpire, false, rangeString, tickerString, prevValues, sortModel[0], bidAsk)
    }
  }, [debouncedPrem, debouncedExpire, rangeString, tickerString, company, symbols, sortModel, bidAsk, prevValues]);
  const getMoreRows = useCallback(() => {
    liveFetchOptions(debouncedPrem, debouncedExpire, true, rangeString, company ? symbols : tickerString, false, sortModel[0], bidAsk)
  }, [debouncedPrem, debouncedExpire, rangeString, tickerString, liveFetchOptions, company, symbols, sortModel, bidAsk]);

  useEffect(() => {
    searchAllSymbols(debouncedSymbol);
  }, [debouncedSymbol]);

  useEffect(() => {
    if (
      moment().format("YYYY-MM-DD") ===
      moment(dateRange.startDate).format("YYYY-MM-DD")
    ) {
      setRangeString("");
    } else {
      setRangeString(
        `${moment
          .tz(dateRange.startDate, "America/New_York")
          .format("YYYY-MM-DD")},${moment
            .tz(dateRange.endDate, "America/New_York")
            .format("YYYY-MM-DD")}`
      );
    }
  }, [JSON.stringify(dateRange)]);
  return (
    <>
      {
        !company && (
          <Helmet>
            <title>Live Options | TradeAlgo </title>
          </Helmet>
        )
      } {
        hide ? null : (
          <InfiniteScroll
            dataLength={newLiveOptions.length}
            next={getMoreRows}
            hasMore={true}
            scrollableTarget="scrollable-target"
            style={{ overflow: 'none' }}
          >
            <div>
              <div className="contents">
                <Summary />
              </div>
              <br />
              <Card className={clsx(classes.background, "filter-cards")}>
                <CardBody>
                  <div className="row live-options-filter-container">
                    <div
                      style={{ zIndex: show ? 2 : 1 }}
                      className={"live-option-date-filter"}
                    >
                      <span className={clsx(classes.text, "dates")}>
                        {dateRangeString}
                        <IconButton
                          className="calendar-button"
                          onClick={() => setShow(!show)}
                        >
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 4.98389H5C3.89543 4.98389 3 5.87932 3 6.98389V20.9839C3 22.0885 3.89543 22.9839 5 22.9839H19C20.1046 22.9839 21 22.0885 21 20.9839V6.98389C21 5.87932 20.1046 4.98389 19 4.98389Z"
                              stroke="#555555"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3 10.9839H21"
                              stroke="#555555"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 2.98389V6.98389"
                              stroke="#555555"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8 2.98389V6.98389"
                              stroke="#555555"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconButton>
                        <>
                          {show && (
                            <RangePicker
                              style={{ gridArea: "1 / 1 / 2 / 2" }}
                              selectedRange={dateRange}
                              onRangeSelect={(r) => {
                                setDateRange(r);
                                if (
                                  moment(r.endDate).diff(
                                    moment(r.startDate),
                                    "days"
                                  ) >= 1
                                ) {
                                  setTimeout(() => {
                                    setShow(!show);
                                  }, 500);
                                }
                              }}
                            />
                          )}
                        </>
                      </span>
                    </div>
                    <div
                      style={{ zIndex: show ? 1 : 2 }}
                      className={"live-option-slider-filter"}
                    >
                      <div style={{ textAlign: "center" }} className="row">
                        <div className="col-12">
                          <label className={`min-prem-${themeMode}`}>Minimum Premium</label>
                          <CustomSlider
                            value={minPremium}
                            min={35000}
                            step={1000}
                            max={10000000}
                            valueLabelDisplay="on"
                            valueLabelFormat={valuetext}
                            onChange={handleChangePrem}
                            aria-labelledby="non-linear-slider"
                          />
                        </div>
                        <div className="col-12">
                          <label className={`exp-within-${themeMode}`}> Expires Within</label>
                          <CustomSlider
                            value={expiresWithin}
                            min={1}
                            step={1}
                            max={365 * 2}
                            valueLabelDisplay="on"
                            valueLabelFormat={valuetextExpire}
                            onChange={handleChangeExpire}
                            aria-labelledby="non-linear-slider"
                          />
                        </div>
                      </div>
                    </div>
                    <div>

                      {
                        !company && (
                          <div
                            className={"live-option-search-filter"}
                          >
                            <OptionTickerSearch
                              open={open}
                              setOpen={setOpen}
                              valueLabelDisplay="on"
                              options={searchedSymbols}
                              value={symbol}
                              setSymbol={setSymbol}
                              symbol={symbol}
                              tickers={ticker}
                              loading={loading}
                              setTicker={setTicker}
                              // setFilter={setFilter}
                              // fetch={fetch}
                              setTickerString={setTickerString}
                              customMinWidth={250}
                            />
                          </div>
                        )
                      }
                      {
                        dateRangeString.includes("Today") && (
                          <div
                            className={classes.popper}
                          >
                            <BidAskSelect themeMode={themeMode} value={bidAsk} setValue={setBidAsk} />
                          </div>
                        )
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
                {
                  optionsLoading && !newLiveOptions.length ? <TableSkeleton/> : <TradeAlgoTable  sortModel={sortModel} setSortModel={setSortModel} rows={newLiveOptions} columns={columns} loading={false} hasRowClassName={true} rowClassName={rowClassName} />
                }
                
              </div>
            </div>
          </InfiniteScroll>
        )
      }
    </>
  );

};

const stateToProps = (state) => ({
  searchedSymbols: state.searchSymbols.searchSymbols,
  loading: state.searchSymbols.symbolsLoading,
  symbols: state.symbol.symbol.value,
  newLiveOptions: state.newLiveOptions.liveOptions,
  optionsLoading: state.newLiveOptions.loading,
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  searchAllSymbols: (symbol) => dispatch(searchLoad(symbol)),
  listen: (channels) => dispatch(socketListen(channels)),
  setSymbolRedux: (symbol) => dispatch(symbolSetter(symbol)),
  liveFetchOptions: (tickers, prem_min, exp_within, last_time, date, clearCache, sortModel, bidAskFilter) => dispatch(liveOptionsLoad(tickers, prem_min, exp_within, last_time, date, clearCache, sortModel, bidAskFilter))
});

export default connect(stateToProps, dispatchToProps)(LiveOptionsContainer);