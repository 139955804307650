import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import { connect } from "react-redux";
import { modalClosedSetter } from "../../appRedux/ducks/modalClosed";
import { IconButton,Modal } from "@material-ui/core";
import moment from "moment";
import "./modal.css"
import CloseIcon from '@mui/icons-material/Close';
const InvestorSurvey = () => {
  const [visible, setVisible] = useState(false);
  const { userData, user } = useAuth();
  useEffect(() => {
    const createdDate = moment(user?.metadata?.creationTime).format('YYYY-MM-DD')
    const daysDifference = moment().diff(createdDate, 'days')
    // if its been 7 days since account creation and the user signed the docusign agreement already and they have not taken this survey
    if (!!user && user?.metadata.creationTime && daysDifference >= 7 && userData?.agreement_signed_new && !userData?.investor_survey) {
      // then show the modal
      setVisible(true);      
    }
    // if they already took the survey close it
    if(userData?.investor_survey) {
      setVisible(false)
    }
  }, [userData, user]);
  function closeModal() {
    setVisible(false)
  }
  if (!userData) return null
  return (
    <Modal
      open={visible}
      width={800}
      onClose={(event,reason) => {
        setVisible(false)
      }}
      footer={null}
    >
      <div className="outer-modal-container" style={{
        textAlign: 'center', width: '55%', paddingBottom: "7px", position: 'absolute', top: '2%', left: '24%', background: 'white'
      }}>
        <IconButton onClick={closeModal} style={{ position: 'absolute', top: '20px', right: '19px' }}>
          <CloseIcon className="ta-logo-modal-black" />
        </IconButton>
        <div style={{height: '100%', overflow: 'hidden'}}>
        <iframe
          src={`https://survey.zohopublic.com/zs/cdD7R8?uid=${userData?.userId || ""}&email=${userData?.email || ""}`}
          height="850px"
          width="95%"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          scrolling="auto"
          allow="geolocation"
          display="block"
          borderStyle="none"
          ></iframe>
          </div>
      </div>
    </Modal>
  );
};

const stateToProps = (state) => ({
  modalClosed: state.modalClosed.closed,
  themeMode: state.themeMode.themeMode
});
const dispatchToProps = (dispatch) => ({
  setModalClosed: (closed) => dispatch(modalClosedSetter(closed))
});
export default connect(stateToProps, dispatchToProps)(InvestorSurvey);