import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const ADD_USER_LOAD = "AU/LOAD/TOP";
export const ADD_USER_LOAD_SUCCESS = "AU/LOAD/TOP/SUCCESS";
export const ADD_USER_LOAD_FAILURE = "AU/LOAD/TOP/FAILURE";

// Action Creators
export const addUserLoad = (username) => ({
  type: ADD_USER_LOAD,
  username,
});
export const addUserLoadSuccess = (data) => ({
  type: ADD_USER_LOAD_SUCCESS,
  data,
});
export const addUserLoadFailure = (error) => ({
  type: ADD_USER_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchAddUser(action) {
  try {
    const { username } = action;
    const response = yield axios.put(`${apiURL}/user/display-name`,
      {name: username},
      { withCredentials: true }
    );
    yield put(addUserLoadSuccess(response.data || []));
  } catch (error) {
    yield put(addUserLoadFailure(error));
  }
}

function* listenFetchAddUserLoad() {
  yield takeEvery(ADD_USER_LOAD, fetchAddUser);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchAddUserLoad)]);
}

const INIT_STATE = {
  usernameAdd: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_USER_LOAD_SUCCESS:
      return {
        ...state,
        usernameAdd: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
