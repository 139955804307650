import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check';
import image from "../../../assets/images/trading.png";
import SignUpHint from "./SignUpHint/SignUpHint";
import PlatinumProgramSignUpResult from "./PlatinumProgramSignUpResult";
import "./PlatinumProgramSignUp.scss";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { useAuth } from "../../../contexts/Auth";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-media";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import leftArrow from "../PastVideos/leftArrow.png"
import closeIcon from "../PastVideos/Minimize.png"
import { makeStyles } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.primary.background} !important`,
    zIndex: 1000000
  }
}));

const mediaQuery = {
  isMobile: "screen and (max-width: 799px)",
};
const PlatinumProgramSignUp = ({ userSettings }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const submit = () => {
    window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_dpZFHJJgR9T2ffittFjEUqWKmZ5vMY-n2igdh0xv-2bqzbPykOlauUX83a23K8PoUR"
  }

  const submitAnnual = () => {
    window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1vSn8ZCl11nUNKv7_ktzGqmkp0S8uK0I8j-LTIEmKWHSYCs23LRMowYfA=="
  }
  const { loading, hasPlatinumProgram } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!loading && hasPlatinumProgram) {
      history.push('/platinum-program/livestream')
    }
  }, [hasPlatinumProgram, loading])
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 390 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className="find-out-more">
        <IconButton onClick={() => setDrawerOpen(false)} aria-label="delete" size="small">
          <img src={closeIcon} className="w-70px" fontSize="inherit" />
        </IconButton>
      </div>
      <SignUpHint />
    </Box>
  );
  return (
    !!userSettings?.platinum_sign_up ? (
      <PlatinumProgramSignUpResult />
    ) : (
      <div className="platinum-program-sign-up">
        <div className="find-out-more">
          {
            isMobile && (
              <Button onClick={() => setDrawerOpen(true)} variant="outlined" startIcon={<img className="left-arrow" src={leftArrow} />}>
                Find out more
              </Button>
            )
          }
          <Drawer
            classes={{ paper: classes.paper }}
            anchor={'right'}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
        </div>
        <div className="platinum-program-sign-up-left">
          <div className="platinum-program-sign-up-header">
            <div className="platinum-program-sign-up-title">The Platinum Program</div>
            <div className="platinum-program-sign-up-info">Profitable Option Trading</div>
            <div className="platinum-program-sign-up-info-1">Made Easy.</div>
            {/* <Button className="platinum-program-sign-up-button" onClick={submit.bind(this)}>Sign me up</Button> */}
          </div>
          <div className="platinum-program-sign-up-trading-section">
            <div className="platinum-program-sign-up-unlock-text">Unlock the Secrets to Successful Trading</div>
            <img src={image} alt='trading' className="platinum-program-sign-up-trading" />
          </div>

          <div className="platinum-program-sign-up-items">
            <div className="platinum-program-sign-up-item-row">
              <div className="platinum-program-sign-up-item">
                <span className="platinum-program-sign-up-item-icon"><CheckIcon /></span> Short Interest
              </div>
              <div className="platinum-program-sign-up-item">
                <span className="platinum-program-sign-up-item-icon"><CheckIcon /></span> Artificial Intelligence
              </div>
            </div>
            <div className="platinum-program-sign-up-item-row">
              <div className="platinum-program-sign-up-item">
                <span className="platinum-program-sign-up-item-icon"><CheckIcon /></span> Real-Time Alerts for Trading
              </div>
              <div className="platinum-program-sign-up-item">
                <span className="platinum-program-sign-up-item-icon"><CheckIcon /></span> Premium Support
              </div>
            </div>
          </div>

          <div className="platinum-program-sign-up-price-row">
            <div className="platinum-program-sign-up-price-row-inner">
              <div className="platinum-program-plan">
                <div className="platinum-program-plan-header">Standard</div>
                <div className="platinum-program-plan-content">
                  <div className="platinum-program-plan-title">The Platinum<br />Program</div>
                  <ul className="platinum-program-plan-features">
                    <li className="platinum-program-plan-feature"><span>Tutorials</span></li>
                    <li className="platinum-program-plan-feature"><span>Daily Live Streaming</span></li>
                    <li className="platinum-program-plan-feature"><span>Daily Live FAQ sessions</span></li>
                    <li className="platinum-program-plan-feature"><span>Platinum Signal Alerts</span></li>
                  </ul>

                  <div className="platinum-prgoram-plan-price">$2,500 <span className="platinum-small-text">USD</span></div>
                  <div className="platinum-prgoram-plan-duration">/ 3 months</div>
                  <Button className="platinum-program-sign-up-button-small" onClick={submit.bind(this)}>Sign me up</Button>
                </div>
              </div>

              <div className="platinum-program-plan">
                <div className="platinum-program-plan-header">Most Recommended</div>
                <div className="platinum-program-plan-content">
                  <div className="platinum-program-plan-title">Options Mentorship<br />Platinum</div>
                  <ul className="platinum-program-plan-features">
                    <li className="platinum-program-plan-feature"><span>Options trading pro-led personalized coaching</span></li>
                    <li className="platinum-program-plan-feature"><span>Tutorials</span></li>
                    <li className="platinum-program-plan-feature"><span>Daily Live Streaming</span></li>
                    <li className="platinum-program-plan-feature"><span>Daily Live FAQ sessions</span></li>
                    <li className="platinum-program-plan-feature"><span>Platinum Signal Alerts</span></li>
                  </ul>

                  <div className="platinum-prgoram-plan-price">$9,995 <span className="platinum-small-text">USD</span></div>
                  <div className="platinum-prgoram-plan-duration">/ 52 sessions</div>
                  <Button className="platinum-program-sign-up-button-small" onClick={submitAnnual.bind(this)}>Sign me up</Button>
                </div>
              </div>


              {/* <div className="platinum-program-sign-up-price">$2,500</div>
              <div className="platinum-program-sign-up-year">/ 3 months</div>
              <Button className="platinum-program-sign-up-button-small" onClick={submit.bind(this)}>Sign me up</Button> */}
            </div>
          </div>

          <div className="platinum-program-sign-up-footer">
            <div className="platinum-program-sign-up-footer-left">
              <div className="platinum-program-sign-up-footer-title">Access To Cutting Edge Option Strategies</div>
              <div className="platinum-program-sign-up-footer-description">
                This page is exclusively designed for our registered members and provides access to a wealth of information and resources that are not available to the general public.
                <br /><br />
                Don't miss out on this incredible opportunity to access our private content. Sign up now to start exploring everything that what our <span className="platinum-program-gradient-text">Platinum Program</span> 💎 has to offer.
              </div>
              {/* <Button className="platinum-program-sign-up-button-outline" onClick={submit.bind(this)}>Sign me up</Button> */}
            </div>
          </div>

        </div>
        {
          !isMobile && (
            <div className="platinum-program-sign-up-right">
              <SignUpHint />
            </div>
          )
        }
      </div>
    )
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(PlatinumProgramSignUp);
