import React from "react";
import StarterPlan from "../OptionsMentorship/Plans/StarterPlan";
import IntermediatePlan from "../OptionsMentorship/Plans/IntermediatePlan";
import AdvancedPlan from "../OptionsMentorship/Plans/AdvancedPlan";
import PlatinumPlan from "../OptionsMentorship/Plans/PlatinumPlan";
import "./index.scss";
import { useAuth } from "../../contexts/Auth";

const MentorshipPaywellConfirm = () => {
  const { hasOptionsMentorshipStarter, hasPlatinumProgram, hasOptionsMentorshipIntermediate, hasOptionsMentorshipAdvanced } = useAuth()

  return (
    <div className="mentorship-paywell-confirm-page">
      <div className="mentorship-paywell-confirm-page-title">TradeAlgo Options Mentorship</div>
      <div className="mentorship-paywell-confirm-page-subtitle">Launch your trading journey and execute consistently profitable trades with the TradeAlgo <br /> Options Mentorship.</div>
      <div className="mentorship-paywell-confirm-page-options">
        <div className="mentorship-paywell-confirm-page-options-title">Chose Your Plan: </div>

        <div className="mentorship-paywell-confirm-page-options-basic-title">Basic Options Mentorship Plans</div>
        <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-23">Find the mentorship plan that will suit your needs and level of trading knowledge below and <br /> kickstart your trading journey.</div>


        <div className="mentorship-paywell-confirm-page-option-row">
          {
            !hasOptionsMentorshipStarter && <StarterPlan />
          }
          {
            !hasOptionsMentorshipIntermediate && <IntermediatePlan />
          }
          {
            !hasOptionsMentorshipAdvanced && <AdvancedPlan />
          }
        </div>
        {
          !hasPlatinumProgram && (
          <>
            <div className="mentorship-paywell-confirm-page-options-plat-title mb-7">Platinum Mentorship Plans</div>
            <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-7">Personalized coaching and equipping the users with all the key resources and erudition required to comprehend the essential <br/> concepts and rules of options trading. The program has 52 one-on-one meetings, along with complete access to the <br/> all the Platinum program with its own live stream and the sought-after Platinum Alerts.</div>
            <div className="mentorship-paywell-confirm-page-options-basic-subtitle mb-23">Platinum Membership plans will consist of everything that is available in the <span className="blue-txt">Basic Options Mentorship</span> and <span className="blue-txt"> Platinum Program</span>.</div>

            <PlatinumPlan />
          </>
          )}
      </div>

    </div>
  )
}

export default MentorshipPaywellConfirm;
