import React from "react";

import FallbackError from "../common/FallbackError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError --->", error)
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("error", error);
    console.log('Error Stack ---> ', errorInfo)
  }

  render() {
    // console.log("this.state.hasError:", this.state.hasError);

    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div className="container">
          <FallbackError />
        </div>
      );
      //   return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
