import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const GAINER_LOSER_LOAD = "GA/LO/LOAD";
export const GAINER_LOSER_LOAD_SUCCESS = "GA/LO/SUCCESS";
export const GAINER_LOSER_LOAD_FAILURE = "GA/LO/FAILURE";

// Action Creators
export const gainersLosersLoad = (timeframe) => ({
  type: GAINER_LOSER_LOAD,
  timeframe,
});
export const gainersLosersSuccess = (timeframe) => ({
  type: GAINER_LOSER_LOAD_SUCCESS,
  timeframe,
});
export const gainersLosersFailure = (payload) => ({
  type: GAINER_LOSER_LOAD_FAILURE,
  payload,
});

// Sagas
function* fetchChartData(action) {
  //fetch
  try {
    const gainersLosersCall = yield axios.get(
      `${apiURL}/crypto/gainers_losers`, {withCredentials: true}
    );
    const lists = {
      gainersList: gainersLosersCall.data.gainers,
      losersList: gainersLosersCall.data.losers,
    };
    if (!gainersLosersCall.data) {
      yield put(gainersLosersFailure("no data found: Gainers/Losers"));
      return;
    }
    yield put(gainersLosersSuccess(lists));
  } catch (error) {
    yield put(gainersLosersFailure(error));
  }
}

function* listenFetchChart() {
  yield takeEvery(GAINER_LOSER_LOAD, fetchChartData);
}
// Root Saga
export function* saga() {
  yield all([fork(listenFetchChart)]);
}
const INIT_STATE = {
  gainers: [],
  losers: [],
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GAINER_LOSER_LOAD_SUCCESS:
      return {
        ...state,
        gainers: action.timeframe.gainersList,
        losers: action.timeframe.losersList,
      };
    default:
      return state;
  }
};

export default reducer;
