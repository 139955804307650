
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const CHAT_PARTICIPANTS_LOAD = "CTP/LOAD/TOP";
export const CHAT_PARTICIPANTS_LOAD_SUCCESS = "CTP/LOAD/TOP/SUCCESS";
export const CHAT_PARTICIPANTS_LOAD_FAILURE = "CTP/LOAD/TOP/FAILURE";

// Action Creators
export const chatParticipantsLoad = () => ({
    type: CHAT_PARTICIPANTS_LOAD,
});
export const chatParticipantsLoadSuccess = (data) => ({
    type: CHAT_PARTICIPANTS_LOAD_SUCCESS,
    data,
});
export const chatParticipantsLoadFailure = (error) => ({
    type: CHAT_PARTICIPANTS_LOAD_FAILURE,
    error,
});

// Sagas
function* fetchChatParticipants() {
    try {
        const response = yield axios.get(`${apiURL}/chat-participants`, { withCredentials: true });
        yield put(chatParticipantsLoadSuccess(response?.data?.participant_count || 0));
    } catch (error) {
        yield put(chatParticipantsLoadFailure(error));
    }
}

function* listenChatParticipantsLoad() {
    yield takeEvery(CHAT_PARTICIPANTS_LOAD, fetchChatParticipants);
}

// Root Saga
export function* saga() {
    yield all([fork(listenChatParticipantsLoad)]);
}

const INIT_STATE = {
    chatParticipants: 0
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAT_PARTICIPANTS_LOAD_SUCCESS:
            return {
                ...state,
                chatParticipants: action.data
            };
        default:
            return state;
    }
};

export default reducer;
