import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React from "react";
import {
    defaultCountries,
    FlagEmoji,
    parseCountry,
    usePhoneInput
} from "react-international-phone";

const PhoneNumberInput = ({
    value,
    onChange,
    ...restProps
}) => {
    const {
        phone,
        handlePhoneValueChange,
        inputRef,
        country,
        setCountry
    } = usePhoneInput({
        defaultCountry: "us",
        value,
        countries: defaultCountries,
        onChange: (data) => {
            onChange(data.phone);
        }
    });

    return (
        <div>
            <label htmlFor="email">Phone Number</label>
            <TextField
                variant="outlined"
                color="primary"
                value={phone}
                onChange={handlePhoneValueChange}
                type="tel"
                placeholder="Enter Phone Number"
                className="special-phone"
                inputRef={inputRef}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            style={{ marginRight: "2px", marginLeft: "-8px" }}
                        >
                            <Select
                                MenuProps={{
                                    style: {
                                        height: "300px",
                                        width: "360px",
                                        top: "10px",
                                        left: "-34px"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }}
                                sx={{
                                    width: "max-content",
                                    // Remove default outline (display only on focus)
                                    fieldset: {
                                        display: "none"
                                    },
                                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                                        fieldset: {
                                            display: "block"
                                        }
                                    },
                                    // Update default spacing
                                    ".MuiSelect-select": {
                                        padding: "8px",
                                        paddingRight: "24px !important"
                                    },
                                    svg: {
                                        right: 0
                                    }
                                }}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                renderValue={(value) => (
                                    <FlagEmoji iso2={value} style={{ display: "flex" }} />
                                )}
                            >
                                {defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                        <MenuItem key={country.iso2} value={country.iso2}>
                                            <FlagEmoji
                                                iso2={country.iso2}
                                                style={{ marginRight: "8px" }}
                                            />
                                            <Typography marginRight="8px">{country.name}</Typography>
                                            <Typography color="gray">+{country.dialCode}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </InputAdornment>
                    )
                }}
                {...restProps}
            />
        </div>
    );
};

export default PhoneNumberInput;
