import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import "./ChatContainer.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatSkeleton } from "../common/Skeletons/ChatSkeleton";
import { useMedia } from "react-media";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {
    OpenChannel,
    OpenChannelSettings,
    SendBirdProvider
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import Brian from "../OptionsAcademy/assets/Brian.JPG";
import TelegramWhite from "../OptionsAcademy/assets/Telegram-White.png"
import Telegram from "../OptionsAcademy/assets/Telegram.png"
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment'
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));
const mediaQuery = {
    isMobile: "screen and (max-width: 991px)",
};
function OptionsAcademyChat({ themeMode }) {
    const [showSettings, setShowSettings] = useState(false);
    const { authUser, userData, loading } = useAuth();
    const [broncoOnly, setBroncoOnly] = useState(false);
    const history = useHistory();
    const { isMobile } = useMedia({
        queries: mediaQuery,
    });
    const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
    const channelUrl = process.env.REACT_APP_SENDBIRD_OA_CHAT
    // "sendbird_open_channel_12232_33ef03bdbdee581b06104d8c29999d7e53168c27"
    function handleBroncoOnly() {
        setBroncoOnly(!broncoOnly)
    }

    useEffect(() => {
        if (!!userData && !userData.display_name && !loading) {
            history.push("/modify-username");
        }
    }, [userData, loading])

    const nickName = useMemo(() => {
        return userData ? userData?.display_name || "" : "";
    }, [JSON.stringify(userData)]);

    const stringSet = { PLACE_HOLDER__NO_CHANNEL: "" }

    const BottomSection = useMemo(() => {
        return (

            <div className='card-bg'>
                <div className='d-flex'>
                    <img src={Brian} alt="Brian" className='live-streams-author brian-profile' />
                    <div className='platinum-program-page-live-streams-author-info'>
                        <div className='platinum-program-page-live-streams-author-name'>Bronco Anderson</div>
                        <div className='platinum-program-page-live-streams-author-job'>TradeAlgo Professional Analyst</div>
                    </div>
                </div>
                <div className='platinum-program-page-live-streams-info-content'>
                    <div className='platinum-program-page-live-streams-title'>Chat with Bronco</div>
                    <div className='platinum-program-page-live-streams-description'>Join and interact with Bronco on our trading platform for guidance, answers to your queries, and follow-up on your trades. Dive into a community of aspiring traders and master the art of options trading through real-time discussions with Bronco and fellow members.</div>
                </div>
            </div>
        )
    }, [isMobile, themeMode])

    const Component = useMemo(() => {
        return !!nickName.length && (
            <div>
                <Helmet>
                    <title> Options Academy Chat | TradeAlgo </title>
                </Helmet>
                <div className="top-block-wrapper oa-chat">
                    <div
                        className={`item-1 sendbird-theme--${themeMode} sendbird-openchannel-app pb-10`}
                    >
                        <SendBirdProvider
                            appId={appId}
                            userId={authUser}
                            nickname={nickName}
                            stringSet={stringSet}
                        >
                            <OpenChannel
                                renderChannelTitle={({ channel, user }) => {
                                    const participants = channel?.participantCount
                                    return (
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row-reverse"
                                        }} className="sendbird-openchannel-conversation-header">
                                            <div className="sendbird-openchannel-conversation-header__left">
                                                <div className="sendbird-openchannel-conversation-header__left__cover-image sendbird-avatar" role="button" tabindex="0" style={{ height: "32px", width: "32px" }}>
                                                    <div className="sendbird-avatar-img sendbird-image-renderer" style={{ width: " 100%", minWidth: "32px", maxWidth: "400px", height: "32px" }}><div className="sendbird-image-renderer__image" style={{ width: "100%", minWidth: "32px", maxWidth: "400px", height: "32px", position: "absolute", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "cover", backgroundImage: "url(https://static.sendbird.com/sample/cover/cover_11.jpg)" }}></div>
                                                        <img className="sendbird-image-renderer__hidden-image-loader" src="https://static.sendbird.com/sample/cover/cover_11.jpg" alt="channel cover image" />
                                                    </div>
                                                </div>
                                                <span className="sendbird-openchannel-conversation-header__left__title sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1">TradeAlgo</span>
                                                <span className="sendbird-openchannel-conversation-header__left__sub-title sendbird-label sendbird-label--body-2 sendbird-label--color-onbackground-2">{participants} participants</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }} classname="sendbird-openchannel-conversation-header__right">
                                                <FormControlLabel
                                                    control={<Android12Switch checked={broncoOnly} onChange={handleBroncoOnly} />}
                                                    label="Bronco Messages Only"
                                                />
                                            </div>
                                        </div>
                                    )
                                }}
                                channelUrl={channelUrl}
                                disableUserProfile
                                onChatHeaderActionClick={() => {
                                    setShowSettings(true);
                                }}
                                renderCustomMessage={(message) => {
                                    if (message._sender && (message._sender.userId === "svr66Mx1ipXlkrUQyhYCGjVMhbm2") && message.type !== 'image/png') {
                                        return () => (
                                            <div className="sendbird-msg-hoc sendbird-msg--scroll-ref">
                                                <div className="sendbird-openchannel-user-message">
                                                    <div className="sendbird-openchannel-user-message__left">
                                                        <div className="sendbird-context-menu" style={{ display: 'inline' }}>
                                                            <div className="sendbird-openchannel-user-message__left__avatar sendbird-avatar" style={{ height: '28px', width: '28px' }}>
                                                                <div className="sendbird-avatar-img sendbird-image-renderer" style={{ width: '100%', minWidth: '28px', maxWidth: '400px', height: '28px' }}>
                                                                    <div className="sendbird-avatar-img--default" style={{ width: '28px', height: '28px' }}>
                                                                        <div className=" sendbird-icon sendbird-icon-user sendbird-icon-color--content" style={{ width: '16.1px', minWidth: '16.1px', height: '16.1px', minHeight: '16.1px' }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ height: '100%', width: '100%' }}><path className="icon-user_svg__fill" d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z" fill="#000" fillRule="evenodd"></path></svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sendbird-openchannel-user-message__right">
                                                        <div className="sendbird-openchannel-user-message__right__top">
                                                            <span className={`${message._sender.userId === 'svr66Mx1ipXlkrUQyhYCGjVMhbm2' ? 'brian' : 'ross'} sendbird-openchannel-user-message__right__top__sender-name sendbird-label sendbird-label--caption-2 `}>
                                                                {message._sender.nickname}
                                                            </span>
                                                            <span className="sendbird-openchannel-user-message__right__top__sent-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-3">{moment(message.createdAt).format("LT")}</span>
                                                        </div>
                                                        <div className="sendbird-openchannel-user-message__right__bottom">
                                                            <span className="sendbird-openchannel-user-message__right__bottom__message sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                                                                {message.message}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="sendbird-openchannel-user-message__context-menu">

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (broncoOnly && message._sender.userId !== "svr66Mx1ipXlkrUQyhYCGjVMhbm2") {
                                        return () => (
                                            <div>
                                            </div>
                                        )
                                    }
                                }}
                            ></OpenChannel>
                            {showSettings && (
                                <div className="sendbird-openchannel-app__settings">
                                    <OpenChannelSettings
                                        channelUrl={channelUrl}
                                        onCloseClick={() => {
                                            setShowSettings(false);
                                        }}
                                    />
                                </div>
                            )}
                        </SendBirdProvider>
                    </div>

                    <div className='trade-alerts-container item-2'>
                        <div className="description-blocks mw-n mt-0 br-10">
                            <span className="title">Trade Alerts and Signals</span>
                            <div className="image-title-description">
                                <img src={themeMode === 'light' ? Telegram : TelegramWhite} className="bell" />
                                <div className="title-description">
                                    <span className="inner-title">Join the Community</span>
                                    <span className="inner-description">Bronco provides profitable trade alerts in our exclusive telegram group. Join the community and start making smarter trades.</span>
                                </div>
                            </div>
                            <Button onClick={() => window.open('https://t.me/+3pkD301hY3s5ZDYx', "_blank")} className="supports-widget-item-button" >Join Telegram</Button>
                        </div>
                    </div>
                </div>
                {BottomSection}
            </div>
        )
    }, [
        nickName,
        appId,
        authUser,
        stringSet,
        showSettings,
        themeMode,
        loading,
        isMobile,
    ]);
    if (loading) return <ChatSkeleton />
    return Component;
}

const stateToProps = (state) => ({
    themeMode: state.themeMode.themeMode,
});


export default connect(stateToProps, null)(OptionsAcademyChat);
