
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { darkflowTableLoad } from "../../appRedux/ducks/darkflowTable";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { abbreviate, decimal } from "../homePage/TradeAlgoCard";
import DarkflowTabs from "../common/Tabs/DarkflowTabs";
import { useHistory, useLocation } from "react-router-dom";
import SparkLinesRow from "../common/Tables/ResponsiveTableRows/SparkLinesRow";
import SparkLines from "../common/SparkLines";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import CssPop from "../common/Tables/animations/CssPop";
import { useMedia } from "react-media";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { isInternalWS } from "../../appRedux/actions/helpers";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  contrastBackground: {
    backgroundColor: theme.palette.primary.contrastBackground,
  },
  text: {
    color: theme.palette.primary.text + " !important",
    textDecoration: 'none'
  }

}));

const DashboardTableContainer = ({
  darkflowTable,
  getDarkFlow,
  setSymbol,
  currentPrices,
  marketStatus,
  popLoading,
  popularStocks,
  isPopularTab,
  setIsPopularTab,
  up,
  setUp,
  currentCards,
  themeMode,
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { pathname } = useLocation();
  const history = useHistory()
  const { weekly } = darkflowTable;
  const classes = useStyles();
  const [customLoading, setCustomLoading] = useState(false)
  const [sortModel, setSortModel] = useState([
    {
      field: "shareGain",
      sort: "desc",
    },
  ]);
  useEffect(() => {
    getDarkFlow('', pathname.includes('Weekly'));
  }, []);
  const popularStonks = useMemo(() => {
    return popularStocks.map((item) => {
      const totalPremium =
        parseInt(item?.darkflow?.options?.put_total_prem) + parseInt(item?.darkflow?.options?.call_total_prem);
      const ratio = parseInt(item?.darkflow?.options?.call_total_prem) / totalPremium;
      return {
        ...item,
        sentiment: Number(ratio * 100).toFixed(0) + '%'
      }
    });
    // eslint-disable-next-line
  }, [JSON.stringify(popularStocks)])

  useEffect(() => {
    setCustomLoading(true)
    let timeout = setTimeout(() => {
      setCustomLoading(false)
    }, 100)
    return () => clearTimeout(timeout)
  }, [up])

  const loading = useMemo(() => {
    return isPopularTab ? popLoading : darkflowTable.loading;
  }, [darkflowTable.loading, isPopularTab, popLoading]);
  const columns = useMemo(() => {
    let columnList = isPopularTab
      ? [
        {
          field: "symbol",
          headerName: "TICKER",
          flex: 0.7,
          renderCell: (params) => {
            return (
              <div
                onClick={() => {
                  setSymbol({
                    type: "",
                    value: params.value,
                  });
                  history.push(`/company/${params.value}`)
                }}
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative",
                  left: "8px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </div>
            );
          },
        },

        {
          field: "price",
          headerName: "SHARE PRICE",
          flex: 0.9,
          renderCell: (params) => {
            const livePrice =
              (
                currentPrices.get(params.row.symbol)
                || params.value
              ).toFixed(2);
            return (
              <div
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative",
                  left: "12px",
                  fontWeight: 500,
                  fontSize: "14px",
                  display: "flex",
                }}
              >
                <CssPop comparator={`$${livePrice}`} />
              </div>
            );
          },
        },
        {
          field: "openPrice",
          flex: 1,
          headerName: "PERFORMANCE",
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.symbol) || params.row.price
            ).toFixed(2);
            const performance =
              Math.round((livePrice / params.row.openPrice - 1) * 1000) / 10;
            return (
              <span
                style={{
                  position: "relative",
                  left: "25px",
                  fontWeight: 600,
                  color: performance > 0 ? "green" : "red",
                }}
              >
                {decimal(performance)}%
              </span>
            );
          },
          sortComparator: (v1, v2, param1, param2) => {
            const livePrice = (
              currentPrices.get(param1.api.getCellValue(param1.id, "symbol")) || param1.api.getCellValue(param1.id, "price")
            );
            let performance1 =
              Math.round(
                (livePrice / param1.value - 1) * 1000
              ) / 10;

            const livePrice2 = (
              currentPrices.get(param1.api.getCellValue(param2.id, "symbol")) || param2.api.getCellValue(param2.id, "price")
            );
            let performance2 =
              Math.round(
                (livePrice2 / param2.value - 1) * 1000
              ) / 10;

            return performance1 - performance2;
          }
        },
        {
          field: "idNo",
          flex: 0.7,
          headerName: "CHANGE",
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.symbol) || params.row.price
            ).toFixed(2);
            const performance =
              livePrice - params.row.openPrice;
            return (
              <span
                style={{
                  position: "relative",
                  left: "13px",
                  fontWeight: 600,
                  color: performance > 0 ? "green" : "red",
                }}
              >
                {(performance > 0 ? '+' : '') + decimal(performance)}
              </span>
            );
          },
          sortComparator: (v1, v2, param1, param2) => {
            const livePrice = (
              currentPrices.get(param1.api.getCellValue(param1.id, "symbol")) || param1.api.getCellValue(param1.id, "price")
            );
            let performance1 = livePrice - param1.api.getCellValue(param1.id, "openPrice");

            const livePrice2 = (
              currentPrices.get(param1.api.getCellValue(param2.id, "symbol")) || param2.api.getCellValue(param2.id, "price")
            );
            let performance2 = livePrice2 - param2.api.getCellValue(param2.id, "openPrice");

            return performance1 - performance2;
          }
        },
        {
          field: "darkPoolActivity",
          headerName: "DARK POOL ACTIVITY",
          flex: 1.2,
          sortComparator: (v1, v2, param1, param2) => {
            let dv1 = ((v1 - 1) * 100)
            let dv2 = ((v2 - 1) * 100)
            return Number(dv1) - Number(dv2)
          },
          renderCell: (params) => {
            const darkPoolActivity = ((params.value - 1) * 100).toFixed(2);
            const livePrice = (
              currentPrices.get(params.row.symbol) || params.row.price
            ).toFixed(2);
            const performance =
              Math.round((livePrice / params.row.openPrice - 1) * 1000) / 10;
            return (
              <div
                style={{
                  height: "27px",
                  width: "75px",
                  background: performance > 0
                    ? "rgba(13, 173, 96, 0.05)"
                    : themeMode === "dark" ? "rgb(46 4 4)" : "rgb(255, 238, 238",
                  color: performance > 0 ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6)",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "600",
                  borderRadius: "25px",
                  position: "relative",
                  left: "25px",
                  paddingTop: '4px'
                }}
              >
                {darkPoolActivity}%
              </div>
            );
          },
        },
        {
          field: "sparkLine",
          headerName: "SPARK LINE",
          flex: 1,
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.symbol) || params.row.price
            ).toFixed(2);
            const dummyOptions = {
              colors: [
                params.row.performanceRatio > 0 ? "#5EEECD" : "#F32228",
              ],
              chart: {
                type: "area",
                height: 45,
                sparkline: {
                  enabled: true,
                },
                width: 150,
              },
              tooltip: {
                theme: "dark",
                fixed: {
                  enabled: false,
                },
                x: {
                  show: false,
                },
                y: {
                  title: {
                    // eslint-disable-next-line no-unused-vars
                    formatter(seriesName) {
                      return "   $";
                    },
                  },
                },
              },
              stroke: {
                curve: "smooth",
                width: 1.5,
              },
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 0.1,
                  opacityFrom: 0.7,
                  opacityTo: 0,
                  stops: [0, 95],
                },
              },
            };
            return (
              <div className="spark-row">
                <SparkLines
                  style={{ lineHeight: "normal !important" }}
                  series={[
                    {
                      data: [...params?.value || [], Number(livePrice)],
                    },
                  ]}
                  options={dummyOptions}
                  type={dummyOptions.chart.type}
                  height={dummyOptions.chart.height}
                  width={dummyOptions.chart.width}
                />
              </div>
            );
          },
        },
        {
          field: "sentiment",
          headerName: "CALL RATIO",
          flex: 0.9,
          sortComparator: (v1, v2, param1, param2) => v2.replace('%', '') - v1.replace('%', ''),
          renderCell: (params) => {
            return (
              <div
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative", left: isMobile ? '0px' : '25px',
                  textAlign: "center",
                  fontSize: "14px",
                }}>
                {params.value}
              </div>
            )
          }
        },
        {
          field: "marketCap",
          headerName: "MARKET CAP",
          flex: 0.9,
          renderCell: (params) => {
            return (
              <div
                className={classes.text}
                style={{
                  position: "relative",
                  left: "17px",
                  fontSize: "15px",
                }}
              >
                {params.value ? `$${abbreviate(params.value)}` : "-"}
              </div>
            );
          },
        },

        {
          field: "dayVolume",
          headerName: "SHARE VOLUME",
          flex: 1,
          renderCell: (params) => {
            return (
              <div
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative",
                  textAlign: "center",
                  left: "27px",
                  fontSize: "15px",
                }}
              >
                {params.value ? `$${abbreviate(params.value)}` : "-"}
              </div>
            );
          },
        },
      ]
      : [
        {
          field: "ticker",
          headerName: "TICKER",
          flex: 0.7,
          renderCell: (params) => {
            return (
              <div
                onClick={() => {
                  history.push(`/company/${params.value}${weekly ? '?resolution=1d' : ''}`)
                  setSymbol({
                    type: "",
                    value: params.value,
                  });
                }}
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative",
                  left: "8px",
                  // top: "9px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </div>
            );
          },
        },

        {
          field: "idNo",
          headerName: "SHARE PRICE",
          flex: 0.9,
          sortComparator: (v1, v2, param1, param2) => {
            const livePrice = currentPrices.get(param1.api.getCellValue(param1.id, "ticker")) || param1.api.getCellValue(param1.id, "sharePrice")
            const livePrice2 = currentPrices.get(param2.api.getCellValue(param2.id, "ticker")) || param2.api.getCellValue(param2.id, "sharePrice")
            return livePrice - livePrice2;
          },
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.ticker) || params.row.sharePrice
            ).toFixed(2);
            return (
              <span className={clsx(classes.text, "text-fix")}
                style={{
                  position: 'relative', left: '17px', fontWeight: 500,
                  fontSize: "14px",
                  display: "flex",
                }}>
                <CssPop comparator={`$${livePrice}`} />
              </span>
            )
          },
        },
        {
          field: "shareGain",
          flex: 0.9,
          headerName: weekly ? "28 DAY PERFORMANCE" : "PERFORMANCE",
          sortComparator: (v1, v2, param1, param2) => {
            const livePrice = currentPrices.get(param1.value.ticker) || param1.value.item.last_price
            let performance1 =
              Math.round(
                (livePrice / param1.value.item.trend_start - 1) * 1000
              ) / 10;

            const livePrice2 = currentPrices.get(param2.value.ticker) || param2.value.item.last_price || 0
            let performance2 =
              Math.round(
                (livePrice2 / param2.value.item.trend_start - 1) * 1000
              ) / 10;
            return performance1 - performance2;
          },
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.ticker) || params.row.last_price
            ).toFixed(2);
            const performance = Math.round((livePrice / params.row.trend_start - 1) * 1000) / 10;
            const performanceSymbol = performance > 0 ? "+" : "";
            return (
              <div
                style={{
                  fontWeight: "600",
                  position: "relative",
                  left: "11px",
                }}
                className={performanceSymbol === "+" ? "up" : "down"}
              >
                {performanceSymbol}
                {decimal(performance)}%
              </div>
            );
          },
        },
        {
          field: "sharePrice",
          flex: 0.7,
          headerName: "CHANGE",
          sortComparator: (v1, v2, param1, param2) => {
            const livePrice = currentPrices.get(param1.api.getCellValue(param1.id, "ticker")) || param1.api.getCellValue(param1.id, "sharePrice")
            const livePrice2 = currentPrices.get(param2.api.getCellValue(param2.id, "ticker")) || param2.api.getCellValue(param2.id, "sharePrice")

            let performance = livePrice - param1.api.getCellValue(param1.id, "trend_start")
            let performance2 = livePrice2 - param2.api.getCellValue(param2.id, "trend_start")

            return performance - performance2;
          },
          renderCell: (params) => {
            const livePrice = (
              currentPrices.get(params.row.ticker) || params.row.last_price
            ).toFixed(2);
            const performance = livePrice - params.row.trend_start;
            const performanceSymbol = performance > 0 ? "+" : "";
            return (
              <div
                style={{
                  fontWeight: "600",
                  position: "relative",
                  left: "8px",
                }}
                className={performanceSymbol === "+" ? "up" : "down"}
              >
                {performanceSymbol}
                {decimal(performance)}
              </div>
            );
          },
        },
        {
          field: "darkPoolActivity",
          headerName: "DARK POOL ACTIVITY",
          flex: 1.2,
          sortComparator: (v1, v2, param1, param2) => {
            let dv1 = ((v1 - 1) * 100)
            let dv2 = ((v2 - 1) * 100)
            return Number(dv1) - Number(dv2)
          },
          renderCell: (params) => {
            return (
              <div
                style={{
                  height: "27px",
                  width: "75px",
                  background: up
                    ? "rgba(13, 173, 96, 0.05)"
                    : themeMode === "dark" ? "rgb(46 4 4)" : "rgb(255, 238, 238",
                  color: up ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6)",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "600",
                  borderRadius: "25px",
                  position: "relative",
                  left: "28px",
                }}
              >
                <span style={{ position: "relative", bottom: "-3px" }}>
                  +{decimal(Number(params.value * 100))}%
                </span>
              </div>
            );
          },
        },
        {
          field: "sparkLine",
          headerName: "SPARKLINE",
          flex: 1.1,
          renderCell: (params) => {
            return (
              <SparkLinesRow
                marketStatus={marketStatus}
                params={params}
                up={up}
              />
            );
          },
        },
        {
          field: "sentiment",
          headerName: "CALL RATIO",
          flex: 0.9,
          sortComparator: (v1, v2, param1, param2) => v2.replace('%', '').replace('-', '') - v1.replace('%', '').replace('-', ''),
          renderCell: (params) => {

            return (
              <div
                className={clsx(classes.text, "text-fix")}
                style={{
                  position: "relative", left: isMobile ? '-10px' : '30px',
                  textAlign: "center",
                  fontSize: "14px",
                }}>
                {params.value}
              </div>
            )
          }
        },
        {
          field: "marketCap",
          headerName: "MARKET CAP",
          flex: 1,
          renderCell: (params) => {
            return (
              <div className={clsx(classes.text, "text-fix")}
                style={{ position: "relative", left: "10px" }}>
                {params.value ? `$${abbreviate(params.value)}` : "-"}
              </div>
            );
          },
          sortComparator: (v1, v2, param1, param2) => v1 - v2,
        },

        {
          field: "shareVolume",
          headerName: "SHARE VOLUME",
          flex: 1,
          renderCell: (params) => {
            return (
              <div
              className={clsx(classes.text, "text-fix")}
              style={{
                  position: "relative",
                  textAlign: "center",
                  left: "27px",
                }}
              >
                {params.value ? `${abbreviate(params.value)}` : "-"}
              </div>
            );
          },
          sortComparator: (v1, v2, param1, param2) => v1 - v2,
        },
        {
          field: "openPrice",
          headerName: "MARKET CAP",
          hide: true,
        }
      ];

    if (weekly && !!!isPopularTab) {
      columnList.push({
        field: 'date_flagged',
        headerName: 'DATE ADDED',
        flex: 1.2,
        renderCell: (params) => {
          return (
            <div className={clsx(classes.text, "text-fix")}
              style={{ position: "relative", left: weekly ? "7px" : '-10px' }}>
              {moment.tz(params.value, "America/New_York").format("MMM DD, YYYY")}
            </div>
          );
        },
      });
    }

    return columnList;
    // eslint-disable-next-line
  }, [isPopularTab, currentPrices, marketStatus, isMobile, up, themeMode, weekly]);
  useEffect(() => {
    isPopularTab
      ? setSortModel([
        {
          field: "openPrice",
          sort: up ? "desc" : "asc",
        },
      ])
      : weekly ? setSortModel([
        {
          field: "marketCap",
          sort: "desc",
        },
      ]) :
        setSortModel([
          {
            field: "shareGain",
            sort: up ? "desc" : "asc",
          },
        ]);

  }, [isPopularTab, up, themeMode, weekly]);

  const rows = useMemo(() => {
    return isPopularTab
      ? popularStonks
      : currentCards
        .map((item, i) => {
          return {
            id: i + 1,
            idNo: i + 1,
            ticker: item.ticker,
            marketCap: item?.market_cap || 0,
            sharePrice: item?.last_price || 0,
            darkPoolActivity: item?.multiplier || 0,
            shareVolume: item?.ats?.current?.day_volume || 0,
            shareGain: { ...item.day, item },
            sparkLine: item.chart.data,
            ...item,
          };
        })
        .filter((item) => {
          const livePrice = (
            currentPrices.get(item.ticker) || item.last_price || 0
          ).toFixed(2);
          const performance =
            Math.round((livePrice / item.trend_start - 1) * 1000) / 10;
          return up
            ? performance > 0 && !!item.market_cap
            : performance < 0 && !!item.market_cap;
        });
    // eslint-disable-next-line
  }, [currentCards, up, isPopularTab, popularStonks, themeMode, currentPrices]);
  return (
    <Col md={12} lg={12}>
      <Card
        // aria-id="dashboard-card"
        className={classes.contrastBackground}>
        <CardBody>
          <DarkflowTabs
            up={up}
            setUp={setUp}
            popular={true}
            setIsPopularTab={setIsPopularTab}
          />
          <div style={{ height: "fit-content", width: "100%" }}>
            <TradeAlgoTable rows={rows} columns={columns} loading={loading || customLoading} sortModel={sortModel} setSortModel={setSortModel} />
            {isPopularTab && (
              <>
                <br />
                <br />
                <br />
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
const stateToProps = (state) => ({
  darkflowTable: state.darkflowTable,
  subs: state.Socket.channels,
  marketStatus: state.marketStatus.marketStatus,
  popularStocks: state.popularStocks.popularStocks,
  popLoading: state.popularStocks.loading,
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
  themeMode: state.themeMode.themeMode,
});
const dispatchToProps = (dispatch) => ({
  getDarkFlow: (risk, weekly) => dispatch(darkflowTableLoad(risk, weekly)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
export default connect(stateToProps, dispatchToProps)(DashboardTableContainer);
