export const getPercent = (value) => {
  const percentage = value * 100;

  return parseFloat(percentage.toFixed(2));
};

export const getDigits = (value) => {
  const percentage = value * 100;
  return toFixedTrunc(percentage, 2);
};

export const getFloorFigure = (value, decimals) => {
  if (!decimals) {
    decimals = 2;
  }

  const d = Math.pow(10, decimals);

  return (parseInt(value * d) / d).toFixed(decimals);
};

export const toFixedTrunc = (x, n) => {
  const v = (typeof x === "string" ? x : x.toString()).split(".");

  if (n <= 0) return v[0];
  let f = v[1] || "";
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += "0";
  return `${v[0]}.${f}`;
};

export const getRandom = (min = 1, max = 100000) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const capitalizeWord = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const domainFormatter = (url) => {
  if (url) {
    const domain = url
      .replace("http://", "")
      .replace("https://", "")
      .split(/[/?#]/)[0];

    const websiteUrl = url.includes("http") ? url : `https://${url}`;

    return { domain, websiteUrl };
  }

  return {};
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncate = (input) => {
  if (input) {
    if (input.length > 200) return input.substring(0, 100) + "...";
    else return input;
  }
};

export const indexTickerPrettify = (x) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const usdFormat = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })
    .format(value)
    .replace(/\D00$/, "");
};

export const usdFormatZeros = (value) => {
  const parsed = parseInt(value, 10);

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3
  })
    .format(parsed)
    .replace(/\D00$/, "")
    .slice(0, -1);
};

export const abbreviate = (value) => {
  return Math.abs(Number(value)) >= 1.0e9
    ? Math.abs(Number(value)) / 1.0e9 + "B"
    : Math.abs(Number(value)) >= 1.0e6
    ? Math.abs(Number(value)) / 1.0e6 + "M"
    : Math.abs(Number(value)) >= 1.0e3
    ? Math.abs(Number(value)) / 1.0e3 + "K"
    : Math.abs(Number(value));
};

export const abbreviateWithIntegerResult = (value) => {
  return Math.abs(parseFloat(value)) >= 1.0e9
    ? (Math.abs(parseFloat(value)) / 1.0e9).toFixed(2) + "B"
    : Math.abs(parseFloat(value)) >= 1.0e6
      ? (Math.abs(parseFloat(value)) / 1.0e6).toFixed(2) + "M"
      : Math.abs(parseFloat(value)) >= 1.0e3
        ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(2) + "K"
        : (Math.abs(parseFloat(value)))
};

export const abbreviateWithFullLabel = (value) => {
  return Math.abs(parseFloat(value)) >= 1.0e9
    ? (Math.abs(parseFloat(value)) / 1.0e9).toFixed(2) + " Billion"
    : Math.abs(parseFloat(value)) >= 1.0e6
      ? (Math.abs(parseFloat(value)) / 1.0e6).toFixed(2) + " Million"
        : numberWithCommas(Math.round(Math.abs(parseFloat(value))))
};

export const checkAbbreviation = (value) => {
  const units = ["B", "M", "K"];

  const isAbbreviated = endsWithAny(units, value);

  if (!isAbbreviated) {
    return {
      value: Number(value),
      isAbbreviated: false
    };
  }

  const last = value.slice(-1);
  const v = value.slice(0, -1);

  const result = {
    value: 0,
    isAbbreviated: true
  };

  switch (last) {
    case "B":
      result.value = Math.abs(Number(v)) * 1.0e9;
      return result;

    case "M":
      result.value = Math.abs(Number(v)) * 1.0e6;
      return result;

    case "K":
      result.value = Math.abs(Number(v)) * 1.0e3;
      return result;

    default:
      break;
  }
};

export const endsWithAny = (suffixes, string) => {
  return suffixes.some(function (suffix) {
    return string.endsWith(suffix);
  });
};

export const escapeStringRegexp = (str) => {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
};

export const getMatchBounds = (value, query) => {
  if(!query) return null
  const COMBINING_MARKS = /[\u0300-\u036F]/;
  const search = new RegExp(escapeStringRegexp(query), "i");

  const matches = search.exec(value);

  if (!matches) {
    return null;
  }

  let start = matches.index;
  let matchLength = matches[0].length;

  if (COMBINING_MARKS.test(value)) {
    for (let ii = 0; ii <= start; ii++) {
      if (COMBINING_MARKS.test(value[ii])) {
        start += 1;
      }
    }

    for (let ii = start; ii <= start + matchLength; ii++) {
      if (COMBINING_MARKS.test(value[ii])) {
        matchLength += 1;
      }
    }
  }

  return {
    end: start + matchLength,
    start
  };
};

export const simpleNumber = (costOfIt) => {
  // Number to words from Million, Billion, Trillion....

  let visualOfIt = costOfIt.toString();

  let visualLeng = 6;
  let maxLeng = 4;
  let letterArrayIndex = 0;

  let letterArray = [" thousand", " million", " billion", " trillion", " quadrillion"];

  let leng = 4;
  let slic = 1;

  for (let g = 0; g < visualOfIt.length; g++) {
    if (visualOfIt.length <= visualLeng) {
      if (leng < maxLeng) {
        leng = maxLeng;
      }

      if (visualOfIt.length === leng) {
        if (slic > 2) {
          visualOfIt = costOfIt.toString().slice(0, slic) + letterArray[letterArrayIndex];
          break;
        } else {
          visualOfIt = costOfIt.toString().slice(0, slic) + "." + costOfIt.toString().slice(slic, 3) + letterArray[letterArrayIndex];
          break;
        }
      } else {
        leng++;
        slic++;
      }
    } else {
      maxLeng += 3;
      visualLeng += 3;
      letterArrayIndex++;
    }
  }

  return visualOfIt;
}