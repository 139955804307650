/* eslint-disable */
import { Helmet } from 'react-helmet';
import "moment-duration-format";
import React, { useEffect, useMemo, useState } from "react";
import VerifiedIcon from '@mui/icons-material/Verified';

import profileImg from "../../assets/images/profile.png";

import PreviousVideos from "./PreviousVideos";
import './style.scss';
import { connect } from 'react-redux';
import { streamsLoad } from '../../appRedux/ducks/pastStreams';

function BasicsTraining({ fetchStreams, pastStreams, loading }) {
  const [tab, setTab] = useState("1");
  const [previousVideos, setPreviousVideos] = useState([])
  const [currentVideo, setCurrentVideo] = useState({
    live: true,
    url: '',
    date: "",
    description:
      "",
    duration: "",
    link: "",
  });
  useEffect(() => {
    fetchStreams('basic-training')
  }, [])

  useEffect(() => {
    if (!!pastStreams.length) {
      setPreviousVideos(pastStreams)
    }
  }, [pastStreams])

  return (
    <div className="container-xl-live">
      <Helmet>
        <title>{"Live Training"} | TradeAlgo</title>
      </Helmet>

      {
        currentVideo.live ? (
          <div className="basics-training-hero">
            <iframe
              className="basics-training-event-video"
              src="https://vimeo.com/event/2824812/embed/aa239713b4"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title='basics-training-video'
            ></iframe>
            <iframe
              src="https://vimeo.com/event/2824812/chat/aa239713b4"
              className="basics-training-event-chat"
              title='basics-training-chat'
            ></iframe>
          </div>
        ) : (
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: currentVideo.url,
              }}
            />
            <div style={{ position: "relative", top: "5px", left: "29px" }}>
              <div className="row">
                <span
                  className="col-12"
                  style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
                >
                  {currentVideo.duration} | {currentVideo.date} |{" "}
                  {tab === "1" ? "Group Training" : ""}
                </span>
              </div>
              <div className="row">
                <span className="col-12" style={{ paddingTop: "10px" }}>
                  {currentVideo.description}
                </span>
              </div>
            </div>
            <br />
            <div
              style={{
                width: "99%",
                border: "1px solid #F1F1F1",
                marginLeft: "24px",
              }}
            ></div>
            <br />
          </div>
        )
      }

      <div className='basics-training-presentor-wrapper'>
        <div className='basics-training-presentor'>
          <img src={profileImg} alt="profile" className='basics-training-presentor-avatar' />
          <div className='basics-training-presentor-detail'>
            <div className='basics-training-presentor-name'>Dane Glisek <VerifiedIcon /></div>
            <div className='basics-training-presentor-position'>TradeAlgo Analyst</div>
          </div>
        </div>
      </div>

      <div className='basics-training-event-info'>
        <div className='basics-training-event-info-section'>
          <div className='basics-training-event-title'>Group Training Sessions</div>
          <div className='basics-training-event-description'>These group training sessions are designed to explain how to take full advantage of the alerts Trade Algo has to offer.</div>
          <div className='basics-training-event-schedule'>
            <div>
              <div className='basics-training-event-schedule-status'>LIVE Schedule</div>
              <div className='basics-training-event-schedule-day'>Every Wednesday</div>
              <div className='basics-training-event-schedule-time'>Time: 12:00pm EST</div>
            </div>
            <div className='basics-training-event-schedule-reply-msg'>Replays are available at anytime.</div>
          </div>
        </div>


      </div>

      <PreviousVideos tab={tab} setTab={setTab} basics={true} loading={loading} currentVideo={currentVideo} setCurrentVideo={setCurrentVideo} videos={previousVideos} />
    </div>
  );
}



const stateToProps = (state) => ({
  pastStreams: state.streams.streams,
  loading: state.streams.loading
});
const dispatchToProps = (dispatch) => ({
  fetchStreams: (endpoint) => dispatch(streamsLoad(endpoint)),
});
export default connect(stateToProps, dispatchToProps)(BasicsTraining);
