import React, { useMemo, useState } from "react";
import "./liveOptionsTable.css";
import { numberWithCommas } from "../../utilities";
import TradeAlgoTable from "./TradeAlgoTable";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.tableBackground,
  },
  text: {
    color: theme.palette.primary.text,
  },
  unusual: {
    color: theme.palette.primary.unusual + " !important",
  }
}));
const calculateMargin = (value) => {
  let margin = "";
  if (value <= 99) {
    margin = "23px";
  } else if (value > 99 && value <= 999) {
    margin = "14px";
  } else if (value > 999 && value <= 9999) {
    margin = "0px";
  } else if (value > 9999 && value <= 99999) {
    margin = "-10.5px";
  } else if (value > 99999 && value <= 999999) {
    margin = "-20px";
  } else if (value > 999999 && value <= 9999999) {
    margin = "-30px";
  }
  return margin;
};
const UnusualActivityTable = ({
  data,
  getPercentage,
  calcTotalPrice,
  calcAvgPrice,
  rangeEnabled = false,
  sortModel,
  setSortModel
}) => {
  const classes = useStyles()
  const columns = [
    
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.7,
      hide: !rangeEnabled,
      renderHeader: () => <span className="table-headers">Date</span>,
      renderCell: (params) => (
        <span className={clsx(classes.unusual, "unusual-time")}>{moment(params?.value?.split('T')[0] || '').format('MM/DD')}</span>
      ),
    },
    {
      field: "openTime",
      headerName: "Open Time",
      flex: 1.1,
      renderHeader: () => <span className="table-headers">Open Time</span>,
      renderCell: (params) => (
        <span className={clsx(classes.unusual, "unusual-time")}>{params.value}</span>
      ),
    },
    {
      field: "lastTime",
      headerName: "Last Time",
      flex: 1,
      renderHeader: () => <span className="table-headers">Last Time</span>,
      renderCell: (params) => (
        <span className={clsx(classes.unusual, "unusual-time")}>{params.value}</span>
      ),
    },
    {
      field: "totalVolume",
      headerName: "Total Volume",
      flex: 1.1,
      renderHeader: () => <span className="table-headers">Total Volume</span>,
      renderCell: (params) => (
        <span>
          <div className={"table-row-item total-volume"}>
            <span
              style={{ marginLeft: calculateMargin(params.value) }}
              className={clsx(classes.text, "table-row-volume")}
            >
              {numberWithCommas(params.value)}
            </span>
            <span className={"progress-small-percentage"}>
              <span
                className={"avg-progress-indicator"}
                style={getPercentage(params.row)}
              />
            </span>
          </div>
        </span>
      ),
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
    {
      field: "totalTrades",
      headerName: "Total Trades",
      flex: 1,
      renderHeader: () => (
        <span style={{ marginLeft: "-7px" }} className="table-headers">
          Total Trades
        </span>
      ),
      renderCell: (params) => <span className={classes.text}>{numberWithCommas(params.value)}</span>,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderHeader: () => <span className="table-headers">Total Price</span>,
      renderCell: (params) => (
        <span className={clsx(classes.text, "total-price")}>{calcTotalPrice(params.row)}</span>
      ),
      sortComparator: (v1, v2, p1, p2) => {
        const totalPrice1 = p1.api.getCellValue(p1.id, "totalPrice");
        const totalTrades1 = p1.api.getCellValue(p1.id, "totalTrades");
        const totalVolume1 = p1.api.getCellValue(p1.id, "totalVolume");
        const avg1 = parseFloat(totalPrice1 / totalTrades1);
        const value1 = avg1 * totalVolume1
        const totalPrice2 = p2.api.getCellValue(p2.id, "totalPrice");
        const totalTrades2 = p2.api.getCellValue(p2.id, "totalTrades");
        const totalVolume2 = p2.api.getCellValue(p2.id, "totalVolume");
        const avg2 = parseFloat(totalPrice2 / totalTrades2);
        const value2 = avg2 * totalVolume2
        return value1 - value2;
      }

    },
    {
      field: "avgPrice",
      headerName: "Avg. Price",
      flex: 1,
      renderHeader: () => <span className="table-headers">Avg. Price</span>,
      renderCell: (params) => (
        <span className={clsx(classes.text, "avg-price")}>{calcAvgPrice(params.row)}</span>
      ),
      sortComparator: (v1, v2, p1, p2) => {
        const totalPrice1 = p1.api.getCellValue(p1.id, "totalPrice");
        const totalTrades1 = p1.api.getCellValue(p1.id, "totalTrades");
        const value1 = parseFloat(totalPrice1 / totalTrades1).toFixed(2);
        const totalPrice2 = p2.api.getCellValue(p2.id, "totalPrice");
        const totalTrades2 = p2.api.getCellValue(p2.id, "totalTrades");
        const value2 = parseFloat(totalPrice2 / totalTrades2).toFixed(2);
        return value1 - value2;
      },
    },
  ];

  const rows = useMemo(() => {
    return data.map((item, id) => ({ ...item, id }));
  }, [data]);

  return (
    <div >
      {/* <div className="data-grid-wrap"> */}
      <TradeAlgoTable rows={rows} columns={columns} sortModel={sortModel} setSortModel={setSortModel} />
      {/* <div className="bottom-height-table"> </div> */}
      {/* </div> */}
    </div>
  );
};

export default UnusualActivityTable;
