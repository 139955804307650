import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useAuth } from "../../../contexts/Auth";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import VideoPage from "../VideoPage";
import "./EmeraldTutorials.scss";

import { useHistory } from "react-router-dom";

const EmeraldVideoPage = ({ userSettings, setUserSettings, allVideos, videoID }) => {
  const [currentVideo, setCurrentVideo] = useState({
    id: 0,
    title: '',
    videoUrl: "",
    overview: "",
    description: null,
    length: "",
    //checked: false,
    type: '',
    thumbnail: null
  });

  const hardHistory = useHistory();

  const { loading } = useAuth()

  const goBack = () => {
    setCurrentVideo({
      id: 0,
      title: '',
      videoUrl: "",
      overview: "",
      description: null,
      length: "",
      //checked: false,
      type: '',
      thumbnail: null
    });
    hardHistory.push(`/emerald/tutorials`);
  }

  const selectVideo = (id) => {
    hardHistory.push(`/emerald/tutorials/${id}`);
  };

  useEffect(() => {
    if (videoID) {
      const video = allVideos.find(video => video.id === parseInt(videoID));
      setCurrentVideo(video);
    }
  }, [videoID, allVideos]);

  return (
    <div className="emerald-tutorials">
      <VideoPage
        goBack={goBack}
        currentVideo={currentVideo}
        selectVideo={selectVideo}
        allVideos={allVideos}
      />
    </div>
  );
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(EmeraldVideoPage);