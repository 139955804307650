import { makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.primary.skeletonBackground,
    // width: 'auto', // allow table to stretch full width
    flexGrow: 1, // allow table to stretch full width
    '& > div': {
      height: 'auto !important', // override zero height from DataGrid
      width: 'auto !important', // override zero width from DataGrid
    },
    '& .MuiDataGrid-root': {
      overflow: 'auto', // set table to be responsive
      fontSize: '0.9rem',
    },
  },
  skeleton: {
    backgroundColor: theme.palette.primary.skeleton,
    height: '6em',
    margin: 10,
  }
}));

export const ChatSkeleton = () => {
  const classes = useStyles();
  return (
    <div className='container'>
      <div className='row'>
        <Paper className={clsx("col-8", classes.paper)} elevation={0}>
          <br />
          <br />
          <Skeleton className={classes.skeleton} variant="rect" height={50} />
          <Skeleton className={classes.skeleton} variant="rect" height={800} />
          <Skeleton className={classes.skeleton} variant="rect" height={50} />
          <br />
          <br />
        </Paper>
        <Paper className={clsx("col-4", classes.paper)} elevation={0}>
          <br />
          <br />
          <Skeleton className={classes.skeleton} variant="rect" height={50} />
          <Skeleton className={classes.skeleton} variant="rect" height={800} />
          <Skeleton className={classes.skeleton} variant="rect" height={50} />
          <br />
          <br />
        </Paper>
      </div>

    </div>
  );
};
