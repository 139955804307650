import React from "react";
import { connect } from "react-redux";
import VerifiedIcon from '@mui/icons-material/Verified';

import UserAvatar from "../../../assets/images/brian.png";
import TradeAlertsAndSignals from "../TradeAlertsAndSignals";

import "./EmeraldCommonPage.scss";

const EmeraldCommonPage = ({  title, subTitle, description, eventID, from, themeMode }) => {

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  return (
    <div className="emerald-common-page">
      <div className="emerald-common-page__title">{title}</div>

      <div className="emerald-common-page__header">
        <div className="emerald-common-page__left">
          <iframe
            className="emerald-common-page__video"
            src={`https://vimeo.com/event/${eventID}/embed`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={"LiveStream"}
            frameBorder="0"
          ></iframe>
        </div>

        <div className="emerald-common-page__right">
          <iframe
            src={`https://vimeo.com/event/${eventID}/chat`}
            width="100%"
            height="100%"
            frameBorder="0"
            title="livestream-chat"
          />
        </div>
      </div>

      <div className="emerald-common-page__body">
        <div className="emerald-common-page__left">
          <div className="emerald-common-page__content">
            <div className="emerald-common-page-author">
              <img src={UserAvatar} alt="Brian Avatar" className="emerald-common-page-author-avatar"/>
              <div className="emerald-common-page-author-info">
                <div className="emerald-common-page-author-name">Brian Anderson <VerifiedIcon /></div>
                <div className="emerald-common-page-author-title">TradeAlgo Professional Analyst</div>
              </div>
            </div>
            
            <div className="emerald-common-page__content-title">{subTitle}</div>
            <div className="emerald-common-page__content-description">{description}</div>
            <div className="emerald-common-page__live_schedule-title">Live Schedule</div>
            <div className="emerald-common-page__schedules">
              {
                days.map((day, index) => (
                  <div className="emerald-common-page__schedule" key={index}>
                    <div className="emerald-common-page__schedule-day">{day}</div>
                    <div className="emerald-common-page__schedule-time">{ from ==='livestream' ? '3:00pm EST' : '12:00pm EST' }</div>
                    {/* <div className="emerald-common-page__schedule-time">({ from ==='livestream' ? '12:00pm PST' : '9:00am PST' })</div> */}
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="emerald-common-page__right">
          <TradeAlertsAndSignals
            themeMode={themeMode}
            url="https://t.me/+cwpD3G5-eO5mMDRh"
          />
        </div>
      </div>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode
});

export default connect(stateToProps, null)(EmeraldCommonPage);
