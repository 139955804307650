/* eslint-disable */
import React, { useMemo, useState } from "react";
import TabHeaders2 from "../TabHeaders2";
import CompanyHistory from "../CompanyHistory";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  background: {
    color: theme.palette.primary.text,
    backgroundColor: "#FFFFFF",
    height: "100%"
  },
  text: {
    color: theme.palette.primary.text,
  },
}));
const Etf = (props) => {
  const {
    ticker,
    etf,
    meta,
    security,
    securityMetrics,
    securityPrice,
    securityHistory,
    news,
    currentMarketStatus,
    symbol
  } = props;

  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("summary");
  const [differenceValue, setDifferenceValue] = useState("");
  const [differencePercentage, setDifferencePercentage] = useState("");
  const [isSeriesFromUrl, setIsSeriesFromUrl] = useState(false);

  const [lastPrice, setLastPrice] = useState("");

  const tickerSymbol = useMemo(() => {
    return !!symbol.value.length ? symbol.value : ticker;
  }, [symbol.value]);
  
  const showDifferences = (value, percentage) => {
    setDifferenceValue(value);
    setDifferencePercentage(percentage);
  };

  const saveSeriesSource = (flag) => {
    setIsSeriesFromUrl(flag);
  };

  return (
    <>
      <TabHeaders2
        lastPrice={lastPrice}
        setLastPrice={setLastPrice}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        type="etf"
        ticker={tickerSymbol}
        security={etf}
        news={news}
        // followId={etf && etf.id}
        watching={etf && etf.following}
        followType="etfs"
        pinType="ETFPrice"
        securityPrice={securityPrice}
        securityMetrics={securityMetrics}
        differenceValue={differenceValue}
        differencePercentage={differencePercentage}
        isSeriesFromUrl={isSeriesFromUrl}
        currentMarketStatus={currentMarketStatus}
      />

      <div className="row">
        <div className="col-12">
          <div className="custom-tab-content">
            <TabPanel
              value={currentTab}
              index={"summary"}
              className="company-summary-tab"
            >
              <CompanyHistory
                setLastPrice={setLastPrice}
                lastPrice={lastPrice}
                securityMetrics={securityMetrics}
                securityPrice={securityPrice}
                showDifferences={showDifferences}
                saveSeriesSource={saveSeriesSource}
                currentMarketStatus={currentMarketStatus}
              />
            {/*<AtsEquity ticker={ticker} />*/}

            </TabPanel>
            </div>
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  symbol: state.symbol.symbol,
});

export default connect(stateToProps, null)(Etf);