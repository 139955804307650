import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const USER_SETTINGS_LOAD = "UST/LOAD/TOP";
export const USER_SETTINGS_LOAD_SUCCESS = "UST/LOAD/TOP/SUCCESS";
export const USER_SETTINGS_LOAD_FAILURE = "UST/LOAD/TOP/FAILURE";

// Action Creators
export const addUserSettingsLoad = (userSettings, firstRender = false) => ({
  type: USER_SETTINGS_LOAD,
  userSettings,
  firstRender
});
export const addUserSettingsLoadSuccess = (data) => ({
  type: USER_SETTINGS_LOAD_SUCCESS,
  data,
});
export const addUserSettingsLoadFailure = (error) => ({
  type: USER_SETTINGS_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchAddUserSettings(action) {
  try {
    const { userSettings, firstRender } = action;
    yield put(addUserSettingsLoadSuccess(userSettings));
    // if we are setting the default settings from the first render from the user obj then there is no need to update the user obj, just update state
    const { emeraldContact = '', defaultResolution, candleType, expWithin, minPremium, defaultOptionChainTicker, defaultIndicators, chartSaveMode = 0, notifications = {
      mode: 0,
      sound: false
    }, tipsViewed = false, tipsViewed2 = false, viewed_videos = {}, platinum_sign_up = false, om_starter_sign_up = false, om_starter_renewed = false, om_platinum_sign_up = false,
      journey_completed = false,
      om_platinum_renewed = false, om_intermediate_sign_up = false,
      om_intermediate_renewed = false, om_advanced_sign_up = false,
      om_advanced_renewed = false, oa_sign_up = false, oa_renewed = false } = userSettings;
    if (!firstRender) {
      yield axios.put(`${apiURL}/user/settings`,
        // `http://localhost:4000/user/update_settings`
        {
          userSettings: {
            defaultResolution,
            candleType,
            expWithin,
            minPremium,
            defaultOptionChainTicker,
            defaultIndicators,
            chartSaveMode,
            notifications,
            tipsViewed,
            tipsViewed2,
            viewed_videos,
            platinum_sign_up,
            om_starter_sign_up,
            om_starter_renewed,
            om_intermediate_sign_up,
            om_intermediate_renewed,
            om_advanced_sign_up,
            om_advanced_renewed,
            om_platinum_sign_up,
            om_platinum_renewed,
            oa_sign_up,
            oa_renewed,
            journey_completed,
            emeraldContact
          }
        }, { withCredentials: true }
      );
    }
  } catch (error) {
    yield put(addUserSettingsLoadFailure(error));
  }
}

function* listenFetchAddUserSettingsLoad() {
  yield takeEvery(USER_SETTINGS_LOAD, fetchAddUserSettings);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchAddUserSettingsLoad)]);
}

const INIT_STATE = {
  userSettings: {
    defaultResolution: '15',
    candleType: '1',
    minPremium: 35000,
    expWithin: 730,
    defaultOptionChainTicker: "AAPL",
    defaultIndicators: [],
    // community = 1 , private = 0
    chartSaveMode: 0,
    notifications: {
      // none = 0
      // all = 1
      // alerts only = 2
      mode: 0,
      sound: false
    },
    tipsViewed: false,
    tipsViewed2: false,
    viewed_videos: {},
    platinum_sign_up: false,
    om_starter_sign_up: false,
    journey_completed: false,
    emeraldContact: ''
  }
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        userSettings: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
