import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import { widget } from "../../../src/charting_library/charting_library.esm.js";
import Datafeed from "./dataFeedClass";
import { useAuth } from "../../contexts/Auth";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { WidthProvider, Responsive } from "react-grid-layout";
import defaultLayout from "./layout";
import { updateLayoutLoad } from "../../appRedux/ducks/updateLayout";
import { Tooltip } from "@material-ui/core";
import { apiURL, isInternalWS } from "../../appRedux/actions/helpers";


const ReactGridLayout = WidthProvider(Responsive);
function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function FavoritesContainer({ candleDefaults, themeMode, updateLayout }) {
  // const classes = useStyles()
  const [j, setJ] = useState(0)
  const { userData, fetchUser } = useAuth();
  const userId = userData?.userId;
  const [widgetRefs, setWidgetRefs] = useState([]);
  const widgetRefsRef = useRef(null);
  useEffect(() => {
    widgetRefsRef.current = widgetRefs;
  }, [widgetRefs]);
  const indicators = candleDefaults?.defaultIndicators || []
  const resolution = candleDefaults.defaultResolution;
  const candleType = candleDefaults.candleType;
  const settingsMemo = useMemo(() => {
    return `${resolution},${indicators},${candleType}`

  }, [resolution, indicators, candleType])
  const chartSaveMode = candleDefaults?.chartSaveMode || 0;
  const disabled_features = [
    "use_localstorage_for_settings",
    "display_market_status",
    "popup_hints",
    "header_screenshot",
    // "widget_logo",
  ];
  let subSet = useMemo(() => {
    let clone = !!userData?.layouts ? userData?.layouts?.lg : defaultLayout.lg
    return clone
  }, [userData]);

  const [layouts, setLayouts] = useState(defaultLayout);

  useEffect(() => {
    if (!!userData?.layouts) {
      setJ((prev) => prev + 1)
      setLayouts(userData?.layouts);
    }
  }, [userData])

  useEffect(() => {
    //   getFavorites();
    return () => fetchUser()
  }, []);

  useEffect(() => {
    // if its the first render and userData is populated but there is no user layouts saved, then default the user obj's layouts to the default layouts
    if (j >= 4) updateLayout(layouts)
  }, [layouts])

  useEffect(() => {
    if (j === 0 && !!userData?.userId && !userData?.layouts) updateLayout(defaultLayout)
  }, [userData, j])


  useEffect(() => {
    setWidgetRefs([])
    if (!!userData && subSet.length) {
      subSet.forEach((lay, index) => {
        const ticker = lay.ticker
        const i = lay.i
        const widgetOptions = {
          symbol: ticker,
          debug: false,
          interval: lay?.interval || resolution,
          symbol_search_request_delay: 1000,
          datafeed: new Datafeed(),
          container: `tv_chart_container${index}`, // here
          library_path: "/charting_library/",
          locale: getLanguageFromURL() || "en",
          time_frames: [
            { text: "5Y", resolution: "1D", description: "5 Years" },
            { text: "1Y", resolution: "1D", description: "1 Year" },
            { text: "6M", resolution: "1D", description: "6 Months" },
            { text: "3M", resolution: "1D", description: "3 Months" },
            { text: "1M", resolution: "1", description: "1 Month" },
            { text: "5D", resolution: "1", description: "5 Days" },
            { text: "1D", resolution: "1", description: "1 Day" },
          ].reverse(),
          header_widget_buttons_mode: "adaptive",
          disabled_features,
          enabled_features: [
            "side_toolbar_in_fullscreen_mode",
            "chart_style_hilo",
            "secondary_series_extend_time_scale",
            "header_in_fullscreen_mode",
            "hide_last_na_study_output",
            "seconds_resolution"
          ],
          load_last_chart: false,
          fullscreen: false,
          autosize: true,
          width: "100%",
          height: "100%",
          timezone: "America/New_York",
          client_id: "patternscanner",
          user_id: userId,
          auto_save_delay: 10,
          charts_storage_url: apiURL,
          charts_storage_api_version: !!chartSaveMode ? "1.3" : "1.1",
          theme: themeMode,
          overrides: {
            "mainSeriesProperties.showCountdown": true,
          },
          loading_screen: {
            backgroundColor: "#222222",
            foregroundColor: "#229712",
          }
        };
        const tvWidget = new widget(widgetOptions);
        tvWidget.onChartReady(() => {
          setWidgetRefs((prev) => {
            return [...prev, { tvWidget, i }];
          }
          );
          tvWidget
            .chart()
            .onSymbolChanged()
            .subscribe(null, function (symbolData) {
              setJ((prev) => prev + 1)
              setLayouts((prev) => {
                let clone = [...prev.lg];
                clone[index] = {
                  ...clone[index],
                  ticker: symbolData.name,
                };
                return { lg: clone };
              })
            });
        });
      });
    }
  }, [JSON.stringify(subSet), themeMode, userData, chartSaveMode]);
  useEffect(() => {
    if (widgetRefs.length > 0) {
      widgetRefs.forEach((widget, index) => {
        if (Object.keys(widget.tvWidget).length) {
          const widgetRef = widget.tvWidget
          widgetRef.activeChart().removeAllStudies();
          widgetRef.activeChart().setChartType(Number(candleType));
          widgetRef.activeChart().setResolution(resolution);
          indicators?.forEach((indicator) => widgetRef.activeChart().createStudy(indicator))
        }
      })
    }
  }, [widgetRefs, settingsMemo])

  useEffect(() => {
    return () => {
      console.log("Component is unmounting, attempting to close WebSockets.");
      // Make sure to capture the current value of widgetRefs in this scope
      const currentWidgetRefs = widgetRefsRef.current;
      console.log({ currentWidgetRefs })
      currentWidgetRefs.forEach((widgetRef) => {
        const datafeed = widgetRef.tvWidget._options.datafeed;
        console.log({ widgetRef, datafeed })
        if (datafeed) {
          if (isInternalWS) { // Assuming isInternalWS is available in this scope
            datafeed.closeInternalWebSocket();
          } else {
            console.log('UNSUBBIONG FEED')
            datafeed.closeExternalWebSocket();
          }
        }
      });
    };
  }, []);  // Note the empty dependency array
  if (!!userData) return (
    <>
      <Helmet>
        <title>Chart Grid | TradeAlgo</title>
      </Helmet>
      <ReactGridLayout
        className="layout"
        compactType={'vertical'}
        isDraggable
        layouts={layouts}
        isRearrangeable
        isResizable
        onLayoutChange={(layout) => {
          setJ((prev) => prev + 1)
          setLayouts((prev) => {
            let clone = [...prev.lg];
            // keep the ticker from prev override all other properties from prev into layout
            let mapped = clone.map((x) => ({
              ticker: x.ticker,
              ...layout[x.i],
            }))
            return { lg: mapped }
          })
        }}
        breakpoints={{ lg: 0 }}
      >
        {
          layouts.lg.map((lay, i) => {
            return (
              <div
                style={{ cursor: 'grab' }}
                key={i} >
                <Tooltip title="drag to re-arrange" >
                  <span className="drag-text" style={{ zIndex: 10000000 }}>
                    {lay.ticker}
                  </span>
                </Tooltip>
                <div className={`TVChartContainer${i}`} id={`tv_chart_container${i}`} />
              </div>
            )
          })
        }
      </ReactGridLayout>
      <br />
      <br />
      <br />
    </>
  );
  return null
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  candleDefaults: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  updateLayout: (layouts, userData) => dispatch(updateLayoutLoad(layouts, userData)),
});

export default connect(stateToProps, dispatchToProps)(FavoritesContainer);
