import React, { useEffect, useMemo, useState } from "react";
import "./index.scss";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { updateFieldsLoad } from "../../appRedux/ducks/updateFields";
import { connect } from "react-redux";
import { useAuth } from "../../contexts/Auth";
import { Skeleton } from "@mui/material";
import MentorshipPlans from "./MentoshipPlans";
import { useParams } from "react-router-dom";
import { addUserSettingsLoad } from "../../appRedux/ducks/userSettings";
import Button from "@mui/material/Button";
import MentorDropown from "./MentorDropdown";
function OptionsMentorship({ setUserFields, setUserSettings, userSettings }) {
  const { level } = useParams();
  const {
    loading,
    mentorshipSessionsStarter,
    mentorshipSessionsUsed,
    userData,
    mentorshipSessionsPlatinum,
    mentorshipSessionsIntermediate,
    mentorshipSessionsAdvanced,
  } = useAuth();
  const [subtract, setSubtract] = useState(0);
  const [mentor, setMentor] = useState("");

  useEffect(() => {
    if (!loading) {
      if (level === "starter") {
        setUserSettings({
          ...userSettings,
          om_starter_sign_up: false,
          om_starter_renewed: false,
        });
      }
      if (level === "platinum") {
        setUserSettings({
          ...userSettings,
          om_platinum_sign_up: false,
          om_platinum_renewed: false,
        });
      }
      if (level === "intermediate") {
        setUserSettings({
          ...userSettings,
          om_intermediate_sign_up: false,
          om_intermediate_renewed: false,
        });
      }
      if (level === "advanced") {
        setUserSettings({
          ...userSettings,
          om_advanced_sign_up: false,
          om_advanced_renewed: false,
        });
      }
    }
  }, [loading]);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setSubtract((prev) => prev + 1);
      setUserFields({
        training_sessions_used: {
          ...mentorshipSessionsUsed,
          [`options_mentorship_${level}`]:
            (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) + 1,
        },
      });
    },
  });

  const sessionsLeft = useMemo(() => {
    if (level === "starter")
      return mentorshipSessionsStarter -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract <=
        0
        ? 0
        : mentorshipSessionsStarter -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract;
    if (level === "platinum")
      return mentorshipSessionsPlatinum -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract <=
        0
        ? 0
        : mentorshipSessionsPlatinum -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract;
    if (level === "intermediate")
      return mentorshipSessionsIntermediate -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract <=
        0
        ? 0
        : mentorshipSessionsIntermediate -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract;
    if (level === "advanced")
      return mentorshipSessionsAdvanced -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract <=
        0
        ? 0
        : mentorshipSessionsPlatinum -
        (mentorshipSessionsUsed[`options_mentorship_${level}`] || 0) -
        subtract;
  }, [
    mentorshipSessionsStarter,
    subtract,
    level,
    mentorshipSessionsPlatinum,
    mentorshipSessionsIntermediate,
    mentorshipSessionsAdvanced,
  ]);

  const description = useMemo(() => {
    if (level === "starter") {
      return "The starter level sessions focus on introducing the fundamental concepts and principles of options trading. Participants will learn about market structure, basic trading strategies, and essential tools for success. Through hands-on exercises and engaging examples, newcomers will develop a strong foundation in the world of options trading, preparing them for more advanced topics.";
    }

    if (level === "intermediate") {
      return "Building on the knowledge acquired in the Starter level, Intermediate sessions delve deeper into the intricacies of options trading. Participants will explore more sophisticated strategies and risk management techniques. With a mix of theory and practical application, these 1 on 1 sessions aim to sharpen options trading skills and enhance decision-making abilities for consistent returns.";
    }

    if (level === "advanced") {
      return "The Advanced level is designed for experienced traders who are looking to take their trading skills to an even higher level. Our team of expert mentors will provide you with personalized guidance as you navigate the complex and volatile market conditions. With the support from our analysts, you will also have access to exclusive market analysis and research tools to help you stay ahead of the competition.";
    }

    if (level === "platinum") {
      return "Personalized coaching and equipping the users with all the key resources and erudition required to comprehend the essential concepts and rules of options trading. The program has 52 one-on-one meetings, along with complete access to the all the Platinum program with its own live stream and the sought-after Platinum Alerts.";
    }
  }, [level]);

  const telegramLink = useMemo(() => {
    if (level === "starter") {
      return "https://t.me/+ikR0cMfCb0NiYTQx";
    }

    if (level === "intermediate") {
      return "https://t.me/+8L4VZ2JJ2RszNzAx";
    }

    if (level === "advanced") {
      return "https://t.me/+5IwxM3Vxzps1OTEx";
    }

    if (level === "platinum") {
      return "https://t.me/+12TAYz7H9nhhMzk5";
    }
  }, [level]);

  const availableMentors = useMemo(() => {
    if (level === "starter") {
      return ["Shane", "Roman", "Devin", "Santiago"];
    }
    if (level === "intermediate") {
      return ["Shane"];
    }
    if (level === "advanced") {
      return ["Roman", "Shane"];
    }
    if (level === "platinum") {
      return ["Eric", "Dane", "Roman", "Shane"];
    }
    return [];
  }, [level, mentor]);

  const calendlyLink = useMemo(() => {
    let link = "";
    if (level === "starter") {
      if (mentor.includes("Roman")) {
        link = "https://calendly.com/analysts-4/options-membership-starter";
      }
      if (mentor.includes("Devin")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-starter-devin";
      }
      if (mentor.includes("Santiago")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-starter-santiago";
      }
      if (mentor.includes("Shane")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-starter-shane";
      }
    }
    if (level === "intermediate") {
      if (mentor.includes("Shane")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-intermediate-shane";
      }
    }
    if (level === "advanced") {
      if (mentor.includes("Roman")) {
        link = "https://calendly.com/analysts-4/options-mentorship-advanced";
      }
      if (mentor.includes("Shane")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-advanced-shane";
      }
    }
    if (level === "platinum") {
      if (mentor.includes("Eric")) {
        link =
          "https://calendly.com/analysts-4/platinum-vip-1-on-1-presentation";
      }
      if (mentor.includes("Dane")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-platinum-dane";
      }
      if (mentor.includes("Roman")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-platinum-roman";
      }
      if (mentor.includes("Shane")) {
        link =
          "https://calendly.com/analysts-4/options-mentorship-platinum-shane";
      }

    }
    return link;
  }, [level, mentor]);


  useEffect(() => {
    setMentor("")
  },[level])

  return (
    <>
      <div className={`options-mentorship-container ${level}-container`}>
        <div className="options-mentorship-inner-container">
          <div style={{ display: "flex" }}>
            <span className="om-tier">
              TradeAlgo Options Mentorship:
              <br />
              <span className={`${level}-color`}>{level}</span>
            </span>

            {availableMentors.length > 0 && (
              <MentorDropown
                mentor={mentor}
                setMentor={setMentor}
                availableMentors={availableMentors}
                level={level}
              />
            )}
          </div>
          <span className="launch-journey mt-20">{description}</span>
          <span className="launch-journey mt-20">
            To access the telegram channel, click the link here:{" "}
            <Button
              className="mentorship-plans-page-header-telegram-button"
              onClick={() => window.open(telegramLink, "_blank")}
            >
              Mentorship Telegram Alerts
            </Button>
          </span>
          <span className="sessions-avail mt-20">
            Number of Sessions Available :{" "}
            {loading ? (
              <Skeleton
                style={{
                  marginLeft: "6px",
                  marginTop: "2px",
                  width: "25px",
                  background: "#5EEECD",
                }}
              />
            ) : (
              sessionsLeft
            )}
          </span>
        </div>
      </div>
      {loading && (
        <div style={{ display: "grid" }}>
          <Skeleton
            style={{
              width: "80%",
              height: "700px",
              display: "flex",
              justifySelf: "center",
              background: "#5EEECD",
              borderRadius: 7,
              position: "relative",
              top: "-90px",
            }}
          />
        </div>
      )}
      {sessionsLeft !== 0 && !loading && !!calendlyLink && (
        <InlineWidget
          prefill={{
            email: userData?.email || "",
            name:
              (userData?.firstName || "") + " " + (userData?.lastName || ""),
          }}
          url={calendlyLink}
        />
      )}
      {sessionsLeft === 0 && !loading && <MentorshipPlans level={level} />}
    </>
  );
}
const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(OptionsMentorship);
