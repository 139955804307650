import React, { memo } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import classNames from "classnames";
import "./_apexcharts.scss";
const colors = {
  REACT_APP_PRIMARY_COLOR: "#6c5dd3",
  REACT_APP_SECONDARY_COLOR: "#ffa2c0",
  REACT_APP_SUCCESS_COLOR: "#46bcaa",
  REACT_APP_INFO_COLOR: "#4d69fa",
  REACT_APP_WARNING_COLOR: "#ffcf52",
  REACT_APP_DANGER_COLOR: "#f35421",
  REACT_APP_LIGHT_COLOR: "#e7eef8",
  REACT_APP_DARK_COLOR: "#1f2128",
};
const Chart = ({
  series,
  options,
  type,
  width,
  height,
  className,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={{lineHeight: 'normal !important'}} className={classNames("apex-chart", className)} {...props}>
      <ReactApexChart
        options={{
          colors: [
            colors.REACT_APP_PRIMARY_COLOR,
            colors.REACT_APP_SECONDARY_COLOR,
            colors.REACT_APP_SUCCESS_COLOR,
            colors.REACT_APP_INFO_COLOR,
            colors.REACT_APP_WARNING_COLOR,
            colors.REACT_APP_DANGER_COLOR,
          ],
          plotOptions: {
            candlestick: {
              colors: {
                upward: colors.REACT_APP_SUCCESS_COLOR,
                downward: colors.REACT_APP_DANGER_COLOR,
              },
            },
            boxPlot: {
              colors: {
                upper: colors.REACT_APP_SUCCESS_COLOR,
                lower: colors.REACT_APP_DANGER_COLOR,
              },
            },
          },
          ...options,
        }}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    </div>
  );
};
Chart.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            ),
            PropTypes.shape({
              x: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                ),
                PropTypes.object,
              ]),
              y: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                ),
                PropTypes.object,
              ]),
            }),
          ])
        ),
      }),
    ])
  ).isRequired,
  options: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    annotations: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    chart: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    colors: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    dataLabels: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    fill: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    grid: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    labels: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    legend: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    markers: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    noData: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    plotOptions: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    responsive: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    series: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    states: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    stroke: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    subtitle: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    theme: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    title: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    tooltip: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    xaxis: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    // eslint-disable-next-line react/forbid-prop-types
    yaxis: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  type: PropTypes.oneOf([
    "line",
    "area",
    "bar",
    "pie",
    "donut",
    "scatter",
    "bubble",
    "heatmap",
    "radialBar",
    "rangeBar",
    "candlestick",
    "boxPlot",
    "radar",
    "polarArea",
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
Chart.defaultProps = {
  type: "line",
  width: "100%",
  height: "auto",
  className: null,
};

export default memo(Chart);
