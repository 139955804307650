import React, { useState } from "react";
import "./index.scss"
import MyProfile from "./MyProfile";
import Subscriptions from "./Subscriptions";
import Tabs from "./Tabs";
import { useAuth } from "../../contexts/Auth";
import { CircularProgress } from "@mui/material";

function UserProfile() {
    const [currentNav, setCurrentNav] = useState(0);
    const { loading } = useAuth();
    if (loading) {
        return (
            <div className="platinum-program-sign-up-center-element user-profile">
            <div className="platinum-program-sign-up-result-icon-wrapper mt-250">
              <CircularProgress size={200} color="success" />
            </div>
          </div>
        )
    }
    return (
        <div className="user-profile-wrapper" >
            <Tabs value={currentNav} setValue={setCurrentNav} />
            {
                currentNav === 0 ? <MyProfile /> : <Subscriptions />
            }
        </div>
    )
}

export default UserProfile