import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const OE_LOAD_TOP = "OE/LOAD/TOP";
export const OE_LOAD_TOP_SUCCESS = "OE/LOAD/TOP/SUCCESS";
export const OE_LOAD_TOP_FAILURE = "OE/LOAD/TOP/FAILURE";

// Action Creators
export const optionExpiryLoad = (symbol) => ({
  type: OE_LOAD_TOP,
  symbol,
});
export const optionExpiryLoadSuccess = (data) => ({
  type: OE_LOAD_TOP_SUCCESS,
  data,
});
export const optionExpiryLoadFailure = (error) => ({
  type: OE_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchOptionExpiry(action) {
  try {
    const { symbol } = action;
    if (!!symbol) {
      const response = yield axios.get(`${apiURL}/option-expiry/${symbol}`, { withCredentials: true });
      yield put(optionExpiryLoadSuccess(response.data));
    }
  } catch (error) {
    yield put(optionExpiryLoadFailure(error));
  }
}

function* listenOptionExpiryLoad() {
  yield takeEvery(OE_LOAD_TOP, fetchOptionExpiry);
}

// Root Saga
export function* saga() {
  yield all([fork(listenOptionExpiryLoad)]);
}

const INIT_STATE = {
  ticker: "",
  expirationDates: []
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OE_LOAD_TOP_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default reducer;
