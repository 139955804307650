import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const HISTORICAL_DARKFLOW_LOAD_TOP = "HDF/LOAD/TOP";
export const HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS = "HDF/LOAD/TOP/SUCCESS";
export const HISTORICAL_DARKFLOW_LOAD_TOP_FAILURE = "HDF/LOAD/TOP/FAILURE";

// Action Creators
export const historicalDarkflowLoad = (
  trendingStatus,
  notifications = false
) => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP,
  trendingStatus,
  notifications,
});
export const historicalDarkflowLoadSuccess = (data) => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS,
  data,
});
export const historicalDarkflowLoadFailure = (error) => ({
  type: HISTORICAL_DARKFLOW_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchHistoricalDarkFlow(action) {
  try {
    yield put(
      historicalDarkflowLoadSuccess({
        historicalDarkflow: [],
        loading: true,
      })
    );
    const { trendingStatus, notifications } = action;
    const response = yield axios.get(`${apiURL}/ats/historic/daily-darkflow${notifications ? "-today" : ""}?trending_status=${trendingStatus}`, { withCredentials: true });
    yield put(
      historicalDarkflowLoadSuccess({
        historicalDarkflow: [...response.data],
        loading: false,
      })
    );
  } catch (error) {
    yield put(historicalDarkflowLoadFailure(error));
  }
}

function* listenHistoricalDarkflowLoad() {
  yield takeEvery(HISTORICAL_DARKFLOW_LOAD_TOP, fetchHistoricalDarkFlow);
}

// Root Saga
export function* saga() {
  yield all([fork(listenHistoricalDarkflowLoad)]);
}

const INIT_STATE = {
  historicalDarkflow: [],
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HISTORICAL_DARKFLOW_LOAD_TOP_SUCCESS:
      return {
        ...state,
        historicalDarkflow: action.data.historicalDarkflow,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
