import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight:"none",
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        color: '#3183FF'
    };
}

export default function MentorDropown({ mentor, setMentor, availableMentors }) {
    const theme = useTheme();
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setMentor(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div style={{ position: 'relative', top: '-20px', left: '40px' }}>
            <FormControl sx={{ m: 1, width: 275, mt: 3 }}>
                <Select
                    multiple={false}
                    displayEmpty
                    value={mentor}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return <em style={{
                            fontFamily: 'Inter',
                            fontStyle: "normal",
                            fontSize: "20px",
                            fontWeight: 700,
                            color: '#3183FF'
                        }}>{selected.length === 0 ? "Select Your Mentor" : selected.join(', ')}</em>;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem disabled value="">
                        <em >Select Your Mentor</em>
                    </MenuItem>
                    {availableMentors.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, mentor, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
