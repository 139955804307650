import React, { useMemo } from "react";
import NumAbbr from "number-abbreviate";
import { get } from "lodash";
import { connect } from "react-redux";
import "./Trade-Algo-Card.scss";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Progress } from "reactstrap";
import "./progress-bar.scss";
import SparkLines from "../common/SparkLines";
import CircularProgressBar from "./circularProgress/CircularProgressBar.js"
import { symbolSetter } from "../../appRedux/ducks/symbol";
import CssPop from "../common/Tables/animations/CssPop";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { isInternalWS } from "../../appRedux/actions/helpers";

const useStyles = makeStyles((theme) => ({
  tableBackground: {
    background: theme.palette.primary.tableBackground,
    color: theme.palette.primary.tableHeaderText,
  },
  text: {
    color: theme.palette.primary.text,
  },
}));
export const decimal = (input) => input.toFixed(2);
export const abbreviate = (x) =>
  new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, 1);
const TradeAlgoCard = (props) => {
  const classes = useStyles();
  const {
    up,
    ticker,
    name,
    trend_start,
    day,
    chart,
    ats,
    multiplier,
    marketStatus,
    currentPrices,
    last_price,
    options,
    setSymbol,
    themeMode,
    weekly
  } = props;
  const livePrice = useMemo(() => {
    return currentPrices.get(ticker);
    // eslint-disable-next-line
  }, [currentPrices, ticker]);
  const newPrice = useMemo(() => {
    return parseFloat(livePrice || last_price || 0);
  }, [livePrice, last_price]);
  // eslint-disable-next-line
  const [performance, gain] = useMemo(() => {
    let performance = Math.round((newPrice / trend_start - 1) * 1000) / 10;
    const gain = Number(newPrice || 0) - (trend_start || 0)
    if (
      marketStatus === "pre-open" ||
      marketStatus === "open" ||
      get(day, "close", "") === ""
    ) {
      if (!parseFloat(day?.prev_close || 0)) {
        return [performance, gain];
      }
      return [performance, gain];
    } else if (marketStatus === "after" || marketStatus === "closed") {
      return [performance, gain];
    }
    // eslint-disable-next-line
  }, [marketStatus, trend_start, newPrice]);
  const show = useMemo(() => {
    return up ? gain >= 0 : gain <= 0;
  }, [gain, up])
  const colors = useMemo(() => {
    let sparklines = up ? "#5EEECD" : "#F32228"
    let bar = up ? 'red' : 'green'
    return {
      sparklines,
      bar
    }
  }, [up, performance])
  const dummyOptions = {
    colors: [colors.sparklines],
    chart: {
      type: "area",
      width: "100%",
      height: 120,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          // eslint-disable-next-line no-unused-vars
          formatter(seriesName, s) {
            return "$";
          },
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };
  const comparison = useMemo(() => {
    return abbreviate(Number(ats?.previous?.day_dollar_volume || 0));
    // eslint-disable-next-line
  }, [ats?.previous?.day_dollar_volume || 0]);
  const percentageBar = useMemo(() => {
    const previous = Number(ats?.previous?.day_dollar_volume || 0);
    const today = Number(ats?.current?.day_dollar_volume || 0);
    // return Number((previous / today) * 100);
    return {
      today: previous > today ? Number(today / previous) * 100 : 100,
      sevenDay: today > previous ? Number((previous / today) * 100) : 100
    }
    // eslint-disable-next-line
  }, [comparison]);
  const dFlowTitle = useMemo(() => {
    if (multiplier > 2) {
      return "High"
    } else if (multiplier > 1) {
      return "Medium"
    } else {
      return "Low"
    }
  }, [multiplier])

  const calcFlowSentimentLevel = (sentiment) => {
    // A) 0 ~ 0.48 = Bearish
    // B) 0.48 - 0.52 = Neutral
    // C) 0.52 - 1.0 = Bullish
    sentiment = sentiment ? sentiment : 0;

    if (sentiment >= 0 && sentiment < 0.48) {
      return "Bearish";
    }

    if (sentiment >= 0.48 && sentiment <= 0.52) {
      return "Neutral";
    }

    if (sentiment > 0.52 && sentiment <= 1) {
      return "Bullish";
    }
  };
  const totalPremium =
    parseInt(options?.put_total_prem) + parseInt(options?.call_total_prem);
  // const putPremiumRatio = parseInt(options?.put_total_prem) / totalPremium;
  const callPremiumRatio = parseInt(options?.call_total_prem) / totalPremium;
  const callRatio = Number(callPremiumRatio * 100).toFixed(0) + '%'
  const optionSentiment = useMemo(() => {
    return calcFlowSentimentLevel(callPremiumRatio)
  }, [callPremiumRatio])
  const color = useMemo(() => {
    let col = ''
    if (optionSentiment === "Bullish") {
      col = "#3EB489"
    } else if (optionSentiment === "Bearish") {
      col = "rgb(249 27 27)"
    } else {
      col = "rgb(249 182 60)"
    }
    return col
  }, [optionSentiment])
  const width = useMemo(() => {
    return performance >= 100 ? '77px' : '63px'
  }, [performance])
  const hasOptions = useMemo(() => {
    return (!!Number(options?.call_total_prem) || !!Number(options?.put_total_prem))
  }, [options])
  if (show) {

    return (
      <Card
        elevation={0}
        className="trade-algos-darkflow-card"
        style={{ borderRadius: "16px", margin: "15px", overflow: "visible", background: themeMode === 'dark' ? 'rgb(19 15 15 / 50%)' : '#F5F5F5' + ' !important' }}
        sx={{
          minWidth: "400px",
          maxWidth: "400px",
          minHeight: "630px",
          maxHeight: "641px",
        }}
      >
        <CardContent className={clsx(classes.tableBackground, "trade-algos-darkflow-card-content")}>
          <div
            style={{
              height: "70px",
              borderBottom: themeMode === "dark" ? "1px solid  rgb(44 43 43 / 50%)" : "1px solid #E2E8F0",
              paddingTop: "8px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "20px",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              <Link onClick={() => {
                setSymbol({
                  value: ticker,
                  type: ''
                })
              }} className={classes.text} to={`/company/${ticker}`}>
                {ticker}
              </Link>

              <span
                className="top-price"
                style={{ float: "right" }}
              >
                <span
                >
                  <span style={{ float: 'right' }}>

                    <CssPop comparator={`$${decimal(newPrice)}`} />
                  </span>
                </span>
                <div style={{ display: 'flex', position: 'relative', left: '20px', top: '21px' }}>
                  <span style={{
                    fontSize: "15px",
                    color: String(gain).includes("-")
                      ? "#f91b1b"
                      : "#3EB489",
                    paddingTop: "9px",
                    marginRight: '-5px',
                    fontWeight: "600",
                    whiteSpace: 'nowrap'
                  }}>
                    {String(gain).includes("-") ? "" : "+ "}
                    {gain.toFixed(2)}
                  </span>
                  <div className="col-6">
                    <div
                      style={{
                        height: "24px",
                        width,
                        background: String(performance).includes("-")
                          ? "#f91b1b"
                          : "#3EB489",
                        color: "white",
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: "600",
                        borderRadius: "25px",
                        marginTop: "8px",
                        marginLeft: "-2px",
                      }}
                    >
                      <span style={{ position: "relative", top: "0.8px", whiteSpace: 'nowrap' }}>
                        {String(performance).includes("-") ? "" : "+ "}
                        {decimal(performance)}%
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </span>
            <div className="name">{name}</div>
          </div>
          <div
            className="compare"
            style={{
              padding: "32px 0px 16px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ position: 'relative', left: weekly ? '-5px' : '', width: weekly ? '' : '57px', whiteSpace: 'nowrap' }}>{weekly ? 'Past Week' : 'Today'}</span>
            <div
              className={`progress-wrap-${themeMode} progress-wrap--ta-${up ? 'green' : 'red'}`}
              style={{ marginBottom: 0, width: "60%" }}
            >
              <Progress value={percentageBar.today} />
            </div>
            <span style={{ width: "calc(40% - 80px)" }}>
              {abbreviate(Number(ats?.current?.day_dollar_volume || 0))}
            </span>
          </div>
          <div
            className="compare"
            style={{
              paddingBottom: 32,
              borderBottom: themeMode === "dark" ? "1px solid  rgb(44 43 43 / 50%)" : "1px solid #E2E8F0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ position: 'relative', left: weekly ? '-7px' : '', width: weekly ? '' : '57px', whiteSpace: 'nowrap' }}>{weekly ? 'Past Month' : '7d Avg'}</span>
            <div
              style={{ width: "60%", marginBottom: 0 }}
              className={`progress-wrap-${themeMode} progress-wrap--ta-${up ? 'green' : 'red'}`}
            >
              <Progress value={percentageBar.sevenDay} />
            </div>
            <span style={{ width: "calc(40% - 80px)" }}>{comparison}</span>
          </div>

          <div style={{
            borderBottom: themeMode === "dark" ? "1px solid  rgb(44 43 43 / 50%)" : "1px solid #E2E8F0",
          }} className={"trade-algo-darkflow-card-graph-wrapper"}>
            <SparkLines
              series={[
                {
                  data: [...(chart?.data || []), Number(newPrice)].filter((i) => !!i).map(
                    (el) => Number(el).toFixed(2)
                  ),
                },
              ]}
              options={dummyOptions}
              type={dummyOptions.chart.type}
              height={dummyOptions.chart.height}
              width={dummyOptions.chart.width}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>

            <div style={{ position: 'relative', top: "-20px" }}>
              <span className="darkflow-title"> Dark Flow </span>
              <CircularProgressBar
                percentage={Number(multiplier) * 100}
                title={dFlowTitle}
                value={Number(multiplier).toFixed(2) + 'x'}
                color={up ? '#3EB489' : 'rgb(249 27 27)'}
                up={up}
              />
            </div>
            {
              hasOptions && (

                <div style={{ position: 'relative', top: "-20px" }}>
                  <span className="options-title"> Options Flow </span>
                  <CircularProgressBar
                    callRatio={callRatio.replace('%', '')}
                    percentage={5}
                    title={calcFlowSentimentLevel(callPremiumRatio)}
                    color={color}
                    up={up}
                    options={true}
                  />
                </div>
              )
            }
          </div>
          <div className="container">
            <div className="row">
              <div className={`col-${hasOptions ? '6' : '12'}`}>
                <div className="row">
                  <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                    Share Vol
                  </div>
                  <div className={`col-6 left-side-value-${up ? 'green' : 'red'}`}>
                    {abbreviate(ats?.current?.day_volume)}
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                    Trade Flow
                  </div>
                  <div className={`col-6 left-side-value-${up ? 'green' : 'red'}`}>
                    ${abbreviate(ats?.current?.day_trades)}
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                    Total $ Vol
                  </div>
                  <div className={`col-6 left-side-value-${up ? 'green' : 'red'}`}>
                    ${abbreviate(ats?.current?.day_dollar_volume)}
                  </div>
                </div>
              </div>
              {
                hasOptions && (
                  <div className="col-6">
                    <div className="row">
                      <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                        Call Ratio
                      </div>
                      <div className={`col-6 right-side-value-${themeMode}`}>
                        {callRatio}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                        Call Prem
                      </div>
                      <div className={`col-6 right-side-value-${themeMode}`}>
                        ${abbreviate(options?.call_total_prem)}
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }} className="col-6 left-side">
                        Put Prem
                      </div>
                      <div className={`col-6 right-side-value-${themeMode}`}>
                        ${abbreviate(options?.put_total_prem)}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
  return null
};

const stateToProps = (state) => ({
  // currentPrices: state.dxFeedSocket.socketData,
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData ,
  themeMode: state.themeMode.themeMode,
  weekly: state.darkflowTable.weekly,
});
const mapDispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
export default connect(stateToProps, mapDispatchToProps)(TradeAlgoCard);
