import React, { useMemo } from "react";
import { connect } from "react-redux";
import "./EmeraldTabs.scss";

const EmeraldTabs = ({ activeTab, setActiveTab, resetView }) => {
  const handleResetView = () => {
    setActiveTab('tutorials')
    resetView();
  }

  return (
    <div className="emerald-tabs">
      <div className={`emerald-tabs__tab ${activeTab === 'tutorials' ? 'active' : ''}`} onClick={handleResetView}>Tutorials</div>
      <div className={`emerald-tabs__tab ${activeTab === 'livestream' ? 'active' : ''}`} onClick={() => setActiveTab('livestream')}>Livestream</div>
      <div className={`emerald-tabs__tab ${activeTab === 'real-time-alerts' ? 'active' : ''}`} onClick={() => setActiveTab('real-time-alerts')}>Real Time Alerts</div>
      <div className={`emerald-tabs__tab ${activeTab === 'support' ? 'active' : ''}`} onClick={() => setActiveTab('support')}>Support</div>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(EmeraldTabs);