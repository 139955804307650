import axios from "axios";
import { apiURL, isPolyREST, isInternalWS, wsUrl } from "../../appRedux/actions/helpers";
import moment from "moment";
import { generateBars, resolutionsSupported, updateBar, updateBarDx } from "./candleControl";
import Feed, { EventType } from "@dxfeed/api";

const supportedResolutions = resolutionsSupported();
const config = {
  supported_resolutions: supportedResolutions,
};
let allBars = [];

const api_root = apiURL;
const history = {};
let secPrice = {
  last_price: "",
  bounds: { ticker: "" },
};
let socket;
var sub;
let feed;
let unsub

const connect = async () => {
  //get Token
  feed = new Feed();
  feed.connect(wsUrl);
  return new Promise((resolve) => {
    resolve(feed);
  });
};
const socket_url = process.env.REACT_APP_WEBSOCKET
if (isInternalWS) {
  socket = new WebSocket(socket_url);
}
var sub;
// Trading View JS Datafeed
const datafeed = {
  onReady: (cb) => {
    if (!feed && !isInternalWS) connect()
    setTimeout(() => cb(config), 0);
  },
  storeSec: (lastPrice) => {
    secPrice = lastPrice;
  },
  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    try {
      const results = [];
      const { data } = await axios.get(`${api_root}/search/${userInput}`, { withCredentials: true })
      const availableSymbols = data;
      if (availableSymbols.length) {
        for (let i = 0; i <= availableSymbols.length; i++) {
          const data = availableSymbols[i];
          if (data) {
            let type = "Stock";
            if (data.type && data.type.includes("fund")) {
              type = "Fund";
            } else {
              if (data.type === "etf") {
                type = "Etf";
              } else {
                if (data.type === "common_stock") {
                  type = "Stock";
                }
              }
            }
            let formatted = {
              symbol: data.ticker,
              full_name: data.name,
              description: data.name,
              exchange: "US",
              ticker: data.ticker,
              type,
            };
            if (
              data.type === "common_stock" ||
              data.type === "etf" ||
              data.type === "mutual_fund"
            ) {
              results.push(formatted);
            }
          }
        }
        onResultReadyCallback(results);
      }
    } catch (error) {
      console.log(error, 'error searching symbols')
    }
  },

  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    // expects a symbolInfo object in response
    const isOption = symbolName.includes('OPTION_CONTRACT')
    var symbol_stub = {
      isOption,
      name: isOption ? symbolName.replace("OPTION_CONTRACT", "") : symbolName, // used for charting historical data 
      description: (isOption && isPolyREST) ? symbolName.split(' ')[1].replace("OPTION_CONTRACT", "") : symbolName.replace("OPTION_CONTRACT", ""),
      type: "stock",
      timezone: "Etc/UTC",
      ticker: symbolName.replace("OPTION_CONTRACT", ""),
      // exchange: 'NYSE',
      minmov: 1,
      pricescale: 100,
      has_weekly_and_monthly: false,
      has_seconds: true,
      seconds_multipliers: ["1", "5", "15", "30"],
      has_intraday: true,
      intraday_multipliers: ["1", "5", "15", "30", "60", "120", "240", "360"],
      supported_resolution: supportedResolutions,
      volume_precision: 1,
      data_status: "streaming",
    };
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      let { from, to, firstDataRequest } = periodParams;
      let qs = {
        // firstDataRequest,
        symbol: symbolInfo.name,
        resolution: resolution.replace("1D", "D"),
        fromTime: moment(from * 1000).subtract(resolution.includes("S") ? firstDataRequest ? 5 : 12 : 5, resolution.includes("S") ? "hours" : 'days').valueOf(),
        toTime: to * 1000,
        timespan: "m"
      };
      const sunday = moment().startOf('week').format("YYYY-MM-DD");
      let fromTime = moment(qs.fromTime).format("YYYY-MM-DD");
      if (moment(sunday).isSame(fromTime, 'day') && resolution.includes('S')) {
        qs.fromTime = moment(qs.fromTime).subtract(2, 'days').startOf("day").valueOf();
      }
      if (qs.resolution === "D") {
        // day
        qs.timespan = "d";
        qs.resolution = 1;
      } else if (resolution.includes("S")) {
        qs.timespan = ''
        // qs.resolution = resolution.replace("S", "")
      } else {
        //minute || hour
        qs.timespan = 'm';
        qs.resolution = qs.resolution;
        if (qs.resolution >= 60) {
          qs.timespan = 'h'
          qs.resolution = qs.resolution / 60;
        }
      }
      let symbol = qs.symbol;
      const bars = await generateBars(symbolInfo.isOption, symbol, qs.resolution, qs.timespan, qs.fromTime, qs.toTime)
      if (bars.length) {
        if (firstDataRequest) {
          var lastBar = bars[bars.length - 1];
          history[symbolInfo.name] = { lastBar: lastBar };
        }
        allBars = allBars.concat(bars).sort((a, b) => a.time - b.time);
        // return bars
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback([], { noData: true });
      }
    } catch (error) {
      console.log({ error });
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    let symbol = symbolInfo.name.replace("/", "");
    const { isOption } = symbolInfo
    if (isInternalWS) {
      socket.send(
        JSON.stringify([isOption && isPolyREST ? `options_tns_dx;${symbol.split(' ')[1].replace('OPTION_CONTRACT', '')}` : `equities_candle_dx;${symbol}`])
      );
    } else {
      let sym = symbolInfo.name.replace("/", "");
      let symbol = isOption && isPolyREST ? sym.split(' ')[1].replace('OPTION_CONTRACT', '') : sym
      !!unsub && unsub()
      unsub = feed.subscribe([EventType.Candle], [symbol], (event) => {
        const { eventSymbol } = event;
        if (symbol === eventSymbol) {
          var _lastBar = updateBarDx(event, sub);
          if (!!_lastBar) {
            if (allBars[allBars.length - 1]) {
              allBars[allBars.length - 1].close = Number(_lastBar.close);
            }
            _lastBar.close = Number(_lastBar.close);
          } else {
            _lastBar = allBars[allBars.length - 1]
          }
          sub.listener(_lastBar);
          // update our own record of lastBar
          sub.lastBar = _lastBar;
        }
      });
    }
    if (history[symbolInfo.name]) {
      const newSub = {
        symbol,
        subscribeUID,
        resolution,
        symbolInfo,
        lastBar: history[symbolInfo.name].lastBar,
        listener: onRealtimeCallback,
      };
      sub = newSub;
    }

  },

  unsubscribeBars: (flag) => {
    allBars = [];
    if (!isInternalWS && flag === 'full-unsub' && !!feed) {
      feed.disconnect()
      feed = undefined
      unsub = undefined
    }
  },
};

// handle socket event
if (isInternalWS) {

  socket.onopen = function (event) {
    console.log("socket opened");
  };

  socket.addEventListener("message", function (event) {
    if (event.data !== "pong") {
      const message = JSON.parse(event.data);
      if (message) {
        const data = message?.data;
        if (!!data) {
          const trades = JSON.parse(data)
          trades.forEach((el) => {
            let barData
            if (message.channel.includes('options')) {
              barData = {
                symbol: el.symbol,
                price: Number(el.price),
                volume: Number(el.size),
                time: (el.time),
              };
            } else {
              if (!!el.eventSymbol) {
                barData = {
                  symbol: el.eventSymbol,
                  price: Number(el.close),
                  volume: Number(el.volume),
                  time: (el.time),
                };
              }
            }
            var _lastBar = updateBar(barData, sub);
            // send the most recent bar back to TV's realtimeUpdate callback
            if (allBars[allBars.length - 1]) {
              allBars[allBars.length - 1].close = Number(_lastBar.close);
            }
            _lastBar.close = Number(_lastBar.close);
            sub.listener(_lastBar);
            // update our own record of lastBar
            sub.lastBar = _lastBar;
          });
        }
      }
    }
  });
}
export function getCurrentCandleSocket() {
  return socket;
}
export function getAllBars() {
  if (allBars[0] && !allBars[0]?.ticker?.includes("darkFlow")) {
    return allBars;
  } else {
    return [];
  }
}


export function getSub() {
  if (!!sub?.lastBar) {
    return sub.lastBar
  } else {
    return {
      isLastBar: false,
      close: '',
      symbol: "",
    }
  }
}
export function getUnsub() {
  return datafeed.unsubscribeBars;
}

export function closeSocket() {
  if (isInternalWS && socket.readyState === socket.OPEN) {
    socket.send(JSON.stringify([]))
  }
}

export default datafeed;
