import DashboardTableContainer from "./DashboardTableContainer";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import TradeAlgoDarkflowCards from "./TradeAlgoDarkflowCards";
import { Helmet } from 'react-helmet';
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
// import CustomerFeedback from "./CustomerFeedback";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
// import ChangeLog from "./ChangeLog";
import { useMedia } from "react-media";


export function chunkArray(myArray, chunk_size) {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
}
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const HomePage = (props) => {
  const { popLoading, popularStocks, darkflowTable, listen, subs, tabRisk, themeMode, socketListen } = props;
  let { large, medium, small } = props.darkflowCards;
  // state for TABLE controllers
  const [isPopularTab, setIsPopularTab] = useState(false);
  const [up, setUp] = useState(window.location.pathname.includes('Up'));
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const currentCards = useMemo(() => {
    const trendingUp = darkflowTable.trending_up || [];
    const trendingDown = darkflowTable.trending_down || [];
    return (up ? trendingUp : trendingDown).slice(0, 10).map((item) => {
      const totalPremium =
        parseInt(item.options?.put_total_prem) + parseInt(item.options?.call_total_prem);
      const callPremiumRatio = parseInt(item?.options?.call_total_prem) / totalPremium;
      return {
        ...item,
        sentiment: (callPremiumRatio ? (callPremiumRatio * 100).toFixed(0) : 0) + '%'

      }
    });
    // eslint-disable-next-line
  }, [up, JSON.stringify(darkflowTable)]);

  const loading = useMemo(() => {
    return isPopularTab ? popLoading : darkflowTable.loading;
  }, [darkflowTable.loading, isPopularTab, popLoading]);

  const allTableTickers = useMemo(() => {
    const allTickers = isPopularTab
      ? popularStocks.map((p) => `${p.symbol}`)
      : currentCards.map((item) => `${item.ticker}`);
    if (!!allTickers.length) {
      return allTickers;
    } else {
      return []
    }
    // eslint-disable-next-line
  }, [loading, up, isPopularTab]);
  // state for CARD controllers 
  const [value, setValue] = useState("0");
  useEffect(() => {
    let direction = value === "0" ? "trending_up" : "trending_down";
    // const allTickers = allTableTickers.concat(large[direction]).concat(medium[direction]).concat(small[direction]).map((item) => `equities_tns_dx;${!!item?.ticker ? item.ticker : item}`);
    const allTickers = allTableTickers.concat(large[direction]).concat(medium[direction]).concat(small[direction]).map((item) => `equities_candle_dx;${!!item?.ticker ? item.ticker : item}`);
    if (!!allTickers.length && !!allTableTickers.length) {
      // listen([...new Set(allTickers.concat(allTableTickers))], false, false, risk);
      socketListen([...new Set(allTickers)])
    }
    // return () => listen([], false, true);
    return () => socketListen([])
    // eslint-disable-next-line
  }, [small.loading, value, allTableTickers]);

  return (
    <>
      <Helmet>
        <title> Dashboard | TradeAlgo </title>
      </Helmet>
      <div className="flex-row">
        <div className="col-12 col-xl-12 px-2">
          {/* <CustomerFeedback /> */}
          {/* {
            !isMobile && (
              <ChangeLog themeMode={themeMode} />
            )
          } */}
          <DashboardTableContainer currentCards={currentCards} isPopularTab={isPopularTab} setIsPopularTab={setIsPopularTab} up={up} setUp={setUp} />
          {!isPopularTab && <TradeAlgoDarkflowCards value={value} setValue={setValue} />}
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  subs: state.Socket.channels,
  data: state.Socket.data,
  popLoading: state.popularStocks.loading,
  popularStocks: state.popularStocks.popularStocks,
  darkflowTable: state.darkflowTable,
  darkflowCards: state.darkflow,
  currentSubs: state.dxFeedSocket.currentSubs,
  tabRisk: state.darkflowTable.risk,
  themeMode: state.themeMode.themeMode
});

const dispatchToProps = (dispatch) => ({
  listen: (symbols, options, disconnect, risk) => dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
  // TA ws
  socketListen: (channels) => dispatch(equtiesSocketListen(channels)),
});

export default connect(stateToProps, dispatchToProps)(HomePage);
