import { DataGridPro } from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core";
import { TableSkeleton } from "../Skeletons/TableSkeleton";
import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMedia } from "react-media";
import { connect } from "react-redux";
import clsx from "clsx";
const mediaQuery = {
    isMobile: "screen and (max-width: 991px)",
};
const useStyles = makeStyles((theme) => ({
    noData: {
        background: theme.palette.primary.tableBackground,
        color: theme.palette.primary.tableHeaderText,
        padding: "29px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "16px"
    },
    dataGrid: {
        background: theme.palette.primary.tableBackground,
        borderRadius: 16,
        "& .MuiIconButton-label": {
            color: theme.palette.primary.text,
        },
        "& .MuiDataGrid-root": {
            border: "none !important",
            position: "relative",
            top: "24px",
            overflow: "hidden !important",
            fontFamily: "Poppins,sans-serif !important",
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "normal !important",
            borderBottomColor: theme.palette.primary.bb
        },
        "& .MuiDataGrid-columnSeparator": {
            display: "none",
        },
        "& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
            color: theme.palette.primary.tableHeaderText,
            fontSize: "14px",
            fontWeight: "600",
            fontFamily: "Poppins,sans-serif !important",
        },
        "& .MuiDataGrid-columnsContainer": {
            borderBottomColor: theme.palette.primary.bb,
        },
        "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
        },
        "& .MuiDataGrid-cell:focus": {
            outline: "none !important",
        },
    },
    tableBackground: {
        background: theme.palette.primary.tableBackground,
        color: theme.palette.primary.tableHeaderText,
    },
    tableText: {
        color: theme.palette.primary.tableHeaderText + " !important",
        fontSize: "14px",
        fontFamily: "Poppins,sans-serif !important",
        fontWeight: 600,
        whiteSpace: "nowrap",
    }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({

    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary.text,
        borderBottomColor: "rgb(60, 64, 67)"

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottomColor: "rgb(60, 64, 67)"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function MobileTable({ rows = [], columns, loading, sortModel }) {
    const classes = useStyles()
    if (!rows.length && !loading) return <div className={clsx(classes.noData, "MuiDataGrid-windowContainer")} style={{ top: "56px", overflowY: "hidden" }}>No Rows</div>
    return (
        <TableContainer elevation={0}>
            {loading ? (
                <TableSkeleton />
            ) : (
                <Table className={classes.tableBackground} sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((col, index) => {
                                    if (col.hide) return null
                                    return (
                                        <StyledTableCell className={classes.tableText} key={index}>{col.headerName}</StyledTableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...rows]?.sort((a, b) => (b[sortModel]) - (a[sortModel]))?.map((row, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    {
                                        columns?.map((col, j) => {
                                            if (col.hide) return null
                                            if (col.renderCell) {
                                                let params = {
                                                    value: row[columns[j]?.field],
                                                    row
                                                }
                                                return (
                                                    <StyledTableCell key={j} component="th" scope="row">
                                                        {col?.renderCell(params)}
                                                    </StyledTableCell>
                                                )
                                            }
                                            return (
                                                <StyledTableCell key={index} component="th" scope="row">
                                                    {typeof row[columns[j].field] === 'object' ? '' : row[columns[j].field]}
                                                </StyledTableCell>
                                            )
                                        })
                                    }

                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}


const TradeAlgoTable = ({
    rows, columns, loading = false, sortModel, setSortModel, hasRowClassName = false, rowClassName = '', themeMode
}) => {
    const classes = useStyles();
    const { isMobile } = useMedia({
        queries: mediaQuery,
    });
    const Table = useMemo(() => {
        return (
            <>
                {loading ? (
                    <TableSkeleton />
                ) : (
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        hideFooterPagination={true}
                        pageSize={rows.length + 1}
                        disableColumnMenu={true}
                        isRowSelectable={false}
                        sortingOrder={["asc", "desc"]}
                        hideFooter={true}
                        autoHeight={true}
                        sortModel={sortModel}
                        scrollbarSize={1}
                        getRowClassName={hasRowClassName ? rowClassName : ''}
                        onSortModelChange={(model) => {
                            if (
                                model[0].field !== sortModel[0].field ||
                                model[0].sort !== sortModel[0].sort
                            ) {
                                setSortModel(model);
                            }
                        }}
                    />
                )}</>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rows), loading, columns, sortModel, rows.length, themeMode])
    if (isMobile) return <MobileTable sortModel={sortModel[0].field} loading={loading} rows={rows} columns={columns} />
    return (
        <div className={classes.dataGrid}>
            <div style={{ height: "fit-content", width: "100%" }}>
                {Table}
                <div style={{ height: "4px" }}> </div>
            </div>
        </div>
    );
};


const stateToProps = (state) => ({
    themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TradeAlgoTable);
