import DashboardTableContainer from "./DashboardTableContainer";
import React, { useEffect, useMemo, useState } from "react";
import { useMedia } from "react-media";
import { connect } from "react-redux";
import { browserName } from "react-device-detect";
import TradeAlgoDarkflowCards from "./TradeAlgoDarkflowCards";
import { Helmet } from 'react-helmet';
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import MentorshipPromoBanner from "../MentorshipPromoBanner";
import { useAuth } from "../../contexts/Auth";
import WelcomeModal from "./WelcomeModal";
import { useHistory } from "react-router-dom";

export function chunkArray(myArray, chunk_size) {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
}
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const HomePage = (props) => {
  const { popLoading, popularStocks, darkflowTable, listen, subs, tabRisk } = props;
  let { large, medium, small } = props.darkflowCards;
  const { showCTABanner, CTABannerLink, loading } = useAuth()
  // state for TABLE controllers
  const [isPopularTab, setIsPopularTab] = useState(false);
  const [up, setUp] = useState(window.location.pathname.includes('Up'));
  const currentCards = useMemo(() => {
    const trendingUp = darkflowTable.trending_up || [];
    const trendingDown = darkflowTable.trending_down || [];
    return (up ? trendingUp : trendingDown).slice(0, 10).map((item) => {
      const totalPremium =
        parseInt(item.options?.put_total_prem) + parseInt(item.options?.call_total_prem);
      const callPremiumRatio = parseInt(item?.options?.call_total_prem) / totalPremium;
      return {
        ...item,
        sentiment: (callPremiumRatio ? (callPremiumRatio * 100).toFixed(0) : 0) + '%'

      }
    });
    // eslint-disable-next-line
  }, [up, JSON.stringify(darkflowTable)]);
  const tablesLoading = useMemo(() => {
    return isPopularTab ? popLoading : darkflowTable.loading;
  }, [darkflowTable.loading, isPopularTab, popLoading]);

  const allTableTickers = useMemo(() => {
    const allTickers = isPopularTab
      ? popularStocks.map((p) => `${p.symbol}`)
      : currentCards.map((item) => `${item.ticker}`);
    if (!!allTickers.length) {
      return allTickers;
    } else {
      return []
    }
    // eslint-disable-next-line
  }, [tablesLoading, up, isPopularTab]);
  // state for CARD controllers 
  const [value, setValue] = useState("0");
  const risk = useMemo(() => {
    if (isPopularTab) return 'popular'
    else if (tabRisk === 'large') return 'large'
    else if (tabRisk === 'medium') return 'medium'
    else if (tabRisk === 'small') return 'small'
    else return 'auto'
  }, [isPopularTab, tabRisk])
  useEffect(() => {
    let direction = value === "0" ? "trending_up" : "trending_down";
    const allTickers = large[direction].concat(medium[direction]).concat(small[direction]).map((item) => `${item?.ticker}`);
    if (!!allTickers.length && !!allTableTickers.length) {
      listen([...new Set(allTickers.concat(allTableTickers))], false, false, risk);
    }
    return () => listen([], false, true);
    // eslint-disable-next-line
  }, [small.loading, value, allTableTickers]);
  // done

  const [allSubs, setAllSubs] = useState([]);
  const [i, setI] = useState(0);
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  
  useEffect(() => {
    let all = [];
    if (!!subs.length) {
      all = subs.concat(allSubs).filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      })
      setAllSubs(all);
    }
    if (!!allSubs.length) {
      let interval = setTimeout(() => {
        if (browserName.toLocaleLowerCase().includes('safari') || isMobile) {
          let chunks = chunkArray(allSubs, 10);
          if (chunks[i]) {
            listen(chunks[i]);
            setI(i + 1);
          } else {
            setI(0);
          }
        }
      }, 10000);
      return () => clearTimeout(interval);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.subs), i, browserName]);
  useEffect(() => {
    if (!browserName.toLocaleLowerCase().includes('safari')) {
      const timeout = setTimeout(() => {
        listen(allSubs);
        return () => listen([], false, true);
      }, 2500);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [allSubs.length]);
  return (
    <>
      <Helmet>
        <title> Dashboard | TradeAlgo </title>
      </Helmet>
      <div className="flex-row">
        <div className="col-12 col-xl-12 px-2">
          {/* <CustomerFeedback /> */}
          {(showCTABanner && !loading) && <MentorshipPromoBanner link={CTABannerLink} />}
          <DashboardTableContainer currentCards={currentCards} isPopularTab={isPopularTab} setIsPopularTab={setIsPopularTab} up={up} setUp={setUp} />
          {!isPopularTab && <TradeAlgoDarkflowCards value={value} setValue={setValue} />}
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  subs: state.Socket.channels,
  popLoading: state.popularStocks.loading,
  popularStocks: state.popularStocks.popularStocks,
  darkflowTable: state.darkflowTable,
  darkflowCards: state.darkflow,
  currentSubs: state.dxFeedSocket.currentSubs,
  tabRisk: state.darkflowTable.risk,
  themeMode: state.themeMode.themeMode
});

const dispatchToProps = (dispatch) => ({
  listen: (symbols, options, disconnect, risk) => dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(stateToProps, dispatchToProps)(HomePage);
