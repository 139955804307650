import React, { useEffect, useMemo, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

function TradeMessage({ message, wsMessages, dbMessages, updateStatus, isAnalyst, themeMode }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [tradeStatus, setTradeStatus] = useState('')
    const messageId = message.messageId;
    const handleClose = (status) => {
        setAnchorEl(null);
        setTradeStatus(status)
        updateStatus(status, messageId)
    };
    useEffect(() => {
        const tradeStat = wsMessages.get(messageId) || dbMessages?.find((el) => messageId === Number(el?.message_id))?.trade_status || ''
        setTradeStatus(tradeStat)
    }, [JSON.stringify(dbMessages), JSON.stringify(wsMessages), messageId])
    return (
        <div className="sendbird-msg-hoc sendbird-msg--scroll-ref trade-ideas">
            <div className={`trade-${tradeStatus} new-item-${themeMode} sendbird-openchannel-user-message`}>
                <div className="sendbird-openchannel-user-message__left">
                    <div className="sendbird-context-menu" style={{ display: 'inline' }}>
                        <div className="sendbird-openchannel-user-message__left__avatar sendbird-avatar" style={{ height: '28px', width: '28px' }}>
                            <div className="sendbird-avatar-img sendbird-image-renderer" style={{ width: '100%', minWidth: '28px', maxWidth: '400px', height: '28px' }}>
                                <div className="sendbird-avatar-img--default" style={{ width: '28px', height: '28px' }}>
                                    <div className=" sendbird-icon sendbird-icon-user sendbird-icon-color--content" style={{ width: '16.1px', minWidth: '16.1px', height: '16.1px', minHeight: '16.1px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ height: '100%', width: '100%' }}><path className="icon-user_svg__fill" d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z" fill="#000" fillRule="evenodd"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="sendbird-openchannel-user-message__right">
                    <div className="sendbird-openchannel-user-message__right__top">
                        <span className={`sendbird-openchannel-user-message__right__top__sender-name trade-name sendbird-label sendbird-label--caption-2 `}>
                            Brian - Trade {tradeStatus.charAt(0).toUpperCase() + tradeStatus.slice(1)} -
                        </span>
                        <span className={`sendbird-openchannel-user-message__right__top__sent-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-3 trade-time-${tradeStatus}`}>{moment(message.createdAt).format("lll")}</span>
                    </div>
                    <div className="sendbird-openchannel-user-message__right__bottom">
                        <span className={`sendbird-openchannel-user-message__right__bottom__message sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1 trade-text-${tradeStatus}`}>
                            {message.message}
                        </span>
                    </div>
                </div>
                {
                    isAnalyst && (
                        <div style={{ position: 'relative', top: '10px' }} className="sendbird-openchannel-user-message__context-menu">
                            <IconButton
                                className="trade-dropdown sendbird-openchannel-user-message__context-menu--icon sendbird-iconbutton"
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleClose('open')}>Open</MenuItem>
                                <MenuItem onClick={() => handleClose('running')}>Running</MenuItem>
                                <MenuItem onClick={() => handleClose('closed')}>Closed</MenuItem>
                            </Menu>
                        </div>
                    )
                }
            </div>
        </div>
    )

}
export default TradeMessage
