import Zoom from "@mui/material/Zoom";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { darkflowLoad } from "../../appRedux/ducks/darkflow";
import "./TradeAlgo-Settings.scss";
import TradeAlgoCard from "./TradeAlgoCard";
import { CardSkeleton } from "../common/Skeletons/CardSkeleton";
import { makeStyles, Tooltip } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.text,
  }
}));

const TradeAlgoDarkflowCards = (props) => {
  let { value, setValue, themeMode, getDarkFlow, weekly } = props
  let { large, medium, small } = props.darkflowCards
  let { loading } = large
  const classes = useStyles();
  const [customLoading, setCustomLoading] = useState(false)
  let direction = useMemo(() => {
    return value === "0" ? "trending_up" : "trending_down";
  }, [value])
  useEffect(() => {
    getDarkFlow("");
    const interval = setInterval(() => {
      getDarkFlow("");
      //15 minutes
    }, 900000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [weekly]);
  const notActiveColor = useMemo(() => {
    return themeMode !== "dark" ? "#F1F5F9" : "#161718";
  }, [themeMode])
  useEffect(() => {
    setCustomLoading(true)
    let timeout = setTimeout(() => {
      setCustomLoading(false)
    }, 100)
    return () => clearTimeout(timeout)
  }, [value])
  const TooltipComponent = useMemo(() => {
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Tooltip
          TransitionComponent={Zoom}
          placement="bottom"
          title={"Trending Up"}
        >
          <svg
            onClick={() => setValue("0")}
            style={{ marginRight: "10px", cursor: "pointer" }}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="36"
              height="36"
              rx="12"
              fill={value === "0" ? "#5EEECD" : notActiveColor}
            />
            <path
              d="M9 23L15 17L19 21L26.405 13.595"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <path
              d="M26.4062 13.5945L27.0008 13"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M27 16V13H24"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </Tooltip>
        <Tooltip
          TransitionComponent={Zoom}
          placement="bottom"
          title={"Trending Down"}
        >
          <svg
            style={{ cursor: "pointer" }}
            onClick={() => setValue("1")}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="36"
              height="36"
              rx="12"
              fill={value === "1" ? "#5EEECD" : notActiveColor}
            />
            <path
              d="M12.9993 9L18.9993 15L14.9993 19L22.4043 26.405"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <path
              d="M22.4055 26.4053L23 26.9999"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M20 27L23 27L23 24"
              stroke="#555555"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
        </Tooltip>
      </span>
    )
  }, [value, notActiveColor, themeMode])
  return (
    <div className={""}>
      {
        (large[direction].length || loading) && (
          <div className="container-lg trade-algos-darkflow-activity-wrapper py-4">
            <span
              className={classes.text}
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontFamily: "poppins",
              }}
            >
              Top Large Cap Unusual Activity
            </span>
            {TooltipComponent}
          </div>
        )
      }
      <div className={"container-lg"}>
        <div className="row trade-algos-darkflow-cards">
          {loading || customLoading ? (
            // put more skeletons to give the illusion that the cards are all done loading when the large section completes
            [1, 2, 3, 4, 5, 6].map((item) => {
              return (
                <CardSkeleton key={item} width="440px" />
              );
            })
          ) : (
            <>
              {
                large[direction].map((card, index) => (
                  <TradeAlgoCard
                    key={index}
                    index={index}
                    allData={card}
                    up={value === "0"}
                    marketStatus={props.marketStatus}
                    {...card}
                  />
                ))
              }
              {
                !!medium[direction].length && (

                  <span key={128712} className="container-lg trade-algos-darkflow-activity-wrapper py-4">
                    <span
                      className={classes.text}
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        fontFamily: "poppins",
                      }}
                    >
                      Top Medium Cap Unusual Activity
                    </span>
                    {(!large[direction].length) && TooltipComponent}
                  </span>
                )
              }
              {
                medium.loading ? [1, 2, 3, 4, 5, 6].map((item, index) => <CardSkeleton key={index} width="440px" />) :
                  medium[direction].map((card, index) => (
                    <TradeAlgoCard
                      key={index}
                      index={index}
                      allData={card}
                      up={value === "0"}
                      marketStatus={props.marketStatus}
                      {...card}
                    />
                  ))
              }
              {
                !loading && !!small[direction].length && (
                  <span key={232323} className="container-lg trade-algos-darkflow-activity-wrapper py-4">
                    <span
                      className={classes.text}
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        fontFamily: "poppins",
                      }}
                    >
                      Top Small Cap Unusual Activity
                    </span>
                    {(!large[direction].length && !medium[direction].length) && TooltipComponent}
                  </span>
                )
              }
              {
                small.loading ? [1, 2, 3, 4, 5, 6].map((item, index) => <CardSkeleton key={index} width="440px" />) :
                  small[direction].map((card, index) => (
                    <TradeAlgoCard
                      key={index}
                      index={index}
                      allData={card}
                      up={value === "0"}
                      marketStatus={props.marketStatus}
                      {...card}
                    />
                  ))
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  darkflowCards: state.darkflow,
  marketStatus: state.marketStatus.marketStatus,
  themeMode: state.themeMode.themeMode,
  weekly: state.darkflowTable.weekly,
});

const dispatchToProps = (dispatch) => ({
  getDarkFlow: (risk) => dispatch(darkflowLoad(risk)),
});

export default connect(stateToProps, dispatchToProps)(TradeAlgoDarkflowCards);
