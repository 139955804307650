import React, { useState } from "react";
import "./SignUp.scss";
import logo from "./ta-logo-w-text.png"
import { Link } from "react-router-dom";
import { useMedia } from "react-media";
const mediaQuery = {
    isMobile: "screen and (max-width: 991px)",
};

const SignUp = () => {
    const { isMobile } = useMedia({
        queries: mediaQuery,
    });
    return (
        <div className="signup-wrapper">
            <img src={logo} className="ta-logo-text" />
            <div className="create-acc">
                Create an account
            </div>
            <div className="already-acc">
                Already have an account? <Link to="/signin"> <span className="logger-in">Log in</span> </Link>
            </div>
            <iframe frameBorder="0" scrolling="no" className={`signup-iframe ${isMobile && "h-1750"}`} src="https://checkout.tradealgo.com/OTesZx3zJo37D4kmR0rPPhV9TH7cjdFZkFh-GZWS1coUrjbt1c4KhafseNmOfXBewg==?style=v2" />
        </div>
    );
};

export default SignUp;