import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { swingTradesLoad } from "../../appRedux/ducks/swingTrades";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import { makeStyles } from "@material-ui/core";
import { Card, CardBody, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import CssPop from "../common/Tables/animations/CssPop";
import { Link } from "react-router-dom";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { abbreviate, decimal } from "../homePage/TradeAlgoCard";
import SparkLines from "../common/SparkLines";
import moment from "moment";
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../OptionScanner/option-scanner.scss";
import { useHistory, useParams } from "react-router-dom";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";


const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.contrastBackground} !important`,
    color: theme.palette.primary.text,

  },
  header: {
    margin: "0 10px 15px 10px",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  text2: {
    color: theme.palette.primary.text + " !important",
    fontSize: '1rem',
    lineHeight: '19px'
  },
}));

const SwingTradesContainer = ({ loading, listen, swingTrades = [], fetchSwingTrades, setSymbol, currentPrices, themeMode, noData }) => {
  const classes = useStyles();
  const [specialNoDataFlag, setFlag] = useState(false)
  const params = useParams()
  const all = params['0']?.split('/')
  const history = useHistory()
  const [page, setPage] = useState(Number(all[0]));
  useEffect(() => {
    if (page === 0 && noData) {
      setPage(1);
      setFlag(true);
    }
  }, [noData])
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP') {
        // Handle events
        const all = location.pathname.slice(1).split('/')
        setPage(Number(all[1]))
      }
    })
  }, [])
  useEffect(() => {
    if (Number(all[0]) !== page) {
      history.push(`/swing-trades/${page}`)
    }
  }, [page])
  const [sortModel, setSortModel] = useState([
    {
      field: "market_cap",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "ticker",
      headerName: "TICKER",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setSymbol({
                type: "",
                value: params.value,
              });
            }}
            style={{
              position: "relative",
              left: "8px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <Link
              className={classes.text}
              to={`/company/${params.value}?resolution=D`}
            >
              {params.value}
            </Link>
          </div>
        );
      },
    },

    {
      field: "last_price",
      headerName: "SHARE PRICE",
      flex: 0.9,
      renderCell: (params) => {
        const livePrice =
          (
            currentPrices.get(params.row.ticker)
            || params.value
          ).toFixed(2);
        return (
          <div
            className={classes.text}
            style={{
              position: "relative",
              left: "12px",
              fontWeight: 500,
              fontSize: "14px",
              display: "flex",
            }}
          >
            <CssPop comparator={`$${livePrice}`} />
          </div>
        );
      },
    },
    {
      field: "performancePercent",
      flex: 1,
      headerName: "52WK PERFORMANCE",
      renderCell: (params) => {

        return (
          <span
            style={{
              position: "relative",
              left: "25px",
              fontWeight: 600,
              color: params.value > 0 ? "green" : "red",
            }}
          >
            {decimal(params.value)}%
          </span>
        );
      },
    },
    {
      field: "performanceChange",
      flex: 0.7,
      headerName: "CHANGE",
      renderCell: (params) => {
        return (
          <span
            style={{
              position: "relative",
              left: "13px",
              fontWeight: 600,
              color: params.value > 0 ? "green" : "red",
            }}
          >
            {(params.value > 0 ? '+' : '') + decimal(params.value)}
          </span>
        );
      },
    },
    {
      field: "ats",
      headerName: "DARK POOL ACTIVITY",
      flex: 1.2,
      sortComparator: (v1, v2, param1, param2) => {
        const darkPoolActivity = ((param1.value?.current?.day_dollar_volume / param1?.value?.previous?.day_dollar_volume - 1) * 100).toFixed(2);
        const darkPoolActivity2 = ((param2.value?.current?.day_dollar_volume / param2?.value?.previous?.day_dollar_volume - 1) * 100).toFixed(2);
        return darkPoolActivity - darkPoolActivity2;
      },
      renderCell: (params) => {
        const darkPoolActivity = ((params.value?.current?.day_dollar_volume / params?.value?.previous?.day_dollar_volume - 1) * 100).toFixed(2);
        const livePrice = (
          currentPrices.get(params.row.ticker) || params.row.last_price
        ).toFixed(2);
        const performance =
          Math.round((livePrice / params.row.sparkline[0] - 1) * 1000) / 10;
        return (
          <div
            style={{
              height: "27px",
              width: "75px",
              background: performance > 0
                ? "rgba(13, 173, 96, 0.05)"
                : themeMode === "dark" ? "rgb(46 4 4)" : "rgb(255, 238, 238",
              color: performance > 0 ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6)",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "25px",
              position: "relative",
              left: "25px",
              paddingTop: '4px'
            }}
          >
            {darkPoolActivity}%
          </div>
        );
      },
    },
    {
      field: "sparkline",
      headerName: "SPARK LINE",
      flex: 1,
      renderCell: (params) => {
        const livePrice = (
          currentPrices.get(params.row.ticker) || params.row.last_price
        ).toFixed(2);
        const dummyOptions = {
          colors: [
            params.value[0] <= params.row.last_price ? "#5EEECD" : "#F32228",
          ],
          chart: {
            type: "area",
            height: 45,
            sparkline: {
              enabled: true,
            },
            width: 150,
          },
          tooltip: {
            theme: "dark",
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                // eslint-disable-next-line no-unused-vars
                formatter(seriesName) {
                  return "   $";
                },
              },
            },
          },
          stroke: {
            curve: "smooth",
            width: 1.5,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 0.1,
              opacityFrom: 0.7,
              opacityTo: 0,
              stops: [0, 95],
            },
          },
        };
        return (
          <SparkLines
            style={{ lineHeight: "normal !important" }}
            series={[
              {
                data: [...params.value, Number(livePrice)],
              },
            ]}
            options={dummyOptions}
            type={dummyOptions.chart.type}
            height={dummyOptions.chart.height}
            width={dummyOptions.chart.width}
          />
        );
      },
    },
    {
      field: "yearly_share_volume",
      headerName: "52WK VOLUME",
      flex: 0.9,
      renderCell: (params) => {
        return (
          <div
            className={classes.text}
            style={{
              position: "relative", left: '25px',
              textAlign: "center",
              fontSize: "14px",
            }}>
            {abbreviate(params.value)}
          </div>
        )
      }
    },
    {
      field: "market_cap",
      headerName: "MARKET CAP",
      flex: 0.9,
      renderCell: (params) => {
        return (
          <div
            className={classes.text}
            style={{
              position: "relative",
              left: "17px",
              fontSize: "15px",
            }}
          >
            {params.value ? `$${abbreviate(params.value)}` : "-"}
          </div>
        );
      },
      sortComparator: (v1, v2, param1, param2) => Number(v1) - Number(v2)
    },

    {
      field: "date_added",
      headerName: "DATE ADDED",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={classes.text}
            style={{
              position: "relative",
              textAlign: "center",
              left: "7px",
              fontSize: "15px",
            }}
          >
            {moment.tz(params.value.split('T')[0], "America/New_York").format("MMM DD, YYYY")}
          </div>
        );
      },
    },
  ]
  useEffect(() => {
    fetchSwingTrades(page);
  }, [page])
  const allTickers = useMemo(() => {
    const tickers = !!swingTrades ? swingTrades?.map((item) => isInternalWS ? `equities_candle_dx;${item?.ticker}` : item.ticker) : [];
    return tickers
  }, [loading])
  useEffect(() => {
    if (!!allTickers.length) {
      if (isInternalWS) {
        listen([...new Set(allTickers), 'CHAT', "TRADE_MESSAGE"])
      } else {
        listen([...new Set(allTickers)], false, false)
      }
    }
    return () => listen([], false, true);

  }, [allTickers])
  // 2022-01-23
  const formattedDateStrings = useMemo(() => {
    let past, present, future, pastDisabled = false, futureDisabled = true
    past = moment().subtract(page + 1, 'weeks').startOf('week').add(1, 'day').format('MMM DD, YYYY') + ' - ' + moment().subtract(page + 1, 'weeks').endOf('week').add(1, "day").format('MMM DD, YYYY')
    present = moment().subtract(page, 'weeks').startOf('week').add(1, 'day').format('MMM DD, YYYY') + ' - ' + moment().subtract(page, 'weeks').endOf('week').add(1, 'day').format('MMM DD, YYYY')
    future = moment().subtract(page - 1, 'weeks').startOf('week').add(1, 'day').format('MMM DD, YYYY') + ' - ' + moment().subtract(page - 1, 'weeks').endOf('week').add(1, 'day').format('MMM DD, YYYY')
    if (page === 0) {
      future = ''
    } else {
      futureDisabled = false
    }
    if (past === 'Jan 24, 2022 - Jan 30, 2022') pastDisabled = true
    if (page === 1 && specialNoDataFlag) futureDisabled = true
    return {
      past,
      present,
      future,
      pastDisabled,
      futureDisabled
    }
  }, [swingTrades, page])
  let rows = useMemo(() => {
    return !!swingTrades ? swingTrades.map((item) => {
      const livePrice = (
        currentPrices.get(item.ticker) || item.last_price
      ).toFixed(2);
      const performancePercent = Math.round((livePrice / item.sparkline[0] - 1) * 1000) / 10;
      const performanceChange = livePrice - item.sparkline[0];
      return {
        ...item,
        market_cap: Number(item.market_cap),
        performancePercent,
        performanceChange
      }
    }).filter((item) => item.performancePercent >= 0) : [];
  }, [swingTrades, loading, currentPrices])
  return (
    <div style={{ textAlign: 'center' }}>
      <Helmet>
        <title>SwingTrade AI | TradeAlgo</title>
      </Helmet>
      <br />
      <Col md={12} lg={12}>
        <Card className={classes.background}>
          <CardBody>
            {/* <span className={classes.text2}>Our algorithms have identified these companies with great momentum and great fundamentals for potential swing trades. We recommend holding these assets for a minimum of 1 month and ideally between 6-12 months for best results. New picks are added every week as they are identified.</span> */}
            <div
              className={clsx(classes.background, "row")}
              style={{ borderRadius: 16 }}
            >
              <div style={{ textAlign: "center" }} className="col-4 text-left">
                {!formattedDateStrings.pastDisabled && (
                  <>
                    <IconButton
                      disabled={formattedDateStrings.pastDisabled}
                      onClick={() => setPage(page + 1)}
                    >
                      <ArrowBackIosIcon style={{ fill: themeMode === "dark" ? "white" : 'black' }} />
                    </IconButton>
                    <span className={clsx(classes.text, "up-strikes")}>
                      {formattedDateStrings.past}
                    </span>
                  </>
                )}
              </div>
              <span style={{
                left: ''
                //  isMobile ? '-30px' : '' 
              }} className={clsx(classes.text, "col-4 current-strike")}>
                {" "}
                {formattedDateStrings.present}
              </span>
              <div style={{ textAlign: "center" }} className="col-4 text-right">
                {!formattedDateStrings.futureDisabled && (
                  <>
                    <span className={clsx(classes.text, "up-strikes")}>
                      {formattedDateStrings.future}
                    </span>
                    <IconButton
                      disabled={formattedDateStrings.futureDisabled}
                      onClick={() => setPage(page - 1)}
                    >
                      <ArrowForwardIosIcon style={{ fill: themeMode === "dark" ? "white" : 'black' }} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <TradeAlgoTable rows={rows} columns={columns} loading={loading} sortModel={sortModel} setSortModel={setSortModel} />

          </CardBody>
        </Card>
        <br />
        <br />
        <br />
      </Col>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  swingTrades: state.swingTrades.swingTrades,
  loading: state.swingTrades.loading,
  // currentPrices: state.dxFeedSocket.socketData,
  currentPrices: isInternalWS ? state.EquitiesSocket.currentPrices : state.dxFeedSocket.socketData,
  noData: state.swingTrades.noData,
});
const dispatchToProps = (dispatch) => ({
  fetchSwingTrades: (page) => dispatch(swingTradesLoad(page)),
  // listen: (channels) => dispatch(equtiesSocketListen(channels)),
  listen: (symbols, options, disconnect) => dispatch(isInternalWS ? equtiesSocketListen(symbols) : dxFeedSocketListen(symbols, options, disconnect)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});

export default connect(stateToProps, dispatchToProps)(SwingTradesContainer);
