/* eslint-disable */
import { Helmet } from 'react-helmet';
import moment from "moment";
import "moment-duration-format";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { makeStyles, Tooltip } from "@material-ui/core";
import Zoom from "@mui/material/Zoom";
import { useHistory } from "react-router-dom";
import StreamSearch from "./StreamSearch";
import LiveSessionsTabs from "../common/Tabs/LiveSessionsTabs";
import { metaData } from "./videoData/videoMetaData.js"
export const widthRegex = /width="(.*?)"/;
export const heightRegex = /height="(.*?)"/;
import "./TutorialsPastVideos.scss";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },

}));
function Tutorials() {
  const classes = useStyles()
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const video = params.get("video");
  const [currentVideo, setCurrentVideo] = useState({
    live: false,
    url: '<iframe src="https://player.vimeo.com/video/796024965?h=b712ac2136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442" width="100%" height="600px" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="TradeAlgo Platform Walkthrough"></iframe>',
    date: "February 05, 2023 2:59PM",
    description:
      "This video is a walkthrough tutorial that covers all the features and functions of the TradeAlgo platform including our state of the art dark pool alert algorithm.",
    duration: "8m",
    link: "https://vimeo.com/666843737",
  });
  const [tab, setTab] = useState("1");
  const embed = useMemo(() => {
    return metaData;
  }, []);

  function videoSetter(el, addToHistory = false) {
    setCurrentVideo({
      live: false,
      url: el.embed.html
        .replace(widthRegex, 'width="100%"')
        .replace(heightRegex, 'height="600px"'),
      date: moment(el.created_time).format("LLL"),
      description: el.description,
      duration: moment
        .duration(el.duration, "seconds")
        .format("h [hrs], m [min]"),
      link: el.link,
    });
    if (addToHistory) {
      history.push(
        "/tutorials?video=" +
        el.uri.split("/")[el.uri.split("/").length - 1]
      );
    }
  }
  useEffect(() => {
    if (video) {
      let el = embed.find((el) => el?.uri?.includes(video));
      if (el) {
        videoSetter(el);
      }
    }
  }, [embed]);
  return (
    <div className="container-xl-live">
      <Helmet>
        <title>{currentVideo.live ? "Livestream" : "Tutorials"} | TradeAlgo</title>
      </Helmet>
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div
          style={{ width: "100%" }}
          className="col-lg-12 col-xl-12 col-md-12 col-sm-12"
        >
          {currentVideo.live ? (
            <div className="row" style={{ marginBottom: "20px", marginLeft: 0, marginRight: 0 }}>
              <iframe
                className="col-xl-9 col-lg-9 col-md-8 col-sm-12"
                src="https://vimeo.com/event/1439418/embed"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{ height: "700px", width: "100%", borderRadius: "25px" }}
              ></iframe>
              <iframe
                src="https://vimeo.com/event/1439418/chat/"
                style={{ width: "100%", height: "700px" }}
                className="col-xl-3 col-lg-3 col-md-4 col-sm-12"
                frameBorder="0"
              ></iframe>
            </div>
          ) : (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: currentVideo.url,
                }}
              />
              <div style={{ position: "relative", top: "5px" }}>
                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <span
                    className="col-12"
                    style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
                  >
                    {currentVideo.duration} | {currentVideo.date} |{" "}
                    {tab === "1" ? "Tutorial" : ""}
                  </span>
                </div>
                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                  <span className="col-12" style={{ paddingTop: "10px" }}>
                    {currentVideo.description}
                  </span>
                </div>
              </div>
              <br />
              <div
                style={{
                  width: "99%",
                  border: "1px solid #F1F1F1",
                  marginLeft: "24px",
                }}
              ></div>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-12">
          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-xs-6">
              <LiveSessionsTabs
                tutorials={true}
                style={{ position: "relative", left: "-10px" }}
                activeTab={tab}
                setActiveTab={setTab}
                setCurrentVideo={setCurrentVideo}
                currentVideo={currentVideo}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                left: "14px",
                top: "4px",
              }}
              className="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-xs-6"
            >
              <StreamSearch
                metaData={metaData}
                setCurrentVideo={setCurrentVideo}
                history={history}
              />
            </div>
          </div>

          <div className='tutorials-past-videos-page'>
            {
              embed.map((el, index) => (
                <div
                  key={index}
                  className={'tutorial-past-video'}
                  onClick={() => {
                    videoSetter(el, true);
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="right"
                    title={el.description}
                  >
                    <div className={classes.background + ' tutorial-past-video-inner-content'}>

                      <div>
                        <img
                          className="tutorial-past-video-thumbnail"
                          src={
                            el.pictures.sizes[3].link_with_play_button
                          }
                          alt="video-thumbnail"
                        />
                      </div>

                      <div className="tutorial-past-video-title">
                        {el.name.split("-")[0]}
                      </div>

                      <div className='tutorial-past-video-length'>
                        {moment
                          .duration(el.duration, "seconds")
                          .format("h[h]:m[m]:s[s]")
                          .replace(/\:0s$/, "")
                          .replace(/\:0m$/, "")
                          .replace(":", " ")
                        }
                      </div>

                    </div>
                  </Tooltip>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}



export default Tutorials;
