import React from "react";
import { connect } from "react-redux";
import NumAbbr from "number-abbreviate";
import ProgressBar from "./ProgressBar";
import CircularProgress from "@mui/material/CircularProgress";

const abbreviate = (x) => new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, 2);

const Summary = (props) => {
  const summary = props?.summary || "";
  const loading = props.loading;
  const themeMode = props.themeMode;
  const calcFlowSentimentLevel = (sentiment) => {
    // A) 0 ~ 0.48 = Bearish
    // B) 0.48 - 0.52 = Neutral
    // C) 0.52 - 1.0 = Bullish
    sentiment = sentiment ? sentiment : 0;

    if (sentiment >= 0 && sentiment < 0.48) {
      return "Bearish";
    }

    if (sentiment >= 0.48 && sentiment <= 0.52) {
      return "Neutral";
    }

    if (sentiment > 0.52 && sentiment <= 1) {
      return "Bullish";
    }
  };

  const calculateStrokeColor = (sentiment) => {
    // A) 0 ~ 0.48 = Bearish
    // B) 0.48 - 0.52 = Neutral
    // C) 0.52 - 1.0 = Bullish

    sentiment = sentiment ? sentiment : 0;

    if (sentiment >= 0 && sentiment < 0.48) {
      return "#ef5656";
    }

    if (sentiment >= 0.48 && sentiment <= 0.52) {
      return "#0167ff";
    }

    if (sentiment > 0.52 && sentiment <= 1) {
      return "#5EEECD";
    }
  };
  const totalPremium =
    parseInt(summary?.put_total_prem) + parseInt(summary?.call_total_prem);

  const putPremiumRatio = parseInt(summary.put_total_prem) / totalPremium;
  const callPremiumRatio = parseInt(summary.call_total_prem) / totalPremium;
  return (
    <>
      {
        <div className="summary summary-blocks">
          <div className={"summary-block"}>
            {loading ? (
              <CircularProgress
                style={{ color: "rgb(1, 103, 255)" }}
                disableShrink={true}
                thickness={1}
                size={155}
              />
            ) : (
              <ProgressBar
                themeMode={themeMode}
                percentage={callPremiumRatio ? callPremiumRatio * 100 : 0}
                bigTitle={calcFlowSentimentLevel(callPremiumRatio)}
                value={"Flow Sentiment"}
                color={calculateStrokeColor(callPremiumRatio)}
              />
            )}
          </div>

          <div className={"summary-block"}>
            {loading ? (
              <CircularProgress
                style={{ color: "rgb(133, 0, 237)" }}
                disableShrink={true}
                thickness={1}
                size={155}
              />
            ) : (
              <ProgressBar
                percentage={
                  summary.put_to_call === -1 || summary.put_to_call === Infinity
                    ? 100
                    : summary.put_to_call
                    ? (summary.put_to_call * 100).toFixed(1) 
                    : 0
                  
                }
                value={
                  summary.put_to_call === -1 || summary.put_to_call === Infinity
                    ? 
                  1900
                    : summary.put_to_call
                    ? summary.put_to_call.toFixed(3) 
                    : 0
                }
                title={"Put to call flow"}
                color={"#8500ED"}
                themeMode={themeMode}
              />
            )}
          </div>

          <div className={"summary-block"}>
            {loading ? (
              <CircularProgress
                style={{ color: "#0DAD60" }}
                disableShrink={true}
                thickness={1}
                size={155}
              />
            ) : (
              <ProgressBar
                percentage={
                  callPremiumRatio ? (callPremiumRatio * 100).toFixed(1) : 0
                }
                value={`$${
                  summary.call_total_prem
                    ? abbreviate(summary.call_total_prem, 2)
                    : 0
                }`}
                title={"Call Premium"}
                color={"#0DAD60"}
                themeMode={themeMode}

              />
            )}
          </div>

          <div className={"summary-block"}>
            {loading ? (
              <CircularProgress
                style={{ color: "#D4472F" }}
                disableShrink={true}
                thickness={1}
                size={155}
              />
            ) : (
              <ProgressBar
                percentage={
                  putPremiumRatio ? (putPremiumRatio * 100).toFixed(1) : 0
                }
                value={`$${
                  summary.put_total_prem
                    ? abbreviate(summary.put_total_prem, 2)
                    : 0
                }`}
                title={"Put Premium"}
                color={"#D4472F"}
                themeMode={themeMode}

              />
            )}
          </div>
        </div>
      }
    </>
  );
};

const stateToProps = (state) => ({
  summary: state.newLiveOptions.summary,
  loading: state.newLiveOptions.loading,
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(Summary);
