import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMemo } from 'react';
import { countries } from './countries';

function StateSelect({ value, setValue, currentStates }) {

    return (
        <Autocomplete
            value={value || ""}
            sx={{ width: "363px" }}
            options={currentStates || []}
            autoHighlight
            onChange={(e, v) => setValue(v || "")}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}



export default StateSelect;