import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  atsEquitiesLoad,
  atsEquitiesSummaryLoad,
} from "../../appRedux/ducks/atsEquities";
import "./UnusualActivity.scss";
import { numberWithCommas } from "../utilities";
import NumAbbr from "number-abbreviate";
import InfiniteScroll from "react-infinite-scroll-component";
import UnusualActivityTable from "../common/Tables/UnusualActivityTable";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { RangePicker } from "../Earnings/components/calendar.tsx";
const today = new Date();
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.tableBackground,
  },
  text: {
    color: theme.palette.primary.text,
  }
}));
const UnusualActivity = ({ ticker, loadData, loadSummary, ats, summary }) => {
  const [hasMore, setHasMore] = useState(true);
  const [showableRows, setShowableRows] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [rangeString, setRangeString] = useState('')
  const [sortModel, setSortModel] = useState([{
    field: 'openTime',
    sort: 'desc',
  },])
  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });
  const getMinMaxDate = (date) => {
    if (!date) {
      return date
    }
    const startTime = new Date(date).getTime()
    return [new Date(startTime - 7 * 24 * 60 * 60 * 1000), new Date(startTime + 7 * 24 * 60 * 60 * 1000)]
  }
  const [minDate, maxDate] = useMemo(() => getMinMaxDate(dateRange.startDate), [dateRange])
  useEffect(() => {
    if (
      moment().format("YYYY-MM-DD") ===
      moment(dateRange.startDate).format("YYYY-MM-DD")
    ) {
      setRangeString("");
    } else {
      setRangeString(
        `${moment
          .tz(dateRange.startDate, "America/New_York")
          .format("YYYY-MM-DD")},${moment
            .tz(dateRange.endDate, "America/New_York")
            .format("YYYY-MM-DD")}`
      );
    }
  }, [JSON.stringify(dateRange)]);
  const dateRangeString = useMemo(() => {
    const todayMoment = moment().format("YYYY-MM-DD");
    if (
      moment(dateRange.startDate).format("YYYY-MM-DD") === todayMoment &&
      moment(dateRange.endDate).format("YYYY-MM-DD") === todayMoment
    ) {
      return "Today's Activity";
    } else {
      return `${moment(dateRange.startDate).format("MMM Do")} - ${moment(
        dateRange.endDate
      ).format("MMM Do")} Activity`;
    }
  }, [dateRange]);
  const itemsPerPage = 100;
  const classes = useStyles();
  let summaryInterval = useRef();

  useEffect(() => {
    loadData(ticker, rangeString) 
    !!rangeString ? setSortModel([{
      field: 'date',
      sort: 'asc',
    },]) : setSortModel([{
      field: 'openTime',
      sort: 'desc',
    },]) 
  },[ticker, rangeString])

  useEffect(() => {
    // loadData(ticker);
    loadSummary(ticker);
    summaryInterval.current = setInterval(() => {
      !rangeString && loadData(ticker);
      loadSummary(ticker);
    }, 600000);
    return () => {
      clearInterval(summaryInterval.current);
    };
    // eslint-disable-next-line
  }, [ticker]);

  const prepareShowableRows = () => {
    setShowableRows(ats?.table ? ats?.table?.slice(0, itemsPerPage) : []);
    setPage(1);
    setHasMore(ats?.table?.length > itemsPerPage);
  };
  const rows = useMemo(() => {
    return !!(ats?.table || []).length ? ats?.table : [];
    // eslint-disable-next-line
  }, [ats?.table, ticker]);
  useEffect(() => {
    const field = sortModel[0].field
    const dir = sortModel[0].sort
    let sorted = []
    if (field === 'totalPrice' || field === 'totalTrades' || field === 'totalVolume') {
      sorted = ats.table.sort((a, b) => dir === 'asc' ? a[field] - b[field] : b[field] - a[field])
    } else if (field === 'avgPrice') {
      sorted = ats.table.sort((a, b) => dir === 'asc' ? Number(a.totalPrice) / Number(a.totalTrades) - Number(b.totalPrice) / Number(b.totalTrades) : Number(b.totalPrice) / Number(b.totalTrades) - Number(a.totalPrice) / Number(a.totalTrades))
    } else {
      if (field !== 'ticker') {
        // "2022-07-08T07:00:00.000Z"
        if (!!rangeString.length && !!ats.table.length && !!ats.table[0]?.date) {
          //if the date col is present
          sorted = ats.table.sort((a, b) => {
            const dateA = a.date.replace('T07:00:00.000Z', ` ${a.openTime}`);
            const dateB = a.date.replace('T07:00:00.000Z', ` ${b.openTime}`);
            return dir === 'asc' ? moment(dateA).diff(moment(dateB)) : moment(dateB).diff(moment(dateA))
          })
        } else {
          // just lastTime/openTime
          const today = moment().format('YYYY-MM-DD')
          sorted = ats.table.sort((a, b) => {
            return dir === 'asc' ? moment(today + ' ' +  a[field]).diff(moment( today + ' ' + b[field])) : moment(today + ' ' +  b[field]).diff(moment(today + ' ' + a[field]))
          })
        }
      }
    }
    setShowableRows(sorted.slice(0,itemsPerPage))
  }, [sortModel, rangeString])
  const calcAvgPrice = (row) => {
    const totalPrice = row.totalPrice;
    const totalTrades = row.totalTrades;

    return `$${numberWithCommas(
      parseFloat(totalPrice / totalTrades).toFixed(2)
    )}`;
  };

  const calcTotalPrice = (row) => {
    const totalPrice = row.totalPrice;
    const totalTrades = row.totalTrades;
    const totalVolume = row.totalVolume;
    const avg = parseFloat(totalPrice / totalTrades);
    return `$${numberWithCommas(
      Math.trunc(parseFloat(avg * totalVolume).toFixed(2))
    )}`;
  };

  const getPercentage = (item) => {
    const biggestNumber = Math.max.apply(
      Math,
      rows.map(function (o) {
        return o.totalVolume;
      })
    );
    const currentNumber = item.totalVolume;
    const perf = ((currentNumber / biggestNumber) * 100).toFixed(2);

    return { width: `${perf}%` };
  };

  const abbreviate = (x) => new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, 2);

  const calcPercentage = (price, max) => {
    const percentage = ((price / max) * 100).toFixed(2);
    return { width: `${percentage}%` };
  };

  const fetchMoreData = () => {
    const newItems = ats?.table?.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );
    let currentRows = [...showableRows];
    let newPageNumber = page + 1;
    currentRows = currentRows.concat(newItems);

    setTimeout(() => {
      setShowableRows(currentRows);
      setHasMore(currentRows.length <= ats?.table?.length);
      setPage(newPageNumber);
    }, 500);
  };

  useEffect(() => {
    prepareShowableRows();
  }, [ats?.table]);
  return (
    <div className="col-md-12 col-lg-12">
      <InfiniteScroll
        dataLength={showableRows.length}
        next={fetchMoreData}
        hasMore={hasMore}
      >
        <div className={clsx(classes.card, "unusual-activity-widget")}>
          <div className={"unusual-activity-widget-header"}>
            <div className={clsx(classes.text, "unusual-activity-widget-ticker")}>
              {ticker} - Unusual Activity
              <div
                style={{ zIndex: show ? 2 : 1 }}
                className={"live-option-date-filter"}
              >
                <span className={clsx(classes.text, "dates")}>
                  {dateRangeString}
                  <Tooltip title="Date Range Selector - Maximum Range of 7 days">
                  <IconButton
                    className="calendar-button"
                    onClick={() => setShow(!show)}
                    >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      >
                      <path
                        d="M19 4.98389H5C3.89543 4.98389 3 5.87932 3 6.98389V20.9839C3 22.0885 3.89543 22.9839 5 22.9839H19C20.1046 22.9839 21 22.0885 21 20.9839V6.98389C21 5.87932 20.1046 4.98389 19 4.98389Z"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                      <path
                        d="M3 10.9839H21"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                      <path
                        d="M16 2.98389V6.98389"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                      <path
                        d="M8 2.98389V6.98389"
                        stroke="#555555"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                    </svg>
                  </IconButton>
                  </Tooltip>
                  <>
                  {show && (
                      <RangePicker
                      style={{ gridArea: "1 / 1 / 2 / 2" }}
                      selectedRange={dateRange}
                      // minDate={minDate}
                      maxDate={maxDate}
                      onRangeSelect={(r) => {
                        if (moment(r.endDate).diff(
                          moment(r.startDate),
                            "days"
                          ) > 7) {
                            let time = r.startDate.getTime()
                            setDateRange({
                              startDate: r.startDate,
                              endDate: new Date(time + 7 * 24 * 60 * 60 * 1000),
                              key: "selection",
                            })
                          } else {
                            setDateRange(r);
                          }
                          if (
                            moment(r.endDate).diff(
                              moment(r.startDate),
                              "days"
                            ) >= 1
                          ) {
                            setTimeout(() => {
                              setShow(!show);
                            }, 500);
                          }
                        }}
                      />
                    )}
                  </>
                </span>
              </div>
              {!summary?.current?.day_dollar_volume && (
                <>
                  <br />
                  <span
                    style={{ position: "relative", top: "20px" }}
                    className={"progress-small-caption"}
                  >
                    No data available for this ticker
                  </span>
                </>
              )}
            </div>
            {!!summary?.current?.day_dollar_volume && (
              <div className={"unusual-activity-widget-progress row"}>
                <div className={"today-progress"}>
                  <span className={"progress-small-caption"}>Today</span>
                  <span className={"progress-small-percentage"}>
                    <span
                      className={"today-progress-indicator"}
                      style={calcPercentage(
                        summary?.current?.day_dollar_volume,
                        Math.max(
                          summary?.current?.day_dollar_volume,
                          summary?.previous?.day_dollar_volume
                        )
                      )}
                    />
                  </span>
                  <span className={"progress-small-caption"}>
                    {abbreviate(summary?.current?.day_dollar_volume)}
                  </span>
                </div>
                <div className={"today-progress"}>
                  <span className={"progress-small-caption"}>7d Avg</span>
                  <span className={"progress-small-percentage"}>
                    <span
                      className={"avg-progress-indicator"}
                      style={calcPercentage(
                        summary?.previous?.day_dollar_volume,
                        Math.max(
                          summary?.current?.day_dollar_volume,
                          summary?.previous?.day_dollar_volume
                        )
                      )}
                    />
                  </span>
                  <span className={"progress-small-caption"}>
                    {abbreviate(summary?.previous?.day_dollar_volume)}
                  </span>
                </div>
              </div>
            )}
          </div>

          {!!summary?.current?.day_dollar_volume && (
            <div className={"unusual-activity-widget-table"}>
              <div className={"unusual-activity-widget-table-header"}>
                <UnusualActivityTable
                  calcAvgPrice={calcAvgPrice}
                  getPercentage={getPercentage}
                  calcTotalPrice={calcTotalPrice}
                  data={showableRows}
                  rangeEnabled={!!rangeString}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                />
              </div>
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};
const dispatchToProps = (dispatch) => {
  return {
    loadData: (ticker, timeframe) => dispatch(atsEquitiesLoad(ticker,timeframe)),
    loadSummary: (ticker) => dispatch(atsEquitiesSummaryLoad(ticker)),
  };
};

const stateToProps = (store) => ({
  ats: store.ats,
  summary: store.ats.summary,
  themeMode: store.themeMode.themeMode,
});

export default connect(stateToProps, dispatchToProps)(UnusualActivity);
