import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const START_ENGINE_POLL_LOAD = "SEP/LOAD/TOP";
export const START_ENGINE_POLL_LOAD_SUCCESS = "SEP/LOAD/TOP/SUCCESS";
export const START_ENGINE_POLL_LOAD_FAILURE = "SEP/LOAD/TOP/FAILURE";

// Action Creators
export const startEnginePollLoad = (pollResult) => ({
  type: START_ENGINE_POLL_LOAD,
  pollResult,
});
export const startEnginePollLoadSuccess = (data) => ({
  type: START_ENGINE_POLL_LOAD_SUCCESS,
  data,
});
export const startEnginePollLoadFailure = (error) => ({
  type: START_ENGINE_POLL_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchStartEnginePoll(action) {
  try {
    const { pollResult } = action;
    const response = yield axios.get(`${apiURL}/user/poll_result?result=${pollResult}&poll=startengine_202208`,{ withCredentials: true });
    yield put(startEnginePollLoadSuccess(response.data || []));
  } catch (error) {
    yield put(startEnginePollLoadFailure(error));
  }
}

function* listenStartEnginePollLoad() {
  yield takeEvery(START_ENGINE_POLL_LOAD, fetchStartEnginePoll);
}

// Root Saga
export function* saga() {
  yield all([fork(listenStartEnginePollLoad)]);
}

const INIT_STATE = {
  startEnginePoll: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_ENGINE_POLL_LOAD_SUCCESS:
      return {
        ...state,
        startEnginePoll: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
