import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { useState } from "react";
import { Button } from "@mui/material";
import { useMedia } from "react-media";
import CountrySelect from "./CountrySelect";
import { countries } from "./countries";
import { useMemo } from "react";
import StateSelect from "./StateSelect";
import { useAuth } from "../../contexts/Auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { auth } from "../../firebase/firebase";
import { useSnackbar } from "notistack";
import { updateProfileLoad } from "../../appRedux/ducks/updateProfile";
import { connect } from "react-redux";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
function MyProfile({ updateProfileSettings }) {
  const { enqueueSnackbar } = useSnackbar();
  const { userData, fetchUser } = useAuth();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const handlePasswordUpdate = () => {
    const user = auth.currentUser;
    user
      .updatePassword(password)
      .then(() => {
        enqueueSnackbar("Password updated successfully!", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
    fetchUser();
  };
  const [name, setName] = useState(userData?.name || "");
  const [email, setEmail] = useState(userData?.email || "");
  const [showPass, setShowPass] = useState(false);
  const [country, setCountry] = useState(userData?.country || "");
  const [currState, setCurrState] = useState(userData?.state || "");
  const currentStates = useMemo(() => {
    return countries.find((i) => i.country === country)?.states;
  }, [countries, country]);
  const [password, setPassword] = useState("");
  const handleClickShowPassword = () => setShowPass((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function updateProfile() {
    const profileSettings = {
      name,
      email,
      state: currState,
      country,
    };
    updateProfileSettings(profileSettings);
    setTimeout(() => {
      fetchUser();
      enqueueSnackbar("User Profile updated successfully!", {
        variant: "success",
      });
    }, 800);
  }
  const disabledUpdate = useMemo(() => {
    const sameName = name === userData?.name || "";
    const sameCountry = country === userData?.country || "";
    const sameState = currState === userData?.state || "";
    return !!(
      !currState.length ||
      !country.length ||
      !name.length ||
      (sameName && sameCountry && sameState)
    );
  }, [name,  userData, currState, country]);
  return (
    <div className="user-profile-inner">
      <div className="info-text">
        The information will not be publicly displayed
      </div>
      <div className="password-wrapper">
        <div className="password-input">
          <FormHelperText className="outlined-weight-helper-text">
            Password
          </FormHelperText>
          <OutlinedInput
            autoComplete="new-password"
            type={showPass ? "text" : "password"}
            value={password}
            onChange={(e, v) => {
              console.log("setting password...", e.target.value);
              setPassword(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              style: {
                height: "6px",
                border: "1px solid #E0E4EC !important",
                borderRadius: "8px",
              },
            }}
          />
        </div>
        <Tooltip
          placement="top"
          title="Password must be a minimum of 8 characters."
        >
          <div
            className={`show-password-button ${
              password?.length < 8 && "disabled"
            }`}
          >
            <Button
              onClick={handlePasswordUpdate}
              disabled={password?.length < 8}
              className="password-button"
              variant="contained"
            >
              Update Password
            </Button>
          </div>
        </Tooltip>
      </div>
      <div className="name-field">
        <FormHelperText className="outlined-weight-helper-text">
          Name
        </FormHelperText>
        <OutlinedInput
          type="text"
          value={name}
          onChange={(e, v) => setName(e.target.value)}
          inputProps={{
            style: {
              width: isMobile ? "283px" : "380px",
              height: "6px",
              border: "1px solid #E0E4EC !important",
              borderRadius: "8px",
            },
          }}
        />
      </div>
      {/* <div className="email-field">
        <FormHelperText className="outlined-weight-helper-text">
          Email
        </FormHelperText>
        <OutlinedInput
          type="email"
          value={email}
          onChange={(e, v) => setEmail(e.target.value)}
          inputProps={{
            style: {
              width: isMobile ? "283px" : "380px",
              height: "6px",
              border: "1px solid #E0E4EC !important",
              borderRadius: "8px",
            },
          }}
        />
      </div> */}
      <div className={`country-field ${isMobile && "is-mobile"}`}>
        <FormHelperText className="outlined-weight-helper-text">
          Country
        </FormHelperText>
        <CountrySelect
          setCurrState={setCurrState}
          value={country}
          setValue={setCountry}
        />
      </div>
      <div className={`state-field ${isMobile && "is-mobile"}`}>
        <FormHelperText className="outlined-weight-helper-text">
          State
        </FormHelperText>
        <StateSelect
          value={currState}
          setValue={setCurrState}
          currentStates={currentStates}
        />
      </div>
      <div className={`update-profile ${disabledUpdate && "disabled"}`}>
        <Button
          onClick={updateProfile}
          disabled={disabledUpdate}
          className="password-button"
          variant="contained"
        >
          Update Profile
        </Button>
      </div>
    </div>
  );
}

const dispatchToProps = (dispatch) => ({
  updateProfileSettings: (profileSettings) =>
    dispatch(updateProfileLoad(profileSettings)),
});

export default connect(null, dispatchToProps)(MyProfile);
