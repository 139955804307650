/* eslint-disable */
import { Helmet } from "react-helmet";
import React, { useEffect, useMemo } from "react";
import "./MovementContainer.scss";
import { connect } from "react-redux";
import MovementBlocks from "./MovementBlocks";
import { darkflowLoad } from "../../appRedux/ducks/darkflow";
import { CardSkeleton } from "../common/Skeletons/CardSkeleton";

function MovementContainer({ darkflowCards, getDarkFlow, loading }) {
  let { large, medium, small } = darkflowCards

  useEffect(() => {
    getDarkFlow("");
  }, []);
  const flows = useMemo(() => {
    let largeBull = [];
    let largeBear = [];

    let medBull = [];
    let medBear = [];

    let smallBull = [];
    let smallBear = [];
    largeBull = large?.trending_up || [];
    largeBear = large?.trending_down || [];

    medBull = medium?.trending_up || [];
    medBear = medium?.trending_down || [];

    smallBull = small?.trending_up || [];
    smallBear = small?.trending_down || [];

    return { largeBull, largeBear, medBull, medBear, smallBull, smallBear };
  }, [large.loading,medium.loading,small.loading]);
  return (
    <div className="container">
      <Helmet>
        <title>Movement | TradeAlgo</title>
      </Helmet>
      {large.loading ? (
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
        </div>
      ) : (
        <MovementBlocks
          bullishFlow={flows.largeBull}
          bearishFlow={flows.largeBear}
          type="Large"
        />
      )}

      {medium.loading ? (
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
        </div>
      ) : (
        <MovementBlocks
          bullishFlow={flows.medBull}
          bearishFlow={flows.medBear}
          type={"Medium"}
        />
      )}
      {small.loading ? (
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <CardSkeleton width="97%" />
          </div>
        </div>
      ) : (
        <MovementBlocks
          bullishFlow={flows.smallBull}
          bearishFlow={flows.smallBear}
          type={"Small"}
        />
      )}
    </div>
  );
}

const stateToProps = (state) => ({
  darkflowCards: state.darkflow,
  loading: state.darkflow.small.loading,
});

const dispatchToProps = (dispatch) => ({
  getDarkFlow: (risk) => dispatch(darkflowLoad(risk)),
});

export default connect(stateToProps, dispatchToProps)(MovementContainer);
