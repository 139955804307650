import React, { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.scss";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.text + " !important",
  }
}));
const OptionsMentorshipSignUpResult = ({ userSettings, setUserSettings }) => {
  const hardHistory = useHistory();
  const { loading, hasOptionsMentorshipAdvanced } = useAuth()
  const goDashboard = () => {
    hardHistory.push('/home/Intraday/Auto/Up');
  }
  const classes = useStyles()
  useEffect(() => {
    if (!loading) {
      setUserSettings({
        ...userSettings,
        om_advanced_sign_up: true,
        // if they have the options mentoship flag already then its a renewal
        om_advanced_renewed: hasOptionsMentorshipAdvanced
      })
    }
  }, [loading])

  return (
    <div className="options-mentorship-sign-up-result">
      {
        !loading ? (
          <div>
            <div className="options-mentorship-sign-up-result-icon-wrapper">
              <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_978_14747)">
                  <path d="M99.998 0C44.7705 0 0 44.7705 0 99.998C0 155.226 44.7705 199.996 99.998 199.996C155.226 199.996 199.996 155.226 199.996 99.998C199.996 44.7705 155.226 0 99.998 0ZM99.998 187.46C51.695 187.46 12.5359 148.301 12.5359 99.998C12.5359 51.695 51.695 12.5398 99.998 12.5398C148.301 12.5398 187.46 51.6989 187.46 100.002C187.46 148.305 148.301 187.464 99.998 187.464V187.46Z" fill="url(#paint0_linear_978_14747)" />
                  <path d="M151.729 61.281C149.525 59.0693 147.239 56.9357 145.136 54.634C144.105 53.5086 143.331 53.6962 142.444 54.5871C137.407 59.6554 132.382 64.7354 127.356 69.8115C125.746 71.2691 124.261 72.8634 122.714 74.3874C112.999 83.9535 103.39 93.629 93.75 103.269C85.8525 111.163 77.9277 119.029 70.0889 126.977C68.8501 128.232 68.1663 128.13 66.9901 126.93C60.6088 120.428 54.1376 114.012 47.7173 107.544C47.0569 106.88 46.6857 106.497 45.7361 107.341C43.2664 109.549 40.9257 111.858 38.7374 114.344C37.991 115.192 38.163 115.536 38.7687 116.352C39.7925 117.724 41.0312 118.853 42.227 120.029C47.1429 124.848 51.8907 129.83 56.9395 134.523C59.0027 136.442 60.7964 138.72 62.8674 140.666C66.7165 144.284 69.6317 145.215 73.43 141.463C92.1791 122.937 110.745 104.223 129.365 85.5635C134.187 80.7296 138.978 75.8606 143.78 71.0073C146.512 68.3227 149.212 65.6107 151.99 62.973C152.858 62.1524 152.217 61.7695 151.732 61.2849L151.729 61.281Z" fill="url(#paint1_linear_978_14747)" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_978_14747" x1="199.996" y1="-0.00104484" x2="-1.76509" y2="1.79575" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5EEECD" />
                    <stop offset="1" stopColor="#5EEECD" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_978_14747" x1="152.418" y1="53.853" x2="37.2411" y2="55.1541" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5EEECD" />
                    <stop offset="1" stopColor="#5EEECD" />
                  </linearGradient>
                  <clipPath id="clip0_978_14747">
                    <rect width="200" height="200" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="options-mentorship-sign-up-result-success-title">
              Thank You For Signing Up!
            </div>

            <div className="options-mentorship-sign-up-result-success-info">
              This is your official confirmation.<br />
              Thanks for joining the <b>Options Mentorship Advanced.</b> Check back in the next 12<br /> hours to access the features fully.
            </div>
            <Link to="home/Intraday/Auto/Up"><Button className={clsx(classes.text, "back-to-dashboard-om")} onClick={goDashboard.bind(this)}>Back to Dashboard</Button></Link>
          </div>
        ) : (
          <div>
            <div className="options-mentorship-sign-up-result-icon-wrapper">
              <CircularProgress size={200} color="success" />
            </div>
            <div className="options-mentorship-sign-up-result-loading-text">
              You're one step closer to trading success! We're processing your account creation.
            </div>
          </div>
        )}
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(OptionsMentorshipSignUpResult);
