import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import "./plans.scss";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { connect } from "react-redux";
import { useAuth } from "../../../contexts/Auth";
import { CircularProgress } from "@mui/material";

const StarterPlan = ({ userSettings }) => {
  const { hasOptionsMentorshipStarter, loading } = useAuth()
  const submit = () => {
    window.location.href = "https://checkout.tradealgo.com/OTesZx3zJo37D_dpZFHJJgR9S3nfmtdqlU9lUKmI6-c_-n30q4hG3f-1Pcj3KS8YlbiVU431pAXdbtlHGlvw8Jkbdj3AG3SC1ep62Ai2MvlGtc4CMk2Z7g8="
  }
  const processing = userSettings.om_starter_sign_up || !!userSettings?.om_starter_renewed || hasOptionsMentorshipStarter;
  return (
    <div className="subscribe-plan-item starter-item">
      <div className="subscribe-plan-item-title">
        TradeAlgo Options<br />Mentorship: <span className="subscribe-plan-item-type">Starter</span>
      </div>
      <div className="subscribe-plan-item-includes-title">
        Includes
      </div>
      <ul className="subscribe-plan-item-includes-entries">
        <li className="subscribe-plan-item-includes-entry">3 sessions over 3 months</li>
        <li className="subscribe-plan-item-includes-entry">
          Options trading pro-led personalized coaching
        </li>
      </ul>
      <div className="subscribe-plan-item-price"><span className="subscribe-plan-item-small-text">USD</span> $749.50</div>
      <Button sx={{ width: processing ? '215px !important' : '' }} disabled={processing || loading} className="subscribe-plan-item-button" onClick={submit.bind(this)}>{loading ? <CircularProgress color="success" size={24} /> : processing ? "Payment Processing" : "Sign Me Up"}</Button>
    </div>
  )
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(StarterPlan);
