import React, { useMemo } from 'react';
import { Select as SelectUnstyled } from '@mui/base';
import { Option as OptionUnstyled } from '@mui/base';
import { Popper as PopperUnstyled } from '@mui/base';
import { styled } from '@mui/system';
import Chip from "@mui/material/Chip";

const labelLookup = {
  "1": "Above Ask",
  "2": "At Ask",
  "3": "Lean Ask",
  "4": "Below Bid",
  "5": "At Bid",
  "6": "Lean Bid",
  "7": "In Between"
}
const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function BidAskSelect({ value, setValue, themeMode }) {
  const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: themeMode === "dark" ? "#230437" : "#003A75"
  };

  const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: 'white',
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: themeMode === "light" ? "#003A75" : '#230437 !important',
    900: "#1A2027",
    1000: `${themeMode === "light" ? "#F1F5F9" : "rgba(16, 9, 22, 1)"} !important`,
    1100: `${themeMode === "light" ? "black" : "white"} !important`,
    1200: `${themeMode === "light" ? "rgb(241, 241, 241)" : "rgba(177, 177, 177, 0.71)"} !important`
  };
  const StyledButton = styled('button')(
    ({ theme }) => `
    background: ${grey[1200]};
    color: ${grey[1100]};
    font-family: IBM Plex Sans,sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    border: transparent;
    min-height: calc(1.5em + 22px);
    height: 48px;
    min-width: 251px;
    border-radius: 16px;
    left: -7px;
    margin: 0.5em;
    padding: 13px;
    position: relative;
    text-align: left;
    top: 10px;
    line-height: 1.5;

  
    &::after {
      font-size: 25px;
      content: '▾';
      float: right;
      position: relative;
        top: -6px;
    }
    &.Mui-expanded{
      &::after {
        content: '▴';
        font-size: 25px;
        position: relative;
        top: -6px;
      }
    }
    `,
  );
  const StyledListbox = styled('ul')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 194px;
    background: ${grey[1000]};
    border-radius: 24px;
    color: ${grey[1100]};
    overflow: auto;
    outline: 0px;
    `
  );

  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: pointer;
  
    &:last-of-type {
      border-bottom: none;
    }
    &.Mui-selected {
      background-color: ${blue[900]};
      color: ${blue[100]};
    }
    &.MuiOption-root:hover{
      background-color: ${grey[800]};
      color: ${grey[300]};
    }
    `
  );
  const CustomMultiSelect = React.forwardRef(function CustomMultiSelect(props, ref) {
    const slots = {
      root: StyledButton,
      listbox: StyledListbox,
      popper: StyledPopper,
      ...props.slots,
    };

    return <SelectUnstyled multiple {...props} ref={ref} slots={slots} />;
  });
  const Component = useMemo(() => {
    return (
      <div>
        <CustomMultiSelect renderValue={() => "Fill Filter"} value={value} onChange={(e, v) => {
          setValue(v)
        }} >
          <StyledOption value={'1'}>Above Ask</StyledOption>
          <StyledOption value={'2'}>At Ask</StyledOption>
          <StyledOption value={'3'}>Lean Ask</StyledOption>
          <StyledOption value={'4'}>Below Bid</StyledOption>
          <StyledOption value={'5'}>At Bid</StyledOption>
          <StyledOption value={'6'}>Lean Bid</StyledOption>
          <StyledOption value={'7'}>In Between</StyledOption>
        </CustomMultiSelect>
        <div style={{ marginTop: '15px', maxWidth: '220px' }}>
          {!!value.length && value.map((d, i) => {
            return (
              <Chip
                style={{
                  margin: 2,
                  background: "rgb(127 248 205)",
                  color: "black",
                }}
                deleteIcon={
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.00195312"
                      y="0.45459"
                      width="24"
                      height="24"
                      rx="12"
                      fill="white"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M16.002 8.45459L8.00195 16.4546"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.00195 8.45459L16.002 16.4546"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                label={labelLookup[d]}
                onDelete={() => {
                  let clone = [...value];
                  let data = clone.filter((x) => x !== d);
                  setValue(data);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }, [themeMode, value])
  return Component
}

export default BidAskSelect