export const metaData = [
    {
        "uri": "/videos/640548603",
        "name": "TradeAlgo Orientation and First steps.",
        "description": "TradeAlgo orientation and first steps, welcome to the winning team.",
        "type": "video",
        "link": "https://vimeo.com/640548603",
        "player_embed_url": "https://player.vimeo.com/video/640548603?h=19ea6076d5",
        "duration": 463,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/796024965?h=b712ac2136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Basic Indicators- An intro to some of the most commonly traded indicators.mp4\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2023-02-05T22:59:16+00:00",
        "modified_time": "2022-02-09T13:50:06+00:00",
        "release_time": "2021-10-29T22:59:16+00:00",
        "content_rating": [
            "safe"
        ],
        "content_rating_class": "safe",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "anybody"
        },
        "pictures": {
            "uri": "/videos/640548603/pictures/1288784886",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "c7034f237c1dd1088244604b08f179f3c0493ae7",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 617
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/640548603/comments",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/640548603/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/640548603/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/640548603/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/640548603/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=90",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/640548603/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/640548603/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/640548603/versions/572069385",
                    "resource_key": "af1d40904a2fc66c2dd94e7f134f7a26f848282d"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/640548603/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/640548603",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "fa8d00c76436c5eb094ac6d0fc37211bbb7c42d5",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/640548603",
        "name": "TradeAlgo Platform walkthrough",
        "description": "This presentation will go over how to use the basics of the tradealgo platform.",
        "type": "video",
        "link": "https://vimeo.com/640548603",
        "player_embed_url": "https://player.vimeo.com/video/640548603?h=19ea6076d5",
        "duration": 3011,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/666843737?h=b712ac2136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Basic Indicators- An intro to some of the most commonly traded indicators.mp4\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-29T22:59:16+00:00",
        "modified_time": "2022-02-09T13:50:06+00:00",
        "release_time": "2021-10-29T22:59:16+00:00",
        "content_rating": [
            "safe"
        ],
        "content_rating_class": "safe",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "anybody"
        },
        "pictures": {
            "uri": "/videos/640548603/pictures/1288784886",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "c7034f237c1dd1088244604b08f179f3c0493ae7",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 617
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/640548603/comments",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/640548603/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/640548603/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/640548603/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/640548603/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=90",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/640548603/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/640548603/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/640548603/versions/572069385",
                    "resource_key": "af1d40904a2fc66c2dd94e7f134f7a26f848282d"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/640548603/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/640548603",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "fa8d00c76436c5eb094ac6d0fc37211bbb7c42d5",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/640548603",
        "name": "Basic Indicators- An intro to some of the most commonly traded indicators.mp4",
        "description": "This presentation will go over some of the most commonly used indicators that many professional traders will all utilize.",
        "type": "video",
        "link": "https://vimeo.com/640548603",
        "player_embed_url": "https://player.vimeo.com/video/640548603?h=19ea6076d5",
        "duration": 840,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/640548603?h=19ea6076d5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Basic Indicators- An intro to some of the most commonly traded indicators.mp4\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-29T22:59:16+00:00",
        "modified_time": "2022-02-09T13:50:06+00:00",
        "release_time": "2021-10-29T22:59:16+00:00",
        "content_rating": [
            "safe"
        ],
        "content_rating_class": "safe",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "anybody"
        },
        "pictures": {
            "uri": "/videos/640548603/pictures/1288784886",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "c7034f237c1dd1088244604b08f179f3c0493ae7",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 617
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/640548603/comments",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/640548603/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/640548603/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/640548603/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/640548603/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=90",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/640548603/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/640548603/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/640548603/versions/572069385",
                    "resource_key": "af1d40904a2fc66c2dd94e7f134f7a26f848282d"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/640548603",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/640548603/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/640548603",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "fa8d00c76436c5eb094ac6d0fc37211bbb7c42d5",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/639313435",
        "name": "Trade Management- How to position yourself for success.mp4",
        "description": "This presentation will go over the basics of what trade management is as well as some basic strategies on how to mitigate risks.",
        "type": "video",
        "link": "https://vimeo.com/639313435",
        "player_embed_url": "https://player.vimeo.com/video/639313435?h=61cd5a3d6b",
        "duration": 1039,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/639313435?h=61cd5a3d6b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Trade Management- How to position yourself for success.mp4\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-26T22:45:03+00:00",
        "modified_time": "2022-02-09T13:23:51+00:00",
        "release_time": "2021-10-26T22:45:03+00:00",
        "content_rating": [
            "safe"
        ],
        "content_rating_class": "safe",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": true,
            "add": true,
            "comments": "anybody"
        },
        "pictures": {
            "uri": "/videos/639313435/pictures/1285561122",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1285561122-cca9b52e1c822c92203576377f45ba922e7306b6106641780_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "d390dcd4667df46181fc27fce49ea32e41b94940",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 408
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/639313435/comments",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/639313435/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/639313435/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/639313435/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/639313435/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=91",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/639313435/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/639313435/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/639313435/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/639313435/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/639313435/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/639313435/versions/570607380",
                    "resource_key": "31cefa7dfc44b7b78ff9305762d7c77f9f3c822f"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/639313435",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/639313435",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/639313435/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/639313435",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "092d618d9e716f63f0b9d8e6208b0cc517ccfd79",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/638873928",
        "name": "Shorting Intro-short-selling and some basics on playing the downside.",
        "description": "This video is a short intro on what short-selling is and some basics on how it can serve you as a trader in a bear market.",
        "type": "video",
        "link": "https://vimeo.com/638873928",
        "player_embed_url": "https://player.vimeo.com/video/638873928?h=f6b269f8de",
        "duration": 513,
        "width": 1920,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/638873928?h=f6b269f8de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Shorting Intro-short-selling and some basics on playing the downside.\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-25T23:57:04+00:00",
        "modified_time": "2022-02-09T12:45:47+00:00",
        "release_time": "2021-10-25T23:57:04+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": true,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/638873928/pictures/1284582171",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284582171-f94d8eed76b128671d21a656fd913c8e95b53b1613aee6f9c_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "da89aba0abce084f7b2e471571120202fece8625",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 275
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/638873928/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/638873928/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/638873928/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/638873928/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/638873928/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=92",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/638873928/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/638873928/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/638873928/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/638873928/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/638873928/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/638873928/versions/570086951",
                    "resource_key": "db08f8f7a3b6f9068b5dabdd1f5ad699b7a87167"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/638873928",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/638873928",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/638873928/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/638873928",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "299b2258f038145a396adf9985910ecc35bb5464",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/638764822",
        "name": "Trading Basics - Some basic rules of thumb and general game plans",
        "description": "This video is a walkthrough on how our users can go about utilizing the platform every morning and some practical advice on what to expect and look for.",
        "type": "video",
        "link": "https://vimeo.com/638764822",
        "player_embed_url": "https://player.vimeo.com/video/638764822?h=5b42337ef8",
        "duration": 636,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/638764822?h=5b42337ef8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Trading Basics - Some basic rules of thumb and general game plans\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-25T17:50:02+00:00",
        "modified_time": "2022-02-09T12:25:52+00:00",
        "release_time": "2021-10-25T17:50:02+00:00",
        "content_rating": [
            "safe"
        ],
        "content_rating_class": "safe",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": true,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/638764822/pictures/1284323308",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284323308-fc8f9b56844092ad718fef589c7add898fa3c574f1ae6b7ca_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "9063efc14b4272c23890895631add7a0ee92348e",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 398
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/638764822/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/638764822/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/638764822/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 1
                },
                "pictures": {
                    "uri": "/videos/638764822/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/638764822/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=93",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/638764822/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/638764822/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/638764822/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/638764822/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/638764822/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/638764822/versions/569953643",
                    "resource_key": "f06a487b053ff62b2bdbb6ce6f0683d5e8ddadd2"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/638764822",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/638764822",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/638764822/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/638764822",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "1ee50b136b525c3734c9a37459b497604a7452fb",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/638726205",
        "name": "ATS Introduction-A transparent look into secretive trading systems",
        "description": "This video is a presentation on what exactly the ATS exchanges are and what they will mean to you as a trader with access to that data.",
        "type": "video",
        "link": "https://vimeo.com/638726205",
        "player_embed_url": "https://player.vimeo.com/video/638726205?h=b65e26bb98",
        "duration": 754,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/638726205?h=b65e26bb98&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"ATS Introduction-A transparent look into secretive trading systems\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-25T16:05:03+00:00",
        "modified_time": "2022-02-09T14:22:12+00:00",
        "release_time": "2021-10-25T16:05:03+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": true,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/638726205/pictures/1284242257",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284242257-4c61de91a6b36444dc91a851cdd8c597c81f0fa779667ee59_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "60b053dc2b326ae88e3d0b037cd4e8f0b0c75a92",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 353
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/638726205/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/638726205/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/638726205/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/638726205/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/638726205/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=94",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/638726205/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/638726205/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/638726205/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/638726205/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/638726205/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/638726205/versions/569907313",
                    "resource_key": "c0c35949ca6b18d9515f2704babd75bdbbe6c5ff"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/638726205",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/638726205",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/638726205/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/638726205",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "01cfa4a7bf360aff09cce339720a62ed7fcce4c9",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/638726004",
        "name": "Time Frame Analysis- How to recontextualize your trades with different time periods",
        "description": "This video goes in-depth on how you as a trader can take advantage of different points of view when considering a potential trade or investment.",
        "type": "video",
        "link": "https://vimeo.com/638726004",
        "player_embed_url": "https://player.vimeo.com/video/638726004?h=cebe2a1264",
        "duration": 788,
        "width": 2560,
        "language": null,
        "height": 1440,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/638726004?h=cebe2a1264&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1440\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Time Frame Analysis- How to recontextualize your trades with different time periods\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-25T16:04:36+00:00",
        "modified_time": "2022-02-08T21:04:12+00:00",
        "release_time": "2021-10-25T16:04:36+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": true,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/638726004/pictures/1284241639",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284241639-c04d4ec364f236e4888337bea1d3493a07954d74ad11108f1_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "56c9fecd4488ac49b39ae19bddf9fdc134131354",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 252
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/638726004/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/638726004/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/638726004/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/638726004/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/638726004/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=95",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/638726004/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/638726004/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/638726004/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/638726004/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/638726004/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/638726004/versions/569907063",
                    "resource_key": "ea667a5166485b69ea2a6a037ed381076427afd4"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/638726004",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/638726004",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/638726004/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/638726004",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "b3a73efd21d53dad0483175c2a40fc743a7862ff",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/638722740",
        "name": "Fibonacci Retracements - Statistics-driven approach to trading",
        "description": "This video is a beginner's practical look into the Fibonacci Retracements system as well as a look into how to utilize them on our system.",
        "type": "video",
        "link": "https://vimeo.com/638722740",
        "player_embed_url": "https://player.vimeo.com/video/638722740?h=5a4114b37d",
        "duration": 662,
        "width": 1920,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/638722740?h=5a4114b37d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"1920\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Fibonacci Retracements - Statistics-driven approach to trading\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2021-10-25T15:56:40+00:00",
        "modified_time": "2022-02-08T03:36:57+00:00",
        "release_time": "2021-10-25T15:56:40+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": true,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/638722740/pictures/1284325474",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1284325474-1c6b695f9c80cf6cc67b95873b94061f69b49bd69896da3ae_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "2663d20b727817330e4755277d83882cd3456cfd",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 203
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/638722740/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/638722740/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/638722740/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/638722740/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/638722740/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": {
                    "uri": "/users/154954846/videos?per_page=100&page=2&offset=96",
                    "options": [
                        "GET"
                    ]
                },
                "recommendations": {
                    "uri": "/videos/638722740/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "albums": {
                    "uri": "/videos/638722740/albums",
                    "options": [
                        "GET",
                        "PATCH"
                    ],
                    "total": 0
                },
                "available_albums": {
                    "uri": "/videos/638722740/available_albums",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "available_channels": {
                    "uri": "/videos/638722740/available_channels",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "versions": {
                    "uri": "/videos/638722740/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/638722740/versions/569903131",
                    "resource_key": "a80390379ea0872468f185b2116792196f8e7889"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/638722740",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/638722740",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/638722740/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/638722740",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "4a081cbd3d670704dde71e5cc8290905ab42f46a",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/666843737",
        "name": "TradeAlgo Platform Walkthrough",
        "description": "This video is a walkthrough tutorial that covers all the features and functions of the TradeAlgo platform including our state of the art dark pool alert algorithm.",
        "type": "video",
        "link": "https://vimeo.com/666843737",
        "player_embed_url": "https://player.vimeo.com/video/666843737?h=b712ac2136",
        "duration": 3011,
        "width": 2560,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/666843737?h=b712ac2136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"TradeAlgo Platform Walkthrough\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2022-01-17T16:50:37+00:00",
        "modified_time": "2022-01-27T21:25:55+00:00",
        "release_time": "2022-01-17T16:50:37+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/666843737/pictures/1350273913",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1350273913-5b592560b4cecb83a0697131855d465fd918a66b131842a817fddb7720aa6c91-d_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "c6c741c36407c2447f499872d7e340c89a6ee5da",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 27
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/666843737/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/666843737/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/666843737/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/666843737/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/666843737/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": null,
                "recommendations": {
                    "uri": "/videos/666843737/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/666843737/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/666843737/versions/605970913",
                    "resource_key": "833b9be9549158e7fdffbec031616692540170b3"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/666843737",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/666843737",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/666843737/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/666843737",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 10
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 176
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "6a4e054bf41259bf2baf2851400c8221ff812350",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/669024896",
        "name": "Introduction to Option Basics",
        "description": "This video tutorial is an introductory course on Option Basics that covers topics such as what makes up an option, basic long/short call/put strategies, ITM/OTM/ATM differences.",
        "type": "video",
        "link": "https://vimeo.com/669024896",
        "player_embed_url": "https://player.vimeo.com/video/669024896?h=681ca589bc",
        "duration": 2076,
        "width": 2560,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/669024896?h=681ca589bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Introduction to Option Basics\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2022-01-23T00:20:41+00:00",
        "modified_time": "2022-01-26T22:22:57+00:00",
        "release_time": "2022-01-23T00:20:41+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/669024896/pictures/1354962123",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1354962123-bdce64e3615201d91195d1201cd375f42249c5147b383e3e30ab5dac56e2d3d6-d_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "dd3fc182d64eea8b05f1db5f0eb4982328dabe00",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 9
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/669024896/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/669024896/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/669024896/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/669024896/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/669024896/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": null,
                "recommendations": {
                    "uri": "/videos/669024896/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/669024896/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/669024896/versions/608763158",
                    "resource_key": "9ffaf02f3e1bb60091eaaa295dbb1af16238c1ca"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/669024896",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/669024896",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/669024896/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/669024896",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 10
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 176
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "405d3386673fcc0ea980d73d2ae463d085ebe228",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/675276260",
        "name": "Trade Algo Onboarding Crash Course- Terminology",
        "description": "This video course covers a full list of general trading terminology, acronyms and jargon that are used in the TradeAlgo community.",
        "type": "video",
        "link": "https://vimeo.com/675276260",
        "player_embed_url": "https://player.vimeo.com/video/675276260?h=ccd84a38e1",
        "duration": 1149,
        "width": 2560,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/675276260?h=ccd84a38e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Trade Algo Onboarding Crash Course- Terminology\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2022-02-09T01:31:46+00:00",
        "modified_time": "2022-02-09T15:05:03+00:00",
        "release_time": "2022-02-09T01:31:46+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/675276260/pictures/1368400505",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1368400505-c19d9e8385eafe888d62b7a52ec0b946692a1a2a4dfaf3d52dcc64e5889fd2fd-d_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "aaefe1288b832d8d25cb91dffd73c5a98ec013ce",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 2
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/675276260/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/675276260/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/675276260/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/675276260/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/675276260/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": null,
                "recommendations": {
                    "uri": "/videos/675276260/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/675276260/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 6,
                    "current_uri": "/videos/675276260/versions/616727694",
                    "resource_key": "442c54b78b2c9100bb4659f1a012b7f6a258bf41"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/675276260",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/675276260",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/675276260/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/675276260",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 11
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 197
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "461c36fe97a7ff74887c8bdeb1345e18a6525ba4",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/685289776",
        "name": "Trading Strategy- Measured Moves",
        "description": "In this video lesson we go over a very simple yet effective strategy- measured moves. We talk about 3 different types of measured moves and how to use them effectively to find price targets.",
        "type": "video",
        "link": "https://vimeo.com/685289776",
        "player_embed_url": "https://player.vimeo.com/video/685289776?h=841e3da622",
        "duration": 2344,
        "width": 2560,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/685289776?h=841e3da622&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Trading Strategy- Measured Moves\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2022-03-07T01:34:23+00:00",
        "modified_time": "2022-03-07T01:39:28+00:00",
        "release_time": "2022-03-07T01:34:23+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/685289776/pictures/1388865923",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1388865923-821139464f038d3aeca4e8839bd8b93c9d0722e8974d0110458ca87296c920c0-d_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "796c07caa573e42fef25ba5c9e7aae19452d575f",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 0
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/685289776/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/685289776/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/685289776/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/685289776/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/685289776/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": null,
                "recommendations": {
                    "uri": "/videos/685289776/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/685289776/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/685289776/versions/629806957",
                    "resource_key": "ba6e8cafc79026068f356aee31dd47d7c8e5841d"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/685289776",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/685289776",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/685289776/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/685289776",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 19
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 236
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "4b0b49b35f49b75171da7211d7fe76700a624cae",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    },
    {
        "uri": "/videos/682339494",
        "name": "Onboarding Crash Course- Chart Setup and Indicators",
        "description": "In this video we go over the chart setup and indicators that are used in our active trading. We briefly discuss some of the most popular indicators such as the SMA, RSI, Bollinger Bands, etc and go over some use cases.",
        "type": "video",
        "link": "https://vimeo.com/682339494",
        "player_embed_url": "https://player.vimeo.com/video/682339494?h=c338aec014",
        "duration": 1739,
        "width": 2560,
        "language": null,
        "height": 1080,
        "embed": {
            "html": "<iframe src=\"https://player.vimeo.com/video/682339494?h=c338aec014&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=229442\" width=\"2560\" height=\"1080\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\"Onboarding Crash Course- Chart Setup and Indicators\"></iframe>",
            "badges": {
                "hdr": false,
                "live": {
                    "streaming": false,
                    "archived": false
                },
                "staff_pick": {
                    "normal": false,
                    "best_of_the_month": false,
                    "best_of_the_year": false,
                    "premiere": false
                },
                "vod": false,
                "weekend_challenge": false
            }
        },
        "created_time": "2022-02-26T22:35:06+00:00",
        "modified_time": "2022-03-01T23:03:50+00:00",
        "release_time": "2022-02-26T22:35:06+00:00",
        "content_rating": [
            "unrated"
        ],
        "content_rating_class": "unrated",
        "rating_mod_locked": false,
        "license": null,
        "privacy": {
            "view": "anybody",
            "embed": "whitelist",
            "download": false,
            "add": false,
            "comments": "nobody"
        },
        "pictures": {
            "uri": "/videos/682339494/pictures/1383134756",
            "active": true,
            "type": "custom",
            "base_link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d",
            "sizes": [
                {
                    "width": 100,
                    "height": 75,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_100x75?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_100x75&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 200,
                    "height": 150,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_200x150?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_200x150&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 295,
                    "height": 166,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_295x166?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_295x166&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 640,
                    "height": 360,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_640x360?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1288784886-1ffaba418ba7c302023178411eed90ef7cadf68ee9a3142f4_640x360&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 960,
                    "height": 540,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_960x540?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_960x540&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1280,
                    "height": 720,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_1280x720?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_1280x720&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                },
                {
                    "width": 1920,
                    "height": 1080,
                    "link": "https://i.vimeocdn.com/video/1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_1920x1080?r=pad",
                    "link_with_play_button": "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1383134756-7514f613ee840fcb1d19891f510cd86c010a8d3934296d66a727b8186c92f5a4-d_1920x1080&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                }
            ],
            "resource_key": "3f83d9a2e616fe0a2103a8d5bdde3d7d0c1b19da",
            "default_picture": false
        },
        "tags": [],
        "stats": {
            "plays": 1
        },
        "categories": [],
        "uploader": {
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            }
        },
        "metadata": {
            "connections": {
                "comments": {
                    "uri": "/videos/682339494/comments",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "credits": {
                    "uri": "/videos/682339494/credits",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "likes": {
                    "uri": "/videos/682339494/likes",
                    "options": [
                        "GET"
                    ],
                    "total": 0
                },
                "pictures": {
                    "uri": "/videos/682339494/pictures",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 1
                },
                "texttracks": {
                    "uri": "/videos/682339494/texttracks",
                    "options": [
                        "GET",
                        "POST"
                    ],
                    "total": 0
                },
                "related": null,
                "recommendations": {
                    "uri": "/videos/682339494/recommendations",
                    "options": [
                        "GET"
                    ]
                },
                "versions": {
                    "uri": "/videos/682339494/versions",
                    "options": [
                        "GET"
                    ],
                    "total": 1,
                    "current_uri": "/videos/682339494/versions/625935457",
                    "resource_key": "90aa723191a499cee34fa96d59b94e1c46b10700"
                }
            },
            "interactions": {
                "watchlater": {
                    "uri": "/users/152244127/watchlater/682339494",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "like": {
                    "uri": "/users/152244127/likes/682339494",
                    "options": [
                        "GET",
                        "PUT",
                        "DELETE"
                    ],
                    "added": false,
                    "added_time": null
                },
                "report": {
                    "uri": "/videos/682339494/report",
                    "options": [
                        "POST"
                    ],
                    "reason": [
                        "pornographic",
                        "harassment",
                        "ripoff",
                        "incorrect rating",
                        "spam",
                        "causes harm",
                        "csam"
                    ]
                },
                "can_update_privacy_to_public": {
                    "uri": "/videos/682339494",
                    "options": [
                        "PATCH"
                    ]
                }
            },
            "is_vimeo_create": false,
            "is_screen_record": false
        },
        "user": {
            "uri": "/users/154954846",
            "name": "TradeAlgo",
            "link": "https://vimeo.com/user154954846",
            "capabilities": {
                "hasLiveSubscription": true,
                "hasEnterpriseLihp": false,
                "hasSvvTimecodedComments": false
            },
            "location": "New York, NY, USA",
            "gender": "",
            "bio": null,
            "short_bio": null,
            "created_time": "2021-10-19T19:51:09+00:00",
            "pictures": {
                "uri": "/users/154954846/pictures/60130157",
                "active": true,
                "type": "custom",
                "base_link": "https://i.vimeocdn.com/portrait/60130157",
                "sizes": [
                    {
                        "width": 30,
                        "height": 30,
                        "link": "https://i.vimeocdn.com/portrait/60130157_30x30"
                    },
                    {
                        "width": 72,
                        "height": 72,
                        "link": "https://i.vimeocdn.com/portrait/60130157_72x72"
                    },
                    {
                        "width": 75,
                        "height": 75,
                        "link": "https://i.vimeocdn.com/portrait/60130157_75x75"
                    },
                    {
                        "width": 100,
                        "height": 100,
                        "link": "https://i.vimeocdn.com/portrait/60130157_100x100"
                    },
                    {
                        "width": 144,
                        "height": 144,
                        "link": "https://i.vimeocdn.com/portrait/60130157_144x144"
                    },
                    {
                        "width": 216,
                        "height": 216,
                        "link": "https://i.vimeocdn.com/portrait/60130157_216x216"
                    },
                    {
                        "width": 288,
                        "height": 288,
                        "link": "https://i.vimeocdn.com/portrait/60130157_288x288"
                    },
                    {
                        "width": 300,
                        "height": 300,
                        "link": "https://i.vimeocdn.com/portrait/60130157_300x300"
                    },
                    {
                        "width": 360,
                        "height": 360,
                        "link": "https://i.vimeocdn.com/portrait/60130157_360x360"
                    }
                ],
                "resource_key": "cad58a91a9261117e048c10aac971927f4818e8f",
                "default_picture": false
            },
            "websites": [],
            "metadata": {
                "connections": {
                    "albums": {
                        "uri": "/users/154954846/albums",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "appearances": {
                        "uri": "/users/154954846/appearances",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "channels": {
                        "uri": "/users/154954846/channels",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "feed": {
                        "uri": "/users/154954846/feed",
                        "options": [
                            "GET"
                        ]
                    },
                    "followers": {
                        "uri": "/users/154954846/followers",
                        "options": [
                            "GET"
                        ],
                        "total": 19
                    },
                    "following": {
                        "uri": "/users/154954846/following",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "groups": {
                        "uri": "/users/154954846/groups",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "likes": {
                        "uri": "/users/154954846/likes",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "membership": {
                        "uri": "/users/154954846/membership/",
                        "options": [
                            "PATCH"
                        ]
                    },
                    "moderated_channels": {
                        "uri": "/users/154954846/channels?filter=moderated",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "portfolios": {
                        "uri": "/users/154954846/portfolios",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "videos": {
                        "uri": "/users/154954846/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 236
                    },
                    "shared": {
                        "uri": "/users/154954846/shared/videos",
                        "options": [
                            "GET"
                        ],
                        "total": 0
                    },
                    "pictures": {
                        "uri": "/users/154954846/pictures",
                        "options": [
                            "GET",
                            "POST"
                        ],
                        "total": 1
                    },
                    "folders_root": {
                        "uri": "/users/154954846/folders/root",
                        "options": [
                            "GET"
                        ]
                    },
                    "teams": {
                        "uri": "/users/154954846/teams",
                        "options": [
                            "GET"
                        ],
                        "total": 1
                    }
                },
                "interactions": {
                    "follow": {
                        "added": false,
                        "added_time": null,
                        "uri": "/users/152244127/following/154954846",
                        "options": [
                            "GET",
                            "PUT",
                            "DELETE"
                        ]
                    },
                    "block": {
                        "uri": "/me/block/154954846",
                        "options": [
                            "PUT",
                            "DELETE"
                        ],
                        "added": false,
                        "added_time": null
                    },
                    "report": {
                        "uri": "/users/154954846/report",
                        "options": [
                            "POST"
                        ],
                        "reason": [
                            "inappropriate avatar",
                            "spammy",
                            "bad videos",
                            "creepy",
                            "not playing nice",
                            "impersonation",
                            "inappropriate job post"
                        ]
                    }
                }
            },
            "location_details": {
                "formatted_address": "New York, NY, USA",
                "latitude": 40.7127762,
                "longitude": -74.0059738,
                "city": "New York",
                "state": "New York",
                "neighborhood": null,
                "sub_locality": null,
                "state_iso_code": "NY",
                "country": "United States",
                "country_iso_code": "US"
            },
            "skills": [],
            "available_for_hire": false,
            "can_work_remotely": false,
            "resource_key": "8f583869bd7d9c2b238ddee05a7c8fcc2b734bec",
            "account": "live_premium"
        },
        "app": {
            "name": "Parallel Uploader",
            "uri": "/apps/87099"
        },
        "status": "available",
        "resource_key": "82c5146408f5a2fa61dc6bc3ece6bff6d3fef6bd",
        "upload": {
            "status": "complete",
            "link": null,
            "upload_link": null,
            "complete_uri": null,
            "form": null,
            "approach": null,
            "size": null,
            "redirect_url": null
        },
        "transcode": {
            "status": "complete"
        },
        "is_playable": true,
        "has_audio": true
    }
]